'use strict';

intellidealerAppCoreModule.filter('findIndex', function () {
  return function(baseArray, object) {

    return baseArray.reduce(function (value, currentObj, index){
      if (currentObj === object){
        return index;
      }
      return value;
    });

  };
});