'use strict';

intellidealerAppCoreModule.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/scripts/modules/core/views/templates/general-inquiry-popover.html',
                     '<div class="modal-body" style="width: 250px;"' +
                     '     data-ng-controller="GeneralInquiryController"' +
                     '     data-ng-form name="inquiryForm">' +
                     '  <div class="row">' +
                     '    <div class="col-xs-12">' +
                     '      <div class="form-group" data-ng-class="{\'has-error\': inquiryForm[\'department\'].$invalid}" data-ng-if="dealershipContactTypes.searchContactTypes.length > 1">' +
                     '        <label for="department" class="control-label">{{\'mydealer.ui.global.department\' | language}}</label>' +
                     '        <select class="form-control"' +
                     '                name="department"' +
                     '                data-ng-model="inquiry.department"' +
                     '                data-ng-options="(\'mydealer.ui.global.department.\' + contact | lowercase | language) for contact in dealershipContactTypes.searchContactTypes | orderBy: contact"' +
                     '                data-validation-popover' +
                     '                data-required>' +
                     '        </select>' +
                     '      </div>' +
                     '    </div>' +
                     '  </div>' +
                     '  <div class="row">' +
                     '    <div class="col-xs-12">' +
                     '      <div class="form-group" data-ng-class="{\'has-error\': inquiryForm[\'message\'].$invalid}">' +
                     '        <label for="message" class="control-label">{{\'mydealer.ui.global.inquiry\' | language}}</label>' +
                     '        <textarea class="form-control"' +
                     '                  style="height: 7em;"' +
                     '                  name="message"' +
                     '                  data-ng-model="inquiry.message"' +
                     '                  data-validation-popover' +
                     '                  data-required></textarea>' +
                     '      </div>' +
                     '    </div>' +
                     '  </div>' +
                     '  <div class="button-row-wrapper">' +
                     '    <div></div>' +
                     '    <div>' +
                     '      <button class="btn btn-info" data-ng-click="toggleGeneralInquiry(\'inquiryButton\')">{{\'mydealer.ui.global.cancel\' | language}}</button>' +
                     '      <button class="btn btn-primary" data-ng-click="sendInquiry()" data-ng-disabled="inquiryForm.$invalid">{{\'mydealer.ui.global.send\' | language}}</button>' +
                     '    </div>' +
                     '  </div>' +
                     '</div>');
}]);
