'use strict';

intellidealerAppCoreModule.controller('DashboardTopPartsWidgetController', function ($scope, $rootScope, $timeout, shoppingCartService) {

  $scope.topPartPurchases = $scope.slide.model;

  $scope.pageStart = 0;
  if ($scope.topPartPurchases.length < 5) {
    $scope.pageEnd = $scope.topPartPurchases.length;
  } else {
    $scope.pageEnd = 5;
  }

  $scope.prevParts = function () {
    if ($scope.pageStart >= 5) {
      $scope.pageStart -= 5;
      if ($scope.topPartPurchases.length < $scope.pageStart + 5) {
        $scope.pageEnd = $scope.topPartPurchases.length;
      } else {
        $scope.pageEnd = $scope.pageStart + 5;
      }
    }
  };

  $scope.nextParts = function () {
    if ($scope.pageEnd < $scope.topPartPurchases.length) {
      $scope.pageStart = $scope.pageEnd;
      if ($scope.topPartPurchases.length < $scope.pageEnd + 5) {
        $scope.pageEnd = $scope.topPartPurchases.length;
      } else {
        $scope.pageEnd += 5;
      }
    }
  };

  $scope.addItem = function (quantity, partNumber, event, partObject, index, searchResult, togglerId) {
    $rootScope.togglePopover(togglerId + index);
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }
    shoppingCartService.addItem(quantity, partNumber, partObject).then(() => $rootScope.togglePopover('#part' , index));
  };

  $scope.updateItem = function (partNumber, quantity, event, partObject, index, searchResult, togglerId) {
    $rootScope.togglePopover(togglerId + index);
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }
    shoppingCartService.updateItem(partNumber, quantity, partObject).then(() => $rootScope.togglePopover('#part' , index));
  };

  $scope.removeItem = function (partNumber, partObject, index, searchResult, togglerId) {
    $rootScope.togglePopover(togglerId + index);
    shoppingCartService.removeOneItem(partNumber, partObject).then(() => $rootScope.togglePopover('#part' , index));
  };
});
