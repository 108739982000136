'use strict';

intellidealerAppCoreModule.directive('filtersAccordion', [function() {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      toggle: '='
    },
    template: '<div class="filters-accordion"><div class="filters-accordion-inner"><div class="row" data-ng-transclude></div></div></div>',
    link: function (scope, element) {
      var filtersAccordion = element.find('.filters-accordion');
      scope.$watch('toggle', function(newValue) {
        if (newValue) {
          filtersAccordion[0].style.height = '';
        }
        else {
          filtersAccordion.height(0);
        }
      });
    }
  };
}]);
