'use strict';
intellidealerAppMyAccountModule.controller('InvoicesController', function ($scope, $state, $stateParams, accountSummary, invoices, appConfigurationService, invoiceHistoryResource,
                                                                           globalAlertService, invoicesFilters, locations, $uibModal, searchValues, search, myProfile, $location,
                                                                           $anchorScroll, $filter, userConfiguration, division1, twocpResource, $window, dealerDivisionSettings,
                                                                           achPaymentAgencies, paymentAgencies, sessionService, authorizeDotNetResource, authDotNetProperties) {

  var isTimeout;
  var timeOutId;
  var selectedInvoiceList;

  $scope.showLocation = division1.printLocationSuffix;
  $scope.dealerDivisionSettings = dealerDivisionSettings;
  $scope.achPaymentAgencies = achPaymentAgencies;
  $scope.paymentAgencies = paymentAgencies;
  $scope.searchedStatus = $stateParams.invoicesFilters ? angular.fromJson($stateParams.invoicesFilters).status : null;
  $scope.usesInHouseAR = userConfiguration.usesInHouseAR;
  $scope.payByCreditCard = !$scope.paymentAgencies.disableTwoCP;
  $scope.payByWorldPay = !!$scope.paymentAgencies.showWorldPay;
  $scope.payByAuthDotNet = !!(sessionService.getFeatures()['PFW.6286'] && authDotNetProperties.apiLoginId
    && authDotNetProperties.transactionKey
    && (userConfiguration.allowPaymentsOnAccount !== 'NONE' && userConfiguration.allowPaymentsOnAccount !== 'CREDIT_CARD_PAYMENT' && $scope.usesInHouseAR)
    && authDotNetProperties.locations.some(loc => {
      const beforeDash = loc.split(' - ')[0];
      return beforeDash === userConfiguration.contactId.company + '/' + userConfiguration.contactId.division+ '/' + userConfiguration.businessLocation;
    }));
  $scope.allowPayment = (userConfiguration.allowPaymentsOnAccount !== 'NONE' && $scope.usesInHouseAR)
    && (($scope.achPaymentAgencies.twoCPACHEnabled && $scope.payByCreditCard)
    || ($scope.achPaymentAgencies.twoCPACHEnabled && userConfiguration.allowPaymentsOnAccount === 'ACH_PAYMENT')
    || ($scope.payByCreditCard && userConfiguration.allowPaymentsOnAccount === 'CREDIT_CARD_PAYMENT')
    || (userConfiguration.allowPaymentsOnAccount === 'BOTH_PAYMENT_TYPES' && ($scope.achPaymentAgencies.twoCPACHEnabled || $scope.payByCreditCard)));
  $scope.myProfile = myProfile;


  if ($scope.searchedStatus === 'O' && !userConfiguration.usesInHouseAR) {
    $scope.searchedStatus = 'P';
  }

  //todo: for world pay
  /*  if (($scope.searchedStatus === 'P') ||
      ($scope.paymentAgencies.disableTwoCP && !$scope.paymentAgencies.showWorldPay && !$scope.achPaymentAgencies.twoCPACHEnabled)) {
      $scope.allowPaymentsOnAccount = false;
    } else {
      $scope.allowPaymentsOnAccount = userConfiguration.allowPaymentsOnAccount;
    }*/

  if (($scope.searchedStatus === 'P') ||
    ($scope.paymentAgencies.disableTwoCP && !$scope.achPaymentAgencies.twoCPACHEnabled && !$scope.payByAuthDotNet)) {
    $scope.allowPaymentsOnAccount = 'NONE';
  } else {
    $scope.allowPaymentsOnAccount = userConfiguration.allowPaymentsOnAccount;
  }

  //summary values
  $scope.accountSummary = accountSummary;
  $scope.accountSummary.overdue =
    ($scope.accountSummary.aged60 ? $scope.accountSummary.aged60 : 0) +
    ($scope.accountSummary.aged90 ? $scope.accountSummary.aged90 : 0) +
    ($scope.accountSummary.aged120 ? $scope.accountSummary.aged120 : 0) +
    ($scope.accountSummary.agedOver120 ? $scope.accountSummary.agedOver120 : 0);

  //invoice list values
  $scope.locations = locations;
  $scope.searchValues = searchValues;
  $scope.invoices = invoices;
  $scope.invoices.content.forEach(function (invoice) {

    if (invoice.appliedAmount && invoice.appliedAmount !== 0) {
      invoice.appliedAmountSelected = Math.abs(invoice.appliedAmount).toFixed(2);
    }
  });

  $scope.getLocation = function () {
    if (!achPaymentAgencies.customerLocation) {
      return dealerDivisionSettings.achBatchLocation;
    } else {
      return userConfiguration.businessLocation;
    }
  };

  $scope.irInvoiceUndefined = function (invoiceNumber) {
    return invoiceNumber === undefined;
  };

  $scope.updateSelected = function (invoice) {
    if ($scope.hasAmount(invoice.appliedAmountSelected)) {
      sessionStorage.setItem(invoice.transactionIdString + 'amt', invoice.appliedAmountSelected);
    } else {
      sessionStorage.removeItem(invoice.transactionIdString + 'amt');
    }
    invoice.selected = $scope.hasAmount(invoice.appliedAmountSelected);
  };

  $scope.paymentDisabled = function (invoice) {
    return $scope.hasAmount(invoice.appliedAmount) || invoice.outstandingAmount < 0;
  };

  $scope.hasAmount = function (amount) {
    return amount && amount !== .00 && amount !== 0.0 && amount !== 0.00;
  };

  $scope.email = {
    address: myProfile.businessEmailAddress
  };
  $scope.emailForm = {};
  $scope.filters = invoicesFilters || {};
  $scope.showFilters = !!(
    $scope.filters.type ||
    $scope.filters.location ||
    $scope.filters.minDate ||
    $scope.filters.maxDate ||
    $scope.filters.status
  );
  $scope.terms = {keywords: search};
  $scope.anySelected = false;
  $scope.taxSummary = {};


  $scope.sortChoices = [
    {
      label: $filter('language')('mydealer.ui.global.date'),
      property: 'billingDate',
      direction: 'asc',
      isHidden: false
    },
    {
      label: $filter('language')('mydealer.ui.invoices.invoice_number'),
      property: 'integratedRentalDetails.invoiceNumber,billingHistoryCashReceiptId.salesOrderNumber,invoiceNumber',
      direction: 'asc',
      isHidden: false
    },
    {
      label: $filter('language')('mydealer.ui.invoices.po_number'),
      isHidden: false,
      property: 'purchaseOrderNumber',
      direction: 'asc'
    },
    {
      label: $filter('language')('mydealer.ui.global.location'),
      property: $scope.searchedStatus === 'P' ? 'location' : 'billingHistoryCashReceiptId.location',
      direction: 'asc',
      isHidden: false
    },
    {
      label: $filter('language')('mydealer.ui.global.department'),
      property: 'invoiceType',
      direction: 'asc',
      isHidden: false
    },
    {
      label: $filter('language')('mydealer.ui.invoices.amount'),
      property: 'invoiceTotal',
      direction: 'asc',
      align: 'right',
      isHidden: false
    }
  ];
  if ($scope.searchedStatus === 'P') {
    $scope.sortChoices.splice(2, 0,
      {
        label: $filter('language')('mydealer.ui.invoices.contract_number'),
        property: 'contractNumber',
        isHidden: false,
        direction: 'asc'
      }
    );
    $scope.sortChoices.splice(6, 1);
  }
  if ($scope.searchedStatus === 'O') {
    $scope.sortChoices.push(
      {
        label: $filter('language')('mydealer.ui.invoices.outstanding'),
        property: 'outstandingAmount',
        direction: 'asc',
        align: 'right',
        isHidden: false
      }
    );
    if ($scope.allowPaymentsOnAccount !== 'NONE' && $scope.usesInHouseAR) {
      $scope.sortChoices.push({
        label: $filter('language')('mydealer.ui.invoices.applied'),
        align: 'left',
        isHidden: false
      });
    }
  }
  if ($scope.searchedStatus === 'P') {
    $scope.sortChoices.push(
      {
        label: $filter('language')('mydealer.ui.invoices.amount'),
        isHidden: false,
        align: 'right'
      }
    );
  }
  $scope.sortChoicesDefault = angular.copy($scope.sortChoices);
  $scope.sortChoices.push({});
  if (!sessionStorage.getItem("sortChoices")) {
    sessionStorage.setItem("sortChoices", JSON.stringify($scope.sortChoices))
  } else {
    $scope.sortChoices = JSON.parse(sessionStorage.getItem("sortChoices"));
  }

  $scope.columns = [];

  $scope.sortChoices.forEach(choice => {
    if (choice.label && choice.label != $filter('language')('mydealer.ui.invoices.applied')) {
      $scope.columns.push({label: choice.label, isSelected: true});
    }
  });
  if (!sessionStorage.getItem("columns")) {
    sessionStorage.setItem("columns", JSON.stringify($scope.columns))
  } else {
    $scope.columns = JSON.parse(sessionStorage.getItem("columns"));
  }
  $scope.toggleColumns = function (col) {
    let index = $scope.columns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.columns[index].isSelected = !$scope.columns[index].isSelected;
    sessionStorage.setItem("columns", JSON.stringify($scope.columns))
    let sortIndex = $scope.sortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if (sortIndex >= 0) {
      $scope.sortChoices[sortIndex].isHidden = !$scope.columns[index].isSelected;
      sessionStorage.setItem("sortChoices", JSON.stringify($scope.sortChoices))
    }

  }

  $scope.isColVisible = function (colName) {
    let index = $scope.columns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.columns[index].isSelected;
  }

  $scope.$watch(function () {
    var anySelected = false;
    $scope.invoices.content.forEach(function (current) {
      if (current.selected) {
        anySelected = true;
      }
    });
    Object.keys(sessionStorage).forEach(key => {
      if (key.includes("chk") && !key.includes("unchk"))
        anySelected = true;
    })
    return anySelected;
  }, function (newValue) {
    $scope.anySelected = newValue;
  });

  $scope.searchOutstanding = function () {
    $scope.filters.status = "O";
    $scope.filters.minDate = "";

    sessionStorage.clear();
    $scope.search();
  };

  var now = new Date();

  $scope.searchHistorical = function () {
    $scope.filters.status = "P";
    if (!$scope.filters.minDate) {
      var fromDate = new Date(now.getFullYear(), now.getMonth() - 3, 1)
      $scope.filters.minDate = fromDate;
    }

    sessionStorage.clear();
    $scope.search();
  };

  var paymentSubmitDialogInstance = null;

  function showPaymentSubmitDialog() {
    if (!paymentSubmitDialogInstance) {
      $uibModal.open({
        templateUrl: 'app/scripts/modules/my-account/views/templates/payment-submitting-prompt.html',
        scope: $scope,
        controller: function ($uibModalInstance) {
          paymentSubmitDialogInstance = $uibModalInstance;
        },
        size: 'md',
        animation: true,
        windowClass: 'center-modal',
        keyboard: false,
        backdrop: 'static'
      });
    }
  }

  function hidePaymentSubmitDialog() {
    if (paymentSubmitDialogInstance) {
      paymentSubmitDialogInstance.dismiss();
      paymentSubmitDialogInstance = null;
    }
  }

  $scope.invoices.content.forEach(invoice => {
    if (sessionStorage.getItem("selectAllToggle") === "true" && !sessionStorage.getItem(invoice.transactionIdString + 'unchk') && invoice.invoiceType.symbol != 'X')
      invoice.selected = true;
    else if (sessionStorage.getItem(invoice.transactionIdString + 'chk') && invoice.invoiceType.symbol != 'X')
      invoice.selected = true;
    if (sessionStorage.getItem(invoice.transactionIdString + 'amt'))
      invoice.appliedAmountSelected = sessionStorage.getItem(invoice.transactionIdString + 'amt');
  });
  $scope.isSelectedCheckbox = function (event, invoice) {
    if (invoice.selected) {
      sessionStorage.removeItem(invoice.transactionIdString + 'chk');
      sessionStorage.setItem(invoice.transactionIdString + 'unchk', 'true');
    } else {
      sessionStorage.setItem(invoice.transactionIdString + 'chk', 'true');
      sessionStorage.removeItem(invoice.transactionIdString + 'unchk');
    }
  }

  function getSortParams(sortParams) {
    if (sortParams) {
      if (sortParams.sort === 'location' || sortParams.sort === 'billingHistoryCashReceiptId.location') {
        sortParams.sort = $scope.filters.status === 'O' && userConfiguration.usesInHouseAR ? 'billingHistoryCashReceiptId.location' : 'location';
      } else if (sortParams.sort === 'invoiceTotal' || sortParams.sort === 'outstandingAmount') {
        sortParams.sort = $scope.filters.status === 'O' && userConfiguration.usesInHouseAR ? sortParams.sort : 'location';
      }
    }
    return sortParams;
  }

  $scope.search = function (event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }
    if ($scope.filtersform.$valid) {
      var params = {};
      var sortParams = angular.fromJson($stateParams.invoicesPagination);
      if (property && direction) {
        sortParams.sort = property;
        sortParams.direction = direction;
      }
      sortParams.page = 0;
      params.forceRefresh = Math.random();
      params.invoicesPagination = angular.toJson(getSortParams(sortParams));

      //add filters
      params.invoicesFilters = angular.toJson($scope.filters);

      //add keywords
      params.search = $scope.terms.keywords;
      $state.go('.', params);
      return true;
    }
    return false;
  };

  $scope.toggleFilters = function () {
    $scope.showFilters = !$scope.showFilters;
  };

  $scope.getPDFUrl = function (transactionIdString) {
    return (appConfigurationService.basePath + '/rest/invoicehistory/invoiceAsPdf?transactionId=' + transactionIdString);
  };

  $scope.showEmailForm = function () {
    $scope.emailForm.toggle = true;
  };

  $scope.downloadSelected = function () {
    var param = {searchedStatus: $scope.searchedStatus};
    invoiceHistoryResource.invoices(param, invoicesFilters).get(
      null,
      function () {
      },
      globalAlertService.handleErrorResponse
    ).$promise.then(function (response) {
      let data = JSON.stringify(response);
      let allInvoices = JSON.parse(data);

      let processedInvoices = [];
      JSON.parse(allInvoices).forEach(invoice => {
        if (sessionStorage.getItem("selectAllToggle") === "true" && !sessionStorage.getItem(invoice.transactionIdString + 'unchk') && invoice.invoiceType.symbol != 'X') {
          invoice = angular.copy(invoice);
          invoice.selected = true;
        } else if (sessionStorage.getItem(invoice.transactionIdString + 'chk') && invoice.invoiceType.symbol != 'X') {
          invoice = angular.copy(invoice);
          invoice.selected = true;
        }
        processedInvoices.push(invoice);
      });

      var selectedInvoices = [];
      processedInvoices.forEach(function (current) {
        if (current.selected) {
          selectedInvoices.push(current.transactionIdString);
        }
      });

      if (selectedInvoices.length == 0) {
        $scope.invoices.content.forEach(invoice => {
          if (invoice.selected)
            selectedInvoices.push(invoice.transactionIdString);
        })
      }

      if (selectedInvoices.length > 0) {
        invoiceHistoryResource.invoiceListAsZip.get(
          {
            'invoiceTransactionIdList': selectedInvoices
          },
          function () {
            selectedInvoices.forEach(invoice => {
              if (sessionStorage.getItem(invoice + 'chk')) {
                sessionStorage.removeItem(invoice + 'chk');
              }
            });
            sessionStorage.clear();
            $scope.search();
          },
          globalAlertService.handleErrorResponse
        );
      }

    });


  };

  function openTwoCPPortal(clientId, portalId) {
    var w = 820;
    var h = 500;
    var y = $window.top.outerHeight / 2 + $window.top.screenY - (h / 2);
    var x = $window.top.outerWidth / 2 + $window.top.screenX - (w / 2);
    return $window.open(appConfigurationService.getTwoCPPaymentPath(clientId, portalId), '_blank',
      'resizeable=true, scrollbars=yes, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
  }

  function parsingAmount(amount) {
    return parseFloat(amount.replace(/,/g, '')).toFixed(2);
  }

  // Pay a specific invoice (used for mobile view)
  $scope.payInvoiceMobile = function () {
    var invoiceSelected;
    $scope.invoices.content.forEach(function (current) {
      if (current.selected) {
        invoiceSelected = current;
      }
    });
    $uibModal.open({
      templateUrl: 'app/scripts/modules/my-account/views/templates/generic-payment.html',
      controller: 'genericPaymentController',
      size: 'sm',
      animation: true,
      windowClass: 'center-modal',
      keyboard: false,
      backdrop: 'static',
      resolve: {
        properties: function () {
          return {
            title: 'mydealer.ui.global.pay',
            invoice: invoiceSelected,
            updatedSelected: function (lumpSumAmount) {
              invoiceSelected.appliedAmountSelected = lumpSumAmount;
              $scope.updateSelected(invoiceSelected)
            },
            callback: function (lumpSumAmount) {
              invoiceSelected.appliedAmountSelected = lumpSumAmount;
              $scope.paySelected();
            }
          };
        }
      }
    });
  };

  // Pay a specific lump sum (equates to adjustment line in cash receipts)
  $scope.payLump = function () {
    $uibModal.open({
      templateUrl: 'app/scripts/modules/my-account/views/templates/generic-lump-payment.html',
      controller: 'genericLumpPaymentController',
      size: 'md',
      animation: true,
      windowClass: 'center-modal',
      keyboard: false,
      backdrop: 'static',
      resolve: {
        properties: function () {
          return {
            title: 'mydealer.ui.invoices.submit.lump_payment',
            callback: function (lumpSumAmount) {
              var selectedInvoices = [];
              selectedInvoices.push({
                appliedAmount: parsingAmount(lumpSumAmount),
                location: userConfiguration.businessLocation,
                adjustment: true
              });
              if (lumpSumAmount > accountSummary.totalOutstanding) {
                confirmLeavePaymentPortal(selectedInvoices);
              } else {
                $scope.processPayments(selectedInvoices);
              }
            }

          };
        }
      }
    });
  };

  function confirmLeavePaymentPortal(selectedInvoices) {
    $uibModal.open({
      templateUrl: 'app/scripts/modules/my-account/views/templates/confirmation-view.html',
      controller: 'confirmationController',
      size: "sm",
      animation: false,
      windowClass: 'center-modal',
      keyboard: false,
      backdrop: 'static',
      resolve: {
        properties: function () {
          return {
            labelText: `${'mydealer.ui.invoices.pay_selected.overpayment'}`,
            buttons: {
              yes: `${'mydealer.ui.global.yes'}`,
              no: `${'mydealer.ui.global.no'}`
            }
          };
        }
      }
    }).result.then(function (value) {
      if (value === "yes") {
        $scope.processPayments(selectedInvoices);
      }
    });
  }

  //Pay the selection invoices from selection
  $scope.paySelected = function () {

    var param = {searchedStatus: $scope.searchedStatus};
    invoiceHistoryResource.invoices(param, invoicesFilters).get(
      null,
      function () {
      },
      globalAlertService.handleErrorResponse
    ).$promise.then(function (response) {
      let data = JSON.stringify(response);
      let allInvoices = JSON.parse(data);

      let processedInvoices = [];
      JSON.parse(allInvoices).forEach(invoice => {
        if (sessionStorage.getItem(invoice.transactionIdString + 'amt')) {
          invoice = angular.copy(invoice)
          invoice.selected = true
          invoice.appliedAmountSelected = sessionStorage.getItem(invoice.transactionIdString + 'amt')
        }
        processedInvoices.push(invoice);
      });


      var isOverPayment = false;
      var selectedInvoices = [];
      var currency = null;
      var isCurrencyChanged = false;
      processedInvoices.forEach(function (current) {

        if (current.selected &&
          !$scope.hasAmount(current.appliedAmount) &&
          $scope.hasAmount(parseInt(current.appliedAmountSelected))) {
          selectedInvoices.push({
            transactionId: current.transactionIdString,
            currencyType: current.currency,
            appliedAmount: parsingAmount(current.appliedAmountSelected),
            location: current.billingHistoryCashReceiptId.location,
            company: current.billingHistoryCashReceiptId.company,
            division: current.billingHistoryCashReceiptId.division,
            customerNumber: current.customerNumber,
            customerName: current.customerName,
            invoiceDate: current.billingDate,
            invoiceNumber: current.billingHistoryCashReceiptId.salesOrderNumber,
            sequence: current.sequence
          });
          if (currency !== null && currency !== current.currency) {
            isCurrencyChanged = true;
          }
          currency = current.currency;
          if (current.appliedAmountSelected > current.outstandingAmount) {
            isOverPayment = true;
          }
        }
      });
      if (isCurrencyChanged) {
        globalAlertService.addError('mydealer.ui.invoices.invalid.currency_type');
        return;
      }
      if (isOverPayment == true) {
        confirmLeavePaymentPortal(selectedInvoices);
      } else {
        $scope.processPayments(selectedInvoices);
      }

    });


  };

  function validateResponse(response, selectedInvoices) {
    if (response.transaction && response.successful) {
      globalAlertService.addSuccess('mydealer.ui.invoices.ach_payment_auth_success');
    } else if (response.lastError.description !== undefined) {
      globalAlertService.addError(response.lastError.description);
    } else {
      globalAlertService.addError('mydealer.ui.invoices.ach_payment_auth_fail');
    }
    hidePaymentSubmitDialog();
    selectedInvoices.forEach(invoice => {
      if (sessionStorage.getItem(invoice.transactionId + 'amt')) {
        sessionStorage.removeItem(invoice.transactionId + 'amt');
        sessionStorage.removeItem(invoice.transactionId + 'chk');
      }
    });
    $scope.search();
  }

  const updateSelectedInvoiceList = (selectedInvoices) => {
    if (selectedInvoices && selectedInvoices.length) {
      selectedInvoiceList = selectedInvoices
        .map(invoice => invoice.invoiceNumber || 'Lump Payment')
        .join(', ');
    }
  };

  const setTimeOutFunction = () => {
    timeOutId = setTimeout(() => {
      isTimeout = true;
      hidePaymentSubmitDialog();
      const message = $filter('language')('mydealer.ui.invoices.ach_payment_timeout_message1') + ' ' + selectedInvoiceList + '.' + '<br>' + $filter('language')('mydealer.ui.invoices.ach_payment_timeout_message2') ;;
      globalAlertService.addError(message);
    }, 30000);
  };

  const readCreditCardPayment = (parameters, selectedInvoices) => {
    if(!isTimeout) {
      twocpResource.readCreditCardPayment(parameters).post(selectedInvoices).$promise.then(function (response) {
        clearTimeout(timeOutId);
        validateResponse(response, selectedInvoices);
      }).catch(() => {
        readCreditCardPayment(parameters, selectedInvoices);
      });
    }
  };

  function readAuthorizationResult(modalInstance, parameters, selectedInvoices) {
    angular.element($window).off('beforeunload', confirmLeavePaymentPortal);
    modalInstance.dismiss();
    showPaymentSubmitDialog();
    readCreditCardPayment(parameters, selectedInvoices);
  }

  $scope.submitBlockedPopupOrder = function () {
    $scope.blockedPopupDetails.parameters.requestId = '';
    $scope.blockedPopupDetails.parameters.preGeneratedInvoiceNumber = $scope.blockedPopupDetails.preGeneratedInvoiceNumber;
    if ($scope.blockedPopupDetails) {
      readAuthorizationResult($scope.blockedPopupDetails.modalInstance, $scope.blockedPopupDetails.parameters, $scope.blockedPopupDetails.selectedInvoices);
    }
  };


  function processCreditCardPayment(selectedInvoices, parameters) {
    if ($scope.payByCreditCard) {
      $uibModal.open({
        templateUrl: 'app/scripts/modules/shopping-cart/views/credit-card-processing-prompt.html',
        scope: $scope,
        controller: function ($uibModalInstance) {
          parameters.location = userConfiguration.businessLocation;
          twocpResource.authorizeCreditCardPayment(parameters).post(selectedInvoices).$promise.then(function (data) {
            if (!data.twoCPPortalAvailable) {
              $uibModalInstance.dismiss();
              globalAlertService.addError('mydealer.ui.checkout.credit_card_portal_fail');
            } else {
              angular.element($window).on('beforeunload', confirmLeavePaymentPortal);
              var twoCPWindow = openTwoCPPortal($scope.paymentAgencies.clientId, data.portalId);
              if (!twoCPWindow || twoCPWindow.closed || typeof twoCPWindow.closed === 'undefined') {
                $scope.blockedPopupDetails = {
                  modalInstance: $uibModalInstance,
                  parameters: parameters,
                  selectedInvoices: selectedInvoices,
                  preGeneratedInvoiceNumber: data.preGeneratedSalesOrderNumber
                };
              } else {
                isTimeout = false;
                var timer = setInterval(function () {
                  if (twoCPWindow.closed) {
                    setTimeOutFunction();
                    $uibModalInstance.dismiss();
                    clearInterval(timer);
                    parameters.requestId = data.portalId;
                    parameters.preGeneratedInvoiceNumber = data.preGeneratedSalesOrderNumber;
                    updateSelectedInvoiceList(selectedInvoices);
                    readAuthorizationResult($uibModalInstance, parameters, selectedInvoices);
                  }
                }, 1000);
              }
            }
          }).catch(function () {
            $uibModalInstance.dismiss();
            globalAlertService.addError('mydealer.ui.checkout.credit_card_auth_fail');
          });
        },
        size: 'md',
        animation: true,
        windowClass: 'center-modal',
        keyboard: false,
        backdrop: 'static'
      });
    }
  }

  function readACHWeb(parameters, selectedInvoices) {
    if (!isTimeout) {
      twocpResource.readACHWeb(parameters).post(selectedInvoices).$promise.then(function (response) {
        clearTimeout(timeOutId);
        validateResponse(response, selectedInvoices);
      }).catch(() => {
        readACHWeb(parameters, selectedInvoices);
      });
    }
  }

  function processACHPayment(selectedInvoices, parameters) {

    $uibModal.open({
      templateUrl: 'app/scripts/modules/my-account/views/templates/process-bank-payment.html',
      controller: function ($uibModalInstance) {
        parameters.location = $scope.getLocation();
        twocpResource.authorizeACHWeb(parameters).post(selectedInvoices).$promise.then(function (data) {
          if (!data.portal.id) {
            $uibModalInstance.dismiss();
            globalAlertService.addError('mydealer.ui.invoices.ach_payment_portal_fail');
          } else {
            var twoCPWindow = $scope.openTwoCPPortal($scope.achPaymentAgencies.clientId, data.portal.id);
            if (!twoCPWindow || twoCPWindow.closed || typeof twoCPWindow.closed === 'undefined') {
              globalAlertService.addError('mydealer.ui.checkout.fail');
            } else {
              isTimeout = false;
              var timer = setInterval(function () {
                if (twoCPWindow.closed) {
                  setTimeOutFunction();
                  clearInterval(timer);
                  $uibModalInstance.dismiss();
                  showPaymentSubmitDialog();
                  parameters.requestId = data.portal.id;
                  parameters.preGeneratedInvoiceNumber = data.preGeneratedInvoiceNumber;
                  updateSelectedInvoiceList(selectedInvoices);
                  readACHWeb(parameters, selectedInvoices);
                }
              }, 1000);
            }
          }
        }).catch(function () {
          hidePaymentSubmitDialog();
          globalAlertService.addError('mydealer.ui.invoices.ach_payment_auth_fail');
        });
      },
      size: 'md',
      animation: true,
      windowClass: 'center-modal',
      keyboard: false,
      backdrop: 'static'
    });
  }


  function processAuthDotNetPayment(selectedInvoices, parameters, callback) {
    parameters.location = $scope.getLocation();
    parameters.iframeCommunicationURL = $location.absUrl().split('#')[0] + 'app/scripts/modules/my-account/views/iframeCommunicator.html';
    var compositeRequest = {
      authDotNetProperties: authDotNetProperties,
      customerPayInvoiceDTOList: selectedInvoices,
      contactDetailsDTO: $scope.myProfile
    };
    authorizeDotNetResource.authorizeDotNetPaymentPageResponse(parameters).post(compositeRequest).$promise.then(function (data) {
      if (data) {
        $uibModal.open({
          templateUrl: 'app/scripts/modules/my-account/views/templates/authorize-dot-net-payment.html',
          controller: 'authorizeDotNetController',
          resolve: {
            properties: function () {
              return {
                callback: callback,
                formToken: data.token,
                preGeneratedInvoiceNumber: data.preGeneratedInvoiceNumber,
                parameters: parameters,
                selectedInvoices: selectedInvoices,
                authDotNetHostedPaymentURL: data.authDotNetHostedPaymentURL
              };
            }

          },
          size: 'lg',
          animation: true,
          windowClass: 'center-modal-custom-width',
          keyboard: false,
          backdrop: 'static'
        });
      }
    }).catch(function (response) {
      globalAlertService.handleErrorResponse(response);
    });
  }


  function paymentSelectionModal(selectedInvoices, parameters) {
    $uibModal.open({
      templateUrl: 'app/scripts/modules/my-account/views/templates/payment-type-selection-modal.html',
      controller: 'paymentTypeSelectionController',
      size: 'md',
      animation: true,
      windowClass: 'center-modal',
      keyboard: false,
      backdrop: 'static',
      resolve: {
        properties: function () {
          return {
            callback: function (selectedType) {
              if (selectedType === 'ACH' && $scope.payByAuthDotNet) {
                processAuthDotNetPayment(selectedInvoices, parameters, $scope.search);
              } else if (selectedType === 'ACH' && $scope.achPaymentAgencies.twoCPACHEnabled) {
                processACHPayment(selectedInvoices, parameters);
              } else {
                processCreditCardPayment(selectedInvoices, parameters);
              }
            }
          };
        }
      }
    });
  }

  $scope.processPayments = function (selectedInvoices) {
    globalAlertService.clearErrors();
    var parameters = {
      company: userConfiguration.contactId.company,
      division: userConfiguration.contactId.division,
      customerNumber: userConfiguration.contactId.customerNumber
    };
    var invalidAmount = null;
    selectedInvoices.forEach(function (invoice) {
      if (isNaN(invoice.appliedAmount) || Math.abs(invoice.appliedAmount) > 999999999.99 || (invoice.appliedAmount) <= 0) {
        invalidAmount = invoice.appliedAmount;
      }
    });

    if (invalidAmount || selectedInvoices.length <= 0) {
      globalAlertService.addError('mydealer.ui.invoices.invalid_payment_amount');
      return;
    }

    if (($scope.payByAuthDotNet || $scope.achPaymentAgencies.twoCPACHEnabled) && (!$scope.paymentAgencies.disableTwoCP && $scope.allowPaymentsOnAccount === 'BOTH_PAYMENT_TYPES')) {
      paymentSelectionModal(selectedInvoices, parameters);
    } else if ($scope.payByAuthDotNet && ($scope.allowPaymentsOnAccount === 'ACH_PAYMENT' ||  $scope.allowPaymentsOnAccount === 'BOTH_PAYMENT_TYPES')) {
      processAuthDotNetPayment(selectedInvoices, parameters, $scope.search);
    }  else if ($scope.achPaymentAgencies.twoCPACHEnabled && ($scope.allowPaymentsOnAccount === 'ACH_PAYMENT' ||  $scope.allowPaymentsOnAccount === 'BOTH_PAYMENT_TYPES')) {
      processACHPayment(selectedInvoices, parameters);
    } else if ($scope.payByCreditCard && ($scope.allowPaymentsOnAccount === 'CREDIT_CARD_PAYMENT' || $scope.allowPaymentsOnAccount === 'BOTH_PAYMENT_TYPES')) {
      processCreditCardPayment(selectedInvoices, parameters);
    }
  };

  $scope.getInvoiceTotal = function (selectedInvoices) {
    var invoiceTotal = 0;
    selectedInvoices.forEach(function (invoice) {
      invoiceTotal = +Math.abs(invoice.appliedAmount) + invoiceTotal;
    });
    return invoiceTotal;
  }

  $scope.openTwoCPPortal = function (clientId, portalId) {
    var w = 820;
    var h = 500;
    var y = $window.top.outerHeight / 2 + $window.top.screenY - (h / 2);
    var x = $window.top.outerWidth / 2 + $window.top.screenX - (w / 2);
    return $window.open(appConfigurationService.getTwoCPPaymentPath(clientId, portalId), '_blank',
      'resizeable=true, scrollbars=yes, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
  };

  $scope.emailSelected = function () {

    var param = {searchedStatus: $scope.searchedStatus};
    invoiceHistoryResource.invoices(param, invoicesFilters).get(
      null,
      function () {
      },
      globalAlertService.handleErrorResponse
    ).$promise.then(function (response) {
      let data = JSON.stringify(response);
      let allInvoices = JSON.parse(data);
      let processedInvoices = [];
      JSON.parse(allInvoices).forEach(invoice => {
        if (sessionStorage.getItem("selectAllToggle") === "true" && !sessionStorage.getItem(invoice.transactionIdString + 'unchk') && invoice.invoiceType.symbol != 'X') {
          invoice = angular.copy(invoice);
          invoice.selected = true;
        } else if (sessionStorage.getItem(invoice.transactionIdString + 'chk') && invoice.invoiceType.symbol != 'X') {
          invoice = angular.copy(invoice);
          invoice.selected = true;
        }
        processedInvoices.push(invoice);
      });

      var selectedInvoices = [];
      processedInvoices.forEach(function (current) {
        if (current.selected) {
          selectedInvoices.push(current.transactionIdString);
        }
      });

      if (selectedInvoices.length == 0) {
        $scope.invoices.content.forEach(invoice => {
          if (invoice.selected)
            selectedInvoices.push(invoice.transactionIdString);
        })
      }
      if (selectedInvoices.length > 0) {
        invoiceHistoryResource.myDealerInvoiceEmail.save(
          {
            'toEmailAddress': $scope.email.address,
            'invoiceTransactionIdList': selectedInvoices
          },
          function () {
            sessionStorage.clear();
            $scope.search();
            $scope.emailForm.toggle = false;
            globalAlertService.addSuccess('mydealer.ui.global.email_sent');
          },
          globalAlertService.handleErrorResponse
        );
      }

    });


  };

  $scope.showTaxSummary = function () {
    if ($scope.taxSummary.toggle === true) {
      $location.hash('tax-summary');
      $anchorScroll();
      return;
    }

    var parameters = {
      keywords: (search ? search : '')
    };

    if (invoicesFilters) {
      parameters.statusType = invoicesFilters.status;
      if (invoicesFilters.type) {
        parameters.invoiceType = invoicesFilters.type.symbol;
      }
      if (invoicesFilters.location) {
        parameters.location = invoicesFilters.location.location;
      }
      parameters.startDate = invoicesFilters.minDate ? new Date(invoicesFilters.minDate).getTime() : null;
      parameters.endDate = invoicesFilters.maxDate ? new Date(invoicesFilters.maxDate).getTime() : null;
    }

    invoiceHistoryResource.assignedInvoiceHistoryTaxSummary.query(
      parameters,
      function (value) {
        $scope.taxSummary.results = value;
        $scope.taxSummary.toggle = true;
        $location.hash('tax-summary');
        $anchorScroll();
      },
      globalAlertService.handleErrorResponse
    );
  };

  $scope.hideTaxSummary = function () {
    $scope.taxSummary.toggle = false;
    $location.hash('');
    $anchorScroll();
  };

  $scope.toggleCardMenu = function (event, invoice) {
    event.stopPropagation();
    invoice.isMenuOpen = !invoice.isMenuOpen;
  };

  $scope.selectCard = function (invoice) {
    $scope.emailForm.toggle = false;
    $scope.invoices.content.forEach(function (current) {
      current.selected = (current === invoice ? true : false);
    });
  };

  $scope.getPDF = function (transactionIdString, invoiceNumber) {

    var parameters = {
      transactionId: transactionIdString
    };
    var isUndefined = $scope.irInvoiceUndefined(invoiceNumber);
    if (isUndefined) {
      invoiceHistoryResource.pdf(parameters).get(
        null,
        function () {
        },
        function (response) {
          globalAlertService.addError('mydealer.ui.invoices.pdf_notGenerated');
        }
      );
    } else {
      invoiceHistoryResource.pdfIR(parameters).get(
        null,
        function () {
        },
        function (response) {
          globalAlertService.addError('mydealer.ui.invoices.pdf_notGenerated');
        }
      );
    }
  };

  $scope.getCsv = function () {
    var parameters = {searchedStatus: $scope.searchedStatus, keywords: search ? search : ''}
    parameters.startDate = invoicesFilters.minDate ? new Date(invoicesFilters.minDate).getTime() : null;
    parameters.endDate = invoicesFilters.maxDate ? new Date(invoicesFilters.maxDate).getTime() : null;
    parameters.location = invoicesFilters.location ? invoicesFilters.location.location : '';
    if (invoicesFilters.type) {
      parameters.invoiceType = invoicesFilters.type.symbol;
    }
    invoiceHistoryResource.csv(parameters).get(
      null,
      function () {
      },
      function (response) {
        globalAlertService.addError('mydealer.ui.invoices.csv_notGenerated');
      }
    );
  };
});
