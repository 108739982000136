'use strict';

intellidealerAppShoppingCartModule.factory('shoppingCartResource', function($resource, appConfigurationService) {

  var resource = {};

  resource.cart = $resource(appConfigurationService.basePath + '/rest/cart/');
  resource.addPartToShoppingCart = $resource(
    appConfigurationService.basePath + '/rest/cart/addPartToShoppingCart/',
    null,
    {
      addPart: {
        method: 'POST',
        params: {
          partNumber: '@partNumber',
          quantity: '@quantity',
          defaultLocation: '@defaultLocation',
          unit: '@unit',
        }
      }
    }
  );
  resource.removeFromShoppingCart = $resource(
    appConfigurationService.basePath + '/rest/cart/removeFromShoppingCart/',
    null,
    {
      removeOne: {
        method: 'DELETE',
        params: {
          id: '@id'
        }
      }
    }
  );
  resource.updatePartQuantity = $resource(
    appConfigurationService.basePath + '/rest/cart/updatePartQuantity/',
    null,
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id',
          quantity: '@quantity',
          unit: '@unit',
          defaultLocation: '@defaultLocation',
        }
      }
    }
  );
  resource.updateUnit = $resource(
    appConfigurationService.basePath + '/rest/cart/updateUnit/',
    null,
    {
      update: {
        method: 'POST'
      }
    }
  );
  resource.saveCartToShoppingList = $resource(
    appConfigurationService.basePath + '/rest/cart/saveCartToShoppingList/',
    null,
    {
      saveList: {
        method: 'POST',
        params: {
          referenceNumber: '@referenceNumber',
          description: '@description'
        }
      }
    }
  );
  resource.getCartWithPartsCatalogUpdate = $resource(
    appConfigurationService.basePath + '/rest/cart/addPartsFromPartCatalog',
    null,
    {
      get: {
        method: 'GET',
        url: appConfigurationService.basePath + '/rest/cart/addPartsFromPartCatalog',
        params:{
          cartId: '@cartId'
        }
      }
    }
  );
  resource.getCartWithCatchACartUpdate = $resource(
    appConfigurationService.basePath + '/rest/cart/addPartsFromCatchACart',
    null,
    {
      get: {
        method: 'GET',
        url: appConfigurationService.basePath + '/rest/cart/addPartsFromCatchACart',
        params:{
          cartId: '@catchACartId'
        }
      }
    }
  );
  resource.addPartsFromCatchACartGeneric = $resource(
    appConfigurationService.basePath + '/rest/cart/addPartsFromCatchACartGeneric',
    null,
    {
      get: {
        method: 'GET',
        url: appConfigurationService.basePath + '/rest/cart/addPartsFromCatchACartGeneric',
        params:{
          cartId: '@catchACartId'
        }
      }
    }
  );
  resource.addPartsFromList = $resource(appConfigurationService.basePath + '/rest/cart/addPartsFromList/');
  resource.requestPartList = $resource(appConfigurationService.basePath + '/rest/parts/partListRequestEmail/');
  resource.mergeGuestCartItems = $resource(appConfigurationService.basePath + '/rest/cart/mergeGuestCartItems/');
  resource.handleShippingAmount = $resource(appConfigurationService.basePath + '/rest/cart/handleShippingAmount/');
  resource.getCustomerShipToAddresses = $resource(
      appConfigurationService.basePath + '/rest/customershiptos/customerShipToDetailsByCustomerNumberList',
      null,
      {
        getAddresses: {
          method: 'POST',
          url: appConfigurationService.basePath + '/rest/customershiptos/customerShipToDetailsByCustomerNumberList',
          isArray: true
        }
      }
    )

  return resource;
});
