'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.myEquipment.myEquipmentDetails.myServiceContract.myServiceContractDetails', {
      url: '/my-service-contract-details/{contractNumber}',
      views : {
        '@navigation.equipment.myEquipment.myEquipmentDetails' : {
          templateUrl: 'app/scripts/modules/equipment/views/my-service-contract-details.html',
          controller: 'MyServiceContractDetailsController',
        }
      },
      resolve: {
        
        serviceAgreementsDetails: [
          'serviceAgreementsResource', 
          'globalAlertService', 
          '$stateParams', 
          '$filter',
          function(serviceAgreementsResource, globalAlertService, $stateParams, $filter) {
            var parameters = {
              stockNumber:  $filter('pathParam')($stateParams.stockNumber),
              contractNumber:  $filter('pathParam')($stateParams.contractNumber)
            };
            
            return serviceAgreementsResource.assignedServiceAgreementsDetails.get(
              parameters, 
              function () {}, 
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ]
        
      }
    }
  );
}]);
