'use strict';


/*
  Wrapper directive for checkboxes and labels.
  Passes ng-model through to the actual input element, bound two-way with parent scope
  Passes ng-change function through to actual input element.
*/
intellidealerAppCoreModule.directive('checkbox', function() {
  return {
    restrict: 'E',
    transclude: true,
    template: '<input id="checkbox{{$id}}" type="checkbox" data-ng-model="ngModel" data-ng-change="ngChange()" ng-required="ngRequired">' +
    '<label for="checkbox{{$id}}"><span data-ng-transclude></span></label>',
    scope: {
      ngModel: '=',
      ngChange: '&',
      ngRequired: '='
    }
  };
});
