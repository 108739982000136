'use strict';

intellidealerAppPartsModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.parts.myShoppingLists', {
      url: '/my-shopping-lists' +
      '?myShoppingListsPagination' +
      '&{myShoppingListsSearch:any}',
      params: {
        myShoppingListsPagination: angular.toJson({
          sort: 'dateOpened',
          direction: 'desc'
        })
      },
      templateUrl: 'app/scripts/modules/parts/views/my-shopping-lists.html',
      controller: 'MyShoppingListsController',
      resolve: {
        help: function () {
          return 'Parts-_My_Shopping_Lists.htm';
        },
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.my_shopping_lists.breadcrumb'));
        }],

        myShoppingListsPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.myShoppingListsPagination);
        }],

        myShoppingLists: [
          'pageableService',
          'onlinePartsOrdersResource',
          'globalAlertService',
          '$stateParams',
          'myShoppingListsPagination',
          function(pageableService, onlinePartsOrdersResource, globalAlertService, $stateParams, myShoppingListsPagination) {
            var parameters = {
              historicalOrders: false,
              keywords: $stateParams.myShoppingListsSearch ? $stateParams.myShoppingListsSearch : ''
            };

            pageableService.addPageableParameters(
              parameters,
              myShoppingListsPagination
            );

            return onlinePartsOrdersResource.assignedOnlineOrders.get(
              parameters,
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ]
      }
    }
  );
}]);
