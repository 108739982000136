'use strict';

intellidealerAppPartsModule.controller('PreviousOrdersDetailsController', function ($scope, $state, $filter, userConfiguration, PartsTopLevelTabsService, previousOrdersDetails, shoppingCartService, $stateParams, onlinePartsOrdersResource, globalAlertService) {

  $scope.linkedStates = PartsTopLevelTabsService;
  $scope.previousOrdersDetails = previousOrdersDetails;
  $scope.anySelected = false;
  $scope.showPrice = userConfiguration.displayPrices;

  $scope.DetailsSortChoices = [
    {label:$filter('language')('mydealer.ui.parts.part_number'), property:'partNumber', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.parts.description')},
    {label:$filter('language')('mydealer.ui.parts.quantity'), align: 'right'}
  ];

  if ($scope.showPrice) {
    $scope.DetailsSortChoices.push({label: $filter('language')('mydealer.ui.parts.price'), align: 'right'});
    $scope.DetailsSortChoices.push({label: $filter('language')('mydealer.ui.parts.extended_price'), align: 'right'});
  }

  if(!sessionStorage.getItem("DetailsSortChoices")){
    sessionStorage.setItem("DetailsSortChoices",JSON.stringify($scope.DetailsSortChoices))
  }else {
    $scope.DetailsSortChoices = JSON.parse(sessionStorage.getItem("DetailsSortChoices"));
  }


  $scope.detailsColumns = [];

  $scope.DetailsSortChoices.forEach(choice => {
    if(choice.label){
      $scope.detailsColumns.push({label:choice.label, isSelected:true});
    }
  });
  if(!sessionStorage.getItem("detailsColumns")){
    sessionStorage.setItem("detailsColumns",JSON.stringify($scope.detailsColumns))
  }else {
    $scope.detailsColumns = JSON.parse(sessionStorage.getItem("detailsColumns"));
  }
  $scope.toggleColumns = function(col){
    let index = $scope.detailsColumns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.detailsColumns[index].isSelected = !$scope.detailsColumns[index].isSelected;
    sessionStorage.setItem("detailsColumns",JSON.stringify($scope.detailsColumns))
    let sortIndex = $scope.DetailsSortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if(sortIndex >=0){
      $scope.DetailsSortChoices[sortIndex].isHidden = !$scope.detailsColumns[index].isSelected;
      sessionStorage.setItem("DetailsSortChoices",JSON.stringify($scope.DetailsSortChoices))
    }

  }

  $scope.previousOrdersDetails.content.forEach(previousOrdersDetail => {
    if(sessionStorage.getItem(previousOrdersDetail.partNumber + 'chk') === "true"
      || (sessionStorage.getItem( "selectAllToggle") === "true" &&
        (sessionStorage.getItem(previousOrdersDetail.partNumber + 'unchk') === null ||
        sessionStorage.getItem(previousOrdersDetail.partNumber + 'unchk') === "false"))) {
      previousOrdersDetail.selected = true;
    }else{
      previousOrdersDetail.selected= false;
    }
  });

  $scope.isSelectedCheckbox = function(event,previousOrdersDetail){
    if(previousOrdersDetail.selected){
      sessionStorage.setItem(previousOrdersDetail.partNumber+ 'unchk','true');
      sessionStorage.setItem(previousOrdersDetail.partNumber+'chk','false');
    }else{
      sessionStorage.setItem(previousOrdersDetail.partNumber+'chk','true');
      sessionStorage.setItem(previousOrdersDetail.partNumber+'unchk','false');
    }
  }

  $scope.isColVisible = function(colName){
    let index = $scope.detailsColumns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.detailsColumns[index].isSelected;
  }

  $scope.$watch(function() {
    var anySelected = false;
    $scope.previousOrdersDetails.content.forEach(function(current) {
      if (current.selected) {
        anySelected = true;
      }
    });
    Object.keys(sessionStorage).forEach(key => {
      if(key.includes("chk") && sessionStorage.getItem(key) === 'true' && !key.includes("unchk"))
        anySelected = true;
    })
    return anySelected;
  }, function (newValue) {
    $scope.anySelected = newValue;
  });

  $scope.addPartsToCart = function() {
    let parameters;
    if ($stateParams.referenceNumber == null) {
       parameters = {
        company: $stateParams.company,
        division: $stateParams.division,
        location: $stateParams.location,
        salesOrderNumber: $filter('pathParam')($stateParams.salesOrderNumber)
      };
       sessionStorage.clear();
      onlinePartsOrdersResource.assignedPartsInvoiceDetailsByList.getPartsInvoiceDetailsList(parameters).get(
        null,
        function () {
        },
        globalAlertService.handleErrorResponse
      ).$promise.then(function (response) {
        $scope.errorResponse(response);
      });
    } else {
       parameters = {
        company: $stateParams.company,
        division: $stateParams.division,
        location: $stateParams.location,
        referenceNumber: $filter('pathParam')($stateParams.referenceNumber)
      };
      onlinePartsOrdersResource.assignedOnlineOrderDetailsByList.getOnlineOrdersDetailsList(parameters).get(
        null,
        function () {
        },
        globalAlertService.handleErrorResponse
      ).$promise.then(function (response) {
        $scope.errorResponse(response);
      });
    }
  };

  $scope.toggleCardMenu = function(event, previousOrdersDetail) {
    event.stopPropagation();
    previousOrdersDetail.isMenuOpen = !previousOrdersDetail.isMenuOpen;
  };

  $scope.selectCard = function(previousOrdersDetail) {
    $scope.previousOrdersDetails.content.forEach(function(current) {
      current.selected = (current === previousOrdersDetail ? true : false);
    });
  };

  $scope.getOrderDetailsXls = function () {
    if ($stateParams.referenceNumber == null) {
      let parameters = {
        company: $stateParams.company,
        division: $stateParams.division,
        location: $stateParams.location,
        salesOrderNumber: $filter('pathParam')($stateParams.salesOrderNumber),
        screenName: 'orderList'
      };
      onlinePartsOrdersResource.exportPartInvoiceDetailsData.xls(parameters).get(null, function () {
      }, function (response) {
        globalAlertService.addError('mydealer.ui.equipment.csv_notGenerated');
      });
    } else {
      let parameters = {
        company: $stateParams.company,
        division: $stateParams.division,
        location: $stateParams.location,
        referenceNumber: $filter('pathParam')($stateParams.referenceNumber),
        screenName: 'orderList'
      };
      onlinePartsOrdersResource.exportCsvOrderData.csv(parameters).get(null, function () {
      }, function (response) {
        globalAlertService.addError('mydealer.ui.equipment.csv_notGenerated');
      });
    }
  };

  $scope.previousPage = function() {
    sessionStorage.clear();
    sessionStorage.removeItem("selectAllToggle");
    $state.go('navigation.parts.previousOrders');
  }

  $scope.errorResponse = function(response) {
    let data = JSON.stringify(response);
    let allOrderList = JSON.parse(data);
    let processedOrderList = [];
    JSON.parse(allOrderList).forEach(previousOrdersDetail => {
      if ((sessionStorage.getItem("selectAllToggle") === "true" && !(sessionStorage.getItem(previousOrdersDetail.partNumber + 'unchk') === "true")) || sessionStorage.getItem(previousOrdersDetail.partNumber + 'chk') === "true") {
        previousOrdersDetail = angular.copy(previousOrdersDetail);
        previousOrdersDetail.selected = true;
      }
      processedOrderList.push(previousOrdersDetail);
    });
    var selectedParts = [];
    processedOrderList.forEach(function (current) {
      if (current.selected) {
        selectedParts.push({ partNumber: current.partNumber,
          quantity: (!isNaN(current.quantity) && current.quantity > 0 ? current.quantity: 1)});
      }
    });
    if (selectedParts.length == 0) {
      $scope.previousOrdersDetails.content.forEach(previousOrdersDetail => {
        if (previousOrdersDetail.selected)
          selectedParts.push({
            partNumber: previousOrdersDetail.partNumber,
            quantity: (!isNaN(previousOrdersDetail.quantity) && previousOrdersDetail.quantity > 0 ? previousOrdersDetail.quantity: 1)});
      })
    }

    if (selectedParts.length > 0) {
      shoppingCartService.addItemList(selectedParts).then(
        function () {
          $state.reload($state.current);
        },
        function () {
        }
      );
    }
  }

});
