'use strict';

intellidealerAppCoreModule
  .service('pageableService', function () {  

  /*
    Adds paging and sorting parameters to the parameters object in the format required for Spring.
    If the second parameter is an object, it is assumed to be an object holding the rest 
      of the parameters (convenience for our JSON query parameters)
  */
  this.addPageableParameters = function(parameters, page, size, sort, direction) {
    parameters = parameters ? parameters : {};
    if (angular.isObject(page)){
      size = page.size;
      sort = page.sort;
      direction = page.direction;
      page = page.page;
    }
    
    page = page ? page : 0;
    size = size ? size : 10;
    direction = direction ? direction : 'asc';

    parameters.page = page;
    parameters.size = size;

    if (sort){
      parameters.sort = sort + ',' + direction;
    }
  };

  /*
    Same as above, but allows for sorting with multiple sort parameters in the format for Spring.
    The multiple sort parameters will be comma-delimited in the page object
  */
 this.addMultipleSortPageableParameters = function(parameters, page, size, sort, direction) {
   parameters = parameters ? parameters : {};
   if (angular.isObject(page)){
     size = page.size;
     sort = page.sort;
     direction = page.direction;
     page = page.page;
   }
   page = page ? page : 0;
   size = size ? size : 10;

   parameters.page = page;
   parameters.size = size;

   var arr = sort.split(',');
   parameters.sort = [];
   for(let i in arr) {
     parameters.sort.push(arr[i] + ',' + direction);
   }
 };

});
