'use strict';

intellidealerAppCoreModule.service('imagePathService', function ($location) {
  this.getFullPath = function (relativePath) {
    var origin;
    if ($location.host() === 'localhost') {
      origin = 'http://100.78.65.20'
    } else {
      origin = $location.protocol() + '://' + $location.host() + ':' + $location.port();
    }
    return origin + this.getEncodedPath(relativePath);
  };

  this.getEncodedPath = function (path) {
    return path? path.replace(/%/g, '%25').replace(/#/g, '%23') : path;
  }
});
