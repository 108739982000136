'use strict';

intellidealerAppCoreModule.service('loginService', function(contactsResource, $uibModal, globalAlertService) {
  var service = this;

  var selectCustomer = function (customerNumber, contactNumber, company, division, loginCallback) {
    contactsResource.updateSessionCustomer.update(
      {
        customerNumber: customerNumber,
        contactNumber: contactNumber,
        company: company,
        division: division
      },
      function () {
        loginCallback && loginCallback();
      },
      function (response) {
        globalAlertService.handleErrorResponse(response);
      }
    );
  };

  service.populateAssignedContacts = function (loginCallback, company, division) {
    var params = (company && division)? {company: company, division: division} : {};
    contactsResource.alternateContacts.query(params,
      function (data) {
        if (data.length > 1) {
          $uibModal.open({
            templateUrl: 'app/scripts/modules/core/views/templates/multiple-contact-selection.html',
            controller: 'multipleContactSelectionCtrl',
            animation: true,
            backdrop: 'static',
            size: 'lg',
            resolve: {
              properties: function () {
                return {
                  alternateContacts: data,
                  customerSelected: selectCustomer,
                  loginCallback: loginCallback
                };
              }
            }
          })
        } else {
          loginCallback && loginCallback();
        }
      }, function (response) {
        globalAlertService.handleErrorResponse(response);
      }
    );
  }

});
