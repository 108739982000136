'use strict';

intellidealerAppAlertsModule.controller('AlertsController', function ($scope, $state, $stateParams, $filter, $sce, alerts, alertsFilters, search, myDealerAlertTypeAreas, alertHistoryResource) {

  $scope.filters = alertsFilters || {};

  $scope.alerts = alerts;
  $scope.myDealerAlertTypeAreas = myDealerAlertTypeAreas;

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.alerts.date'), property:'alertHistoryId.date', direction: 'desc', isHidden:false},
    {label:$filter('language')('mydealer.ui.global.department'), isHidden: false,property:'alertType.areaType', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.alerts.type'), isHidden: false, property:'alertType.alertTypeName', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.alerts.message'), isHidden: false}
  ];

  if(!sessionStorage.getItem("sortChoices")){
    sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
  }else {
    $scope.sortChoices = JSON.parse(sessionStorage.getItem("sortChoices"));
  }


  $scope.columns = [];

  $scope.sortChoices.forEach(choice => {
    if(choice.label){
      $scope.columns.push({label:choice.label, isSelected:true});
    }
  });

  if(!sessionStorage.getItem("columns")){
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
  }else {
    $scope.columns = JSON.parse(sessionStorage.getItem("columns"));
  }

  $scope.toggleColumns = function(col){
    let index = $scope.columns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.columns[index].isSelected = !$scope.columns[index].isSelected;
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
    let sortIndex = $scope.sortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if(sortIndex >=0){
      $scope.sortChoices[sortIndex].isHidden = !$scope.columns[index].isSelected;
      sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
    }

  }

  $scope.isColVisible = function(colName){
    let index = $scope.columns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.columns[index].isSelected;
  }



  // need to trust values with html to prevent sanitization
  $scope.trustAsHtml = function(html) {
    return $sce.trustAsHtml(html);
  };

  $scope.search = function(event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    var params = {};

    var sortParams = angular.fromJson($stateParams.alertsPagination);
    if (property && direction) {
      sortParams.sort = property;
      sortParams.direction = direction;
    }
    sortParams.page = 0;
    params.alertsPagination = angular.toJson(sortParams);

    //add filters
    params.alertsFilters = angular.toJson($scope.filters);
    $state.go('.', params);
  };

  $scope.selectCard = function(alert) {
    $scope.alerts.content.forEach(function(current) {
      current.selected = (current === alert ? !current.selected : false);
    });
  };

  $scope.getPlainMessage = function(message) {
    return (message ? String(message).replace(/<[^>]+>/gm, '') : '');
  };

  $scope.getCsv = function () {
    const parameters = {
      alertTypeArea: alertsFilters.area,
      keywords: search ? search : ''
    }
    alertHistoryResource.csv(parameters).get(
      null,
      function () {
      },
      function (response) {
        globalAlertService.addError('mydealer.ui.invoices.csv_notGenerated');
      }
    );
  }

});
