'use strict';

intellidealerAppCoreModule.factory('partsResource', function ($resource, appConfigurationService) {

  var resource = {};

  resource.customerPartList = $resource(appConfigurationService.basePath + '/rest/parts/customerPartList/');
  resource.partRequestEmail = $resource(appConfigurationService.basePath + '/rest/parts/partRequestEmail/');
  resource.requestPartList = $resource(appConfigurationService.basePath + '/rest/parts/partListRequestEmail/');
  resource.partAvailabilityByPart = $resource(appConfigurationService.basePath + '/rest/parts/partAvailabilityByPart/');

  return resource;
});
