'use strict';

intellidealerAppCoreModule.factory('dealershipResource', function ($resource, appConfigurationService) {
  return {
    publicDetails: $resource(appConfigurationService.basePath + '/rest/dealership/publicDetails/'),
    dealerContactInformation: $resource(appConfigurationService.basePath + '/rest/dealership/dealerContactInformation/'),
    dealershipShipViaList: $resource(appConfigurationService.basePath + '/rest/dealership/dealershipShipViaList/'),
    dealershipContactTypes: $resource(appConfigurationService.basePath + '/rest/dealership/dealershipContactTypes/'),
    myDealerGeneralInquiryEmail: $resource(appConfigurationService.basePath + '/rest/dealership/myDealerGeneralInquiryEmail/'),
    myDealerBranchSettings: $resource(appConfigurationService.basePath + '/rest/dealership/dealerBranchSettings/')
  }
});
