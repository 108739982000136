'use strict';

intellidealerAppPartsModule.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/scripts/modules/core/views/templates/login-popover-forgot-password.html',
    '<div class="form-group">' +
    '<label class="forgot-password-text">{{\'mydealer.ui.login.forgot_credentials\' | language}}</label>' +
    '<hr class="forgot-password-hr">' +
    '<label class="control-label forgot-password-label normal-font-weight">{{\'mydealer.ui.login.username\' | language}}</label>' +
    '<input type="text" class="form-control" data-ng-model="forgotForm.username" data-ui-keypress="{\'enter\':\'forgotPassword($event)\'}" maxlength="10">' +
    '<label class="control-label forgot-password-label normal-font-weight">{{\'mydealer.ui.login.email\' | language}}</label>' +
    '<input type="text" class="form-control" data-ng-model="forgotForm.emailAddress" data-ui-keypress="{\'enter\':\'forgotPassword($event)\'}" maxlength="60">' +
    '</div>' +
    '<div class="forgot-password-buttons">' +
    '<button class="btn btn-primary" data-ng-disabled="!forgotForm.username && !forgotForm.emailAddress" data-ng-click="forgotPassword();">{{\'mydealer.ui.global.send\' | language}}</button>' +
    '<button class="btn btn-info" data-ng-click="$root.togglePopover(\'forgotPasswordLink\');">{{\'mydealer.ui.login.cancel\' | language}}</button>' +
    '</div>');
}]);
