'use strict';

intellidealerAppEquipmentModule.controller('MyEquipmentDetailsNoLongerOwnController', function ($scope, equipmentResource, globalAlertService) {

  $scope.emailFields = {};

  $scope.preventEnterKey = function(event) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }
  };

  $scope.submit = function() {
    if ($scope.noLongerOwnForm.$valid) {
      equipmentResource.myDealerEquipmentNoLongerOwnRequestEmail.save(
        {
          equipmentNewOwner: $scope.emailFields.newOwnersName,
          equipmentRemovalDate: $scope.emailFields.date ? new Date($scope.emailFields.date).getTime() : null,
          equipmentStatus: $scope.emailFields.status,
          equipmentStockNumber: $scope.model.equipmentId.stockNumber,
          inquiryRequest: $scope.emailFields.additionInformation
        },
        function(){
          globalAlertService.addSuccess('mydealer.ui.global.email_sent');
          $scope.configuration.selected = false;
        },
        globalAlertService.handleErrorResponse
      );
    }
  };

});
