'use strict';

//Escapes an entire path for special characters. Not slashes.
intellidealerAppCoreModule.filter('escapeLansaimg', function () {

  function escapeRegExp(string) {
    return string.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  }

  return function(input) {
    var escapedString = encodeURI(input);
    escapedString = escapedString.replace(new RegExp(escapeRegExp('#'), 'g'), '%23');
    escapedString = escapedString.replace(new RegExp(escapeRegExp('$'), 'g'), '%24');
    escapedString = escapedString.replace(new RegExp(escapeRegExp('&'), 'g'), '%26');
    escapedString = escapedString.replace(new RegExp(escapeRegExp('+'), 'g'), '%2B');
    escapedString = escapedString.replace(new RegExp(escapeRegExp(','), 'g'), '%2C');
    escapedString = escapedString.replace(new RegExp(escapeRegExp(':(?!\d+/)'), 'g'), '%3A'); //try not to match port identifier
    escapedString = escapedString.replace(new RegExp(escapeRegExp(';'), 'g'), '%3B');
    escapedString = escapedString.replace(new RegExp(escapeRegExp('='), 'g'), '%3D');
    escapedString = escapedString.replace(new RegExp(escapeRegExp('?'), 'g'), '%3F');
    escapedString = escapedString.replace(new RegExp(escapeRegExp('@'), 'g'), '%40');

    return escapedString;
  };
});