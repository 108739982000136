'use strict';

intellidealerAppCoreModule.directive('sortingHeadings', [function() {
  return {
    restrict: 'A',
    scope: {
      options: '=',
      selectAll: '@',
      queryParams: '@'
    },
    transclude: true,
    template: '<th data-ng-transclude data-ng-if="selectAll"></th><th data-sortable="{{option.property}}" data-ng-attr-query-params="{{queryParams}}" data-ng-repeat="option in options" data-ng-class="{\'sortable\': option.property,\'table-align-right\': option.align === \'right\'}" data-ng-if="!option.isHidden">{{option.label | language }}</th>'
  };

}]);
