'use strict';

// Replaces the image src with our no-image image if ng-src is falsy or if the image fails to load.
// Can optionally hide the image if the attribute value "hide" is used
intellidealerAppCoreModule.directive('noImage', function(appConfigurationService) {
  return {
    restrict: 'A',
    scope: {
      noImage: '@',
    },
    link: function (scope, element, attrs) {
      if (!attrs.ngSrc) {
        element.attr('src', appConfigurationService.noImageUrl);
        if (angular.lowercase(scope.noImage) === 'hide') {
          element.hide();
        }
      }    

      function noImageCallback(event) {
        if (event.type === 'error') {
          element.attr('src', appConfigurationService.noImageUrl);
          if (angular.lowercase(scope.noImage) === 'hide') {
            element.hide();
          }
        }
      }
      element.bind('error', noImageCallback);
    }
  };
});