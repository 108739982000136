'use strict';

intellidealerAppCoreModule.factory('equipmentResource', function ($resource, appConfigurationService) {

  var resource = {};
  resource.equipmentDetails = $resource(appConfigurationService.basePath + '/rest/equipment/equipmentDetails/');
  resource.assignedEquipment =  $resource(appConfigurationService.basePath + '/rest/equipment/assignedEquipment/');
  resource.dealershipRentalEquipment =  $resource(appConfigurationService.basePath + '/rest/equipment/dealershipRentalEquipment/');
  resource.dealershipRentalEquipmentDetails =  $resource(appConfigurationService.basePath + '/rest/equipment/dealershipRentalEquipmentDetails/');
  resource.equipmentTypeSearchValues =  $resource(appConfigurationService.basePath + '/rest/equipment/equipmentTypeSearchValues/');
  resource.equipmentStatusSearchValues =  $resource(appConfigurationService.basePath + '/rest/equipment/equipmentStatusSearchValues/');
  resource.myDealerEquipmentAddUnitRequestEmail =  $resource(appConfigurationService.basePath + '/rest/equipment/myDealerEquipmentAddUnitRequestEmail/');
  resource.equipmentNoLongerOwnedStatusSearchValues =  $resource(appConfigurationService.basePath + '/rest/equipment/equipmentNoLongerOwnedStatusSearchValues/');
  resource.updateAssignedEquipmentHours = $resource(
    appConfigurationService.basePath + '/rest/equipment/updateAssignedEquipmentHours/',
    null,
    {
      update: {
        method: 'PUT'
      }
    }
  );
  resource.myDealerEquipmentNoLongerOwnRequestEmail =  $resource(appConfigurationService.basePath + '/rest/equipment/myDealerEquipmentNoLongerOwnRequestEmail/');
  resource.equipmentReadyToSellStatusSearchValues =  $resource(appConfigurationService.basePath + '/rest/equipment/equipmentReadyToSellStatusSearchValues/');
  resource.myDealerEquipmentSellTradeRequestEmail =  $resource(appConfigurationService.basePath + '/rest/equipment/myDealerEquipmentSellTradeRequestEmail/');
  resource.createEquipmentCustomerHistory =  $resource(appConfigurationService.basePath + '/rest/equipmentcustomerhistory/createEquipmentCustomerHistory/');

  resource.getFleet = function(options) {
    return $resource(appConfigurationService.basePath + '/rest/telematics/myDealerFleet',
      options, {get: {method: 'GET', isArray: true}})
  };
  resource.getUnit = $resource(appConfigurationService.basePath + '/rest/telematics/unit')
  resource.getAempGpsCompanies =
    function(params) {
      return $resource(appConfigurationService.basePath + '/rest/oem/aemp/properties/aempGpsCompanies', params, {get: {method: 'GET', isArray: true}})
    };

  resource.convertBytes = function (byteArray) {
    const binaryString = window.atob(byteArray);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  function getDateStamp(){
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var monthStr = month +1 < 9 ? "0"+(month+1) : month+1;
    var dayStr = day < 9 ? "0"+(day) : day;
    var stamp = year + '_' + monthStr + '_' + dayStr;
    return stamp;
  }
  resource.csv = function (parameters) {
    return $resource(
      appConfigurationService.basePath + '/rest/equipment/exportAssignedEquipmentCsv/',
      parameters,
      {
        get: {
          method: 'GET',
          url: appConfigurationService.basePath + '/rest/equipment/exportAssignedEquipmentCsv/',
          interceptor: {
            response: function (response) {
              if (response.status === 200) {
                var blob = new Blob([resource.convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob, 'equipment' + '.xls');
                } else {
                  var fileUrl = URL.createObjectURL(blob);
                  var downloadLink = document.createElement('a');
                  downloadLink.href = fileUrl;
                  downloadLink.download = 'MyEquipment_' + getDateStamp() +'.xls';
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                }
              }
            }
          }
        }
      }
    );
  };

  resource.equipmentRentXls = function (parameters) {
    return $resource(
      appConfigurationService.basePath + '/rest/equipment/exportEquipmentRentCsv/',
      parameters,
      {
        get: {
          method: 'GET',
          url: appConfigurationService.basePath + '/rest/equipment/exportEquipmentRentCsv/',
          interceptor: {
            response: function (response) {
              if (response.status === 200) {
                var blob = new Blob([resource.convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob, 'assignedEquipments' + '.xls');
                } else {
                  var fileUrl = URL.createObjectURL(blob);
                  var downloadLink = document.createElement('a');
                  downloadLink.href = fileUrl;
                  downloadLink.download = 'EquipmentForRent' +'_'+ getDateStamp() + '.xls';
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                }
              }
            }
          }
        }
      }
    );
  };

  return resource;
});
