'use strict';

intellidealerAppCoreModule.service('sessionService', function(publicResource, securityResource, globalAlertService) {
  var versionPromise;
  var features = {};
  var isIREnabled;

  this.getVersionPromise = function () {
    if (!versionPromise) {
      versionPromise = publicResource.version.get().$promise;
    }
    return versionPromise;
  };

  this.populateFeatures = function () {
    return securityResource.features.query(
      {},
      function (response) {
        angular.forEach(response, function (feature) {
          features[feature.featureCode] = feature.licensed;
        });
      },
      globalAlertService.handleErrorResponse
    ).$promise.then(function () {
      return features;
    });
  };

  this.isIREnabled = function () {
    return securityResource.isIREnabled.get(
      {},
      function (response) {
        isIREnabled = response.isIREnabled;
      },
      globalAlertService.handleErrorResponse
    ).$promise.then(function () {
      return isIREnabled;
    });
  };

  this.getFeatures = function () {
    return angular.copy(features);
  };
});
