'use strict';

intellidealerAppMyAccountModule.controller('paymentTypeSelectionController',
  function ($scope, $rootScope, $uibModalInstance, properties) {
    $scope.callback = properties.callback;
    $scope.paymentType = '';

    $scope.dismiss = function (value) {
      $uibModalInstance.close(value);
    };

    $scope.selectedPaymentType = function () {
      $scope.callback($scope.paymentType);
      $scope.dismiss();
    };
  });
