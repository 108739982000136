'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.myEquipment', {
      url: '/my-equipment' +
        '?myEquipmentPagination' +
        '&force' +
        '&fromCart' +
        '&{search:any}',
      params: {
        myEquipmentPagination: angular.toJson({
          sort: 'description',
          direction: 'asc'
        })
      },
      templateUrl: 'app/scripts/modules/equipment/views/my-equipment.html',
      controller: 'MyEquipmentController',
      resolve: {
        help: function () {
          return 'Equipment-_My_Equipment.htm';
        },
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.my_equipment.breadcrumb'));
        }],

        authorization: ['userConfiguration', function(userConfiguration) {
          return userConfiguration.isAuthorized(['myDealerEquipmentAccess']);
        }],

        myEquipmentPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.myEquipmentPagination);
        }],

        myEquipment: [
          'pageableService', 
          'equipmentResource', 
          'globalAlertService', 
          'myEquipmentPagination',
          '$stateParams',
          function(pageableService, equipmentResource, globalAlertService, myEquipmentPagination, $stateParams) {
            var parameters = {
              keywords: ($stateParams.search ? $stateParams.search : '')
            };
            pageableService.addPageableParameters(
              parameters,
              myEquipmentPagination
            );

            return equipmentResource.assignedEquipment.get(
              parameters, 
              function () {}, 
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ]
      }
    }
  );
}]);
