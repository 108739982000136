'use strict';

intellidealerAppPartsModule.controller('MyShoppingListsDetailsController', function ($scope, $state, $filter, $stateParams,userConfiguration, PartsTopLevelTabsService, myShoppingListsDetails, shoppingCartService, globalAlertService, onlinePartsOrdersResource) {

  $scope.showPrice = userConfiguration.displayPrices;
  $scope.linkedStates = PartsTopLevelTabsService;
  $scope.myShoppingListsDetails = myShoppingListsDetails;
  $scope.anySelected = false;

  $scope.DetailsSortChoices = [
    {label:$filter('language')('mydealer.ui.parts.part_number'), property:'partNumber', direction: 'asc', isHidden: false},
    {label:$filter('language')('mydealer.ui.parts.description'), isHidden: false},
    {label:$filter('language')('mydealer.ui.parts.quantity'), align: 'right', isHidden: false}
  ];
  if(!sessionStorage.getItem("DetailsSortChoices")){
    sessionStorage.setItem("DetailsSortChoices",JSON.stringify($scope.DetailsSortChoices))
  }else {
    $scope.DetailsSortChoices = JSON.parse(sessionStorage.getItem("DetailsSortChoices"));
  }

  $scope.detailsColumns = [];
  $scope.DetailsSortChoices.forEach(choice => {
    if(choice.label && choice.label != $filter('language')('')){
      $scope.detailsColumns.push({label:choice.label, isSelected:true});
    }
  });

  if(!sessionStorage.getItem("detailsColumns")){
    sessionStorage.setItem("detailsColumns",JSON.stringify($scope.detailsColumns))
  }else {
    $scope.detailsColumns = JSON.parse(sessionStorage.getItem("detailsColumns"));
  }

  $scope.toggleColumns = function(col){
    let index = $scope.detailsColumns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.detailsColumns[index].isSelected = !$scope.detailsColumns[index].isSelected;
    sessionStorage.setItem("detailsColumns",JSON.stringify($scope.detailsColumns))
    let sortIndex = $scope.DetailsSortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if(sortIndex >=0){
      $scope.DetailsSortChoices[sortIndex].isHidden = !$scope.detailsColumns[index].isSelected;
      sessionStorage.setItem("DetailsSortChoices",JSON.stringify($scope.DetailsSortChoices))
    }
  }
  $scope.myShoppingListsDetails.content.forEach(shoppingListsDetail => {
    if(sessionStorage.getItem(shoppingListsDetail.partNumber +'chk') === "true"
      || (sessionStorage.getItem("selectAllToggle") === "true" && (sessionStorage.getItem(shoppingListsDetail.partNumber+'unchk') === null ||
        sessionStorage.getItem(shoppingListsDetail.partNumber +'unchk') === "false"))) {
      shoppingListsDetail.selected = true;
    }else{
      shoppingListsDetail.selected= false;
    }
  });

  $scope.isSelectedCheckbox = function(event,shoppingListsDetail){
    if(shoppingListsDetail.selected){
      sessionStorage.setItem(shoppingListsDetail.partNumber+ 'unchk','true');
      sessionStorage.setItem(shoppingListsDetail.partNumber+'chk','false');
    }else{
      sessionStorage.setItem(shoppingListsDetail.partNumber+'chk','true');
      sessionStorage.setItem(shoppingListsDetail.partNumber+'unchk','false');
    }
  }
  $scope.isColVisible = function(colName){
    let index = $scope.detailsColumns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.detailsColumns[index].isSelected;
  }

  $scope.$watch(function() {
    var anySelected = false;
    $scope.myShoppingListsDetails.content.forEach(function(current) {
      if (current.selected) {
        anySelected = true;
      }
    });
    Object.keys(sessionStorage).forEach(key => {
      if(key.includes("chk") && sessionStorage.getItem(key) === 'true' && !key.includes("unchk"))
        anySelected = true;
    })
    return anySelected;
  }, function (newValue) {
    $scope.anySelected = newValue;
  });

  $scope.addPartsToCart = function() {
    var parameters = {
      company: $stateParams.company,
      division: $stateParams.division,
      location: $stateParams.location,
      referenceNumber: $filter('pathParam')($stateParams.referenceNumber)
    };
    onlinePartsOrdersResource.assignedOnlineOrderDetailsByList.getOnlineOrdersDetailsList(parameters).get(
      null,
      function () {
      },
      globalAlertService.handleErrorResponse
    ).$promise.then(function (response) {
      let data = JSON.stringify(response);
      let allShoppingList = JSON.parse(data);
      let processedShoppingList = [];
      JSON.parse(allShoppingList).forEach(shoppingListsDetail => {
        if ((sessionStorage.getItem("selectAllToggle") === "true" && !(sessionStorage.getItem(shoppingListsDetail.partNumber + 'unchk') === "true")) || sessionStorage.getItem(shoppingListsDetail.partNumber + 'chk') === "true") {
          shoppingListsDetail = angular.copy(shoppingListsDetail);
          shoppingListsDetail.selected = true;
        }
        processedShoppingList.push(shoppingListsDetail);
      });

      var selectedParts = [];
      processedShoppingList.forEach(function (current) {
        if (current.selected) {
          selectedParts.push({
            partNumber: current.partNumber,
            quantity: current.quantity
          });
        }
      });
      if (selectedParts.length == 0) {
        $scope.myShoppingListsDetails.content.forEach(shoppingListsDetail => {
          if (shoppingListsDetail.selected)
            selectedParts.push({
              partNumber: shoppingListsDetail.partNumber,
              quantity: shoppingListsDetail.quantity
            });
        })
      }

      if (selectedParts.length > 0) {
        shoppingCartService.addItemList(selectedParts).then(
          function () {
            $state.reload($state.current);
          },
          function () {
          }
        );
      }
    });
  };

  $scope.toggleCardMenu = function(event, shoppingListsDetail) {
    event.stopPropagation();
    shoppingListsDetail.isMenuOpen = !shoppingListsDetail.isMenuOpen;
  };

  $scope.selectCard = function(shoppingListsDetail) {
    $scope.myShoppingListsDetails.content.forEach(function(current) {
      current.selected = (current === shoppingListsDetail ? true : false);
    });
  };

  $scope.getCsv = function () {
    var parameters = {
      company: $stateParams.company,
      division: $stateParams.division,
      location: $stateParams.location,
      referenceNumber: $filter('pathParam')($stateParams.referenceNumber),
      screenName: 'ShoppingList',
    };
    onlinePartsOrdersResource.exportCsvDetailsData.csv(parameters).get(
      null,
      function () {
      },
      function (response) {
        globalAlertService.addError('mydealer.ui.equipment.csv_notGenerated');
      });
  };

  $scope.previousPage = function() {
    sessionStorage.removeItem("selectAllToggle");
    $state.go('navigation.parts.myShoppingLists');
  }

});
