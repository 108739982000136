'use strict';

intellidealerAppEquipmentModule.controller('EquipmentForRentController', function ($scope, $state, globalAlertService, $stateParams, equipmentResource, $filter, equipmentForRent, equipmentForRentFilters, userConfiguration, myDealerDealershipLinks, customizedMessagesService, dealershipConfiguration, sessionService) {

  $scope.filters = equipmentForRentFilters || {};
  $scope.datepickers= {};
  $scope.customizedMessage = null;
  $scope.showLocationInRental = dealershipConfiguration.showLocationInRental;

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.equipment.description'), property:'description', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.equipment.make'), property:'make', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.equipment.model'), property:'model', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.equipment.category'), property:'category', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.equipment.class'), property:'equipmentClass', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.equipment.subclass'), property:'equipmentSubClass', direction: 'asc'},
    {}
  ];
  if ($scope.showLocationInRental) {
    $scope.sortChoices.splice(1, 0, {
      label: $filter('language')('mydealer.ui.global.location'),
      property: 'location',
      direction: 'asc'
    });
  }

  $scope.equipmentForRent = equipmentForRent;
  $scope.terms = {
    keywords:  $stateParams.search
  };

  // load the dealership link for equipment for sale
  var dealershipEquipmentSaleLink = null;
  angular.forEach(myDealerDealershipLinks, function(value) {
    if (value.subType === 'EQUIPMENT_FOR_SALE' && (!value.location || value.location === userConfiguration.businessLocation)) {
      dealershipEquipmentSaleLink = value;
    }
  });

  $scope.linkedStates = [];
  if (userConfiguration.myDealerEquipmentSalesAccess && !dealershipEquipmentSaleLink) {
    $scope.linkedStates.push({
      title: $filter('language')('mydealer.ui.equipment_for_sale.breadcrumb'),
      state:'navigation.equipment.equipmentForSale'});
  }
  if (sessionService.getFeatures()['PFW.6512'] && userConfiguration.accessRentalOptions) {
    $scope.linkedStates.push({
      title:$filter('language')('mydealer.ui.equipment_for_rent.breadcrumb'),
      state:'navigation.equipment.equipmentForRent'});
  }
  if(!sessionStorage.getItem("sortChoices")){
    sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
  }else {
    $scope.sortChoices = JSON.parse(sessionStorage.getItem("sortChoices"));
  }

  $scope.columns = [];

  $scope.sortChoices.forEach(choice => {
    if(choice.label && choice.label != $filter('language')('mydealer.ui.equipment.subclass')){
      $scope.columns.push({label:choice.label, isSelected:true});
    }
  });

  if(!sessionStorage.getItem("columns")){
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
  }else {
    $scope.columns = JSON.parse(sessionStorage.getItem("columns"));
  }
  $scope.toggleColumns = function(col){
    let index = $scope.columns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.columns[index].isSelected = !$scope.columns[index].isSelected;
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
    let sortIndex = $scope.sortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if(sortIndex >=0){
      $scope.sortChoices[sortIndex].isHidden = !$scope.columns[index].isSelected;
      sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
    }

  }
  $scope.isColVisible = function(colName){
    let index = $scope.columns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.columns[index].isSelected;
  }
  $scope.search = function(event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    if ($scope.filtersform.$valid) {
      var params = {};

      var sortParams = angular.fromJson($stateParams.equipmentForRentPagination);
      //re-sort if sorting properties passed in
      if (property && direction) {
        sortParams.sort = property;
        sortParams.direction = direction;
      }
      sortParams.page = 0;
      params.equipmentForRentPagination = angular.toJson(sortParams);

      //add filters
      params.equipmentForRentFilters = angular.toJson($scope.filters);

      //add keywords
      params.search = $scope.terms.keywords;

      $state.go('.', params);
    }
  };

  $scope.showFilters = !!(
    $scope.filters.startDate ||
    $scope.filters.endDate
  );
  $scope.toggleFilters = function() {
    $scope.showFilters = !$scope.showFilters;
  };

  $scope.getCsv = function () {
    var parameters = {
      keywords: ($stateParams.search ? $stateParams.search : ''),
      startDate: $scope.filters.startDate,
      endDate: $scope.filters.endDate
    };
    equipmentResource.equipmentRentXls(parameters).get(
      null,
      function () {
      },
      function (response) {
        globalAlertService.addError('mydealer.ui.equipment.csv_notGenerated');
      }
    );
  };
  // set the customized message if one is configured
  function loadCustomizedMessage() {
    $scope.customizedMessage = null;
    angular.forEach(customizedMessagesService.getMessages(), function(value) {
      if (value.referenceNumber === 'RENTALAVAIL' && (!value.location || value.location === userConfiguration.businessLocation)) {
        $scope.customizedMessage = value.description;
      }
    });
  }

  loadCustomizedMessage();

});
