'use strict';

intellidealerApp.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/scripts/modules/core/views/templates/validation-popover.html',
                     '<div data-ng-messages="errorObject" data-ng-messages-multiple>' +
                     '<div data-ng-message="required">{{\'mydealer.ui.global.required_error\' | language}}</div>' +
                     '<div data-ng-message="email">{{\'mydealer.ui.global.email_error\' | language}}</div>' +
                     '<div data-ng-message="phoneNumber">{{\'mydealer.ui.global.phone_number_error\' | language}}</div>' +
                     '<div data-ng-message="number">{{\'mydealer.ui.global.number_error\' | language}}</div>' +
                     '<div data-ng-message="currency">{{\'mydealer.ui.global.currency_error\' | language}}</div>' +
                     '<div data-ng-message="currencyRange">{{\'mydealer.ui.global.currency_range\' | language | range: minvalue: maxvalue }}</div>' +
                     '<div data-ng-message="date">{{\'mydealer.ui.global.date_error\' | language}}</div>' +
                     '<div data-ng-message="backend">{{backendError | language}}</div>' +
                     '<div data-ng-message="exceedsMaxLength">{{\'mydealer.ui.global.exceeds_length\' | language | maxValueLength: maxValueLength}}</div>' +
                     '</div>');
}]);
