'use strict';

intellidealerAppCoreModule.controller('DashboardController',
  function ($scope, userConfiguration, accountSummary, dealershipNewsList, dealerContactInformation, alertHistory, $location, $anchorScroll, dealershipConfiguration, myDealerDealershipLinks, topPartPurchases, customerAlerts, sessionService,locationService,myDealerBranchSettings, multiMediaStreamingURLService) {

    $scope.dealerType = dealershipConfiguration.dealerType.symbol;
    $scope.myDealerHomePageImg = myDealerBranchSettings.myDealerHomePageImg && (multiMediaStreamingURLService.getMultiMediaStreamingDealerSettingURL(dealershipConfiguration.remoteServerPath, myDealerBranchSettings, false));
    $scope.myDealerBranchLogoImg = myDealerBranchSettings.myDealerBranchLogoImg && (multiMediaStreamingURLService.getMultiMediaStreamingDealerSettingURL(dealershipConfiguration.remoteServerPath, myDealerBranchSettings, true));
    $scope.slides = [];
    var dealershipWidgetInformation = {
      dealerContactInformation: dealerContactInformation,
      dealershipLogo: dealershipConfiguration.remoteServerPath + dealershipConfiguration.dealershipLogo,
      myDealerBranchLogoImg: $scope.myDealerBranchLogoImg ? $scope.myDealerBranchLogoImg.trim() : $scope.myDealerBranchLogoImg

    };
    $scope.slides.push({template: 'app/scripts/modules/core/views/templates/dashboard-dealership-widget.html', model: dealershipWidgetInformation});
    if (userConfiguration.myDealerAccountAccess && userConfiguration.usesInHouseAR) {
      $scope.slides.push({template: 'app/scripts/modules/core/views/templates/dashboard-account-widget.html', model: accountSummary});
    }
    if (userConfiguration.myDealerPartsAccess) {
      $scope.slides.push({template: 'app/scripts/modules/core/views/templates/dashboard-top-parts-widget.html', model: topPartPurchases});
    }
    if (userConfiguration.myDealerAlertManagementAccess && userConfiguration.hideAlerts !== true && customerAlerts.length > 0) {
      $scope.slides.push({template: 'app/scripts/modules/core/views/templates/dashboard-notification-widget.html', model: alertHistory});
    }

    // load the dealership link for equipment for sale
    var dealershipEquipmentSaleLink = null;
    angular.forEach(myDealerDealershipLinks, function(value) {
      if (value.subType === 'EQUIPMENT_FOR_SALE' && (!value.location || value.location === userConfiguration.businessLocation)) {
        dealershipEquipmentSaleLink = value;
      }
    });

    $scope.omniSearchConfiguration = [
      {
        state: 'navigation.myAccount.invoices',
        searchParameter: 'search',
        placeholder: 'mydealer.ui.invoices.placeholder',
        text: 'mydealer.ui.invoices.breadcrumb',
        show: userConfiguration.myDealerAccountAccess
      },
      {
        state: 'navigation.equipment.myEquipment',
        searchParameter: 'search',
        placeholder: 'mydealer.ui.my_equipment.placeholder',
        text: 'mydealer.ui.my_equipment.breadcrumb',
        show: userConfiguration.myDealerEquipmentAccess
      },
      {
        state: 'navigation.equipment.myRentals',
        searchParameter: 'myRentalsSearch',
        placeholder: 'mydealer.ui.my_rentals.placeholder',
        text: 'mydealer.ui.my_rentals.breadcrumb',
        show: sessionService.getFeatures()['PFW.6512'] && userConfiguration.myDealerEquipmentAccess
      },
      {
        state: 'navigation.equipment.equipmentForSale',
        searchParameter: 'search',
        placeholder: 'mydealer.ui.equipment_for_sale.placeholder',
        text: 'mydealer.ui.equipment_for_sale.breadcrumb',
        show: userConfiguration.myDealerEquipmentSalesAccess && !dealershipEquipmentSaleLink
      },
      {
        state: 'navigation.equipment.equipmentForRent',
        searchParameter: 'search',
        placeholder: 'mydealer.ui.equipment_for_rent.placeholder',
        text: 'mydealer.ui.equipment_for_rent.breadcrumb',
        show: sessionService.getFeatures()['PFW.6512'] && userConfiguration.accessRentalOptions
      },
      {
        state: 'navigation.parts.shopForParts',
        searchParameter: 'shopForPartsSearch',
        placeholder: 'mydealer.ui.shop_for_parts.placeholder',
        text: 'mydealer.ui.shop_for_parts.breadcrumb',
        show: userConfiguration.myDealerPartsAccess
      },
      {
        state: 'navigation.parts.orderHistory',
        searchParameter: 'orderHistorySearch',
        placeholder: 'mydealer.ui.my_parts_purchases.placeholder',
        text: 'mydealer.ui.my_parts_purchases.breadcrumb',
        show: userConfiguration.myDealerPartsAccess
      },
      {
        state: 'navigation.quotes',
        searchParameter: 'search',
        placeholder: 'mydealer.ui.quotes.placeholder',
        text: 'mydealer.ui.quotes.breadcrumb',
        show: userConfiguration.myDealerQuoteAccess
      }
    ];

    // determine if the omni search has any available options
    $scope.showOmniSearch = false;
    angular.forEach($scope.omniSearchConfiguration, function(value) {
      if (value.show) {
        $scope.showOmniSearch = true;
      }
    });

    // setup the data and functions for the news view
    $scope.newsSlides = [];
    $scope.newsControl = {};
    $scope.newsArticle = {
      // the displayed article, or 'null' for the slide listing
      model: null
    };
    $scope.showNewsArticle = function(newsModel) {
      $scope.newsArticle.model = newsModel;
      $scope.newsControl.stop();
      // $location.hash('newsContent');
      // $anchorScroll();
    };
    $scope.hideNewsArticle = function() {
      $scope.newsArticle.model = null;
      $scope.newsControl.start();
      // $location.hash('newsHeading');
      // $anchorScroll();
    };

    // load news articles into the slide array
    angular.forEach(dealershipNewsList.content, function(item) {
      // add the functions to be used for callbacks
      item.showNewsArticle = $scope.showNewsArticle;
      item.hideNewsArticle = $scope.hideNewsArticle;
      $scope.newsSlides.push({
        template: 'app/scripts/modules/core/views/templates/dashboard-news-slide.html',
        model: item
      });
    });

});
