'use strict';

intellidealerAppShoppingCartModule.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/scripts/modules/shopping-cart/views/templates/shopping-cart-popover-empty-cart.html',
                     '<div class="button-row-wrapper shopping-cart-buttons-only">' +
                     '<div>' +
                     '<button class="btn btn-info" data-ng-click="$root.togglePopover(\'emptyCartButton\');">{{\'mydealer.ui.global.cancel\' | language}}</button>' +
                     '<button class="btn btn-primary" data-ng-click="removeAllItems();">{{\'mydealer.ui.shopping_cart.empty\' | language}}</button>' +
                     '</div>' +
                     '</div>');
}]);
