'use strict';

/*
  The visual display of errors is handled by a bunch of different directives.  This directive takes priority, adds those directives, removes itself and then recompiles the element
*/
intellidealerAppCoreModule.directive('validationPopover', ['$compile', function($compile) {
  return {
    restrict: 'A',
    priority: 1000,
    require: '^form',
    terminal: true,
    link: function (scope, element, attrs, formObject) {

      //add error popover directives
      element.attr('data-tooltip-placement', 'bottom');
      element.attr('data-tooltip-trigger', 'focus');
      element.attr('data-tooltip-class', 'error-tooltip');
      element.attr('data-uib-tooltip-template', '\'app/scripts/modules/core/views/templates/validation-popover.html\'');

      //Add our error message scoping directive
      element.attr('data-validation-popover-internal', attrs.validationPopover);

      //remove this directive and recompile the element with the new directives added
      element.removeAttr('validation-popover');
      element.removeAttr('data-validation-popover');

      //used for range filter
      scope.minvalue = element.attr('minvalue');
      scope.maxvalue = element.attr('maxvalue');

      $compile(element)(scope);
    }
  };
}]);
