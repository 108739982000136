'use strict';

intellidealerAppShoppingCartModule.controller('ShoppingCartSaveListController', function ($scope, $location, $anchorScroll, globalAlertService, shoppingCartResource, shoppingCartService) {

  $scope.saveList = {};
  $scope.shoppingCartService = shoppingCartService;

  $scope.scrollHere = function() {
    $location.hash('saveList');
    $anchorScroll();
  };

  $scope.close = function() {
    $scope.toggleSaveListForm(false);
  };

  $scope.save = function() {
    if ($scope.saveListForm.$valid) {
      shoppingCartResource.saveCartToShoppingList.saveList(
        {
          referenceNumber: $scope.saveList.listname,
          description: $scope.saveList.description
        },
        null, // don't send json payload
        function(){
          globalAlertService.addSuccess('mydealer.ui.my_shopping_lists.list_saved');
          $scope.close();
        },
        globalAlertService.handleErrorResponse
      );
    }
  };

});
