'use strict';

intellidealerAppCoreModule.config(['$httpProvider', function($httpProvider) {
  $httpProvider.interceptors.push(['loadingIndicatorService', '$q', function(loadingIndicatorService, $q) {
    return {
      'request': function(request) {
        if (request.cache) {
          if (request.cache.get(request.url)) {
            loadingIndicatorService.addCachedRequest(request.url);
            return request;
          }
        }
        loadingIndicatorService.addRequest();
        return request;
      },
      'response': function(response) {
        if (loadingIndicatorService.removeCachedRequest(response.config.url)) {
          return response;
        }
        loadingIndicatorService.removeRequest();
        return response;
      },
      'responseError': function(response) {
        if (loadingIndicatorService.removeCachedRequest(response.config.url)) {
          return $q.reject(response);
        }
        loadingIndicatorService.removeRequest();
        return $q.reject(response);
      }
    };
  }]);
}]);

intellidealerAppCoreModule
  .service('loadingIndicatorService', ['$timeout', function ($timeout) {

    var outstandingRequests = 0;
    var isLoading = false;
    var checkIfLoaded;
    var outstandingCachedRequests = [];

    this.addCachedRequest = function(url) {
      outstandingCachedRequests.push(url);
    };

    this.removeCachedRequest = function(url) {
      var cachedIndex = outstandingCachedRequests.indexOf(url);
      if (cachedIndex > -1) {
        outstandingCachedRequests.splice(cachedIndex, 1);
        return true;
      }
      return false;
    };

    this.isPendingCachedRequests = function() {
      return (outstandingCachedRequests.length > 0);
    };

    this.addRequest = function() {
      $timeout.cancel(checkIfLoaded);
      isLoading = true;
      outstandingRequests ++;
    };

    //If the outstanding requests are 0 after this call, it will wait 1/10th of a second to make sure there were no sequential requests before removing the loading indicator
    this.removeRequest = function() {
      outstandingRequests --;
      if (outstandingRequests === 0) {
        checkIfLoaded = $timeout(
          function() {
            isLoading = false;
          },
          100
        );
      }
    };

    this.isLoading = function() {
      return isLoading;
    };
  }]);
