'use strict';

intellidealerAppShoppingCartModule.controller('shoppingCartController', function ($scope, $rootScope, $state, $uibModal, $timeout, $filter, $window, $stateParams, locationService, publicModeService, userConfiguration, globalAlertService, shoppingCartService, partsResource, $anchorScroll, $location) {

  $scope.hasEquipmentAccess = userConfiguration.myDealerEquipmentAccess;
  $scope.showAvailability = userConfiguration.displayAvailability !== 'NONE';
  $scope.showPrice = userConfiguration.displayPrices;
  $scope.newItem = {};
  $scope.notFoundItems = shoppingCartService.notFoundItems;
  $scope.displayPartsCatalog = userConfiguration.displayPartsCatalogLink;
  $scope.saveListForm = {
    toggle: false
  };
  $scope.shoppingCart = shoppingCartService.cart;
  $scope.userLocations = {};
  $scope.assignedLocations = locationService.getAssignedLocations();
  $scope.getUnitDescription = shoppingCartService.getUnitDescription;

  $scope.freshStateAfterDeere = false;

  $scope.userLocations.location = locationService.getDefaultLocation();
  locationService.setDefaultLocation($scope.userLocations.location);
  $scope.latLongValue = '';
  $scope.cityOrPostalCode = '';
  $scope.modalInstance = {};
  $scope.publicMode = ($rootScope.isPublicState && !$rootScope.guestLoggedin);

  var userLocation = '';
  $scope.updateWarning = false;

  var showWarning;

  //pagination for parts not added panel
  $scope.notFoundPartTable = {
    'currentPage': 1,
    'numPerPage': 5,
    'maxSize': 5
  };

  $scope.filteredNotFoundItems = [];
  $scope.requestItems = [];

  $scope.$watch("notFoundPartTable.currentPage + notFoundPartTable.numPerPage", function () {
    var begin = (($scope.notFoundPartTable.currentPage - 1) * $scope.notFoundPartTable.numPerPage),
      end = begin + $scope.notFoundPartTable.numPerPage;
    $scope.filteredNotFoundItems = $scope.notFoundItems.items.slice(begin, end);
  });

  $scope.$watch('notFoundItems.items', function (oldVal, newVal) {
    updateFilteredItems(oldVal, newVal);
  });

  $scope.clearSelected = function () {
    for (var i = $scope.notFoundItems.items.length - 1; i >= 0; --i) {
      if (typeof $scope.notFoundItems.items[i].selected != 'undefined' && $scope.notFoundItems.items[i].selected) {
        $scope.clearItem(i);
        $scope.anySelected = false;
      }
    }
  };
  $scope.clearItem = function (item) {
    $scope.notFoundItems.items.splice(item, 1);
    updateFilteredItems($scope.notFoundItems.items, $scope.filteredNotFoundItems);
    $scope.anySelected = false;
  };

  $scope.removeEmailForm = function (index) {
    $scope.requestItems.splice(index, 1);
  };

  // copy the cart so form binding don't update the service directly
  // add a watch on the cart items so changes will reflect on the form
  $scope.$watch('shoppingCart.items', function () {
    $scope.shoppingCartForm = angular.copy($scope.shoppingCart);
    $scope.related($scope.shoppingCartForm.items);
    angular.forEach($scope.shoppingCartForm.items, function (item) {
      item.availability = item.availabilityOnHand;
      item.prevQuantity = item.quantity;
    });
  });

  $scope.getLatLongByAddress = function () {
      locationService.getGeoLocationForAddress(($rootScope.isPublicState && !$rootScope.guestLoggedin), $scope.cityOrPostalCode).then(function (response) {
        $scope.assignedLocations = response;
        $scope.cityOrPostalCode = locationService.getCityOrPostalCode();
      });
  };

  $scope.showSortedLocations = function () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        userLocation = `${position.coords.latitude},${position.coords.longitude}`;
        locationService.populateAssignedLocations($scope.publicMode, userLocation).then(function () {
          $scope.assignedLocations = locationService.getAssignedLocations();
          localStorage.setItem('latLongValue', userLocation);
          $scope.cityOrPostalCode = '';
          locationService.clearCityPostalCode();
          globalAlertService.addSuccess('mydealer.ui.parts.userLocation.success');
        });
      }, function (error) {
        locationService.showError(error)
      });
    } else {
      globalAlertService.addError('mydealer.ui.parts.userLocation.permission_denied_error');
    };
  }

  $scope.showLocationsOnMap = function () {
    $scope.modalInstance = $uibModal.open({
      templateUrl: 'app/scripts/modules/parts/geolocation/home/geolocation.html',
      controller: 'geolocationController',
      scope: $scope,
      size: 'lg',
      animation: true,
      windowClass: 'geoLocation-modal',
      keyboard: false,
      backdrop: true,
    });
  }

  //To retain Zip/postal code value
  $scope.$watch('cityOrPostalCode', function (newVal,oldValue) {
    if (newVal === oldValue && locationService.getCityOrPostalCode()) {
      $scope.cityOrPostalCode = locationService.getCityOrPostalCode();
    }
  });

  //To capture map marker click
  $scope.$on('$setMarkerClickDefaultLocation', function (event, data) {
      $scope.userLocations.location = data.location;
      locationService.setDefaultLocation($scope.userLocations.location);
    }
  );

  function updateFilteredItems(newVal, oldVal) {
    if (newVal !== oldVal || typeof oldVal == "undefined") {
      $scope.filteredNotFoundItems = angular.copy($scope.notFoundItems.items);
    }
    var begin = (($scope.notFoundPartTable.currentPage - 1) * $scope.notFoundPartTable.numPerPage);
    var end = begin + $scope.notFoundPartTable.numPerPage;
    $scope.filteredNotFoundItems = $scope.notFoundItems.items.slice(begin, end);
  }

  shoppingCartService.registerObserverCallback(updateFilteredItems);

  $scope.anySelected = false;

  $scope.$watch(function () {
    var anySelected = false;
    $scope.notFoundItems.items.forEach(function (current) {
      if (current.selected) {
        anySelected = true;
      }
    });
    return anySelected;
  }, function (newValue) {
    $scope.anySelected = newValue;
  });

  $scope.requestParts = function () {
    //get a list of the selected parts from notFoundItems
    var requestedNotFoundItems = [];
    for (var i = 0; i < $scope.notFoundItems.items.length; ++i) {
      if ($scope.notFoundItems.items[i].selected)
        requestedNotFoundItems.push($scope.notFoundItems.items[i]);
    }

    //send the list to the request for parts endpoint
    shoppingCartService.requestPartEmail(requestedNotFoundItems);
  };

  $scope.partNotFoundFields = [
    {label: $filter('language')('mydealer.ui.parts.part_number')},
    {label: $filter('language')('mydealer.ui.parts.quantity')},
    {label: $filter('language')('mydealer.ui.parts.manufacturer')},
    {label: $filter('language')('mydealer.ui.parts.description')}
  ];

  $scope.sortChoices = [
    {label: $filter('language')('mydealer.ui.parts.quantity')},
    {},
    {label: $filter('language')('mydealer.ui.parts.part_number')},
    {label: $filter('language')('mydealer.ui.parts.description')}
  ];
  if ($scope.showAvailability) {
    $scope.sortChoices.push({label: $filter('language')('mydealer.ui.parts.locationAvailability'), align: 'right'});
    $scope.sortChoices.push({label: $filter('language')('mydealer.ui.parts.totalAvailability'), align: 'right'});
  }
  $scope.sortChoices.push({label: $filter('language')('mydealer.ui.shopping_cart.extended_weight'), align: 'right'});
  if ($scope.showPrice) {
    $scope.sortChoices.push({label: $filter('language')('mydealer.ui.parts.price'), align: 'right'});
    $scope.sortChoices.push({label: $filter('language')('mydealer.ui.parts.extended_price'), align: 'right'});
  }
  $scope.sortChoices.push({});

  if (!$rootScope.isPublicState) {
    $scope.sortChoices.splice(1, 0, {label: $filter('language')('mydealer.ui.shopping_cart.unit')});
  }

  $scope.setDefaultLocation = function () {
    publicModeService.clearDeereDealerNumber();
    $scope.userLocations && locationService.setDefaultLocation($scope.userLocations.location);
    $state.reload($state.current).then(function () {
      shoppingCartService.loadShoppingCart();
    });
  };

  // use jQuery to trigger a click event on the 'parent' element
  // should be done in a more standard angular way, if possible
  $scope.closePopover = function (id) {
    var element = angular.element('#' + id);
    $timeout(function () {
      element.trigger('click');
    });
  };

  // Don't enable the shopping cart button if no location is selected
  // Would only be an issue in public mode
  $scope.showShopCart = null;
  if ($scope.userLocations.location) {
    $scope.showShopCart = 'Y';
  }

  $scope.toggleSaveListForm = function (state) {
    $scope.saveListForm.toggle = (state ? state : !$scope.saveListForm.toggle);
  };

  $scope.showWarning = function (partNumber, partObject) {
    $uibModal.open({
      templateUrl: 'app/scripts/modules/my-account/views/templates/confirmation-view.html',
      controller: 'confirmationController',
      size: 'sm',
      animation: false,
      windowClass: 'center-modal',
      keyboard: false,
      backdrop: 'static',
      resolve: {
        properties: function () {
          return {
            labelText: `${'mydealer.ui.global.remove_message'}`,
            buttons: {
              yes: `${'mydealer.ui.global.cancel'}`,
            }
          };
        }
      }
    }).result.then(function(value) {
      if(value === 'yes') {
          partObject.quantity = partObject.prevQuantity;
          partObject.updateQuantity = '';
      }
      showWarning = false;
    });
  };

  $scope.removeSubs = function () {
    $scope.shoppingCartForm && $scope.shoppingCartForm.items && $scope.shoppingCartForm.items.forEach(function (value) {
      value.expanded = false;
    });
  };

  $scope.addItem = function (quantity, partNumber, unit, event, partObject) {
    if (event && event.preventDefault) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    if (!partNumber || partNumber.length <= 0) {
      return;
    }

    $scope.removeSubs();

    if (isNaN(quantity) || quantity.length == 0) {
      quantity = 1;
    }

    if (quantity == 0) {
      this.removeOneItem(partNumber, partObject);
      $scope.newItem.quantity = '';
      $scope.newItem.partNumber = '';
      placeCursorInQuantityField();
    } else {
      quantity = Number(quantity);
      shoppingCartService.addItem(quantity, partNumber, partObject, unit).then(function (data) {
        if (data && data.items && data.items.length > 0 && $scope.displayPartsCatalog) {
          $timeout(function () {
            $location.hash('parts-not-found');
            $anchorScroll();
          }, 0);
        }
      });
      $scope.newItem.quantity = '';
      $scope.newItem.partNumber = '';
      $scope.newItem.unit = '';
      $scope.newItem.unitDescription = '';
      placeCursorInQuantityField();
    }
  };

  function placeCursorInQuantityField() {
    $timeout(function () {
      if (window.innerWidth > 993 && document.getElementById('newItemQuantityField')) {
        document.getElementById('newItemQuantityField').focus();
      } else if (window.innerWidth < 993 && document.getElementById('newItemQuantityCardField')) {
        document.getElementById('newItemQuantityCardField').focus();
      }
    })
  }

  placeCursorInQuantityField();

  $scope.updateItem = function (partNumber, quantity, cartItem, cart, togglerId, index, event) {

    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    if (!quantity) {
      return;
    }

    quantity = Number(quantity);
    if (quantity === 0) {
      $scope.removeOneItem(partNumber, cartItem, cart, togglerId, index);
    }

    if (!isNaN(quantity) && quantity > 0) {

      for(const cartItem of cart){
        if(cartItem.relatedPartList !== undefined && cartItem.relatedPartList.length > 0) {
          for(const part of cartItem.relatedPartList){
            if(part.partNumber === partNumber && quantity < cartItem.quantity * part.quantity && !part.prompt){
              showWarning = true;
              break;
            }
          }
        }
      }

      if(showWarning){
        $scope.updateWarning = true;
        $scope.showWarning(partNumber, cartItem);
      }else{
        shoppingCartService.updateItem(partNumber, quantity, cartItem);
      }
      $rootScope.togglePopover(togglerId + index);
    }
  };

  $scope.removeOneItem = function (partNumber, partObject, cart, togglerId, index) {
    $rootScope.togglePopover(togglerId + index);

    if(partObject.relatedAvailable){
      $scope.resetQuantity(cart);
    }

    for(const cartItem of cart){
      if(cartItem.relatedPartList !== undefined && cartItem.relatedPartList.length > 0) {
        for(const part of cartItem.relatedPartList){
          if(part.partNumber === partNumber && !part.prompt){
            showWarning = true;
            break;
          }
        }
      }
    }

    if(showWarning){
      $scope.updateWarning = false;
      $scope.showWarning(partNumber, partObject);
    }else{
      shoppingCartService.removeOneItem(partNumber, partObject).then(() => $scope.closePopover(togglerId));
    }
    partObject.addedQty = '';
  };

  $scope.related = function(items){
    var relatedNumbers = []
    var mandatoryRelatedNumbers=[];
    items.forEach(item => {
      if (item.relatedPartList && item.relatedPartList.length > 0){
        item.relatedPartList.forEach(relatedPart =>{
          relatedNumbers.push(relatedPart.partNumber);
          if(relatedPart.prompt==false){
            mandatoryRelatedNumbers.push(relatedPart.partNumber)
          }
        })
      }
    })
    $scope.relatedPartNumbers = relatedNumbers;
    $scope.relatedMandatoryPartNumbers = mandatoryRelatedNumbers;
  }

  $scope.removeAllItems = function () {
    $scope.closeOpenPopover();
    shoppingCartService.removeAllItems();
  };

  $scope.openPartProfile = function (cartItem) {
    publicModeService.clearCameFromDeere();
    if (cartItem.hasMultimedia) {
      var toState = publicModeService.getStateWithPrefix($rootScope.isPublicState, '.shopping.shoppingCart.partDetail');
      $state.go(toState, {partNumber: cartItem.partNumber, partDetails: cartItem});
    }
  };

  $scope.selectUnits = function () {
    sessionStorage.clear();
    $state.go('navigation.equipment.myEquipment', {fromCart: true});
  };

  $scope.continueShopping = function () {
    publicModeService.clearCameFromDeere();
    $state.go(publicModeService.getStateWithPrefix($rootScope.isPublicState, '.parts.shopForParts'));
  };

  $scope.showUnits = function (event, item, callback) {
    $uibModal.open({
      templateUrl: 'app/scripts/modules/shopping-cart/views/templates/unit-selection-popup.html',
      controller: 'unitSelectionPopupController',
      size: 'md',
      animation: true,
      windowClass: 'center-modal',
      keyboard: false,
      backdrop: 'static',
      resolve: {
        properties: function () {
          return {
            units: $scope.shoppingCart.cartUnits,
            selectedItem: item,
            callback: callback
          };
        }
      }
    })
  };

  $scope.checkoutItems = function () {
    publicModeService.clearCameFromDeere();
    if (!$rootScope.isPublicState) {
      $state.go('navigation.shopping.shoppingCart.checkout');
    } else {
      $scope.closeOpenPopover();
      if ($rootScope.guestLoggedin) {
        $state.go('public.shopping.shoppingCart.checkout');
      } else {
        return $uibModal.open({
          templateUrl: 'app/scripts/modules/shopping-cart/views/guest-checkout-prompt.html',
          controller: 'guestCheckoutPromptController',
          size: 'md',
          animation: true,
          windowClass: 'center-modal',
          keyboard: false,
          backdrop: 'static',
          resolve: {
            properties: function () {
              return {
                userConfiguration: userConfiguration,
                cartItems: angular.copy($scope.shoppingCart.items),
                guestOnlineOrderInfo: {
                  company: userConfiguration.contactId.company,
                  division: userConfiguration.contactId.division,
                  location: userConfiguration.businessLocation,
                  customerNumber: userConfiguration.contactId.customerNumber,
                  referenceNumber: publicModeService.getCartReferenceNumber()
                }
              };
            }
          }
        }).result;
      }
    }
  };

  // get the availability list for the popover contents
  $scope.loadCartItemAvailability = function (cartItem) {
    if (cartItem && (!cartItem.availability || cartItem.availability <= 0)) {
      return;
    }
    // if already have list, do nothing
    if (cartItem && !cartItem.partAvailabilityList) {
      partsResource.partAvailabilityByPart.query(
        {partNumber: cartItem.partNumber},
        function (value) {
          // if successful, add the list to the cart item
          cartItem.partAvailabilityList = value;
        },
        function () {
          // if failed, add an empty list to the cart item
          cartItem.partAvailabilityList = [];
        }
      );
    }
  };

  $scope.removeRequestItem = function (index) {
    $scope.filteredNotFoundItems.splice(index, 1);
  };

  $scope.toggleCardMenu = function (event, cartItem) {
    event.stopPropagation();
    if (!cartItem.isMenuOpen) {
      $scope.closeOpenPopover();
    }
    cartItem.isMenuOpen = !cartItem.isMenuOpen;
  };

  var openPopoverId = null;
  $scope.closeOpenPopover = function (event, id, cartItem) {
    if (event) {
      var target = event.target;
      if ($.isWindow(target)) {
        return;
      }
      while (target && target.tagName !== 'TD' && target.tagName !== 'BUTTON') {
        target = target.parentElement;
      }
      if (target && angular.element(target).hasClass('has-popover')) {
        return;
      }
    }
    if (openPopoverId) {
      if (openPopoverId !== id) {
        var closeId = openPopoverId;
        if (closeId.length > 5 && closeId.substr(0, 5) === 'avail') {
          $timeout(function () {
            angular.element('#' + closeId).trigger('toggleAvailabilityPopover');
          });
        } else {
          $timeout(function () {
            if (cartItem == undefined) {
              angular.element('#' + closeId).trigger('togglePopover');
            }
          });
        }
        openPopoverId = id;
      } else {
        openPopoverId = null;
      }

    } else {
      openPopoverId = id;
    }

  };

  angular.element($window).bind('resize', $scope.closeOpenPopover);

  $rootScope.$on('$locationChangeStart', function () {
    if (publicModeService.getCameFromDeere() && $scope.freshStateAfterDeere) {
      history.go(-1);
    }
  });

  $rootScope.$on('$locationChangeSuccess', function () {
    $scope.freshStateAfterDeere = true;
  });

  if ($stateParams.cartId) { //and user has deere access.
    //make call to johnDeere
    shoppingCartService.PartsCatalogUpdate($stateParams.cartId, $stateParams['dealer-id']).then(function () {
      $location.search('cartId', null);
      publicModeService.setDeereDealerNumber($stateParams['dealer-id']);
    });
  }

  if ($stateParams.catchACartId) {
    shoppingCartService.catchACartUpdate($stateParams.catchACartId).then(function (results) {
      // Removing catch cart Id from URL once cart is caught, so that re-hitting the url will not cause the parts to be re-added.
      $location.search('catchACartId', null);
      publicModeService.setCameFromDeere();
      publicModeService.setDeereDealerNumber(results.dealerNumber);
    });
  }

});
