'use strict';

/*
  Very loose checking of phone number validity.  Matches the regex used on the back end (except accepts no value)
*/
intellidealerAppCoreModule.directive('validPhoneNumber', [function() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, required) {
      required.$validators.phoneNumber = function(modelValue, viewValue) {
        if (!modelValue) {
          return true;
        }
        var phoneNumberRegex = /^([0-9+\(\)#\.\s\/ext-]+)?$/;
        if (phoneNumberRegex.test(viewValue)) { 
          return true;
        } 
        return false;    
      };
    }
  };
}]);