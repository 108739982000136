'use strict';

intellidealerAppMyAccountModule.controller('ProfileChangePasswordController', function ($scope, $location, $anchorScroll, globalAlertService, securityResource) {

  $scope.changePassword = {};

  $scope.scrollHere = function() {
    $location.hash('changePassword');
    $anchorScroll();
  };

  $scope.close = function() {
    $scope.toggleChangePasswordForm(false);
  };

  $scope.submit = function() {
    if ($scope.changePasswordForm.$valid) {
      globalAlertService.clearErrors();

      if ($scope.changePassword.newPassword !== $scope.changePassword.confirmPassword) {
        globalAlertService.addError('mydealer.ui.profile.confirm_password_does_not_match');
        return;
      }

      if($scope.changePassword.newPassword.length > 0 && $scope.changePassword.newPassword.trim() === '') {
        globalAlertService.addError('mydealer.ui.profile.invalid_password');
        return;
      }

      securityResource.updateMyDealerPassword.save(
        {
          oldPassword: $scope.changePassword.oldPassword,
          newPassword: $scope.changePassword.newPassword
        },
        function(){
          globalAlertService.addSuccess('mydealer.ui.profile.password_changed');
          $scope.close();
        },
        globalAlertService.handleErrorResponse
      );
    }
  };

});
