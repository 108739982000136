'use strict';

intellidealerAppCoreModule.controller('DashboardNewsSlideController', function ($scope, appConfigurationService, $sce) {

  $scope.getImageUrl = function(timestamp) {
    return (timestamp ? appConfigurationService.basePath +
            '/rest/news/dealershipNewsImageByTimestamp?timestamp=' + moment(timestamp).valueOf() +
            '&width=250&height=150' : '');
  };

  // need to trust values with html to prevent sanitization
  $scope.trustAsHtml = function(html) {
    return $sce.trustAsHtml(html);
  };

});
