'use strict';

intellidealerAppCoreModule.config(['$httpProvider', function($httpProvider) {
  $httpProvider.interceptors.push(['loginRedirectService', '$q', function(loginRedirectService, $q) {
    return {
      response: function(response) {
        // loginRedirectService.requireLogin(response);
        return response;
      },
      responseError: function(response) {
        loginRedirectService.requireLogin(response);
        return $q.reject(response);
      }
    };
  }]);
}]);

intellidealerAppCoreModule.service('loginRedirectService', ['$location', '$stateParams', '$rootScope', '$timeout', function($location, $stateParams, $rootScope, $timeout) {

  this.requireLogin = function(response) {
    var url = $location.url();
    var path = $location.path();
    var library = $stateParams.library ? $stateParams.library.toUpperCase() : $stateParams.library;
    if (!library) {
      var paths = path.split('/');
      if (paths.length > 1 && paths[1]) {
        library = paths[1];
      }
    }
    if (response.status === 401 && library && path.indexOf('/login/') !== 0 && path.indexOf('/forgot-password') !== 0) {
      $timeout(function() {
        $rootScope.$apply(function() {
          $location.path('/login/' + library)
            .search('redirectTo', url)
            .search('_', (new Date()).getTime());
        });
      });
    }
  };

  this.continueLogin = function(redirectTo) {
    $timeout(function() {
      $rootScope.$apply(function() {
        $location.url(redirectTo);
      });
    });
  };

}]);
