'use strict';

intellidealerAppCoreModule.factory('serviceAgreementsResource', function ($resource, appConfigurationService) {
  
  var resource = {}; 
  resource.assignedServiceAgreementsByStockNumber = $resource(appConfigurationService.basePath + '/rest/serviceagreements/assignedServiceAgreementsByStockNumber');
  resource.assignedServiceAgreementsDetails = $resource(appConfigurationService.basePath + '/rest/serviceagreements/assignedServiceAgreementsDetails');
  resource.serviceAgreementProgramsByMakeModel = $resource(appConfigurationService.basePath + '/rest/serviceagreements/serviceAgreementProgramsByMakeModel');
  resource.serviceAgreementScheduleByMakeModelProgram = $resource(appConfigurationService.basePath + '/rest/serviceagreements/serviceAgreementScheduleByMakeModelProgram');
  
  return resource;
});
