'use strict';

intellidealerAppCoreModule.directive('pagination', ['$state', '$stateParams',function($state, $stateParams) {
  return {
    restrict: 'A',
    scope: {
      page: '=',
      totalElements: '=',
      queryParams: '@'
    },
    transclude: true,
    template: '' +
      '  <div class = "row">' +
      '                <div class = "col-sm-4">' +
      '                  <ul uib-pagination boundary-links="true" total-items="totalElements" items-per-page ="page.pageSize" ng-model="internalPageNumber" class="pagination-lg-custom" previous-text="&lsaquo;" next-text="&rsaquo;" first-text="&laquo;" last-text="&raquo;"></ul>' +
      '                </div>' +
      '                <div class = "col-sm-4" style="margin-top:30px; text-align: center">' +
      '                  <p data-ng-if="totalElements">Showing {{(internalPageNumber-1) * page.pageSize + 1}} to {{(internalPageNumber) * page.pageSize < totalElements ? (internalPageNumber) * page.pageSize : totalElements}}  of {{totalElements}} Results</p>' +
      '                  <p data-ng-if="!totalElements">Sorry, but no information was found.  </p>' +
      '                </div>' +
      '                <div class = "col-sm-4" style="margin-top:30px; text-align: right">' +
      '                  <p>Page Size: <span><a class="p5" href  ng-click="selectPageSize(10)" ng-class="{sel:page.pageSize == 10}">10</a> | <a href class="p5"  ng-click="selectPageSize(20)" ng-class="{sel:page.pageSize == 20}">20</a> | <a href class="p5" ng-click="selectPageSize(50)" ng-class="{ sel: page.pageSize == 50}">50</a> | <a class="p5" href ng-click="selectPageSize(100)" ng-class=" { sel: page.pageSize == 100}">100</a></span></p>' +
      '                </div>' +
      '            </div>',
    controller: ['$scope', '$element', function($scope, $element) {
      $scope.internalPageNumber =  $scope.page.pageNumber+1;
      $scope.queryParameterName = {};
      if (!$scope.queryParams) {
        $scope.queryParameterName = $element.inheritedData('$uiView').state.self.name.split('.');
        $scope.queryParameterName = ($scope.queryParameterName[$scope.queryParameterName.length - 1] + 'Pagination');
      }
      else {
        $scope.queryParameterName = $scope.queryParams;
      }

      $scope.queryParameter = $stateParams[$scope.queryParameterName] ? angular.fromJson($stateParams[$scope.queryParameterName]) : {};
      $scope.paging = function() {
        var queryObject = {};
        var totalPages = Math.ceil($scope.totalElements/$scope.queryParameter.size);
        $scope.queryParameter.page = totalPages - 1 < $scope.internalPageNumber - 1?totalPages - 1:$scope.internalPageNumber - 1 ;
        queryObject[$scope.queryParameterName] = angular.toJson($scope.queryParameter);
        return queryObject;
      };

      $scope.selectPageSize= function(size) {
        $scope.queryParameter.size = size;
      }

    }],
    link: function (scope, element) {
      if (scope.page) {
        element.bind('click', function() {
          $state.go('.', scope.paging());
        });
      }
    }
  };
}]);
