'use strict';

intellidealerAppCoreModule.controller('DashboardDealershipWidgetController', function ($scope, multiMediaStreamingURLService) {
  $scope.logo = $scope.slide.model.dealershipLogo;
  $scope.contact = $scope.slide.model.dealerContactInformation;
  $scope.dealershipLogoBranch = $scope.slide.model.myDealerBranchLogoImg;
  $scope.img="";
  if(!$scope.dealershipLogoBranch || $scope.dealershipLogoBranch == ""){
    $scope.img=multiMediaStreamingURLService.getMultiMediaStreamingWebConfigURL($scope.logo, true);
  }else{
    $scope.img=$scope.dealershipLogoBranch;
  }

});
