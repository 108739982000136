'use strict';

intellidealerAppCoreModule.service('customizedMessagesService', ['memosResource', function(memosResource) {
  var messages = [];
  
  this.populateLoginMessages = function(library) {
    return memosResource.publicCustomizedMessages.query(
      {libraryName: library},
      function (value) {
        messages = value;
      }
    ).$promise;
  };

  this.populatePublicMessages = function(library, company, division) {
    return memosResource.publicCustomizedMessages.query(
      {libraryName: library, company: company, division: division},
      function (value) {
        messages = value;
      }
    ).$promise;
  };

  this.populateAssignedMessages = function() {
    return memosResource.myDealerCustomizedMessages.query(
      null,
      function (value) {
        messages = value;
      }
    ).$promise;
  };
  
  this.getMessages = function() {
    return messages;
  };
}]);
