'use strict';

intellidealerAppEquipmentModule.controller('serviceHistoryController', function ($scope, $state, $stateParams, $filter, invoices, customerEquipmentHistories, appConfigurationService) {

  $scope.serviceHistoryType = localStorage.getItem("serviceHistoryType") || 'Dealer';

  if ($scope.serviceHistoryType === 'Dealer'){
    $scope.invoices = invoices;
  } else {
    $scope.customerEquipmentHistories = customerEquipmentHistories;
  }

  $scope.searchBarPlaceholder = ($scope.serviceHistoryType === 'Dealer')?
    $filter('language')('mydealer.ui.service_history.dealer.placeholder') : $filter('language')('mydealer.ui.service_history.added_by_me.placeholder');

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.invoices.invoice_number'), property:'equipmentHistoryId.invoiceNumber', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.global.date'), property:'convertedBillingDate', direction: 'desc'},
    {label:$filter('language')('mydealer.ui.invoices.description'), property:'description', direction: 'asc'},
    {}
  ];

  $scope.customerAddedServiceHistoryHeaders = [
    {label:$filter('language')('mydealer.ui.global.date'), property:'equipmentCustomerHistoryId.date', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.invoices.description'), property:'equipmentCustomerHistoryId.description', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.hours'), property:'equipmentCustomerHistoryId.hours', direction: 'desc'},
    {label:$filter('language')('mydealer.ui.invoices.repairDollars'), property:'equipmentCustomerHistoryId.repairDollars', direction: 'asc'}
  ];

  $scope.toggleSearch = function(dealerSearch) {
    localStorage.setItem("serviceHistoryType",  dealerSearch ? 'Dealer' : 'Customer');
    $state.transitionTo($state.current, $stateParams, {
      reload: true, inherit: false, notify: true
    });
  };

  $scope.terms = {
    keywords:  $stateParams.serviceHistorySearch
  };

  $scope.search = function(event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    var params = {};
    var sortParams = angular.fromJson($stateParams.serviceHistoryPagination);
    if (property && direction) {
      sortParams.sort = property;
      sortParams.direction = direction;
    }
    sortParams.page = 0;
    params.serviceHistoryPagination = angular.toJson(sortParams);

    params.serviceHistorySearch = $scope.terms.keywords;
    $state.go('.', params);
  };

  $scope.getPDFUrl = function(transactionIdString) {
    return (appConfigurationService.basePath + '/rest/invoicehistory/invoiceAsPdf?transactionId=' + transactionIdString);
  };

});
