'use strict';

intellidealerAppCoreModule.service('shoppingCartService', function (globalAlertService, shoppingCartResource, $state, locationService, publicModeService, cdkAnalyticsService) {
  var self = this;

  // the cart object which contains an array of items and total amounts
  this.cart = {
    referenceNumber: null,
    items: [],
    subtotal: 0,
    discount: 0,
    total: 0,
    totalWeight: 0,
    relatedPartNumbers: [],
  };

  this.notFoundItems = {
    items: []
  };

  this.clearNotFoundItems = function () {
    this.notFoundItems = {items: []};
  };

  this.updateNotFoundList = function (listOfItems) {
    //loop through this.notFoundItems and loop through listOfItems and add items / update quantity
    var itemAlreadyInTheList = false;

    if (this.notFoundItems.items.length === 0) {
      this.notFoundItems.items = listOfItems;
      notifyObservers();
      return;
    }

    for (var i = 0; i < listOfItems.length; ++i) {
      for (var k = 0; k < this.notFoundItems.items.length; ++k) {
        if (this.notFoundItems.items[k].partNumber === listOfItems[i].partNumber) {
          this.notFoundItems.items[k].quantity += listOfItems[i].quantity;
          itemAlreadyInTheList = true;
          break;
        }
      }
      if (!itemAlreadyInTheList) {
        this.notFoundItems.items.push(listOfItems[i]);
      }
      itemAlreadyInTheList = false;
    }
    notifyObservers();
  };

  var observerCallbacks = [];
  this.registerObserverCallback = function (callback) {
    observerCallbacks.push(callback);
  };

  var notifyObservers = function () {
    angular.forEach(observerCallbacks, function (callback) {
      callback();
    });
  };

  this.addItem = function (quantity, partNumber, partObject, unit, manufacturer, description) {
    return shoppingCartResource.addPartToShoppingCart.addPart(
      {
        partNumber: partNumber.toUpperCase(),
        quantity: (!isNaN(quantity) && quantity > 0 ? quantity : 1),
        defaultLocation: locationService.getDefaultLocation(),
        unit: (!isNaN(unit) && unit > 0 ? unit : 0)
      },
      null, // don't send json payload
      function (data) {
        self.loadShoppingCart();
        if (data.items && data.items.length) {

          //populate description and manufacturer if provided
          if(manufacturer){
            data.items.forEach(function(item){
              item.manufacturer = manufacturer;
            });
          }
          if(description){
            data.items.forEach(function(item){
              item.description = description;
            });
          }

          self.updateNotFoundList(data.items);
        } else {
          cdkAnalyticsService.logAddToCart(partNumber, quantity, partObject);
          globalAlertService.addSuccess('mydealer.ui.shopping_cart.item_added');
        }
      }, function (httpResponse) {
        globalAlertService.handleErrorResponse(httpResponse);
        self.loadShoppingCart();
      }
    ).$promise;
  };

  this.updateUnit = function (equipment, equipmentSearchMethod) {
    var remove = equipment.onCart && (equipment.onCart === true);
    return shoppingCartResource.updateUnit.update(
      {
        stockNumber: equipment.equipmentId.stockNumber,
        description: equipment.description,
        machineSerialNumber: equipment.machineSerialNumber,
        remove: remove
      },
      function () {
        equipmentSearchMethod(undefined, undefined, undefined, true);
      }, function (httpResponse) {
        globalAlertService.handleErrorResponse(httpResponse);
      }
    ).$promise;
  };

  this.addItemList = function (itemList) {
    return shoppingCartResource.addPartsFromList.save(
      {
        cartPartItemDTOList: itemList
      },
      function () {
        self.loadShoppingCart();
        globalAlertService.addSuccess('mydealer.ui.shopping_cart.item_list_added');
        sessionStorage.clear();
      }, function (httpResponse) {
        globalAlertService.handleErrorResponse(httpResponse);
        self.loadShoppingCart();
      }
    ).$promise;
  };

  this.updateItem = function (partNumber, quantity, partObject) {
    if (isNaN(quantity)) {
      return;
    }
    quantity = parseInt(quantity);
    if (quantity < 0 || quantity === 0) {
      return this.removeOneItem(partNumber, partObject);
    }

    return shoppingCartResource.updatePartQuantity.update(
      {
        partNumber: partNumber,
        quantity: quantity,
        defaultLocation: locationService.getDefaultLocation(),
        unit: partObject.unit,
      },
      null, // don't send json payload
      function () {
        self.loadShoppingCart();
        globalAlertService.addSuccess('mydealer.ui.shopping_cart.item_updated');
      },
      globalAlertService.handleErrorResponse
    ).$promise;
  };

  this.removeOneItem = function (partNumber, partObject) {
    return shoppingCartResource.removeFromShoppingCart.removeOne(
      {
        partNumber: partNumber,
        defaultLocation: locationService.getDefaultLocation(),
      },
      function () {
        cdkAnalyticsService.logRemoveFromCart(partNumber, partObject);
        self.loadShoppingCart();
        globalAlertService.addSuccess('mydealer.ui.shopping_cart.item_removed');
      },
      globalAlertService.handleErrorResponse
    ).$promise;
  };

  this.removeAllItems = function () {
    return shoppingCartResource.cart.remove(
      null,
      function () {
        cdkAnalyticsService.logRemoveAllFromCart(self.cart.items);
        self.loadShoppingCart();
        globalAlertService.addSuccess('mydealer.ui.shopping_cart.cart_cleared');
      },
      globalAlertService.handleErrorResponse
    ).$promise;
  };

  var addUnit = function (unit, index) {
    if (!unit.stockNumber || unit.stockNumber === '') {
      return;
    }
    var addedUnit = angular.copy(unit);
    addedUnit.index = index;
    self.cart.cartUnits.push(addedUnit);
  };

  this.getUnitDescription = function (index) {
    var unitDescription = '';
    if (self.cart.cartUnits) {
      for (var i = 0; i < self.cart.cartUnits.length; ++i) {
        if (self.cart.cartUnits[i].index === index) {
          return self.cart.cartUnits[i].description;
        }
      }
    }
    return unitDescription;
  };

  this.loadShoppingCart = function () {
    return shoppingCartResource.cart.get(
      {
        defaultLocation: locationService.getDefaultLocation()
      },
      function (data) {
        self.cart.referenceNumber = data.referenceNumber;
        self.cart.items = data.cartItems;
        self.cart.subtotal = data.subtotal;
        self.cart.discount = data.discount;
        self.cart.total = data.total;
        self.cart.totalWeight = data.totalWeight;
        self.cart.cartUnits = [];
        addUnit(data.cartUnits.unit1, 1);
        addUnit(data.cartUnits.unit2, 2);
        addUnit(data.cartUnits.unit3, 3);
        addUnit(data.cartUnits.unit4, 4);
        publicModeService.setCartReferenceNumber(data.referenceNumber);
      },
      globalAlertService.handleErrorResponse
    ).$promise;
  };

  this.PartsCatalogUpdate = function (cartId, dealerId) {
    return shoppingCartResource.getCartWithPartsCatalogUpdate.get({
        'cartId': cartId,
        'dealerId': dealerId
      }, function (data) {
        self.updateNotFoundList(data.items);
        locationService.setDefaultLocation(data.location.location);
        self.loadShoppingCart();
      },
      globalAlertService.handleErrorResponse
    ).$promise;
  };

  this.catchACartUpdate = function(catchACartId) {
    return shoppingCartResource.getCartWithCatchACartUpdate.get({
        'catchACartId': catchACartId
      }, function (data) {
        if (data.items.length > 0) {
          var combinedNotFoundParts = publicModeService.getDeereNotFoundParts()? data.items.concat(publicModeService.getDeereNotFoundParts()) : data.items;
          combinedNotFoundParts = _.uniqBy(combinedNotFoundParts, function (e) {
            return e.partNumber;
          });
          publicModeService.setDeereNotFoundParts(combinedNotFoundParts);
          globalAlertService.addSuccess('mydealer.ui.shopping_cart.deere_not_found_parts');
        }
        locationService.setDefaultLocation(data.location.location);
        self.loadShoppingCart();
      },
      globalAlertService.handleErrorResponse
    ).$promise;
  };

  this.catchACartUpdateGeneric = function (catchACartId) {
    return shoppingCartResource.addPartsFromCatchACartGeneric.get({
        'catchACartId': catchACartId
      }, function (data) {
        self.updateNotFoundList(data.items);
        self.loadShoppingCart();
      },
      globalAlertService.handleErrorResponse
    ).$promise;
  };


  this.requestPartEmail = function (requestedParts) {
    var requestedItems = [];

    for (var i = 0; i < requestedParts.length; ++i) {
      var tempItem = {};
      tempItem.partNumber = requestedParts[i].partNumber || '';
      tempItem.quantity = requestedParts[i].quantity || '';
      tempItem.manufacturer = requestedParts[i].manufacturer || '';
      tempItem.description = requestedParts[i].description || '';
      requestedItems.push(tempItem);
    }

    return shoppingCartResource.requestPartList.save({
      myDealerPartRequestDTOList: requestedItems
    }, function () {
      globalAlertService.addSuccess('mydealer.ui.global.email_sent');
    }, globalAlertService.handleErrorResponse);
  };

  this.mergeGuestCartItems = function (cartItems, guestOnlineOrderInfo) {
    var itemList = cartItems.map(function (cartItem) {
      return {partNumber: cartItem.partNumber, quantity: cartItem.quantity}
    });

    return self.loadShoppingCart().then(function () {
      var cartItemsExist = self.cart.items.length > 0;
      return shoppingCartResource.mergeGuestCartItems.save(
        {
          onlineOrderInfo: guestOnlineOrderInfo,
          cartPartListAddDTO: {cartPartItemDTOList: itemList}
        },
        function () {
          self.loadShoppingCart();
          globalAlertService.addSuccess(cartItemsExist ? 'mydealer.ui.checkout.merge_cart_msg' : 'mydealer.ui.shopping_cart.item_list_added');
        }, function (httpResponse) {
          globalAlertService.handleErrorResponse(httpResponse);
          self.loadShoppingCart();
        }
      ).$promise;
    });
  };

  this.handleShippingAmount = function (shipViaCode) {
    return shoppingCartResource.handleShippingAmount.get(
      {
        shipViaCode: shipViaCode
      },
      function () {
        globalAlertService.addSuccess('mydealer.ui.checkout.total_changed');
      },
      globalAlertService.handleErrorResponse
    ).$promise;
  };
});
