'use strict';

intellidealerAppEquipmentModule.controller('EquipmentForSaleDetailsController', function ($scope, $rootScope, $state, publicModeService, globalAlertService, equipmentSaleDetails, $filter, multiMediaStreamingURLService) {
  $scope.equipmentSaleDetails = equipmentSaleDetails;

  // if images are accessed in public mode stream images without login
  $scope.equipmentSaleDetails.pictureLocations = equipmentSaleDetails.pictureLocations.map((pictureLocation) => {

    let params = multiMediaStreamingURLService.getEquipmentSalesMultimediaParams(equipmentSaleDetails, pictureLocation);

    return $rootScope.isPublicState ? multiMediaStreamingURLService.getMultiMediaStreamingPublicURL(pictureLocation, params) :
      multiMediaStreamingURLService.getMultiMediaStreamingURL(pictureLocation, params);
  });

  $scope.goBack = function () {
    $state.go(publicModeService.getStateWithPrefix($rootScope.isPublicState, '.equipment.equipmentForSale'));
  };

  $scope.showcase = {
    defaultTemplate: 'app/scripts/modules/equipment/views/templates/equipment-for-sale-details-default.html',
    narrow: equipmentSaleDetails.pictureLocations.length < 1,
    selected: '',
    subViews: [
      {'label':$filter('language')('mydealer.ui.equipment.actions.inquire_now'), 'glyphicon':'glyphicon-envelope', 'template': 'app/scripts/modules/equipment/views/templates/equipment-for-sale-details-inquire.html'}
    ],
    extendedHeight: $rootScope.isPublicState,
    goBack: $scope.goBack
  };

  $scope.linkedStates = [];

  function pushState(object, title, state) {
    var populated = false;
    angular.forEach(object, function(value) {
      if (value) {
        populated = true;
      }
    });
    if (populated) {
      $scope.linkedStates.push({title: title, state: publicModeService.getStateWithPrefix($rootScope.isPublicState, state)});
    }
  }

  pushState($scope.equipmentSaleDetails.engine, $filter('language')('mydealer.ui.equipment_for_sale.engine'), '.equipment.equipmentForSale.equipmentForSaleDetails.engine');
  pushState($scope.equipmentSaleDetails.transmission, $filter('language')('mydealer.ui.equipment_for_sale.transmission'), '.equipment.equipmentForSale.equipmentForSaleDetails.transmission');
  pushState($scope.equipmentSaleDetails.differential, $filter('language')('mydealer.ui.equipment_for_sale.differential'), '.equipment.equipmentForSale.equipmentForSaleDetails.differential');
  pushState($scope.equipmentSaleDetails.tire, $filter('language')('mydealer.ui.equipment_for_sale.tire'), '.equipment.equipmentForSale.equipmentForSaleDetails.tires');
  pushState($scope.equipmentSaleDetails.groundEngaging, $filter('language')('mydealer.ui.equipment_for_sale.ground_engaging'), '.equipment.equipmentForSale.equipmentForSaleDetails.groundEngaging');
  pushState($scope.equipmentSaleDetails.hydraulic, $filter('language')('mydealer.ui.equipment_for_sale.hydraulic'), '.equipment.equipmentForSale.equipmentForSaleDetails.hydraulics');
  pushState($scope.equipmentSaleDetails.cab, $filter('language')('mydealer.ui.equipment_for_sale.cab'), '.equipment.equipmentForSale.equipmentForSaleDetails.cab');
});
