'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.myEquipment.myEquipmentDetails.myServiceContract', {
      url: '/my-service-contract' + 
      '?myServiceContractPagination',
      params: {
        myServiceContractPagination: angular.toJson({
          sort: 'contractNumber',
          direction: 'asc'
        })
      },
      templateUrl: 'app/scripts/modules/equipment/views/my-service-contract.html',
      controller: 'MyServiceContractController',
      resolve: {
        
        myServiceContractPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.myServiceContractPagination);
        }],
        
        serviceAgreements: ['pageableService', 
                            'serviceAgreementsResource', 
                            'globalAlertService', 
                            '$stateParams', 
                            '$filter', 
                            'myServiceContractPagination',
                            function(pageableService, serviceAgreementsResource, globalAlertService, $stateParams, $filter, myServiceContractPagination) {

                              var parameters = {
                                stockNumber: $filter('pathParam')($stateParams.stockNumber)
                              };
                              pageableService.addPageableParameters(
                                parameters,
                                myServiceContractPagination
                              );

                              return serviceAgreementsResource.assignedServiceAgreementsByStockNumber.get(
                                parameters, 
                                function () {}, 
                                globalAlertService.handleErrorResponse
                              ).$promise;
                            }
                           ]
      }
    }
  );
}]);
