'use strict';

intellidealerAppCoreModule.factory('equipmentSalesResource', function ($resource, appConfigurationService) {
  var resource = {};
  resource.convertBytes = function (byteArray) {
    const binaryString = window.atob(byteArray);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  function getDateStamp(){
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var monthStr = month +1 < 9 ? "0"+(month+1) : month+1;
    var dayStr = day < 9 ? "0"+(day) : day;
    var stamp = year + '_' + monthStr + '_' + dayStr;
    return stamp;
  }

 return {
    searchValues: $resource(appConfigurationService.basePath + '/rest/equipmentsales/myDealerEquipmentSaleSearchValues/'),
    equipmentSaleDetails: $resource(appConfigurationService.basePath + '/rest/equipmentsales/equipmentSaleDetails/'),
    dealershipEquipment: $resource(appConfigurationService.basePath + '/rest/equipmentsales/dealershipEquipment/'),
    myDealerEquipmentInquiryEmail: $resource(appConfigurationService.basePath + '/rest/equipmentsales/myDealerEquipmentInquiryEmail/'),

   csv:function(parameters){
     return $resource(
       appConfigurationService.basePath + '/rest/equipmentsales/exportAssignedEquipmentSaleCsv',
       parameters,
       {
         get: {
           method: 'GET',
           url: appConfigurationService.basePath +'/rest/equipmentsales/exportAssignedEquipmentSaleCsv',
           interceptor: {
             response: function (response) {
               if (response.status === 200) {
                 var blob = new Blob([resource.convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                 if (window.navigator.msSaveOrOpenBlob) {
                   window.navigator.msSaveOrOpenBlob(blob, 'outstandingInvoices' + '.xls');
                 } else {
                   var fileUrl = URL.createObjectURL(blob);
                   var downloadLink = document.createElement('a');
                   downloadLink.href = fileUrl;
                   downloadLink.download = 'EquipmentForSale' +'_'+ getDateStamp() + '.xls';
                   document.body.appendChild(downloadLink);
                   downloadLink.click();
                   document.body.removeChild(downloadLink);
                 }
               }
             }
           }
         }
       }
     );
   }
}
});
