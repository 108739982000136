'use strict';

intellidealerAppCoreModule.factory('invoiceHistoryResource', function ($resource, appConfigurationService, globalAlertService, pageableService, imagePathService) {

  var resource = {};
  resource.assignedOutstandingInvoices = $resource(appConfigurationService.basePath + '/rest/invoicehistory/assignedOutstandingInvoices');
  resource.assignedHistoricalInvoices = $resource(appConfigurationService.basePath + '/rest/invoicehistory/assignedHistoricalInvoices');

  resource.assignedInvoices = function (invoicesPagination, invoicesFilters, search, userConfiguration) {
    var parameters = {
      keywords: (search ? search : '')
    };

    if (invoicesFilters) {
      if (invoicesFilters.type) {
        parameters.invoiceType = invoicesFilters.type.symbol;
      }
      if (invoicesFilters.location) {
        parameters.location = invoicesFilters.location.location;
      }
      parameters.startDate = invoicesFilters.minDate ? new Date(invoicesFilters.minDate).getTime() : null;
      parameters.endDate = invoicesFilters.maxDate ? new Date(invoicesFilters.maxDate).getTime() : null;
    }

    var action;

    if (invoicesFilters.status === 'O' && userConfiguration.usesInHouseAR) {
      action = resource.assignedOutstandingInvoices;
      if (invoicesPagination.sort === 'integratedRentalDetails.invoiceNumber,billingHistoryCashReceiptId.salesOrderNumber,invoiceNumber') {
        invoicesPagination.sort = invoicesPagination.sort.replace(',invoiceNumber', '');
      }
    }
    else  {
      action = resource.assignedHistoricalInvoices;
      // Correct the sort property names for the INVHH model.
      invoicesPagination = angular.copy(invoicesPagination);
      if (invoicesPagination.sort === 'billingDate') {
        invoicesPagination.sort = 'dateInvoiced';
      } else if (invoicesPagination.sort === 'billingHistoryCashReceiptId.salesOrderNumber') {
        invoicesPagination.sort = 'invoiceNumber';
      } else if (invoicesPagination.sort === 'integratedRentalDetails.invoiceNumber,billingHistoryCashReceiptId.salesOrderNumber,invoiceNumber') {
        invoicesPagination.sort = invoicesPagination.sort.replace(',billingHistoryCashReceiptId.salesOrderNumber', '');
      }
    }

    if(invoicesPagination.sort.includes(',')) {
      pageableService.addMultipleSortPageableParameters(
        parameters,
        invoicesPagination
      );
    } else {
      pageableService.addPageableParameters(
        parameters,
        invoicesPagination
      );
    }

    return action.get(
      parameters,
      function () {
      },
      globalAlertService.handleErrorResponse
    ).$promise;
  };

  resource.assignedInvoiceHistoryTaxSummary = $resource(appConfigurationService.basePath + '/rest/invoicehistory/assignedInvoiceHistoryTaxSummary');

  resource.getDiivison1 = function (parameters) {
    return $resource(
      appConfigurationService.basePath + '/rest/configuration/division1',
      parameters);
  };

  resource.dealerDivisionSettings = function (parameters) {
    return $resource(
      appConfigurationService.basePath + '/rest/dealership/dealerDivisionSettings',
      parameters);
  };


  resource.invoices = function (param, invoicesFilters) {

    if (invoicesFilters) {
      if (invoicesFilters.type) {
        param.invoiceType = invoicesFilters.type.symbol;
      }
      if (invoicesFilters.location) {
        param.location = invoicesFilters.location.location;
      }
      param.startDate = invoicesFilters.minDate ? new Date(invoicesFilters.minDate).getTime() : null;
      param.endDate = invoicesFilters.maxDate ? new Date(invoicesFilters.maxDate).getTime() : null;
    }

    return $resource(
      appConfigurationService.basePath + '/rest/invoicehistory/getAllInvoice',
      param,
      {
        get: {
          method: 'GET',
          url: appConfigurationService.basePath + '/rest/invoicehistory/getAllInvoice',
          responseType: 'arraybuffer',

          interceptor: {
            response: function (response) {
              let decoder = new TextDecoder("utf-8");
              return decoder.decode(response.data);
            }
          }
        }
      }
    );
  };

  resource.pdf = function (parameters) {
    return $resource(
      appConfigurationService.basePath + '/rest/invoicehistory/invoicePdf',
      parameters,
      {
        get: {
          method: 'GET',
          url: appConfigurationService.basePath + '/rest/invoicehistory/invoiceAsPdf',
          responseType: 'arraybuffer',
          interceptor: {
            response: function (response) {
              if (response.status === 200) {
                var blob = new Blob([response.data], {type: 'application/pdf'});
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob, parameters.transactionId + '.pdf');
                } else {
                  var fileUrl = URL.createObjectURL(blob);
                  window.open(fileUrl);
                }
              }
            }
          }
        }
      }
    );
  };

  resource.pdfIR = function (parameters) {
    return $resource(
      appConfigurationService.basePath + '/rest/invoicehistory/invoiceDetails',
      parameters,
      {
        get: {
          method: 'GET',
          url: appConfigurationService.basePath + '/rest/invoicehistory/invoiceDetails',
          responseType: 'arraybuffer',
          interceptor: {
            response: function (response) {
              if (response.status === 200) {
                var blob = new Blob([response.data], {type: 'application/json'});
                blob.text().then(value => {
                  let objectName = JSON.parse(value);
                  window.open(imagePathService.getFullPath(objectName.invoiceLink));
                });
              }
            }
          }
        }
      }
    );
  };

  resource.convertBytes = function (byteArray) {
    const binaryString = window.atob(byteArray);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  function getDateStamp(){
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var monthStr = month +1 < 9 ? "0"+(month+1) : month+1;
    var dayStr = day < 9 ? "0"+(day) : day;
    var stamp = year + '_' + monthStr + '_' + dayStr;
    return stamp;

  }

  resource.csv = function (parameters) {
    return $resource(
      appConfigurationService.basePath + '/rest/invoicehistory/exportAssignedOutstandingInvoicesCsv',
      parameters,
      {
        get: {
          method: 'GET',
          url: appConfigurationService.basePath + '/rest/invoicehistory/exportAssignedOutstandingInvoicesCsv',
          interceptor: {
            response: function (response) {
              if (response.status === 200) {
                var blob = new Blob([resource.convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob, 'outstandingInvoices' + '.xls');
                } else {
                  var fileUrl = URL.createObjectURL(blob);
                  var downloadLink = document.createElement('a');
                  downloadLink.href = fileUrl;
                  downloadLink.download = 'Invoices_' + getDateStamp() +'.xls';
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                }
              }
            }
          }
        }
      }
    );
  };

  function arrayBufferToObject(data, headersGetter, status) {
    if (status === 200) {
      let blob = new Blob([data], {type: 'application/zip;'});
      return saveAs(blob, 'invoices.zip');
    }
    let decoder = new TextDecoder('utf-8');
    let domString = decoder.decode(data);
    let json = JSON.parse(domString);
    return json;
  }

  resource.invoiceListAsZip = $resource(
    appConfigurationService.basePath + '/rest/invoicehistory/invoiceListAsZip',
    {},
    {
      get: {
        method: 'POST', //even though the intention was to GET, the complex transaction requires a POST
        url: appConfigurationService.basePath + '/rest/invoicehistory/invoiceListAsZip',
        responseType: 'arraybuffer',
        transformResponse: arrayBufferToObject
      }
    }
  );

  resource.myDealerInvoiceEmail = $resource(appConfigurationService.basePath + '/rest/invoicehistory/myDealerInvoiceEmail/');
  resource.myDealerInvoiceListingSearchValues = $resource(appConfigurationService.basePath + '/rest/invoicehistory/myDealerInvoiceListingSearchValues/');
  return resource;
});
