'use strict';

intellidealerAppCoreModule.service('publicModeService', function ($rootScope) {
  var service = this;

  service.getPageTitle = function (stateName) {
    var pageTitle = undefined;
    if (stateName.startsWith('public.parts') || stateName.startsWith('public.shopping')) {
      pageTitle = 'mydealer.ui.shop_for_parts.breadcrumb';
    } else if (stateName.startsWith('public.equipment')) {
      pageTitle = 'mydealer.ui.equipment_for_sale.breadcrumb';
    }
    return pageTitle;
  };

  service.getStateWithPrefix = function (isPublicState, subState) {
    return (isPublicState? 'public' : 'navigation') + subState;
  };

  service.getCartReferenceNumber = function () {
    return localStorage.getItem('_cartRefNum');
  };

  service.setCartReferenceNumber = function (referenceNum) {
    if ($rootScope.isPublicState && !$rootScope.guestLoggedin) {
      localStorage.setItem('_cartRefNum', referenceNum);
    }
  };

  service.clearCartReferenceNumber = function () {
    localStorage.removeItem('_cartRefNum');
  };

  service.getDeereNotFoundParts = function () {
    return angular.fromJson(localStorage.getItem('_deereNotFoundParts'));
  };

  service.setDeereNotFoundParts = function (notFoundParts) {
    localStorage.setItem('_deereNotFoundParts', angular.toJson(notFoundParts));
  };

  service.clearDeereNotFoundParts = function () {
    localStorage.removeItem('_deereNotFoundParts');
  };

  service.setCameFromDeere = function () {
    localStorage.setItem('_cameFromDeere', 'true');
  };

  service.getCameFromDeere = function () {
    return localStorage.getItem('_cameFromDeere');
  };

  service.clearCameFromDeere = function () {
    localStorage.removeItem('_cameFromDeere');
  };

  service.setDeereDealerNumber = function (dealerNumber) {
    localStorage.setItem('_deereDealerNumber', dealerNumber);
  };

  service.getDeereDealerNumber = function () {
    return localStorage.getItem('_deereDealerNumber');
  };

  service.clearDeereDealerNumber = function () {
    localStorage.removeItem('_deereDealerNumber');
  };

});
