'use strict';

intellidealerAppEquipmentModule.controller('MyRentalsController', function ($scope, $state, $stateParams, myRentals, userConfiguration, $filter, sessionService,rentalContractsResource,globalAlertService) {
  $scope.linkedStates = [];
  if (userConfiguration.myDealerEquipmentAccess) {
    $scope.linkedStates.push({title: $filter('language')('mydealer.ui.my_equipment.breadcrumb'), state:'navigation.equipment.myEquipment'});
  }
  if (sessionService.getFeatures()['PFW.6512'] && userConfiguration.myDealerEquipmentAccess) {
    $scope.linkedStates.push({title: $filter('language')('mydealer.ui.my_rentals.breadcrumb'), state:'navigation.equipment.myRentals'});
  }

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.rental.contract_number'), property:'id.contractNumber', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.rental.description'), property:'description', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.rental.stock_part_number'), property:'combinedStockPartNumber', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.rental.job_site'), property:'jobSite', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.rental.po_number'), property:'rentalContract.customerOrderNumber', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.rental.covering_to'), property:'rentalInDate', direction: 'desc',isHidden: false},
    {label:$filter('language')('mydealer.ui.rental.return_confirmation'), property:'unitReturnedNumber', direction: 'asc',isHidden: false},
    {label: $filter('language')('mydealer.ui.rental.status'), property: 'unitReturned', direction: 'asc',iisHidden: false},
    {label: "",isHidden: false, align:'right'},
  ];
  $scope.columns = [];

  $scope.sortChoices.forEach(choice => {
    if(choice.label){
      $scope.columns.push({label:choice.label, isSelected:true});
    }
  });
  if(!sessionStorage.getItem("sortChoices")){
    sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
  }else {
    $scope.sortChoices = JSON.parse(sessionStorage.getItem("sortChoices"));
  }
  if(!sessionStorage.getItem("columns")){
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
  }else {
    $scope.columns = JSON.parse(sessionStorage.getItem("columns"));
  }

  $scope.toggleColumns = function(col){
    let index = $scope.columns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.columns[index].isSelected = !$scope.columns[index].isSelected;
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
    let sortIndex = $scope.sortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if(sortIndex >=0){
      $scope.sortChoices[sortIndex].isHidden = !$scope.columns[index].isSelected;
      sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
    }

  }

  $scope.isColVisible = function(colName){
    let index = $scope.columns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.columns[index].isSelected;
  }
  $scope.myRentals = myRentals;
  $scope.myRentals.content.forEach(function (row) {
    row.showRentalUnitReturned = row.unitReturned === 'OFF_RENT' || row.unitReturned === 'HOLD_UNIT';
  });

  $scope.terms = {
    keywords:  $stateParams.myRentalsSearch
  };

  $scope.search = function(event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    var params = {};

    var sortParams = angular.fromJson($stateParams.myRentalsPagination);
    if (property && direction) {
      sortParams.sort = property;
      sortParams.direction = direction;
    }
    sortParams.page = 0;
    params.myRentalsPagination = angular.toJson(sortParams);

    params.myRentalsSearch = $scope.terms.keywords;
    $state.go('.', params);
  };

  $scope.clearStorage = function(){
    sessionStorage.clear()
  }
  $scope.getCsv = function () {
    let parameters = {keywords:  $stateParams.myRentalsSearch ? $stateParams.myRentalsSearch : ''};
    rentalContractsResource.csv(parameters).get(
      null,
      function () {
      },
      function (response) {
        globalAlertService.addError('mydealer.ui.rental.csv_notGenerated');
      }
    );
  };

});
