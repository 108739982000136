'use strict';

intellidealerAppAlertsModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.alerts', {
      url: '/alerts?' + 
      '&alertsPagination' +
      '&alertsFilters' +
      '&{search:any}',
      params: {
        alertsPagination: angular.toJson({
          sort: 'alertHistoryId.date',
          direction: 'desc'
        })
      },
      templateUrl: 'app/scripts/modules/alerts/views/alerts.html',
      controller: 'AlertsController',
      resolve: {
        help: function () {
          return 'My_Alerts.htm';
        },
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.alerts.breadcrumb'));
        }],
        alertsPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.alertsPagination);
        }],
        
        alertsFilters: ['$stateParams', function($stateParams) {
          if ($stateParams.alertsFilters) {
            return angular.fromJson($stateParams.alertsFilters);
          } else {
            return null;
          }
        }], 
        
        search: ['$stateParams', function($stateParams) {
          return $stateParams.search;
        }],
        
        alerts: [
          'alertHistoryResource', 
          'globalAlertService', 
          'pageableService',
          'alertsPagination',
          'alertsFilters',
          '$stateParams',
          'search',
          function(alertHistoryResource, globalAlertService, pageableService, alertsPagination, alertsFilters, $stateParams, search) {
            var parameters = {
              keywords: (search ? search : '')
            };

            if (alertsFilters) {
              if (alertsFilters.area) {
                parameters.alertTypeArea = alertsFilters.area;
              }
            }

            pageableService.addPageableParameters(
              parameters,
              alertsPagination
            );

            return alertHistoryResource.myDealerAlertHistoryByCustomer.get(
              parameters, 
              function () {}, 
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ],
        
        myDealerAlertTypeAreas: ['alertTypeResource', 'globalAlertService', function(alertTypeResource, globalAlertService) {
          return alertTypeResource.myDealerAlertTypeAreas.get(
            {}, 
            function () {}, 
            globalAlertService.handleErrorResponse
          ).$promise;
        }]
      }
    });
}]);
