'use strict';

intellidealerAppCoreModule.filter('maxValueLength', function () {
  return function(input, maxValueLength) {
    //replace the first occurrence of {} with minvalue
    var message = input.replace("{0}", maxValueLength);

    return message;
  }
});
