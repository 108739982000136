'use strict';

intellidealerAppPartsModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.parts.macdon', {
      url: '/macdon',
      templateUrl: 'app/scripts/modules/parts/views/macdon.html',
      controller: 'MacDonController',
      resolve: {
        help: function () {
          return 'Parts-_MacDon.htm';
        },
        breadcrumb: function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.macDon.breadcrumb'));
        }
      }
    }
  );
}]);
