'use strict';

intellidealerAppPartsModule.controller('MyShoppingListsController', function ($scope, $state, $stateParams, $timeout, $filter, globalAlertService, myShoppingLists, PartsTopLevelTabsService, onlinePartsOrdersResource, shoppingCartService) {

  $scope.linkedStates = PartsTopLevelTabsService;
  $scope.myShoppingLists = myShoppingLists;
  $scope.anySelected = false;

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.my_shopping_lists.date_opened'), property:'dateOpened', direction: 'desc',  isHidden: false},
    {label:$filter('language')('mydealer.ui.my_shopping_lists.reference_number'), property:'onlineOrderId.referenceNumber', direction: 'asc',  isHidden: false},
    {label:$filter('language')('mydealer.ui.my_shopping_lists.description'), property:'description', direction: 'asc',  isHidden: false},
    {label:$filter('language')('mydealer.ui.my_shopping_lists.line_items'), align: 'right', isHidden: false},
    {}
  ];
  if(!sessionStorage.getItem("sortChoices")){
    sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
  }else {
    $scope.sortChoices = JSON.parse(sessionStorage.getItem("sortChoices"));
  }

  $scope.columns = [];

  $scope.sortChoices.forEach(choice => {
    if(choice.label && choice.label != $filter('language')('')){
      $scope.columns.push({label:choice.label, isSelected:true});
    }
  });

  if(!sessionStorage.getItem("columns")){
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
  }else {
    $scope.columns = JSON.parse(sessionStorage.getItem("columns"));
  }
  $scope.toggleColumns = function(col){
    let index = $scope.columns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.columns[index].isSelected = !$scope.columns[index].isSelected;
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
    let sortIndex = $scope.sortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if(sortIndex >=0){
      $scope.sortChoices[sortIndex].isHidden = !$scope.columns[index].isSelected;
      sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
    }

  }
  $scope.isColVisible = function(colName){
    let index = $scope.columns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.columns[index].isSelected;
  }
  // use jQuery to trigger a click event on the 'parent' element
  // should be done in a more standard angular way, if possible
  $scope.closePopover = function(id) {
    var element = angular.element('#' + id);
    $timeout(function() {
      element.trigger('click');
    });
  };

  $scope.terms = {
    keywords:  $stateParams.myShoppingListsSearch
  };

  $scope.search = function(event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    var params = {};
    var sortParams = angular.fromJson($stateParams.myShoppingListsPagination);
    if (property && direction) {
      sortParams.sort = property;
      sortParams.direction = direction;
    }
    sortParams.page = 0;
    params.myShoppingListsPagination = angular.toJson(sortParams);

    params.myShoppingListsSearch = $scope.terms.keywords;
    $state.go('.', params);
  };

  $scope.$watch(function() {
    var anySelected = false;
    $scope.myShoppingLists.content.forEach(function(current) {
      if (current.selected) {
        anySelected = true;
      }
    });
    Object.keys(sessionStorage).forEach(key => {
      if(key.includes("chk") && sessionStorage.getItem(key) === 'true' && !key.includes("unchk"))
        anySelected = true;
    })
    return anySelected;
    }, function (newValue) {
    $scope.anySelected = newValue;
  });

  $scope.myShoppingLists.content.forEach(shoppingList => {
    if(sessionStorage.getItem(shoppingList.onlineOrderId.company+shoppingList.onlineOrderId.division+shoppingList.onlineOrderId.location+shoppingList.onlineOrderId.referenceNumber+'chk') === "true"
      || (sessionStorage.getItem("selectAllToggle") === "true" && (sessionStorage.getItem(shoppingList.onlineOrderId.company+shoppingList.onlineOrderId.division+shoppingList.onlineOrderId.location+shoppingList.onlineOrderId.referenceNumber+'unchk') === null ||
        sessionStorage.getItem(shoppingList.onlineOrderId.company+shoppingList.onlineOrderId.division+shoppingList.onlineOrderId.location+shoppingList.onlineOrderId.referenceNumber+'unchk') === "false"))) {
      shoppingList.selected = true;
    }else{
      shoppingList.selected= false;
    }
  });

  $scope.setSelectedCheckbox = function(event,shoppingList){
   if(shoppingList.selected){
      sessionStorage.setItem(shoppingList.onlineOrderId.company+shoppingList.onlineOrderId.division+shoppingList.onlineOrderId.location+shoppingList.onlineOrderId.referenceNumber+'unchk','true');
      sessionStorage.setItem(shoppingList.onlineOrderId.company+shoppingList.onlineOrderId.division+shoppingList.onlineOrderId.location+shoppingList.onlineOrderId.referenceNumber+'chk','false');
    }else {
     sessionStorage.setItem(shoppingList.onlineOrderId.company+shoppingList.onlineOrderId.division+shoppingList.onlineOrderId.location+shoppingList.onlineOrderId.referenceNumber+'chk','true');
     sessionStorage.setItem(shoppingList.onlineOrderId.company+shoppingList.onlineOrderId.division+shoppingList.onlineOrderId.location+shoppingList.onlineOrderId.referenceNumber+'unchk','false');
    }
  }

  $scope.addListsToCart = function() {
    var parameters = {
      historicalOrders: false,
      keywords: $stateParams.myShoppingListsSearch ? $stateParams.myShoppingListsSearch : '',
    };
    onlinePartsOrdersResource.assignedOnlineOrdersList.getAllList(parameters).get(
      null,
      function () {
      },
      globalAlertService.handleErrorResponse
      ).$promise.then(function (response) {
      let data = JSON.stringify(response);
      let allShoppingList = JSON.parse(data);
      let processedShoppingList = [];
      JSON.parse(allShoppingList).forEach(shoppingList => {
        const uniqueKey = shoppingList.onlineOrderId.company + shoppingList.onlineOrderId.division + shoppingList.onlineOrderId.location + shoppingList.onlineOrderId.referenceNumber
        if ((sessionStorage.getItem("selectAllToggle") === "true" && !(sessionStorage.getItem(uniqueKey + 'unchk')==="true")) || sessionStorage.getItem(uniqueKey + 'chk') ==="true") {
          shoppingList = angular.copy(shoppingList);
          shoppingList.selected = true;
        }
        processedShoppingList.push(shoppingList);
      });

      var selectedLists = [];
      processedShoppingList.forEach(function (current) {
        if (current.selected) {
          selectedLists.push(current.onlineOrderId);
        }
      });
      if(selectedLists.length == 0){
        $scope.myShoppingLists.content.forEach(shoppingList => {
          if(shoppingList.selected)
            selectedLists.push(shoppingList.onlineOrderId.company+shoppingList.onlineOrderId.division+shoppingList.onlineOrderId.location+shoppingList.onlineOrderId.referenceNumber);
        })
      }
      if (selectedLists.length > 0) {
        onlinePartsOrdersResource.addPartsFromOnlineOrders.save(
          {
            onlineOrderIdList: selectedLists
          },
          function () {
            globalAlertService.addSuccess('mydealer.ui.my_shopping_lists.lists_added_to_cart');
            shoppingCartService.loadShoppingCart();
            sessionStorage.clear();
            $state.reload($state.current);
          },
          function (httpResponse) {

            globalAlertService.handleErrorResponse(httpResponse);
            shoppingCartService.loadShoppingCart();
          }
        );
      }
    });
  }


  $scope.removeLists = function() {
    $scope.closePopover('removeListsButton');
    var parameters = {
      historicalOrders: false,
      keywords: $stateParams.myShoppingListsSearch ? $stateParams.myShoppingListsSearch : '',
    };
    onlinePartsOrdersResource.assignedOnlineOrdersList.getAllList(parameters).get(
      null,
      function () {
      },
      globalAlertService.handleErrorResponse
    ).$promise.then(function (response) {
      let data = JSON.stringify(response);
      let allShoppingList = JSON.parse(data);
      let processedShoppingList = [];
      JSON.parse(allShoppingList).forEach(shoppingList => {
        const uniqueKey = shoppingList.onlineOrderId.company + shoppingList.onlineOrderId.division + shoppingList.onlineOrderId.location + shoppingList.onlineOrderId.referenceNumber
        if ((sessionStorage.getItem("selectAllToggle") === "true" && !(sessionStorage.getItem(uniqueKey + 'unchk')==="true")) || sessionStorage.getItem(uniqueKey + 'chk') ==="true") {
          shoppingList = angular.copy(shoppingList);
          shoppingList.selected = true;
        }
        processedShoppingList.push(shoppingList);
      });

      var selectedLists = [];
      processedShoppingList.forEach(function (current) {
        if (current.selected) {
          selectedLists.push(current.onlineOrderId);
        }
      });

      if(selectedLists.length == 0){
        $scope.myShoppingLists.content.forEach(shoppingList => {
          if(shoppingList.selected)
            selectedLists.push(shoppingList.onlineOrderId.company+shoppingList.onlineOrderId.division+shoppingList.onlineOrderId.location+shoppingList.onlineOrderId.referenceNumber);
        })
      }

      if (selectedLists.length > 0) {
        onlinePartsOrdersResource.deleteOnlineOrders.remove(
          {
            onlineOrderIdList: selectedLists
          },
          function () {
            globalAlertService.addSuccess('mydealer.ui.my_shopping_lists.lists_removed');
            sessionStorage.clear();
            $state.reload($state.current);
          },
          globalAlertService.handleErrorResponse
        );
      }
    });
  };

  $scope.toggleCardMenu = function(event, shoppingList) {
    event.stopPropagation();
    shoppingList.isMenuOpen = !shoppingList.isMenuOpen;
  };

  $scope.selectCard = function(shoppingList) {
    $scope.myShoppingLists.content.forEach(function(current) {
      current.selected = (current === shoppingList ? true : false);
    });
  };

  $scope.getCsv = function () {
    var parameters = {
      historicalOrders: false,
      keywords: $stateParams.myShoppingListsSearch ? $stateParams.myShoppingListsSearch : '',
    };
    onlinePartsOrdersResource.exportShoppingListCsvData.csv(parameters).get(
      null,
      function () {
      },
      function (response) {
        globalAlertService.addError('mydealer.ui.equipment.csv_notGenerated');
      }
    );
  };

  $scope.getShoppingDetails= function(shoppingList){
    sessionStorage.clear();
    $state.go('navigation.parts.myShoppingLists.myShoppingListsDetails',
      {company: shoppingList.onlineOrderId.company, division: shoppingList.onlineOrderId.division, location: shoppingList.onlineOrderId.location, referenceNumber: shoppingList.onlineOrderId.referenceNumber})
  }

});
