'use strict';

/*
  Validation of plain numbers
*/
intellidealerAppCoreModule.directive('validNumber', [function() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, required) {
      required.$validators.number = function(modelValue, viewValue) {
        if (!modelValue) {
          return true;
        }
        var numberRegex = /^[0-9]*$/;
        if (numberRegex.test(viewValue)) { 
          return true;
        } 
        return false;    
      };
    }
  };
}]);