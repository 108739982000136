'use strict';

intellidealerAppCoreModule.factory('workOrderServiceRequestsResource', function ($resource, appConfigurationService) {

  var resource = {};

  resource.createServiceRequest =  $resource(
    appConfigurationService.basePath + '/rest/workorderservicerequests/createServiceRequest', 
    {}, {}
  );

  return resource;
});
