intellidealerAppCoreModule.controller('multipleContactSelectionCtrl', function ($scope, properties, $uibModalInstance) {

  $scope.alternateContacts = properties.alternateContacts;
  $scope.customerSelected = properties.customerSelected;

  $scope.selectCustomer = function (customerNumber, contactNumber, company, division) {
    $scope.customerSelected(customerNumber, contactNumber, company, division, properties.loginCallback);
    $uibModalInstance.dismiss();
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss();
  };
});
