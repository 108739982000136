'use strict';

intellidealerAppCoreModule.directive('flatTabs', ['$state', function($state) {
  return {
    restrict: 'E',
    scope: {
      linkedStates: '='

    },
    templateUrl: 'app/scripts/modules/core/views/templates/flat-tabs.html',
    link: function (scope, element, attrs) {
      scope.$state = $state;
      scope.tabsGrow = angular.isDefined(attrs.tabsGrow);
      scope.clearStorage = function() {
        sessionStorage.clear()
      }
    }
  };
}]);

