'use strict';

intellidealerAppMyAccountModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.myAccount.profile', {
      url: '/profile',
      templateUrl: 'app/scripts/modules/my-account/views/profile.html',
      controller: 'ProfileController',
      resolve: {
        help: function () {
          return 'My_Account-_Profile.htm';
        },
        breadcrumb: ['businessProfile', function (businessProfile) {
          return businessProfile.soldToCustomerName;
        }],

        cellCarriers: ['customersResource', 'globalAlertService', function (customersResource, globalAlertService) {
          return customersResource.cellCarriers.query(
            {},
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }],

        businessProfile: ['customersResource', 'globalAlertService', function (customersResource, globalAlertService) {
          return customersResource.assignedCustomer.get(
            {},
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }],

        myProfile: ['contactsResource', 'globalAlertService', function (contactsResource, globalAlertService) {
          return contactsResource.assignedContact.get(
            {},
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }]
      }
    }
  );
}]);
