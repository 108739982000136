'use strict';

/*
  Directive for a search box with a toggle for which state to do a search in.

  Configuration parameter takes an array of objects representing the states it plugs in to with the signature of:
  {
    state: 'state name',
    searchParameter: 'search paramater name',
    placeholder: 'input placeholder text',
    text: 'dropdown text',
    show: security boolean
  }
*/

intellidealerAppCoreModule.directive('omniSearch', ['$state', '$filter', function($state, $filter) {
  return {
    restrict: 'E',
    scope: {
      configuration: '='
    },
    templateUrl: 'app/scripts/modules/core/views/templates/omni-search.html',
    link: function (scope) {
      scope.filteredOptions = $filter('filter')(scope.configuration, {show:'true'});    
      scope.area = {selected: scope.filteredOptions[0]};

      scope.search = function(event) {
        if (event) {
          event.preventDefault(); // prevent default form submit (IE10)
        }
        if (scope.area.selected) {
          var params = {};
          params[scope.area.selected.searchParameter] = scope.keywords;
          $state.go(scope.area.selected.state, params);
        }
      };
    }
  };
}]);

