'use strict';

intellidealerAppCoreModule.factory('rentalContractsResource', function ($resource, appConfigurationService) {
  let resource = {};
    resource.assignedRentalContracts = $resource(appConfigurationService.basePath + '/rest/rentalcontracts/assignedRentalContracts');
    resource.assignedRentalContractDetails = $resource(appConfigurationService.basePath + '/rest/rentalcontracts/assignedRentalContractDetails');
    resource.rentalUnitOffRent = $resource(appConfigurationService.basePath + '/rest/rentalcontracts/rentalUnitOffrent');


  resource.convertBytes = function (byteArray) {
    const binaryString = window.atob(byteArray);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };
  function getDateStamp(){
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var monthStr = month +1 < 9 ? "0"+(month+1) : month+1;
    var dayStr = day < 9 ? "0"+(day) : day;
    var stamp = year + '_' + monthStr + '_' + dayStr;
    return stamp;
  }
  resource.csv = function (parameters) {
    return $resource(
      appConfigurationService.basePath + '/rest/rentalcontracts/exportAssignedRentalCsv/',
      parameters,
      {
        get: {
          method: 'GET',
          url: appConfigurationService.basePath + '/rest/rentalcontracts/exportAssignedRentalCsv/',
          interceptor: {
            response: function (response) {
              if (response.status === 200) {
                var blob = new Blob([resource.convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob, 'rentals' + '.xls');
                } else {
                  var fileUrl = URL.createObjectURL(blob);
                  var downloadLink = document.createElement('a');
                  downloadLink.href = fileUrl;
                  downloadLink.download = 'MyRental_' + getDateStamp() +'.xls';
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);

                }
              }
            }
          }
        }
      }
    );
  };

  return resource;
});
