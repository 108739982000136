'use strict';

intellidealerAppMyAccountModule.controller('StatementsController', function ($state, $scope, $filter,$stateParams, statementHistoryResource, globalAlertService, statements, appConfigurationService, myProfile) {

  $scope.statements = statements;
  $scope.email = {
    address: myProfile.businessEmailAddress
  };
  $scope.emailForm = {};
  $scope.anySelected = false;

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.statements.statement_date'), property:'statementRunDate', direction: 'desc',isVisible: true},
    {}
  ];

  $scope.statements.content.forEach(statement => {
    if(sessionStorage.getItem("selectAllToggle") === "true" && !sessionStorage.getItem(statement.transactionIdString + 'unchkStatement'))
      statement.selected = true;
    else if(sessionStorage.getItem(statement.transactionIdString+'chkStatement'))
      statement.selected=true;
  });

  $scope.isSelectedCheckbox = function(event,statement){
    if(statement.selected){
      sessionStorage.removeItem(statement.transactionIdString+'chkStatement');
      sessionStorage.setItem(statement.transactionIdString+'unchkStatement','true');
    }else{
      sessionStorage.removeItem(statement.transactionIdString+'unchkStatement');
      sessionStorage.setItem(statement.transactionIdString+'chkStatement','true');
    }
  }

  $scope.$watch(function() {
    var anySelected = false;
    $scope.statements.content.forEach(function(current) {
      if (current.selected) {
        anySelected = true;
      }
    });
    Object.keys(sessionStorage).forEach(key => {
      if(key.includes("chk") && !key.includes("unchk"))
        anySelected = true;
    })
    return anySelected;
  }, function (newValue) {
    $scope.anySelected = newValue;
  });

  $scope.showEmailForm = function() {
    $scope.emailForm.toggle = true;
  };

  //link for single PDFs
  $scope.getPDFUrl = function(transactionIdString) {
    return (appConfigurationService.basePath + '/rest/statementhistory/statementAsPdf?transactionId=' + transactionIdString);
  };

  $scope.downloadSelected = function() {

    var param = {};
    statementHistoryResource.statements(param).get(
      null,
      function () {
      },
      globalAlertService.handleErrorResponse
    ).$promise.then(function (response) {
      let data = JSON.stringify(response);
      let allStatements = JSON.parse(data);

      let processedStatements = [];
      JSON.parse(allStatements).forEach(statement => {
        if(sessionStorage.getItem("selectAllToggle") === "true" && !sessionStorage.getItem(statement.transactionIdString + 'unchkStatement')){
          statement = angular.copy(statement);
          statement.selected = true;
        }

        else if (sessionStorage.getItem(statement.transactionIdString + 'chkStatement')) {
          statement = angular.copy(statement);
          statement.selected = true;
        }
        processedStatements.push(statement);
      });

      var selectedStatements = [];
      processedStatements.forEach(function(current) {
        if (current.selected) {
          selectedStatements.push(current.transactionIdString);
        }
      });

      if(selectedStatements.length == 0){
        $scope.statements.content.forEach(statement => {
          if(statement.selected)
            selectedStatements.push(statement.transactionIdString);
        })
      }

      if (selectedStatements.length > 0) {
        statementHistoryResource.statementListAsZip.get(
          {
            'invoiceTransactionIdList': selectedStatements
          },
          function() {
            sessionStorage.clear();
            selectedStatements.forEach(statement => {
                sessionStorage.removeItem(statement + 'chkStatement');
                sessionStorage.removeItem(statement + 'unchkStatement');
                sessionStorage.removeItem("selectAllToggle")
            });
            $scope.search();

          },
          globalAlertService.handleErrorResponse
        );
      }

    });
  };

  $scope.emailSelected = function() {

    var param = {};
    statementHistoryResource.statements(param).get(
      null,
      function () {
      },
      globalAlertService.handleErrorResponse
    ).$promise.then(function (response) {
      let data = JSON.stringify(response);
      let allStatements = JSON.parse(data);

      let processedStatements = [];
      var selectedStatements = []
      JSON.parse(allStatements).forEach(statement => {
        if(sessionStorage.getItem("selectAllToggle") === "true" && !sessionStorage.getItem(statement.transactionIdString + 'unchkStatement')){
          statement = angular.copy(statement);
          statement.selected = true;
          selectedStatements.push(statement.transactionIdString);
        }
        else if (sessionStorage.getItem(statement.transactionIdString + 'chkStatement')) {
          statement = angular.copy(statement);
          statement.selected = true;
          selectedStatements.push(statement.transactionIdString);
        }
        processedStatements.push(statement);
      });
      ;

      if(selectedStatements.length == 0){
        $scope.statements.content.forEach(statement => {
          if(statement.selected)
            selectedStatements.push(statement.transactionIdString);
        })
      }

      if (selectedStatements.length > 0) {
        statementHistoryResource.myDealerStatementEmail.save(
          {
            'toEmailAddress': $scope.email.address,
            'invoiceTransactionIdList': selectedStatements
          },
          function() {
            $scope.emailForm.toggle = false;
            globalAlertService.addSuccess('mydealer.ui.global.email_sent');
            sessionStorage.clear();
            selectedStatements.forEach(statement => {

            });
            sessionStorage.clear();
            $scope.search();
          },
          globalAlertService.handleErrorResponse
        );
      }
    });
  };

  $scope.toggleCardMenu = function(event, statement) {
    event.stopPropagation();
    statement.isMenuOpen = !statement.isMenuOpen;
  };

  $scope.selectCard = function(statement) {
    $scope.emailForm.toggle = false;
    $scope.statements.content.forEach(function(current) {
      current.selected = (current === statement ? true : false);
    });
  };


  $scope.search = function (event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }
      var params = {};
      var sortParams = $stateParams.statementsPagination ? angular.fromJson($stateParams.statementsPagination):"";
      if(!sortParams || sortParams.page==0 )
        $state.reload();
      else {
        sortParams.page = 0;
        params.forceRefresh = Math.random();
        params.statementsPagination = angular.toJson(sortParams);
        $state.go('.', params);
        return true;
      }


  };
});
