'use strict';

intellidealerAppEquipmentModule.controller('MyEquipmentDetailsUpdateHoursController', function ($scope, equipmentResource, globalAlertService) {
  $scope.model = $scope.$parent.model;
  $scope.formValues = {
    newHours: ''
  };

  $scope.preventEnterKey = function(event) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }
  };

  $scope.updateHours = function() {
    if ($scope.updateHoursForm.$valid) {
      var newDate = Date.now();
      equipmentResource.updateAssignedEquipmentHours.update(
        {
          stockNumber: $scope.model.equipmentId.stockNumber,
          unitHours: $scope.formValues.newHours,
          hourReadingToDate: newDate
        },
        function() {
          globalAlertService.addSuccess('mydealer.ui.equipment.update_hours.success');
          $scope.configuration.selected = false;

          // Update the parent model details
          $scope.model.unitHours = $scope.formValues.newHours;
          $scope.model.hourReadingToDate = newDate;
        },
        globalAlertService.handleErrorResponse
      );
    }
  };
});
