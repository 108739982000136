'use strict';

intellidealerAppCoreModule.service('cdkAnalyticsResource', function ($resource, appConfigurationService) {
    var basePath = appConfigurationService.basePath + '/rest/googleAnalytics';

    function transformResponse(response, header, status) {
      if (status === 200) {
        return {id: response};
      } else {
        return JSON.parse(response);
      }
    }

    return {
      cdkTrackingId: $resource(basePath + "/cdkTrackingId",
        {
          applicationType: 'MYDEALER'
        }, {
          get: {
            method: 'GET',
            transformResponse: transformResponse
          }
        }),
      dealerTrackingId: $resource(basePath + '/dealerTrackingId',
        {
          library: appConfigurationService.getLibrary(),
          applicationType: 'MYDEALER'
        }, {
          get: {
            method: 'GET',
            transformResponse: transformResponse
          }
        }),
      logBlockedService: function () {
        return $resource(basePath + '/blockedService',
          {
            library: appConfigurationService.getLibrary(),
            applicationType: 'MYDEALER',
            userId: ''
          }, {
            post: {
              method: 'POST'
            }
          }
        );
      }
    };
  }
);
