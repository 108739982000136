'use strict';

intellidealerAppEquipmentModule.controller('MyEquipmentDetailsAddServiceHistoryController', function ($scope, $state, equipmentResource, globalAlertService) {

  $scope.formValues = {};

  $scope.preventEnterKey = function(event) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }
  };

  $scope.submit = function() {
    if ($scope.addServiceHistoryForm.$valid) {
      equipmentResource.createEquipmentCustomerHistory.save(
        {
          stockNumber: $scope.model.equipmentId.stockNumber,
          dateOfService: $scope.formValues.date ? new Date($scope.formValues.date).getTime() : null,
          description: $scope.formValues.description,
          hourMeterReading: $scope.formValues.hours,
          costOfService: $scope.formValues.cost
        },
        function() {
          globalAlertService.addSuccess('mydealer.ui.global.email_sent');
          $scope.configuration.selected = false;
          if ($state.current.name === 'navigation.equipment.myEquipment.myEquipmentDetails.serviceHistory') {
            $state.go('navigation.equipment.myEquipment.myEquipmentDetails.serviceHistory', {}, {reload: true});
          }
        },
        globalAlertService.handleErrorResponse
      );
    }
  };
});
