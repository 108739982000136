'use strict';

intellidealerAppPartsModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.parts.previousOrders.previousOrdersDetails', {
      url: '/previous-orders-details/{company}/{division}/{location}/{salesOrderNumber}' +
      '?previousOrdersDetailsPagination',
      params: {
        previousOrdersDetailsPagination: angular.toJson({
          sort: 'partNumber',
          direction: 'asc'
        })
      },
      views : {
        '@navigation.parts' : {
          templateUrl: 'app/scripts/modules/parts/views/previous-orders-details.html',
          controller: 'PreviousOrdersDetailsController'
        }
      },
      resolve: {
        breadcrumb: ['$stateParams', '$filter', function($stateParams, $filter) {
          return $filter('pathParam')($stateParams.salesOrderNumber);
        }],

        previousOrdersDetailsPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.previousOrdersDetailsPagination);
        }],

        previousOrdersDetails: [
          'pageableService',
          'onlinePartsOrdersResource',
          'globalAlertService',
          '$stateParams',
          '$filter',
          'previousOrdersDetailsPagination',
          function(pageableService, onlinePartsOrdersResource, globalAlertService, $stateParams, $filter, previousOrdersDetailsPagination) {
            var parameters = {
              company: $stateParams.company,
              division: $stateParams.division,
              location: $stateParams.location,
              salesOrderNumber: $filter('pathParam')($stateParams.salesOrderNumber)
            };

            pageableService.addPageableParameters(
              parameters,
              previousOrdersDetailsPagination
            );

            return onlinePartsOrdersResource.assignedPartInvoiceDetails.get(
              parameters,
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ]
      }
    }
  );
}]);
