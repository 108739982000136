'use strict';

intellidealerAppCoreModule.factory('securityResource', function ($resource, appConfigurationService) {
  return {
    customerContactSecurity: $resource(appConfigurationService.basePath + '/rest/security/customerContactSecurity/'),
    generateMyDealerForgotPasswordEmail: $resource(appConfigurationService.basePath + '/rest/security/generateMyDealerForgotPasswordEmail/'),
    updateMyDealerPassword: $resource(appConfigurationService.basePath + '/rest/security/updateMyDealerPassword/'),
    retrieveMyDealerPassword: $resource(appConfigurationService.basePath + '/rest/security/retrieveMyDealerPassword'),
    resetMyDealerPassword: $resource(appConfigurationService.basePath + '/rest/security/resetMyDealerPassword'),
    features: $resource(appConfigurationService.basePath + '/rest/security/featureList'),
    isIREnabled: $resource(appConfigurationService.basePath + '/rest/security/isIREnabled')
  };
});
