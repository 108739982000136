'use strict';

intellidealerAppShoppingCartModule.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/scripts/modules/shopping-cart/views/templates/shopping-cart-popover-remove-item.html',
                     '<div class="button-row-wrapper shopping-cart-buttons-only">' +
                     '<div>' +
                     '<button class="btn btn-info" data-ng-click="$root.togglePopover(\'remove\' + $index);">{{\'mydealer.ui.global.cancel\' | language}}</button>' +
                     '<button data-ng-if="cartItem.quantity > 0"  data-ng-disabled="relatedMandatoryPartNumbers.includes(cartItem.partNumber)" class="btn btn-primary" data-ng-click="removeOneItem(cartItem.partNumber, cartItem, shoppingCartForm.items);">{{\'mydealer.ui.global.remove\' | language}}</button>' +
                     '</div>' +
                     '</div>');
}]);
