'use strict';

intellidealerAppCoreModule.controller('DashboardAccountWidgetController', function ($scope) {

  $scope.accountSummary = $scope.slide.model;
  $scope.accountSummary.overdue =
    ($scope.accountSummary.aged60 ? $scope.accountSummary.aged60 : 0) + 
    ($scope.accountSummary.aged90 ? $scope.accountSummary.aged90 : 0) + 
    ($scope.accountSummary.aged120 ? $scope.accountSummary.aged120 : 0) + 
    ($scope.accountSummary.agedOver120 ? $scope.accountSummary.agedOver120 : 0);

  $scope.invoicesFilters = angular.toJson({
    status: 'O'
  });
});
