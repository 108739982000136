'use strict';

intellidealerAppPartsModule.controller('partDetailController', function ($scope, $rootScope,$state, $stateParams, $timeout, $window, $filter, partMultimedia, userConfiguration, partsResource, imagePathService, cdkAnalyticsService, multiMediaStreamingURLService) {

  function isImageOrPdf(filePath) {
    var extension = filePath.split(".").pop();
    return ["bmp", "jpg", "jpeg", "gif", "png", "pdf"].indexOf(extension.toLowerCase()) > -1;
  }

  if (!$stateParams.partDetails) {
    $state.go('^');
  }

  $scope.showcaseModel = $stateParams.partDetails || {};

  $scope.showcaseModel.imagesOrPdfs = partMultimedia.filter(function (file) {
    return isImageOrPdf(file.sourcePath);
  }).map((file) => {
    const httpPattern = /http(s)?:\/\/\S+/;
    if (!httpPattern.test(file.multimediaId.multimediaPath)) {
      // if images are accessed in public mode stream images without login
      let params = multiMediaStreamingURLService.getPartsParams(file.multimediaId);
      file.sourcePath = $rootScope.isPublicState ? multiMediaStreamingURLService.getMultiMediaStreamingPublicURL(file.sourcePath, params) :
        multiMediaStreamingURLService.getMultiMediaStreamingURL(file.sourcePath, params);
      return file;
    }
    else {
      return file;
    }
  });

  $scope.showcaseModel.files = partMultimedia.filter(function (file) {
    return !isImageOrPdf(file.fileName);
  });

  var openPopoverId = null;
  $scope.closeOpenPopover = function (event, id) {
    if (event) {
      var target = event.target;
      while (target && target.tagName !== 'I' && target.tagName !== 'BUTTON') {
        target = target.parentElement;
      }
      if (target && angular.element(target).hasClass('has-popover')) {
        return;
      }
    }
    if (openPopoverId) {
      if (openPopoverId !== id) {
        var closeId = openPopoverId;
        if (closeId === 'avail') {
          $timeout(function () {
            angular.element('#' + closeId).trigger('toggleAvailabilityPopover');
          });
        } else {
          $timeout(function () {
            angular.element('#' + closeId).trigger('togglePopover');
          });
        }
        openPopoverId = id;
      } else {
        openPopoverId = null;
      }
    } else {
      openPopoverId = id;
    }
  };

  $scope.toggleAvailabilityPopover = function (id, part) {
    if (part && (!part.availability || part.availability <= 0)) {
      return;
    }

    // if a different popover is open, close it
    $scope.closeOpenPopover(null, id);

    var element = angular.element('#' + id);
    if (!part || part.partAvailabilityList) {
      // if no part (cancel) or already have list, just toggle popover
      $timeout(function () {
        element.trigger('toggleAvailabilityPopover');
      });
    } else {
      partsResource.partAvailabilityByPart.query(
        {partNumber: part.partNumber},
        function (value) {
          // if successful, add the list to the part
          part.partAvailabilityList = value;
          $timeout(function () {
            element.trigger('toggleAvailabilityPopover');
          });
        },
        function () {
          // if failed, add an empty list to the part
          part.partAvailabilityList = [];
          $timeout(function () {
            element.trigger('toggleAvailabilityPopover');
          });
        }
      );
    }
  };

  $scope.closePopover = function (id) {
    var el = angular.element('#' + id);
    $timeout(function () {
      el.trigger('click');
    });
  };

  $scope.applyNonThumbnailClass = function (sourcePath) {
    if (sourcePath.startsWith('http') || sourcePath.startsWith('https')) {
      return "fa fa-fw fa-link";
    } else {
      var fileName = sourcePath.split('/').pop();
      var extension = fileName.split('.').pop();

      if (["pdf"].indexOf(extension.toLowerCase()) >= 0) {
        return "fa fa-file-pdf-o";
      } else {
        return "fa fa-file-o";
      }
    }
  };

  $scope.openSourceFileWindow = function (sourcePath) {
    var url = (sourcePath.startsWith('http') || sourcePath.startsWith('https')) ? sourcePath : imagePathService.getFullPath(sourcePath);
    $window.open(url);
  };

  $scope.showcase = {
    defaultTemplate: 'app/scripts/modules/parts/views/templates/part-detail-default.html',
    narrow: partMultimedia.length < 1,
    selected: '',
    subViews: [],
    //custom
    showPrice: userConfiguration.displayPrices,
    showAvailability: userConfiguration.displayAvailability !== 'NONE',
    toggleAvailabilityPopover: $scope.toggleAvailabilityPopover,
    closeOpenPopover: $scope.closeOpenPopover,
    applyNonThumbnailClass: $scope.applyNonThumbnailClass,
    openSourceFileWindow: $scope.openSourceFileWindow
  };
  // Do not log empty part view when partNumber is not provided
  if ($scope.showcaseModel.partNumber) {
    cdkAnalyticsService.logPartView($scope.showcaseModel);
  }
  angular.element($window).bind('resize', $scope.closeOpenPopover);
});
