'use strict';

intellidealerAppCoreModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.dashboard', {
      url: '/dashboard',
      templateUrl: 'app/scripts/modules/core/views/dashboard.html',
      controller: 'DashboardController',
      resolve: {
        help: function () {
          return 'Home_Screen.htm';
        },
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.dashboard.breadcrumb'));
        }],
        accountSummary: [
          'customerAccountsResource', 'globalAlertService', 'userConfiguration',
          function(customerAccountsResource, globalAlertService, userConfiguration) {
            if (!userConfiguration.myDealerAccountAccess){
              return false;
            }
            return customerAccountsResource.assignedCustomerAccount.get(
              {},
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }],
        dealershipNewsList: ['newsResource', 'globalAlertService', 'pageableService', function(newsResource, globalAlertService, pageableService) {
          var parameters = {};
          // there are no paging controls, so forcing the size and sort here
          var newsPagination = {
            size: 99,
            sort: 'timestamp',
            direction: 'desc'
          };
          pageableService.addPageableParameters(
            parameters,
            newsPagination
          );

          return newsResource.dealershipNewsList.get(
            parameters,
            function () {},
            globalAlertService.handleErrorResponse
          ).$promise;
        }],
        alertHistory: ['alertHistoryResource',  'globalAlertService', 'pageableService', function(alertHistoryResource, globalAlertService, pageableService) {
          var parameters = {};
          // there are no paging controls, so forcing the size and sort here
          var alertPagination = {
            size: 99,
            sort: 'alertHistoryId.date',
            direction: 'desc'
          };
          pageableService.addPageableParameters(
            parameters,
            alertPagination
          );

          return alertHistoryResource.myDealerAlertHistorySinceLastLogin.get(
            parameters,
            function () {},
            globalAlertService.handleErrorResponse
          ).$promise;
        }],
        topPartPurchases: [
          'partHistoryResource', 'globalAlertService', 'userConfiguration',
          function(partHistoryResource, globalAlertService, userConfiguration) {
            if (!userConfiguration.myDealerPartsAccess){
              return false;
            }
            return partHistoryResource.assignedMyDealerTopPartPurchases.query(
              {},
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }],
        myDealerBranchSettings: ['dealershipResource','locationService','globalAlertService','userConfiguration', function (dealershipResource,locationService,globalAlertService,userConfiguration) {
          return dealershipResource.myDealerBranchSettings.get(
            {
              company: userConfiguration.contactId.company,
              division: userConfiguration.contactId.division,
              location: userConfiguration.businessLocation
            },
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }],
      }
    });
}]);
