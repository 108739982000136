'use strict';

// override the angular ui bootstrap pagination template with this one
intellidealerAppCoreModule.run(['$templateCache', function($templateCache) {
  $templateCache.put('template/pagination/pagination.html',
    '<ul class="pagination"> ' +
    '  <li ng-if="boundaryLinks" ng-class="{disabled: noPrevious()}"><a href ng-click="selectPage(1, $event)"> ' +
    '    <span class="hidden-xs">{{getText(\'first\')}}</span><span class="visible-xs-inline">&laquo;</span></a></li> ' +
    '  <li ng-if="directionLinks" ng-class="{disabled: noPrevious()}"><a href ng-click="selectPage(page - 1, $event)"> ' +
    '    <span class="hidden-xs">{{getText(\'previous\')}}</span><span class="visible-xs-inline">&lsaquo;</span></a></li> ' +
//  '  <li ng-if="pages[0].number > 1" class="hidden-xs"><a href ng-click="selectPage(1, $event)">{{1}}</a></li> ' +
//  '  <li ng-if="pages[0].number > 2" class="hidden-xs"><a href ng-click="selectPage(pages[0].number - 1, $event)">...</a></li> ' +
    '  <li ng-repeat="page in pages track by $index" ng-class="{active: page.active}"><a href ng-click="selectPage(page.number, $event)">{{page.text}}</a></li> ' +
//  '  <li ng-if="pages[pages.length - 1].number < totalPages - 1" class="hidden-xs"><a href ng-click="selectPage(pages[pages.length - 1].number + 1, $event)">...</a></li> ' +
//  '  <li ng-if="pages[pages.length - 1].number < totalPages" class="hidden-xs"><a href ng-click="selectPage(totalPages, $event)">{{totalPages}}</a></li> ' +
    '  <li ng-if="directionLinks" ng-class="{disabled: noNext()}"><a href ng-click="selectPage(page + 1, $event)"> ' +
    '    <span class="hidden-xs">{{getText(\'next\')}}</span><span class="visible-xs-inline">&rsaquo;</span></a></li> ' +
    '  <li ng-if="boundaryLinks" ng-class="{disabled: noNext()}"><a href ng-click="selectPage(totalPages, $event)"> ' +
    '    <span class="hidden-xs">{{getText(\'last\')}}</span><span class="visible-xs-inline">&raquo;</span></a></li> ' +
    '</ul>');
}]);

intellidealerAppCoreModule.directive('pageable', ['$state', function($state) {
  return {
    restrict: 'A',
    scope: {
      pageable: '=',
      requiredTerm: '=',
      tableCells: '@',
      queryParams: '@' //if not specified, the directive will assume [stateName]Pagination as the query parameter to use
    },
    template:
      '<tr> ' +
      '<td colspan="{{tableCells}}" style="text-align: center;"> ' +
      '<ul uib-pagination class="hidden-xs-p" ' +
      'data-total-items="pageable.totalElements" ' +
      'data-items-per-page="pageable.size" ' +
      'data-ng-model="oneBasedPage" ' +
      'data-ng-change="pageChanged()" ' +
      'data-ng-show="pageable.totalElements"> ' +
      '</ul> ' +
      '<ul uib-pagination class="visible-xs-p-inline-block" ' +
      'data-max-size="3" ' +
      'data-total-items="pageable.totalElements" ' +
      'data-items-per-page="pageable.size" ' +
      'data-ng-model="oneBasedPage" ' +
      'data-ng-change="pageChanged()" ' +
      'data-ng-show="pageable.totalElements"> ' +
      '</ul> ' +
      '<div data-ng-show="pageable.totalElements"> ' +
      '<div class="well well-sm pagination-totals"> ' +
      '{{\'mydealer.ui.global.page.showing\' | language}} {{pageable.number * pageable.size + 1}} ' +
      '{{\'mydealer.ui.global.page.to\' | language}} {{((pageable.number + 1) * pageable.size < pageable.totalElements ? (pageable.number + 1) * pageable.size : pageable.totalElements)}} ' +
      '{{\'mydealer.ui.global.page.of\' | language}} {{pageable.totalElements}} {{\'mydealer.ui.global.page.results\' | language}}. ' +
      '</div> ' +
      '</div> ' +
      '<span data-ng-show="!pageable.totalElements" class="panel-subheading"> ' +
      '<span data-ng-hide="mustEnterSearchTerm">{{\'mydealer.ui.global.no_results\' | language}}</span>' +
      '<span data-ng-show="mustEnterSearchTerm">{{\'mydealer.ui.global.enter_keyword\' | language}}</span>' +
      '</span> ' +
      '</td> ' +
      '</tr> ',
    controller: ['$scope', '$stateParams', function($scope, $stateParams) {
      $scope.pageChanged = function() {
        var queryParameter = $stateParams[$scope.queryParameterName] ? angular.fromJson($stateParams[$scope.queryParameterName]) : {};
        //pagination directive is 1 based, server is 0 based.
        queryParameter.page = ($scope.oneBasedPage - 1);
        var queryObject = {};
        queryObject[$scope.queryParameterName] = angular.toJson(queryParameter);
        $state.go('.', queryObject);
      };
    }],
    link: function (scope, element, attrs) {
      scope.mustEnterSearchTerm = attrs.requiredTerm ? !scope.requiredTerm : false;

      scope.queryParameterName = {};
      if (angular.isUndefined(scope.queryParams)) {
        scope.queryParameterName = element.inheritedData('$uiView').state.self.name.split('.');
        scope.queryParameterName = (scope.queryParameterName[scope.queryParameterName.length - 1] + 'Pagination');
      }
      else {
        scope.queryParameterName = scope.queryParams;
      }

      //pagination directive is 1 based, server is 0 based.
      scope.oneBasedPage = scope.pageable.number + 1;
    }
  };
}]);

