'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.equipmentForRent', {
      url: '/equipment-for-rent' +
      '?equipmentForRentPagination' +
      '&equipmentForRentFilters' +
      '&{search:any}',
      params: {
        equipmentForRentPagination: angular.toJson({
          sort: 'description',
          direction: 'asc'
        }),
        equipmentForRentFilters : angular.toJson({
          startDate: Date.now()
        })
      },
      templateUrl: 'app/scripts/modules/equipment/views/equipment-for-rent.html',
      controller: 'EquipmentForRentController',
      resolve: {
        help: function () {
          return 'Equipment-_Equipment_for_Rent.htm';
        },
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.equipment_for_rent.breadcrumb'));
        }],
        authorization: ['userConfiguration', function(userConfiguration) {
          return userConfiguration.isAuthorized(['accessRentalOptions']);
        }],

        equipmentForRentPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.equipmentForRentPagination);
        }],

        equipmentForRentFilters: ['$stateParams', function($stateParams) {
          if ($stateParams.equipmentForRentFilters) {
            return angular.fromJson($stateParams.equipmentForRentFilters);
          }
          else {
            return null;
          }
        }],

        equipmentForRent: [
          'pageableService',
          'equipmentResource',
          'globalAlertService',
          'equipmentForRentPagination',
          'equipmentForRentFilters',
          '$stateParams',
          function(pageableService, equipmentResource, globalAlertService, equipmentForRentPagination, equipmentForRentFilters, $stateParams) {
            var parameters = {
              keywords: ($stateParams.search ? $stateParams.search : '')
            };
            if (equipmentForRentFilters) {
              parameters.startDate = equipmentForRentFilters.startDate ? new Date(equipmentForRentFilters.startDate).getTime() : null;
              parameters.endDate = equipmentForRentFilters.endDate ? new Date(equipmentForRentFilters.endDate).getTime() : null;
            }

            pageableService.addPageableParameters(
              parameters,
              equipmentForRentPagination
            );

            return equipmentResource.dealershipRentalEquipment.get(
              parameters,
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ]
      }
    }
  );
}]);
