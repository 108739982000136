'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider
    .state('navigation.equipment.myRentals',
           {url: '/my-rentals' +
            '?myRentalsPagination' +
            '&{myRentalsSearch:any}',
            params: {
              myRentalsPagination: angular.toJson({
                sort: 'description',
                direction: 'asc'
              })
            },
            templateUrl: 'app/scripts/modules/equipment/views/my-rentals.html',
            controller: 'MyRentalsController',
            resolve: {
              help: function () {
                return 'Equipment-_My_Rentals.htm';
              },
              breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
                return ($filter('language')('mydealer.ui.my_rentals.breadcrumb'));
              }],
              authorization: ['userConfiguration', function(userConfiguration) {
                return userConfiguration.isAuthorized(['myDealerEquipmentAccess']);
              }],

              myRentalsPagination: ['$stateParams', function($stateParams) {
                return angular.fromJson($stateParams.myRentalsPagination);
              }],

              myRentals: [
                'pageableService',
                'rentalContractsResource',
                'globalAlertService',
                '$stateParams',
                'myRentalsPagination',
                function(pageableService, rentalContractsResource, globalAlertService, $stateParams, myRentalsPagination) {
                  var parameters = {
                    keywords:  $stateParams.myRentalsSearch ? $stateParams.myRentalsSearch : ''
                  };
                  pageableService.addPageableParameters(
                    parameters,
                    myRentalsPagination
                  );

                  return rentalContractsResource.assignedRentalContracts.get(
                    parameters,
                    function () {},
                    globalAlertService.handleErrorResponse
                  ).$promise;
                }
              ]
            }
           }
          );
}]);
