'use strict';

intellidealerAppCoreModule.service('googleResource', function ($resource, appConfigurationService) {
    var basePath = appConfigurationService.basePath + "/rest/googleApi";
    return {
      googleApiKey: $resource(basePath + "/apiKey", null, {
        get: {method: "GET"}
      })
    }
  }
);
