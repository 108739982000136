'use strict';

intellidealerAppEquipmentModule.controller('MyServiceContractDetailsController', function ($scope, $state, $stateParams, $filter, serviceAgreementsDetails, equipmentJobCodesResource) {

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.my_service_contract.status')},
    {label:$filter('language')('mydealer.ui.my_service_contract.interval')},
    {label:$filter('language')('mydealer.ui.my_service_contract.scheduled_date')},
    {label:$filter('language')('mydealer.ui.my_service_contract.description')}
  ];

  $scope.serviceAgreementsDetails = serviceAgreementsDetails;

  $scope.selectCard = function(serviceAgreementDetail) {
    $scope.serviceAgreementsDetails.serviceAgreementDetailsList.forEach(function(current) {
      if (current === serviceAgreementDetail) {
        current.selected = !current.selected;
        if (!current.jobCodeDescriptionList) {
          // if no long description, retrieve it
          equipmentJobCodesResource.jobCodeDescriptions.query(
            {
              make: serviceAgreementsDetails.make,
              model: serviceAgreementsDetails.model,
              jobCode: serviceAgreementDetail.jobCode
            },
            function (value) {
              // if successful, add the list to the line
              current.jobCodeDescriptionList = [];
              if (value && value.length > 0) {
                if (value[0].jobCodeDescriptionList && value[0].jobCodeDescriptionList.length > 0) {
                  current.jobCodeDescriptionList = value[0].jobCodeDescriptionList;
                }
              }
            },
            function () {
              // if failed, add an empty list to the line
              current.jobCodeDescriptionList = [];
            }
          );
        }
      } else {
        current.selected = false;
      }
    });
  };

});
