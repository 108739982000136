'use strict';

intellidealerAppCoreModule.directive('validMaxLength', [function() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, required) {
      required.$validators.exceedsMaxLength = function(modelValue, viewValue) {
        if (!modelValue) {
          return true;
        }
        scope.maxValueLength;
        if(element.attr("maxlength") ) {
          scope.maxValueLength = parseInt( element.attr("maxlength") );
          if(modelValue.length > scope.maxValueLength || viewValue.length > scope.maxValueLength)
            return false;
        }
        return true;

      }
    }
  }
}]);
