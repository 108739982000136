'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.equipmentForSale.equipmentForSaleDetails.tires', {
      url: '/tires',
      templateUrl: 'app/scripts/modules/equipment/views/tires.html'
    }
  );

  $stateProvider.state(
    'public.equipment.equipmentForSale.equipmentForSaleDetails.tires', {
      url: '/tires',
      templateUrl: 'app/scripts/modules/equipment/views/tires.html'
    }
  );
}]);
