'use strict';

intellidealerAppCoreModule.directive('dateFormatter', ['locationService', function (locationService) {
  return {
    restrict: 'A',
    require: 'ngModel',
    controller: ['$scope', function ($scope) {
      $scope.dateFormat = !locationService.isOtherwiseCountry() ? 'MM-DD-YYYY' : 'DD-MM-YYYY';
      $scope.validDateFormats = !locationService.isOtherwiseCountry() ? ['M-D-YYYY', 'M/D/YYYY', 'MDYYYY', 'YYYY'] : ['D-M-YYYY', 'D/M/YYYY', 'DMYYYY', 'YYYY'];
    }],
    link: function (scope, element, attrs, ngModel) {
      if (attrs.dateFormatter && (attrs.dateFormatter.toUpperCase() === 'YEAR' || attrs.dateFormatter.toUpperCase() === 'YEAR-INCLUSIVE')) {
        ngModel.$formatters.push(function (value) {
          return value ? moment(value).format('YYYY') : value;
        });
      } else {
        ngModel.$formatters.push(function (value) {
          return value ? moment(value).format(scope.dateFormat) : value;
        });
      }

      ngModel.$validators.date = function (modelValue, viewValue) {
        var myMoment = moment(viewValue, scope.validDateFormats, true);
        return !!(!viewValue || myMoment.isValid());
      };

      if (attrs.dateFormatter && (attrs.dateFormatter.toUpperCase() === 'YEAR-INCLUSIVE' || attrs.dateFormatter.toUpperCase() === 'INCLUSIVE')) {
        ngModel.$parsers.push(function (value) {
          var myMoment = moment(value, scope.validDateFormats, true);
          return (value && myMoment.isValid()) ? myMoment.endOf('year').toDate() : null;
        });
      } else {
        ngModel.$parsers.push(function (value) {
          var myMoment = moment(value, scope.validDateFormats, true);
          return (value && myMoment.isValid()) ? myMoment.toDate() : null;
        });
      }
    }
  };
}]);
