'use strict';

intellidealerApp.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/scripts/modules/parts/views/templates/shop-for-parts-add.html',
'<div class="shop-for-parts-add" data-ng-form="addPartForm">'+
'  <div class="form-group" data-ng-class="{\'has-error\': addPartForm[\'added-qty\'].$invalid}">'+
'    <div class="input-group">'+
'      <input data-ng-model="part.addedQty"'+
'             name="added-qty"'+
'             type="text"' +
'             maxlength="5"' +
'             class="form-control shop-for-parts-add-quantity"'+
'             placeholder="{{!part.cartItem && part.relatedQuantity || part.cartItem && part.cartItem.quantity || \'mydealer.ui.parts.quantity\' | language}}"'+
'             data-ui-keypress="{\'enter\': !part.cartItem ? \'addItem(part.addedQty, part.partNumber, $event, part)\' : \'updateItem(part.cartItem.partNumber, part.addedQty, $event, part)\'}"'+
'             data-validation-popover'+
'             data-valid-number>'+
'      <span class="input-group-btn">'+
'        <button class="btn btn-primary"' +
'                type="button"' +
'                data-ng-if="!part.cartItem"'+
'                data-ng-click="addItem(part.addedQty, part.partNumber, undefined, part, $index, shopForParts.content, togglerId)"'+
'                data-ng-disabled="addPartForm.$invalid || !part.addedQty || part.addedQty < 1">'+
'          {{\'mydealer.ui.global.add\' | language}}' +
'        </button>'+
'        <button class="btn btn-primary" '+
'                type="button"' +
'                data-ng-if="part.cartItem"'+
'                data-ng-click="updateItem(part.cartItem.partNumber, part.addedQty, undefined, part, $index, shopForParts.content, togglerId)"'+
'                data-ng-disabled="addPartForm.$invalid || !part.addedQty">'+
'          {{\'mydealer.ui.global.update\' | language}}'+
'        </button>'+
'      </span>'+
'    </div>'+
'  </div>'+
'  <div class="shop-for-parts-add-buttons">'+
'    <button class="btn btn-primary"' +
'            type="button"' +
'            data-ng-if="part.cartItem"'+
'            data-ng-click="removeItem(part.cartItem.partNumber, part, $index, shopForParts.content, togglerId)" data-ng-disabled="relatedMandatoryPartNumbers.includes(part.cartItem.partNumber)">{{\'mydealer.ui.global.remove\' | language}}</button>'+
'    <button class="btn btn-info" data-ng-click="$root.togglePopover(togglerId + $index);">{{\'mydealer.ui.global.cancel\' | language}}</button>'+
'  </div>'+
'</div>'
);
}]);
