'use strict';

intellidealerAppEquipmentModule.controller('MyEquipmentController', function ($scope, $state, $stateParams, $location, $anchorScroll, myEquipment, userConfiguration, $filter, sessionService, shoppingCartService,equipmentResource) {
  $scope.linkedStates = [];
  if (userConfiguration.myDealerEquipmentAccess) {
    $scope.linkedStates.push({title: $filter('language')('mydealer.ui.my_equipment.breadcrumb'), state:'navigation.equipment.myEquipment'});
  }
  if (sessionService.getFeatures()['PFW.6512'] && userConfiguration.myDealerEquipmentAccess) {
    $scope.linkedStates.push({title: $filter('language')('mydealer.ui.my_rentals.breadcrumb'), state:'navigation.equipment.myRentals'});
  }

  $scope.rowClick = function (event, equipment) {
    if ($scope.fromCart) {
      $scope.setCartUnit(event, equipment);
    } else {
      $state.go('navigation.equipment.myEquipment.myEquipmentDetails.openWorkOrders', {
        company: equipment.equipmentId.company,
        stockNumber: equipment.equipmentId.stockNumber,
        division: equipment.equipmentId.division
      });
    }
  };

  $scope.returnToCart = function () {
    $state.go('navigation.shopping.shoppingCart');
  };

  $scope.setCartUnit = function (event, equipment) {
    event.stopPropagation();
    shoppingCartService.updateUnit(equipment, $scope.search).then(function () {
      shoppingCartService.loadShoppingCart();
    });
  };

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.equipment.description'), property:'description', direction: 'asc', isHidden: false},
    {label:$filter('language')('mydealer.ui.equipment.make'), property:'make', direction: 'asc', isHidden: false},
    {label:$filter('language')('mydealer.ui.equipment.model'), property:'model', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.equipment.serial_number'), property:'machineSerialNumber', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.equipment.fleet'), property:'customerFleetNumber', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.equipment.hours'), property:'unitHours', direction: 'asc', align: 'right',isHidden: false},
    {label:$filter('language')('mydealer.ui.equipment.hours_as_of'), property:'hourReadingToDate', direction: 'asc', align: 'right',isHidden: false},
    {},
    {}
  ];

  if(!sessionStorage.getItem("sortChoices")){
    sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
  }else {
    $scope.sortChoices = JSON.parse(sessionStorage.getItem("sortChoices"));
  }

  $scope.columns = [];

  $scope.sortChoices.forEach(choice => {
    if(choice.label){
      $scope.columns.push({label:choice.label, isSelected:true});
    }
  });

  if(!sessionStorage.getItem("columns")){
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
  }else {
    $scope.columns = JSON.parse(sessionStorage.getItem("columns"));
  }
  $scope.toggleColumns = function(col){
    let index = $scope.columns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.columns[index].isSelected = !$scope.columns[index].isSelected;
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
    let sortIndex = $scope.sortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if(sortIndex >=0){
      $scope.sortChoices[sortIndex].isHidden = !$scope.columns[index].isSelected;
      sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
    }

  }

  $scope.isColVisible = function(colName){
    let index = $scope.columns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.columns[index].isSelected;
  }

  $scope.fromCart = $stateParams.fromCart;
  $scope.myEquipment = myEquipment;
  $scope.terms = {
    keywords:  $stateParams.search
  };

  $scope.search = function (event, property, direction, force) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    var params = {};
    if (force) {
      params.force = Math.random();
    }

    var sortParams = angular.fromJson($stateParams.myEquipmentPagination);
    if (property && direction) {
      sortParams.sort = property;
      sortParams.direction = direction;
    }

    sortParams.page = force ? sortParams.page : 0;
    params.myEquipmentPagination = angular.toJson(sortParams);

    params.search = $scope.terms.keywords;
    $state.go('.', params);
  };


  $scope.toggleAdd = function(state) {
    $scope.showAdd = state ? state : !$scope.showAdd;
    if ($scope.showAdd === false) {
      $location.hash('');
    }
  };

  $scope.clearStorage = function(){
    sessionStorage.clear()
  }

  $scope.getCsv = function () {
    var parameters = {
      keywords: ($stateParams.search ? $stateParams.search : '')
    };
    equipmentResource.csv(parameters).get(
      null,
      function () {
      },
      function (response) {
        globalAlertService.addError('mydealer.ui.equipment.csv_notGenerated');
      }
    );
  };


});
