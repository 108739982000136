'use strict';

intellidealerAppCoreModule.service('rememberMeService', ['$window', function($window) {
  var key = 'mydealer-remember-me';
  var data = {};
  
  this.setLogin = function(library, username, password) {
    if ($window.localStorage) {
      loadData();
      data[library] = {username: username, password: password};
      saveData();
    }
  };
  
  this.getLogin = function(library) {
    if ($window.localStorage) {
      loadData();
      if (data[library]) {
        return {username: data[library].username, password: data[library].password};
      }
    }
    return null;
  };
  
  this.clearLogin = function(library) {
    if ($window.localStorage) {
      loadData();
      if (data[library]) {
        delete data[library];
        if (angular.equals({}, data)) {
          $window.localStorage.removeItem(key);
        } else {
          saveData();
        }
      }
    }
  };
  
  function loadData() {
    var item = $window.localStorage.getItem(key);
    data = (item ? angular.fromJson(item) : {});
  }
  
  function saveData() {
    $window.localStorage.setItem(key, angular.toJson(data));
  }
  
}]);
