'use strict';

intellidealerAppCoreModule.controller('GeneralInquiryController', function ($scope, $state, $filter, globalAlertService, dealershipResource) {
  
  $scope.inquiry = {
    department: '',
    message: ''
  };
  
  $scope.sendInquiry = function() {
    if ($scope.inquiryForm.$valid) {
      dealershipResource.myDealerGeneralInquiryEmail.save(
        {
          dealershipContactType: $scope.inquiry.department,
          inquiryRequest: $scope.inquiry.message
        },
        function() {
          globalAlertService.addSuccess('mydealer.ui.global.inquiry_sent');
          $scope.toggleGeneralInquiry();
        },
        globalAlertService.handleErrorResponse
      );
    }
  };
  
  function loadDefaults() {
    // my account
    if ($state.current.name.indexOf('navigation.myAccount') === 0) {
      $scope.inquiry.department = 'ACCOUNT';
    }
    
    // equipment and rental
    if ($state.current.name.indexOf('navigation.equipment') === 0) {
      if ($state.current.name.indexOf('navigation.equipment.myRentals') === 0 ||
         $state.current.name.indexOf('navigation.equipment.equipmentForRent') === 0) {
        $scope.inquiry.department = 'RENTAL';
        if ($state.current.name.indexOf('.myRentalsDetails') > 0) {
          $scope.inquiry.message = $filter('language')('mydealer.ui.rental.contract_number') + ' : ' + $state.params.contractNumber + '\n';
        }
        if ($state.current.name.indexOf('.equipmentForRentDetails') > 0) {
          $scope.inquiry.message = $filter('language')('mydealer.ui.equipment.stock_number') + ' : ' + $state.params.stockNumber + '\n';
        }
      } else {
        $scope.inquiry.department = 'EQUIPMENT';
        if ($state.current.name.indexOf('.myEquipmentDetails') > 0 ||
           $state.current.name.indexOf('.equipmentForSaleDetails') > 0) {
          $scope.inquiry.message = $filter('language')('mydealer.ui.equipment.stock_number') + ' : ' + $state.params.stockNumber + '\n';
        }
      }
    }
    
    // parts and shopping cart
    if ($state.current.name.indexOf('navigation.parts') === 0 ||
       $state.current.name.indexOf('navigation.shopping') === 0) {
      $scope.inquiry.department = 'PARTS';
      if ($state.current.name.indexOf('.myShoppingLists.myShoppingListsDetails') > 0 &&
         $state.params.referenceNumber !== '') {
        $scope.inquiry.message = $filter('language')('mydealer.ui.my_shopping_lists.reference_number') + ' : ' + $state.params.referenceNumber + '\n';
      }
      if ($state.current.name.indexOf('.previousOrders.previousOrdersDetails') > 0) {
        $scope.inquiry.message = $filter('language')('mydealer.ui.my_orders.reference_number') + ' : ' + $state.params.referenceNumber + '\n';
      }
    }
    
    // quotes
    if ($state.current.name.indexOf('navigation.quotes') === 0) {
      $scope.inquiry.department = 'QUOTES';
    }
  }
  
  loadDefaults();

});
