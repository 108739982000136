'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.equipmentForSale.equipmentForSaleDetails.cab', {
      url: '/cab',
      templateUrl: 'app/scripts/modules/equipment/views/cab.html'
    }
  );

  $stateProvider.state(
    'public.equipment.equipmentForSale.equipmentForSaleDetails.cab', {
      url: '/cab',
      templateUrl: 'app/scripts/modules/equipment/views/cab.html'
    }
  );
}]);
