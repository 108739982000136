'use strict';

intellidealerAppShoppingCartModule.controller('unitSelectionPopupController', function ($scope, $rootScope, $uibModalInstance, properties, $filter) {
  $scope.dismiss = function (value) {
      $uibModalInstance.close(value);
  };
  $scope.units = properties.units;
  $scope.selectedItem = properties.selectedItem;
  $scope.callback = properties.callback;
  $scope.hasUnits = $scope.units && $scope.units.length > 0;

  $scope.selectUnit = function(unit) {
    $scope.selectedItem.unit = unit.index;
    $scope.selectedItem.unitDescription = unit.index === 0 ? '' : unit.description;
    if($scope.callback) {
      $scope.callback($scope.selectedItem.partNumber, $scope.selectedItem.quantity, $scope.selectedItem);
    }
    $scope.dismiss();
  }
});
