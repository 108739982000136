'use strict';

intellidealerAppEquipmentModule.controller('EquipmentForRentDetailsController', function ($scope, equipmentForRent, dealerContactInformation, $filter, dealershipConfiguration, multiMediaStreamingURLService) {

  $scope.showcaseModel = equipmentForRent;
  $scope.showcaseModel.dealerContactInformation = dealerContactInformation;
  $scope.showLocationInRental = dealershipConfiguration.showLocationInRental;
  $scope.showcaseModel.pictureLocations = equipmentForRent.pictureLocations.map(pictureLocation => {
    const httpPattern = /http(s)?:\/\/\S+/;
    if (!httpPattern.test(pictureLocation)) {
      let params = multiMediaStreamingURLService.getEquipmentMultimediaParams(equipmentForRent, pictureLocation);
      return multiMediaStreamingURLService.getMultiMediaStreamingURL(pictureLocation, params);
    }
    else {
      return pictureLocation;
    }
  });

  $scope.showcase = {
    defaultTemplate: 'app/scripts/modules/equipment/views/templates/equipment-for-rent-details-default.html',
    narrow: equipmentForRent.pictureLocations.length < 1,
    selected: '',
    subViews: [
      {'label':$filter('language')('mydealer.ui.equipment.actions.inquire_now'),
       'glyphicon':'glyphicon-earphone',
       'template': 'app/scripts/modules/equipment/views/templates/equipment-for-rent-details-inquire.html'}
    ]
  };

});
