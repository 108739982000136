'use strict';

intellidealerAppEquipmentModule.controller('EquipmentScheduleServiceController', function ($scope, $state, workOrderServiceRequestsResource, globalAlertService, $filter) {
  var MAX_PROBLEM_DESCRIPTION = 150;
  $scope.model = $scope.$parent.model;
  $scope.submitModel = {};

  $scope.scheduleService = function() {
    workOrderServiceRequestsResource.createServiceRequest.save(
      {
        stockNumber: $scope.model.requestServiceStockNumber,
        machineDown: $scope.submitModel.machineDown,
        problemDescription: description(),
        extendedDescription: $scope.submitModel.extendedDescription,
        serialNumber: $scope.model.machineSerialNumber
      },
      function(){
        globalAlertService.addSuccess('mydealer.ui.equipment.schedule_service.success');
        $scope.configuration.selected = false;
      },
      globalAlertService.handleErrorResponse
    );

  };

  var description = function() {
    var description = "";
    if($scope.submitModel.extendedDescription != 'undefined') {
        var words = $scope.submitModel.extendedDescription.split(' ');
        var i = 0;

        while( (description.length + words[i].length) < MAX_PROBLEM_DESCRIPTION) {
            description += words[i] + " ";
            ++i;
            if (i >= words.length) break;
        }
      }

      return description;
    }
});
