'use strict';

//Return the string 'PAID' if input is falsey
intellidealerAppCoreModule.filter('invoicePaid', function ($filter) {

  return function(input) {
    if (!input || input ==="$0.00") {
      return $filter('language')('mydealer.ui.invoices.paid');
    }
    return input;
  };
});
