'use strict';

intellidealerAppMyAccountModule.controller('genericPaymentController', function ($scope, $rootScope, $uibModalInstance, properties, $timeout) {
  $scope.dismiss = function (value) {
    $uibModalInstance.close(value);
  };
  $scope.lumpPayment = function () {
    $scope.callback($scope.lumpAmount);
    $scope.dismiss();
  };
  $scope.updatedPay = function(){
  $scope.updatedSelected($scope.lumpAmount);
  };
  $scope.hasAmount = function (amount) {
    return amount && amount !== .00 && amount !== 0.0 && amount !== 0.00;
  };
  // Not sure the requirements for this page, so just gathering a total for the payment processor as proof of concept
  $scope.callback = properties.callback;
  $scope.title = properties.title;
  $scope.invoice = properties.invoice;
  $scope.updatedSelected = properties.updatedSelected;


  $timeout(function () {
    document.getElementById('lumpAmount').focus();
  });
});
