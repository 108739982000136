'use strict';

intellidealerAppMyAccountModule.controller('authorizeDotNetController', function ($scope, $uibModalInstance, $timeout, properties, $filter,
                                                                                  authorizeDotNetResource, globalAlertService, loadingIndicatorService) {

  var isTimeout;
  var timeOutId;
  $scope.formToken = properties.formToken;
  $scope.authDotNetHostedPaymentURL = properties.authDotNetHostedPaymentURL;
  $scope.callback = properties.callback;

  $scope.dismiss = function () {
    $uibModalInstance.close();
  };

  if ($scope.formToken && $scope.authDotNetHostedPaymentURL) {
    $timeout(function () {
      $scope.openAuthorizeNetPage();
    }, 0);
  } else {
    globalAlertService.addError('Empty properties, Failed to open Payment Page');
    $scope.dismiss();
  }

  $scope.openAuthorizeNetPage = function () {
    var paymentForm = document.getElementById('paymentForm');
    var addPaymentIframe = document.getElementById('iframeAuthorizeNet');
    if ($scope.paymentForm) {
      addPaymentIframe.style.display = 'block';
      paymentForm.setAttribute('action', $scope.authDotNetHostedPaymentURL);
      paymentForm.setAttribute('target', 'iframeAuthorizeNet');
      paymentForm.submit();
      $scope.initIframeCommunication();
    }
  };

  function setTimeOutFunction(){
    var selectedInvoiceList;
    if (properties.selectedInvoices && properties.selectedInvoices.length) {
      selectedInvoiceList = properties.selectedInvoices
        .map(invoice => invoice.invoiceNumber || 'Lump Payment')
        .join(', ');
    }
    timeOutId = setTimeout(() => {
      $scope.dismiss();
      isTimeout = true;
      const message = $filter('language')('mydealer.ui.invoices.ach_payment_timeout_message1') + ' ' + selectedInvoiceList + '.' + '<br>' + $filter('language')('mydealer.ui.invoices.ach_payment_timeout_message2') ;;
      globalAlertService.addError(message);
    }, 30000);
  };

  function validateResponse(response, selectedInvoices) {
    $scope.dismiss();
    if (response.successful) {
      globalAlertService.addSuccess('mydealer.ui.invoices.ach_payment_auth_success');
    } else if (response.lastError.description !== undefined) {
      globalAlertService.addError(response.lastError.description);
    } else {
      globalAlertService.addError('mydealer.ui.invoices.ach_payment_auth_fail');
    }

    selectedInvoices.forEach(invoice => {
      if (sessionStorage.getItem(invoice.transactionId + 'amt')) {
        sessionStorage.removeItem(invoice.transactionId + 'amt');
        sessionStorage.removeItem(invoice.transactionId + 'chk');
      }
    });

    if($scope.callback) {
      $scope.callback();
    }
  }

  function handleTransactionResponse(compositeRequest) {
    if (!isTimeout) {
      authorizeDotNetResource.authorizeDotNetPaymentSuccess(properties.parameters).post(compositeRequest).$promise.then(function (response) {
        clearTimeout(timeOutId);
        validateResponse(response, properties.selectedInvoices);
      }).catch(() => {
        handleTransactionResponse(compositeRequest);
      });
    }
  }

  function validatePayment(response) {
    properties.parameters.preGeneratedInvoiceNumber = properties.preGeneratedInvoiceNumber;
    var compositeRequest = {
      customerPayInvoiceDTOList: properties.selectedInvoices,
      transactionResponse: response
    };
    setTimeOutFunction();
    isTimeout = false;
    handleTransactionResponse(compositeRequest);
  }

  function parseQueryString(str) {
      var vars = [];
      var responseIndex = str.indexOf('response=');
      if (responseIndex !== -1) {
        var firstPart = str.substring(0, responseIndex - 1);
        var responsePart = str.substring(responseIndex);
        var arr = firstPart.split('&');
        for (var i = 0; i < arr.length; i++) {
          var pair = arr[i].split('=');
          vars.push(pair[0]);
          vars[pair[0]] = decodeURIComponent(pair[1]);
        }
        var responsePair = responsePart.split('=');
        vars.push(responsePair[0]);
        vars[responsePair[0]] = decodeURIComponent(responsePair.slice(1).join('='));
      } else {
        var array = str.split('&');
        for (var j = 0; j < array.length; j++) {
          var newPair = array[j].split('=');
          vars.push(newPair[0]);
          vars[newPair[0]] = decodeURIComponent(newPair[1]);
        }
      }
      return vars;
    }

  function resizeIframe(w, h) {
    var ifrm = document.getElementById('iframeAuthorizeNet');
    if (ifrm !== null) {
      ifrm.style.width = w.toString() + 'px';
      ifrm.style.height = h.toString() + 'px';
    }
  }

  function deleteAuthorizationMaintenance() {
    $scope.dismiss();
    properties.parameters.system = 'CASH_RECEIPTS';
    properties.parameters.orderNumber = properties.preGeneratedInvoiceNumber;
    authorizeDotNetResource.deleteAuthorization(properties.parameters).delete().$promise.catch(function (response) {
      globalAlertService.handleErrorResponse(response);
    });
  }

  // Function to initialize the iframe communicator
  $scope.initIframeCommunication = function () {
    if (!window.AuthorizeNetIFrame) {
      window.AuthorizeNetIFrame = {};
    }
    loadingIndicatorService.addRequest();
    $scope.isLoading = true;
    window.AuthorizeNetIFrame = {
      onReceiveCommunication: function (querystr) {
        var params = parseQueryString(querystr);
        switch (params['action']) {
          case 'successfulSave':
            $uibModalInstance.close();
            break;
          case 'cancel':
            globalAlertService.addError('mydealer.ui.auth.net.user.declinedAmount');
            deleteAuthorizationMaintenance();
            break;
          case 'resizeWindow':
            var w = parseInt(params['width']);
            var h = parseInt(params['height']);
            resizeIframe(w, h);
            break;
          case 'transactResponse':
            var response = angular.fromJson(params['response']);
            if (response.responseCode === '1') {
              validatePayment(response);
            } else {
              globalAlertService.addError('mydealer.ui.auth.net.declinedAmount');
              deleteAuthorizationMaintenance();
            }
        }
        if ($scope.isLoading) {
          $scope.isLoading = false;
          loadingIndicatorService.removeRequest();
        }
      }
    };
  };

  window.addEventListener('resize', () => {
    let width;
    let height;
    if (window.innerWidth >= 500 && window.innerWidth <= 1000) {
      width = window.innerWidth * 0.6;
      height = window.innerHeight * 1.4;
    } else {
      width = window.innerWidth;
      height = window.innerHeight * 0.8;
    }
    resizeIframe(width, height);
  });

});
