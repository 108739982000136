'use strict';

intellidealerAppCoreModule.directive('showcase', function () {
  return {
    restrict: 'E',
    scope: {
      configuration: '=',
      model: '='
    },
    templateUrl: 'app/scripts/modules/core/views/templates/showcase.html',
    controller: function ($scope, $state, $filter) {
      $scope.configurationCopy = angular.copy($scope.configuration);
      $scope.configurationCopy.subViews.push({
        'label': $filter('language')('mydealer.ui.global.prev_screen')
      });

      $scope.$watch('configuration.selected', function (newVal, oldVal) {
        if (newVal === $filter('language')('mydealer.ui.global.prev_screen')) {
          $scope.goBack();
          return;
        }

        if (newVal !== oldVal) {
          $scope.currentAction = null;
          angular.forEach($scope.configurationCopy.subViews, function (value) {
            if (value.label === newVal) {
              $scope.currentAction = value;
            }
          });
        }
      });

      $scope.getSelectedTemplate = function () {
        var foundTemplate;
        $scope.configuration.subViews.forEach(function (value) {
          if (value.label === $scope.configuration.selected) {
            foundTemplate = value.template;
          }
        });
        return foundTemplate || $scope.configuration.defaultTemplate;
      };

      $scope.goBack = function () {
        if ($scope.configuration.goBack) {
          $scope.configuration.goBack();
        } else {
          $state.go('^')
        }
      }
    }
  };
});
