'use strict';

intellidealerAppCoreModule.directive('sortingDropdown', ['$stateParams', function($stateParams) {
  return {
    restrict: 'E',
    scope: {
      options: '=',
      changeAction: '&',
      queryParams: '@' //if not specified, the directive will assume [stateName]Pagination as the query parameter to use
    },
    template: '<div><label>{{\'mydealer.ui.global.sort_by\' | language}} :</label> <select class="form-control sorting-dropdown-select" data-ng-model="sortColumn" data-ng-options="option.label for option in options | sortProperty" data-ng-change="sort({property: sortColumn.property,direction: sortColumn.direction})"></select></div>',
    link: function link(scope, element) {

      //Aquires the query parameter containing the sort JSON object (defaults to [stateName]Pagination)
      scope.queryParameterName = {};
      if (angular.isUndefined(scope.queryParams)) {
        scope.queryParameterName = element.inheritedData('$uiView').state.self.name.split('.');
        scope.queryParameterName = (scope.queryParameterName[scope.queryParameterName.length - 1] + 'Pagination');
      }
      else {
        scope.queryParameterName = scope.queryParams;
      }

      //Sets the selected option to the current sort parameter
      scope.options.forEach(function(current){
        if (current.property === angular.fromJson($stateParams[scope.queryParameterName]).sort) {
          scope.sortColumn = current;
        }
      });
      if (!scope.sortColumn) {
        scope.sortColumn = {property: undefined, dirction: undefined};
      }
    },

    controller: ['$scope', '$state', function($scope, $state) {
      //change the sort params, or call the passed in changeAction if it was passed in
      if (!$scope.changeAction) {
        $scope.sort = function() {
          var params = {};
          var sortParams = angular.fromJson($stateParams[$scope.queryParameterName]);
          sortParams.sort = $scope.sortColumn.property;
          sortParams.direction = $scope.sortColumn.direction;
          sortParams.page = 0;
          params[$scope.queryParameterName] = angular.toJson(sortParams);
          $state.go('.', params);
        };
      }
      else {
        $scope.sort = $scope.changeAction;
      }
    }]
  };
}]);
