'use strict';

var intellidealerAppCoreModule = angular.module('intellidealerApp.core', []); // jshint ignore:line


intellidealerAppCoreModule.run(['$rootScope', '$state', function ($rootScope, $state) {

  $rootScope.$on('$stateChangeStart',
    function (event, toState, toParams, fromState, fromParams, options) {

      // When navigating to a public state make sure that the browser is supported
      // otherwise redirect the user to the login page that would show the
      // browser support dialog with appropriate actions to take.
      if (toState.name.indexOf('public') === 0
        && !$rootScope.useUnsupportedBrowser
        && !(bowser.chrome || bowser.firefox || bowser.msedge || bowser.safari)) {

        event.preventDefault();

        // redirect action from the login page in case the browser is not IE (but is not also supported)
        // to allow the user to continue with the public state in case they decide to do so.
        $rootScope.unsupportedBrowserRedirect = function () {
          $rootScope.useUnsupportedBrowser = true;
          $state.go(toState, toParams, options).then(function(){
            $rootScope.unsupportedBrowserRedirect = undefined;
          });
        };

        return $state.go('login', toParams);
      }
    });
}]);

angular.module('template/accordion/accordion-group.html', []).run(['$templateCache', function($templateCache) {
  $templateCache.put('template/accordion/accordion-group.html',
                     '<div class="panel panel-default">\n' +
                     '  <div class="panel-heading" data-ng-click="toggleOpen()">\n' +
                     '      <span class="accordion-toggle" accordion-transclude="heading" data-ng-class="{\'text-muted\': isDisabled}">{{heading}}</span>\n' +
                     '  </div>\n' +
                     '  <div class="panel-collapse" collapse="!isOpen">\n' +
                     '	  <div class="panel-body" data-ng-transclude></div>\n' +
                     '  </div>\n' +
                     '</div>\n' +
                     '');
}]);



