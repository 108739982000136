'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.telematics', {
      url: '/telematics',
      templateUrl: 'app/scripts/modules/equipment/views/telematics.html',
      controller: 'telematicsController',
      resolve: {
        help: function () {
          return 'Equipment-_Telematics.htm';
        },
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.telematics.breadcrumb'));
        }],
        authorization: ['userConfiguration', function(userConfiguration) {
          return userConfiguration.isAuthorized(['myDealerEquipmentAccess']);
        }],
      }
    }
  );
}]);
