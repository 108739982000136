'use strict';

intellidealerAppPartsModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.parts.cnhDsp', {
      url: '/cnh-dsp?brand&serialNumber',
      templateUrl: 'app/scripts/modules/parts/views/cnh-dsp.html',
      controller: 'CnhDspController',
      resolve: {
        help: function () {
          return 'Parts-_CNH_DSP.htm';
        },
        breadcrumb: function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.cnhDsp.breadcrumb'));
        }
      }
    }
  );
}]);
