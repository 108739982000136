'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  var resolve = {
    help: function () {
      return 'Equipment-_Equipment_Details.htm';
    },
    breadcrumb: [
      'equipmentSaleDetails',
      function (equipmentSaleDetails) {
        return (equipmentSaleDetails.manufacturer + ' ' + equipmentSaleDetails.model);
      }],
    equipmentSaleDetails: [
      'equipmentSalesResource',
      '$stateParams',
      '$filter',
      'globalAlertService',
      function(equipmentSalesResource, $stateParams, $filter, globalAlertService) {
        var params = {
          company: $stateParams.company,
          stockNumber: $filter('pathParam')($stateParams.stockNumber),
          division: $stateParams.division
        };
        return equipmentSalesResource.equipmentSaleDetails.get(
          params,
          function () {},
          globalAlertService.handleErrorResponse
        ).$promise;
      }]
  };

  var resolve_public = {
    equipmentSaleDetails: [
      'equipmentSalesResource',
      '$stateParams',
      '$filter',
      'globalAlertService',
      'userConfiguration',
      function(equipmentSalesResource, $stateParams, $filter, globalAlertService, userConfiguration /*wait on this*/) {
        var params = {
          company: $stateParams.company,
          stockNumber: $filter('pathParam')($stateParams.stockNumber),
          division: $stateParams.division
        };
        return equipmentSalesResource.equipmentSaleDetails.get(
          params,
          function () {},
          globalAlertService.handleErrorResponse
        ).$promise;
      }]
  };

  $stateProvider.state('navigation.equipment.equipmentForSale.equipmentForSaleDetails', {
    url: '/equipment-for-sale-details/{company}/{stockNumber}/{division}',
    views : {
      '@navigation.equipment' : {
        templateUrl: 'app/scripts/modules/equipment/views/equipment-for-sale-details.html',
        controller: 'EquipmentForSaleDetailsController'
      }
    },
    resolve: resolve
  });

  $stateProvider.state('public.equipment.equipmentForSale.equipmentForSaleDetails', {
    url: '/equipment-for-sale-details/{company}/{stockNumber}/{division}',
    views : {
      '@public.equipment' : {
        templateUrl: 'app/scripts/modules/equipment/views/equipment-for-sale-details.html',
        controller: 'EquipmentForSaleDetailsController'
      }
    },
    resolve: resolve_public
  });
}]);
