'use strict';

intellidealerAppCoreModule.factory('dealershipConfigurationService', ['globalAlertService', '$q', 'dealershipResource', function (globalAlertService, $q, dealershipResource) {
  var resolved = false;
  var results;
  var resultLibrary = '';

  return function (library) {
    if (!library && !resolved) {
      return $q.reject('failed promise');
    }

    if (resolved && (!library || resultLibrary === library)) {
      return results;
    }

    results = dealershipResource.publicDetails.get(
      {libraryName:library}, 
      function () {
        resolved = true;
        resultLibrary = library;
      }
    ).$promise;

    return results;
  };

}]);
