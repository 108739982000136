'use strict';

intellidealerAppPartsModule.controller('RecommendedServicePartsController', function ($scope, $state, $filter, jobCodePartList, shoppingCartService) {

  $scope.jobCodePartList = jobCodePartList;
  $scope.anySelected = false;
  
  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.parts.part_number')},
    {label:$filter('language')('mydealer.ui.parts.quantity'), align: 'right'}
  ];

  $scope.$watch(function() {
    var anySelected = false;
    $scope.jobCodePartList.forEach(function(current) {
      if (current.selected) {
        anySelected = true;
      }
    });
    return anySelected;
  }, function (newValue) {
    $scope.anySelected = newValue;
  });
  
  $scope.addPartsToCart = function() {
    var selectedParts = [];
    $scope.jobCodePartList.forEach(function(current) {
      if (current.selected) {
        selectedParts.push({
          partNumber: current.partNumber,
          quantity: current.quantity
        }); 
      }
    });
    
    if (selectedParts.length > 0) {
      shoppingCartService.addItemList(selectedParts).then(
        function() {
          $state.reload($state.current);
        },
        function() { }
      );
    }
  };

  $scope.toggleCardMenu = function(event, jobCodePart) {
    event.stopPropagation();
    jobCodePart.isMenuOpen = !jobCodePart.isMenuOpen;
  };

  $scope.selectCard = function(jobCodePart) {
    $scope.jobCodePartList.forEach(function(current) {
      current.selected = (current === jobCodePart ? true : false);
    });
  };

});
