'use strict';

intellidealerAppPartsModule.filter('partDescription', function () {
  return function(part) {

    if (part.marked && !part.stockedPart){
      return 'Reference part';
    }
    return part.description;
  };
});