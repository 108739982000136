'use strict';

intellidealerAppCoreModule.factory('authorizeDotNetResource', function ($resource, appConfigurationService) {

  return {
    authorizeDotNetPaymentPageResponse: function (param) {
      return $resource(appConfigurationService.basePath + '/rest/oem/authorizeDotNet/authorizeInvoiceWeb', param, {post: {method: 'POST'}});
    },

    authorizeDotNetPaymentSuccess: function (param) {
      return $resource(appConfigurationService.basePath + '/rest/oem/authorizeDotNet/transaction', param, {post: {method: 'POST'}});
    },

    deleteAuthorization: function (param) {
      return $resource(appConfigurationService.basePath + '/rest/ach/eCheck/deleteAuthorization', param, {delete: {method: 'DELETE'}});
    },
  };
});
