'use strict';

intellidealerAppPartsModule.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/scripts/modules/parts/views/templates/my-shopping-lists-popover-remove-lists.html',
                     '<div class="button-row-wrapper shopping-cart-buttons-only">' +
                     '<div>' +
                     '<button class="btn btn-info" data-ng-click="$root.togglePopover(\'removeListsButton\');">{{\'mydealer.ui.global.cancel\' | language}}</button>' +
                     '<button class="btn btn-primary" data-ng-click="removeLists();">{{\'mydealer.ui.my_shopping_lists.remove\' | language}}</button>' +
                     '</div>' +
                     '</div>');
}]);
