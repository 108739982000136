'use strict';

intellidealerAppCoreModule.service('userConfigurationService', ['securityResource', 'globalAlertService', '$q', function (securityResource, globalAlertService, $q) {
  var thisService = this;
  this.serviceLoaded = false;

  function checkSwitches(switches, deferred) {
    var isAuthorized = true;
    angular.forEach(switches, function(value) {
      if (!thisService[value]) {
        isAuthorized = false;
      }
    });

    if (isAuthorized) {
      deferred.resolve(true);
    }
    else {
      globalAlertService.addError('mydealer.ui.global.authorization_error');
      deferred.reject(false);
    }
  }

  this.isAuthorized = function(switches) {
    var deferred = $q.defer();

    if (thisService.serviceLoaded) {
      checkSwitches(switches, deferred);
    }
    else {
      securityResource.customerContactSecurity.get(
        function(data) {
          thisService.setFlags(data);
          checkSwitches(switches, deferred);
        }, 
        function(response) {
          globalAlertService.handleErrorResponse(response);
          deferred.reject(false);
        }
      );
    }
    return deferred.promise;
  };

  this.getFlags = function() {
    var deferred = $q.defer();

    if (thisService.serviceLoaded) {
      deferred.resolve(thisService);
    }
    else {
      securityResource.customerContactSecurity.get(
        function(data) {
          thisService.setFlags(data);
          deferred.resolve(thisService);
        }, 
        function(response) {
          globalAlertService.handleErrorResponse(response);
          deferred.reject(false);
        }
      );
    }
    return deferred.promise;
  };

  this.setFlags = function (data) {
    angular.extend(thisService, data);  
    thisService.serviceLoaded = true;
  };
}]);