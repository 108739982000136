'use strict';

/*
  selectAllToggle directive
  Operates based on a boolean value specified by select-all-toggle-property attribute in
  each object of the collection specified by the select-all-toggle-collection attribute.
  There will be performance costs on large collections, since this does a deep watch on the collection.
*/
intellidealerAppCoreModule.directive('selectAllToggle', function() {
  return {
    restrict: 'E',
    template: '<input type="checkbox" ' +
    'id="selectAll{{$id}}" ' +
    'data-ng-model="selectAll" ' +
    'data-ng-change="toggleAll();"> ' +
    '<label for="selectAll{{$id}}"></label>',
    scope: {
      selectAllToggleCollection: '=',
      selectAllToggleProperty: '@'
    },
    link: function (scope, element) {
      var selectedAll = true;
      element.addClass('align-with-cell-content');

      //watch (deeply) for changes to the collection, and update the selectAll checkbox if there are rows to select
        if (scope.selectAllToggleCollection.length !== 0) {
          scope.$watch('selectAllToggleCollection', function (newVal) {
            selectedAll = true;
            Object.keys(sessionStorage).some(key => {
              if (key.includes("chk") || key.includes("unchk")) {
                const value = sessionStorage.getItem(key);
                if (key.includes("unchk")) {
                  if (value === "true") {
                    selectedAll = !value;
                    return !selectedAll;
                  }
                } else {
                  if (value === "false") {
                    selectedAll = value;
                    return !selectedAll;
                  }
                }
              }
            });
            if (sessionStorage.getItem("selectAllToggle") === null || sessionStorage.getItem("selectAllToggle") === "false")
              selectedAll = false;

            scope.selectAll = selectedAll;
          }, true);
        } else {
          scope.selectAll = false;
        }
      //selectAll checkbox was changed, so update the collection to match
      scope.toggleAll = function() {
        scope.selectAllToggleCollection.forEach(function(current) {
          current[scope.selectAllToggleProperty] = scope.selectAll;
        });
        if(!scope.selectAll){
          sessionStorage.setItem("selectAllToggle", "false");
        } else {
          sessionStorage.setItem("selectAllToggle", "true");
        }
        Object.keys(sessionStorage).forEach(key => {
          if(key.includes("chk") || key.includes("unchk"))
            sessionStorage.removeItem(key);
        })

      };

    }
  };
});

