'use strict';

intellidealerAppMyAccountModule.controller('genericLumpPaymentController', function ($scope, $rootScope, $uibModalInstance, properties, $timeout) {
  $scope.dismiss = function (value) {
    $uibModalInstance.close(value);
  };
  $scope.lumpPayments = function () {
    $scope.callback($scope.lumpAmount);
    $scope.dismiss();
  };

  $scope.callback = properties.callback;
  $scope.title = properties.title;
  $scope.selected= false;

  $scope.handleCheckbox = function(){
    if($scope.selected){
      $scope.selected=false;
    }
    else{
      $scope.selected=true;
    }
  }
  $timeout(function () {
    document.getElementById('lumpAmount').focus();
  });
});


