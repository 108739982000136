'use strict';

intellidealerAppEquipmentModule.controller('MyRentalsDetailsController', function ($scope, myRentalsDetailsWithMultimedia, $filter, userConfiguration, multiMediaStreamingURLService) {

  $scope.myRentalsDetails = myRentalsDetailsWithMultimedia;
  $scope.myRentalsDetails.requestServiceStockNumber = $scope.myRentalsDetails.combinedStockPartNumber;
  $scope.myRentalsDetails.pictureLocations = myRentalsDetailsWithMultimedia.pictureLocations.map(pictureLocation => {
    const httpPattern = /http(s)?:\/\/\S+/;
    if (!httpPattern.test(pictureLocation)) {
      let params = multiMediaStreamingURLService.getMyRentalMultimediaParams(myRentalsDetailsWithMultimedia, pictureLocation);
      return multiMediaStreamingURLService.getMultiMediaStreamingURL(pictureLocation, params);
    }
    else {
      return pictureLocation;
    }
  });

  $scope.showcase = {
    defaultTemplate: 'app/scripts/modules/equipment/views/templates/my-rentals-details-default.html',
    narrow: myRentalsDetailsWithMultimedia.pictureLocations.length < 1,
    selected: '',
    subViews: []
  };

  if ($scope.myRentalsDetails.serialized) {
    $scope.showcase.subViews.push(
      {'label':$filter('language')('mydealer.ui.equipment.actions.request_service'),
       'glyphicon':'glyphicon-wrench',
       'template': 'app/scripts/modules/equipment/views/templates/equipment-schedule-service.html'});
  }

  if ($scope.myRentalsDetails.offRentAvailable && userConfiguration.accessRentalOffRent) {
    $scope.showcase.subViews.push(
      {'label':$filter('language')('mydealer.ui.rental.take_off_rent'),
       'glyphicon':'glyphicon-remove',
       'template': 'app/scripts/modules/equipment/views/templates/my-rentals-details-take-off-rent.html'});
  }

});
