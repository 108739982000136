'use strict';

intellidealerAppShoppingCartModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.shopping.shoppingCart.checkoutComplete', {
      url: '/checkout-complete' +
        '?{company}&{division}&{location}&{referenceNumber:any}&{salesOrderNumber:any}&{customerNumber:any}',
      views : {
        '@navigation.shopping' : {
          templateUrl: 'app/scripts/modules/shopping-cart/views/shopping-cart-checkout-complete.html',
          controller: 'ShoppingCartCheckoutCompleteController'
        }
      },
      resolve: {
        help: function () {
          return 'Checkout.htm';
        },
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.checkout_complete.breadcrumb'));
        }]
      }
    });

  $stateProvider.state(
    'public.shopping.shoppingCart.checkoutComplete', {
      url: '/checkout-complete' +
      '?{company}&{division}&{location}&{referenceNumber:any}&{salesOrderNumber:any}&{customerNumber:any}',
      views : {
        '@public.shopping' : {
          templateUrl: 'app/scripts/modules/shopping-cart/views/shopping-cart-checkout-complete.html',
          controller: 'ShoppingCartCheckoutCompleteController'
        }
      }
    });
}]);
