'use strict';

intellidealerAppShoppingCartModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.shopping.shoppingCart', {
      url: '/shopping-cart?{cartId}${dealer-id}',
      templateUrl: 'app/scripts/modules/shopping-cart/views/shopping-cart.html',
      controller: 'shoppingCartController',
      resolve: {
        help: function () {
          return 'Shopping_Cart.htm';
        },
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.shopping_cart.breadcrumb'));
        }]
      }
    });

  $stateProvider.state(
    'public.shopping.shoppingCart', {
      url: '/shopping-cart?{cartId}&{catchACartId}${dealer-id}',
      templateUrl: 'app/scripts/modules/shopping-cart/views/shopping-cart.html',
      controller: 'shoppingCartController'
    });
}]);
