'use strict';

intellidealerAppEquipmentModule.controller('RecommendedServiceController', function ($scope, $state, $stateParams, $filter, $timeout, $window, recommendedServiceService, equipmentJobCodesResource, userConfiguration, globalAlertService, workOrderServiceRequestsResource) {

  $scope.filters = {program: null, usage: 7};
  $scope.showFilters = false;
  $scope.serviceAgreementPrograms = [];
  $scope.serviceAgreementSchedule = [];
  $scope.showParts = userConfiguration.myDealerPartsAccess;

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.recommended_service.interval')},
    {label:$filter('language')('mydealer.ui.recommended_service.recommended_service_date')},
    {label:$filter('language')('mydealer.ui.recommended_service.description')},
    {label:$filter('language')('mydealer.ui.recommended_service.service')}
  ];
  if (userConfiguration.myDealerPartsAccess) {
    $scope.sortChoices.push({label:$filter('language')('mydealer.ui.recommended_service.parts')});
  }

  if ($stateParams.selectedUsage) {
    $scope.filters.usage = $stateParams.selectedUsage;
  }

  recommendedServiceService.populatePrograms($scope.myEquipment.make, $scope.myEquipment.model).then(function () {
    $scope.serviceAgreementPrograms = recommendedServiceService.getPrograms();
    $scope.filters.program = null;
    if ($scope.serviceAgreementPrograms && $scope.serviceAgreementPrograms.length > 0) {
      if ($stateParams.selectedProgram) {
        angular.forEach($scope.serviceAgreementPrograms, function(value) {
          if (value.program === $stateParams.selectedProgram) {
            $scope.filters.program = value;
          }
        });
      }
      if (!$scope.filters.program) {
        $scope.filters.program = $scope.serviceAgreementPrograms[0];
      }
      $scope.search();
    }
  });

  $scope.search = function(event) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    if (parseInt($scope.filters.usage, 10) < 1) {
      $scope.filters.usage = 7;
    }

    if ($scope.filtersform.$valid && $scope.filters.program && $scope.filters.program.program) {
      recommendedServiceService.populateSchedule($scope.myEquipment.make, $scope.myEquipment.model, $scope.filters.program.program, $scope.filters.usage, $scope.myEquipment.unitHours, $scope.myEquipment.hourReadingToDate).then(function () {
        $scope.serviceAgreementSchedule = recommendedServiceService.getSchedule();
        $scope.showFilters = false;
        angular.forEach($scope.serviceAgreementSchedule, function(value) {
          if (value.serviceAgreementScheduleId.code === 'HOURS') {
            $scope.showFilters = true;
          }
        });
      });
    }
  };

  $scope.toggleCardMenu = function(event, serviceAgreementSchedule) {
    event.stopPropagation();
    if (!serviceAgreementSchedule.isMenuOpen) {
      $scope.closeOpenPopover();
    }
    serviceAgreementSchedule.isMenuOpen = !serviceAgreementSchedule.isMenuOpen;
  };

  $scope.togglePopover = function(id) {
    // if a different popover is open, close it
    $scope.closeOpenPopover(null, id);

    var element = angular.element('#' + id);
    $timeout(function() {
      element.trigger('togglePopover');
    });
  };

  var openPopoverId = null;
  $scope.closeOpenPopover = function(event, id) {
    if (event) {
      var target = event.target;
      while (target && target.tagName !== 'TD' && target.tagName !== 'BUTTON') {
        target = target.parentElement;
      }
      if (target && angular.element(target).hasClass('has-popover')) {
        return;
      }
    }
    if (openPopoverId) {
      if (openPopoverId !== id) {
        var closeId = openPopoverId;
        $timeout(function() {
          angular.element('#' + closeId).trigger('togglePopover');
        });
        openPopoverId = id;
      } else {
        openPopoverId = null;
      }
    } else {
      openPopoverId = id;
    }
  };

  $scope.selectCard = function(serviceAgreementSchedule) {
    $scope.serviceAgreementSchedule.forEach(function(current) {
      if (current === serviceAgreementSchedule) {
        current.selected = !current.selected;
        if (!current.jobCodeDescriptionList) {
          // if no long description, retrieve it
          equipmentJobCodesResource.jobCodeDescriptions.query(
            {
              make: serviceAgreementSchedule.serviceAgreementScheduleId.make,
              model: serviceAgreementSchedule.serviceAgreementScheduleId.model,
              jobCode: serviceAgreementSchedule.serviceAgreementScheduleId.flatRate
            },
            function (value) {
              // if successful, add the list to the line
              current.jobCodeDescriptionList = [];
              if (value && value.length > 0) {
                if (value[0].jobCodeDescriptionList && value[0].jobCodeDescriptionList.length > 0) {
                  current.jobCodeDescriptionList = value[0].jobCodeDescriptionList;
                }
              }
            },
            function () {
              // if failed, add an empty list to the line
              current.jobCodeDescriptionList = [];
            }
          );
        }
      } else {
        current.selected = false;
      }
    });
  };

  $scope.scheduleService = function(serviceAgreementSchedule) {
    var comment = $filter('language')('mydealer.ui.recommended_service.requested_service') + ':' +
        (serviceAgreementSchedule.requestedDate ? ' ' + $filter('date')(serviceAgreementSchedule.requestedDate, 'MM-dd-yyyy') : '') +
        (serviceAgreementSchedule.serviceAgreementScheduleId.period ? ', ' + serviceAgreementSchedule.serviceAgreementScheduleId.period + ' ' + $filter('language')('mydealer.ui.recommended_service.code.' + serviceAgreementSchedule.serviceAgreementScheduleId.code.toLowerCase()) : '') +
        (serviceAgreementSchedule.description ? ', ' + serviceAgreementSchedule.description : '');
    var note = serviceAgreementSchedule.comment;
    workOrderServiceRequestsResource.createServiceRequest.save(
      {
        stockNumber: $scope.myEquipment.equipmentId.stockNumber,
        serialNumber: $scope.myEquipment.machineSerialNumber,
        machineDown: false,
        problemDescription: comment,
        extendedDescription: note,
      },
      function() {
        globalAlertService.addSuccess('mydealer.ui.equipment.schedule_service.success');
        $scope.closeOpenPopover();
      },
      globalAlertService.handleErrorResponse
    );
  };

  $scope.openRequestService = function(id, serviceAgreementSchedule) {
    serviceAgreementSchedule.requestedDate = serviceAgreementSchedule.estimatedDate;
    $scope.togglePopover(id);
  };

  $scope.orderParts = function(serviceAgreementSchedule) {
    if(serviceAgreementSchedule.partsAvailable) {
      $state.go('navigation.equipment.myEquipment.myEquipmentDetails.recommendedService.parts', {
        make: serviceAgreementSchedule.serviceAgreementScheduleId.make,
        model: serviceAgreementSchedule.serviceAgreementScheduleId.model,
        jobCode: serviceAgreementSchedule.serviceAgreementScheduleId.flatRate,
        selectedProgram: ($scope.filters.program.program ? $scope.filters.program.program : ''),
        selectedUsage: ($scope.filters.usage ? $scope.filters.usage : 7)
      });
    }
  };

  angular.element($window).bind('resize', $scope.closeOpenPopover);

});
