'use strict';

intellidealerAppQuotesModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.quotes', {
      url: '/quotes?' +
      '&quotesPagination' +
        '&forceRefresh' +
      '&{search:any}',
      params: {
        quotesPagination: angular.toJson({
          sort: 'quoteCombinedId.quoteNumber',
          direction: 'asc'
        })
      },
      templateUrl: 'app/scripts/modules/quotes/views/quotes.html',
      controller: 'QuotesController',
      resolve: {
        help: function () {
          return 'My_Quotes.htm';
        },
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.quotes.breadcrumb'));
        }],
        quotesPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.quotesPagination);
        }],

        search: ['$stateParams', function($stateParams) {
          return $stateParams.search;
        }],

        quotes: [
          'quotesResource',
          'globalAlertService',
          'pageableService',
          'quotesPagination',
          '$stateParams',
          'search',
          function(quotesResource, globalAlertService, pageableService, quotesPagination, $stateParams, search) {
            var parameters = {
              keywords: (search ? search : '')
            };

            pageableService.addPageableParameters(
              parameters,
              quotesPagination
            );

            return quotesResource.assignedQuotes.get(
              parameters,
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ],

        myProfile: ['contactsResource', 'globalAlertService', function(contactsResource, globalAlertService) {
          return contactsResource.assignedContact.get(
            {},
            function () {},
            globalAlertService.handleErrorResponse
          ).$promise;
        }]
      }
    });
}]);
