'use strict';

intellidealerAppPartsModule.config(['$stateProvider', function ($stateProvider) {
  var resolve = {
    help: function () {
      return 'Parts-_Shop_for_Parts.htm';
    },
    breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
      return ($filter('language')('mydealer.ui.shop_for_parts.breadcrumb'));
    }],
    shopForPartsPagination: ['$stateParams', function($stateParams) {
      return angular.fromJson($stateParams.shopForPartsPagination);
    }],
    shopForPartsFilters: ['$stateParams', function($stateParams) {
      if ($stateParams.shopForPartsFilters) {
        return angular.fromJson($stateParams.shopForPartsFilters);
      }
      else {
        return {};
      }
    }],
    deereUrl: [
      'globalAlertService',
      'oemResource',
      'locationService',
      function(globalAlertService, oemResource, locationService) {
        return oemResource.deereUrl.get({
            'siteLocation': location.origin,
            'location': locationService.getDefaultLocation()
          },
          function () {},
          globalAlertService.handleErrorResponse
        ).$promise;
      }
    ],
    shopForParts: [
      'pageableService',
      'partPricesResource',
      'globalAlertService',
      '$stateParams',
      'shopForPartsPagination',
      'shopForPartsFilters',
      'locations',
      'locationService',
      function(pageableService, partPricesResource, globalAlertService, $stateParams, shopForPartsPagination, shopForPartsFilters, locations, locationService) {
        if (!$stateParams.shopForPartsSearch && !shopForPartsFilters.description) {
          return [];
        }

        var parameters = {
          keywords: $stateParams.shopForPartsSearch,
          description: shopForPartsFilters.description,
          defaultLocation: locationService.getDefaultLocation()
        };
        pageableService.addPageableParameters(
          parameters,
          shopForPartsPagination
        );

        return partPricesResource.myDealerPartPriceCombinedList.get(
          parameters,
          function () {},
          globalAlertService.handleErrorResponse
        ).$promise;
      }
    ]
  };

  var resolve_public = {
    shopForPartsPagination: ['$stateParams', function($stateParams) {
      return angular.fromJson($stateParams.shopForPartsPagination);
    }],
    shopForPartsFilters: ['$stateParams', function($stateParams) {
      if ($stateParams.shopForPartsFilters) {
        return angular.fromJson($stateParams.shopForPartsFilters);
      }
      else {
        return {};
      }
    }],
    deereUrl: [
      'globalAlertService',
      'oemResource',
      'userConfiguration',
      'publicModeService',
      'locationService',
      'locations',
      function(globalAlertService, oemResource, userConfiguration /*wait on this*/, publicModeService, locationService, locations) {
        return oemResource.deereGuestModeUrl.get({
            'siteLocation': location.origin,
            'dealerNumber': publicModeService.getDeereDealerNumber(),
            'location': locationService.getDefaultLocation()
          },
          function () {},
          globalAlertService.handleErrorResponse
        ).$promise;
      }
    ],
    shopForParts: [
      'pageableService',
      'partPricesResource',
      'globalAlertService',
      '$stateParams',
      'shopForPartsPagination',
      'shopForPartsFilters',
      'locations',
      'locationService',
      'userConfiguration',
      function(pageableService, partPricesResource, globalAlertService, $stateParams, shopForPartsPagination, shopForPartsFilters, locations, locationService, userConfiguration /*wait on this*/) {
        if (!$stateParams.shopForPartsSearch && !shopForPartsFilters.description) {
          return [];
        }

        var parameters = {
          keywords: $stateParams.shopForPartsSearch,
          description: shopForPartsFilters.description,
          defaultLocation: locationService.getDefaultLocation()
        };
        pageableService.addPageableParameters(
          parameters,
          shopForPartsPagination
        );

        return partPricesResource.myDealerPartPriceCombinedList.get(
          parameters,
          function () {},
          globalAlertService.handleErrorResponse
        ).$promise;
      }
    ]
  };

  var stateConfig = {
    url: '/shop-for-parts' +
    '?shopForPartsPagination' +
    '&shopForPartsFilters' +
    '&{shopForPartsSearch:any}',
    params: {
      shopForPartsPagination: angular.toJson({
        sort: 'partNumber',
        direction: 'asc'
      })
    },
    templateUrl: 'app/scripts/modules/parts/views/shop-for-parts.html',
    controller: 'shopForPartsController'
  };

  stateConfig.resolve = resolve;
  var stateConfig_public = angular.copy(stateConfig);
  stateConfig_public.resolve = resolve_public;

  $stateProvider.state(
    'navigation.parts.shopForParts', stateConfig
  );

  $stateProvider.state(
    'public.parts.shopForParts', stateConfig_public
  );
}]);
