'use strict';

intellidealerAppCoreModule.factory('statementHistoryResource', function ($resource, appConfigurationService) {

  var resource = {};
  resource.assignedStatementHistory =  $resource(appConfigurationService.basePath + '/rest/statementhistory/assignedStatementHistory');

  resource.myDealerStatementEmail = $resource(appConfigurationService.basePath + '/rest/statementhistory/myDealerStatementEmail');

  resource.statementListAsZip = $resource(
    appConfigurationService.basePath + '/rest/statementhistory/statementListAsZip',
    {},
    {
      get: {
        method: 'POST', //even though the intention was to GET, the complex transaction requires a POST
        url: appConfigurationService.basePath + '/rest/statementhistory/statementListAsZip',
        responseType: 'arraybuffer',
        interceptor: {
          response: function (response) {
            if (response.status === 200) {
              var blob = new Blob([response.data], {type: 'application/zip;'});

              saveAs(blob, 'statements.zip');
              //TODO: this is an alternative approach that might work better with Safari on IOS.  Test which we should use.
              //var URL = window.URL || window.webkitURL;
              //var downloadUrl = URL.createObjectURL(blob);
              //window.location = downloadUrl;

            }
          }
        }
      }
    }
  );

  resource.statements = function (param) {
    return $resource(
      appConfigurationService.basePath + '/rest/statementhistory/statements',
      param,
      {
        get: {
          method: 'GET',
          url: appConfigurationService.basePath + '/rest/statementhistory/statements',
          responseType: 'arraybuffer',

          interceptor: {
            response: function (response) {
              let decoder = new TextDecoder("utf-8");
              return decoder.decode(response.data);
            }
          }
        }
      }
    );
  }

  return resource;
});
