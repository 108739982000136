'use strict';

intellidealerAppCoreModule.directive('loadingIndicator', function() {
  return {
    restrict: 'E',
    controller: ['$scope', 'loadingIndicatorService', '$timeout', function($scope, loadingIndicatorService, $timeout) {
      var classRemovalPromise;
      $scope.$watch(
        function() {
          return loadingIndicatorService.isLoading();
        },
        function(newValue) {
          if (newValue === true) {
            $timeout.cancel(classRemovalPromise);
            $scope.loadingIndicatorClass = 'loading-indicator-visible loading-indicator-in-front';
            loadingIndicatorService.addRequest();
            //Make the loading indicator show at least 300ms.  Simulate a request that takes that long.
            $timeout(
              function() {
                loadingIndicatorService.removeRequest();
              },
              300
            );
          }
          else {
            $scope.loadingIndicatorClass = 'loading-indicator-in-front';
            //If you change this delay, change the transition time in loading-indicator.scss
            classRemovalPromise = $timeout(function(){$scope.loadingIndicatorClass = '';}, 333); 
          }

        }
      );
    }],
    template: '<div id="loading-indicator" data-ng-class="loadingIndicatorClass"><div><i class="fa fa-cog fa-5x"></i></div></div>'
  };
});

