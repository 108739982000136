'use strict';

intellidealerAppCoreModule.service('appConfigurationService', function ($location) {
  var versionSuffix = '/ws-cdkhe';
  var sandboxSuffix = 'GULP_VAR_SANDBOX';
  if ($location.host() === 'localhost') {
    versionSuffix = '';
  } else if (sandboxSuffix) {
    versionSuffix += sandboxSuffix;
  }

  // var twoCPPathPrefix = 'https://paymentsdev.tpro3.com/cdk/Payment/';
  var twoCPPathPrefix = 'https://payments.tpro3.com/cdk/Payment/';
  this.basePath = $location.protocol() + '://' + $location.host() + ':18080' + versionSuffix;
  this.noImageUrl = 'images/no-image.jpg';

  this.getTwoCPPaymentPath = function (clientId, portalId) {
    return twoCPPathPrefix + clientId + '/' + portalId + '?showbtn=1&mydealer=1';
  };

  /**
   * @return current application library
   */
  this.getLibrary = function () {
    if ($location.search().library) {
      return $location.search().library;
    } else {
      var paths = $location.path().split('/');
      if (paths.length > 1 && paths[1]) {
        // library is the second urlParam in login page
        if (paths[1] === 'login') {
          return paths[2].toUpperCase();
        } else {
          return paths[1].toUpperCase();
        }
      }
    }
  };
});
