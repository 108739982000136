'use strict';

intellidealerAppCoreModule.factory('themeApplicationService', ['$css', function ($css) {
  
  var service = function (theme) {
    switch (theme){
      case '#82C600':
        $css.add('styles/82C600.css');
        break;
      case '#367C2B':
        $css.add('styles/367C2B.css');
        break;
      case '#AC202D':
        $css.add('styles/AC202D.css');
        break;
      case '#004890':
        $css.add('styles/004890.css');
        break;
      case '#F0B823':
        $css.add('styles/F0B823.css');
        break;
      default:
        $css.add('styles/82C600.css');
        break;
    }
  };

  return service;
}]);
