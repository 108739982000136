'use strict';

intellidealerAppCoreModule.filter('range', function () {
    return function(input, minvalue, maxvalue) {

        //replace the first occurrence of {} with minvalue
        var message = input.replace("{0}", minvalue);

        //replace the second occurrence of {} with maxvalue
        message = message.replace("{1}", maxvalue);

        return message;
    }
});
