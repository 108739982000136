'use strict';

intellidealerAppMyAccountModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider
    .state(
    'navigation.myAccount.statements', {
      url: '/statements' +
      '?statementsPagination',
      params: {
        c: angular.toJson({
          sort: 'statementRunDate',
          direction: 'desc'
        })
      },
      templateUrl: 'app/scripts/modules/my-account/views/statements.html',
      controller: 'StatementsController',
      resolve: {
        help: function () {
          return 'My_Account-_Statements.htm';
        },
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.statements.breadcrumb'));
        }],
        statementsPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.statementsPagination);
        }],

        statements: ['pageableService', 'statementHistoryResource', 'globalAlertService', '$stateParams', 'statementsPagination', function(pageableService, statementHistoryResource, globalAlertService, $stateParams, statementsPagination) {

          var parameters = {};
          pageableService.addPageableParameters(
            parameters,
            statementsPagination
          );

          return statementHistoryResource.assignedStatementHistory.get(
            parameters,
            function () {},
            globalAlertService.handleErrorResponse
          ).$promise;
        }],
        myProfile: ['contactsResource', 'globalAlertService', function(contactsResource, globalAlertService) {
          return contactsResource.assignedContact.get(
            {},
            function () {},
            globalAlertService.handleErrorResponse
          ).$promise;
        }]
      },
    }
  );
}]);
