'use strict';
/**
 * Datepicker based on uib-datepicker which works with timestamp as the model instead of Date.
 */
intellidealerAppCoreModule.directive('cdkDatepicker', function () {
  return {
    restrict: 'E',
    scope: {
      valueModel: '=',
      cdkDateFormatter: '@',
      cdkDatepickerOptions: '<',
      name: '@'
    },
    require: ['?^form'],
    templateUrl: 'app/scripts/modules/core/directives/cdk-datepicker/cdk-datepicker.html',
    controller: function ($scope) {
      let defaultDatepickerOptions = {};
      if ($scope.cdkDateFormatter && $scope.cdkDateFormatter.toLocaleLowerCase() === 'year') {
        _.merge(defaultDatepickerOptions, {
          datepickerMode: 'year',
          minMode: 'year',
          showWeeks: 'false'
        });
      }
      $scope.cdkDatepickerOptions = $scope.cdkDatepickerOptions || {};
      _.merge($scope.cdkDatepickerOptions, defaultDatepickerOptions);

      if ($scope.valueModel) {
        $scope.dateObject = new Date($scope.valueModel);
      }
      $scope.$watch('dateObject', function () {
        $scope.valueModel = $scope.dateObject ? $scope.dateObject.getTime() : null;
      });
      $scope.isObjectEmpty = function (obj) {
        return Object.keys(obj).length === 0;
      };
    },
    link: function (scope, element, attrs, formCtrl) {
      if (formCtrl && formCtrl[0]) {
        scope.errorObject = formCtrl[0][scope.name].$error;
      }
    }
  };
});
