'use strict';

//Clear errors from previous state when the state transitions
intellidealerAppCoreModule.run(['$rootScope', 'globalAlertService', function($rootScope, globalAlertService) {
  $rootScope.$on('$stateChangeSuccess', function() {
    globalAlertService.clearErrors();
  });
}]);

intellidealerAppCoreModule.service(
  'globalAlertService',
  function (alertService, $filter) {

    var currentAlerts = [];
    this.currentAlerts = currentAlerts;

    function addError (message) {
      alertService.addAlert(
        currentAlerts,
        $filter('language')(message)
      );
    }
    this.addError = addError;

    function addErrorObject (errorObject) {
      errorObject.message=  $filter('language')( errorObject.message);
      errorObject.type="danger";
      alertService.addAlert(
        currentAlerts,
        errorObject
      );
    }
    this.addErrorObject = addErrorObject;

    function addSuccess (message) {
      this.clearErrors();
      alertService.addAlert(
        currentAlerts,
        {
          message: $filter('language')(message),
          type: 'success',
          timed: true
        }
      );
    }
    this.addSuccess = addSuccess;

    function handleSpecificType(httpResponse, type, callback) {

      function callbackIfType(element) {
        if (element.type) {
          if (element.type === type) {
            callback(element);
          }
        }
      }

      if (httpResponse.data) {
        if (Array.isArray(httpResponse.data)) {
          httpResponse.data.forEach(callbackIfType);
        } else {
          callbackIfType(httpResponse.data);
        }
      }

    }

    function handleValidation(httpResponse, validationCollection) {
      var callback;
      var isHandled = false;
      if (validationCollection) {
        callback = function (element) {
          validationCollection[element.property] = element.message;
          isHandled = true;
        };
      } else {
        callback = function (element) {
          addError(element.message);
          isHandled = true;
        };
      }

      handleSpecificType(httpResponse, 'VALIDATION', callback);

      if (validationCollection) {
        if (!angular.equals({}, validationCollection)) {
          for(var property in validationCollection) {
            if(validationCollection.hasOwnProperty(property)) {
              addError(validationCollection[property]);
            }
          }
        }
      }
      return isHandled;
    }

    function handleAuthorization(httpResponse) {
      var isHandled = false;
      var callback = function (element) {
        addError(element.message);
        isHandled = true;
      };
      handleSpecificType(httpResponse, 'AUTHORIZATION', callback);
      return isHandled;
    }

    function handleIOError(httpResponse) {
      var isHandled = false;
      var callback = function (element) {
        addErrorObject(element);
        isHandled = true;
      };
      handleSpecificType(httpResponse, 'IO', callback);
      return isHandled;
    }

    function handleError(httpResponse) {
      var isHandled = false;
      var callback = function (element) {
        addError(element.message);
        isHandled = true;
      };
      handleSpecificType(httpResponse, 'ERROR', callback);
      return isHandled;
    }

    function handleWarning(httpResponse) {
      var isHandled = false;
      var callback = function (element) {
        addError(element.message);
        isHandled = true;
      };
      handleSpecificType(httpResponse, 'WARNING', callback);
      return isHandled;
    }

    function handleNotFound(httpResponse) {
      var isHandled = false;
      var callback = function (element) {
        addError(element.message);
        isHandled = true;
      };
      handleSpecificType(httpResponse, 'NOT_FOUND', callback);
      return isHandled;
    }

    function handleSecurity(httpResponse) {
      var isHandled = false;
      var callback = function (element) {
        addError(element.message);
        isHandled = true;
      };
      handleSpecificType(httpResponse, 'SECURITY', callback);
      return isHandled;
    }

    function handleUsage(httpResponse) {
      var isHandled = false;
      var callback = function (element) {
        addError(element.message);
        isHandled = true;
      };
      handleSpecificType(httpResponse, 'USAGE', callback);
      return isHandled;
    }

    function handleHTTPStatusCodes(status) {
      switch(status) {
        case 0:
          addError('mydealer.ui.global.connection_error');
          break;
        case 401:
          addError('mydealer.ui.global.authorization_error');
          break;
        case 500:
          addError('mydealer.ui.global.internal_server_error');
          break;
        default:
          addError('mydealer.ui.global.default_error');
      }
    }

    function validateForm(formObject) {
      angular.forEach(formObject,function(item) {
        if (item && item.$$parseAndValidate) {
          item.$$parseAndValidate();
        }
      });
    }

    this.handleErrorResponse = function(httpResponse, validationCollection, formObjects) {
      var responseHandled = false;
      responseHandled = handleValidation(httpResponse, validationCollection) || responseHandled;
      responseHandled = handleAuthorization(httpResponse) || responseHandled;
      responseHandled = handleError(httpResponse) || responseHandled;
      responseHandled = handleIOError(httpResponse) || responseHandled;
      responseHandled = handleWarning(httpResponse) || responseHandled;
      responseHandled = handleNotFound(httpResponse) || responseHandled;
      responseHandled = handleSecurity(httpResponse) || responseHandled;
      responseHandled = handleUsage(httpResponse) || responseHandled;

      if (validationCollection && formObjects) {
        if (angular.isArray(formObjects)) {
          formObjects.forEach(validateForm);
        }
        else {
          validateForm(formObjects);
        }
      }

      if (!responseHandled) {
        handleHTTPStatusCodes(httpResponse.status);
      }
    };

    this.clearErrors = function() {
      // currentAlerts.length = 0;
      for (var i = currentAlerts.length - 1; i >= 0; i--) {
        // if (currentAlerts[i].type !== 'success') {
        if (currentAlerts[i].closeable) {
          currentAlerts.splice(i, 1);
        }
      }
    };

  });
