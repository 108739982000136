'use strict';

intellidealerAppPartsModule.controller('CnhDspController', function ($scope, $rootScope, $sce, $state, $stateParams, shoppingCartService,
                                                                     globalAlertService, oemResource, locationService) {
  $scope.urlLoaded = false;
  $scope.cnhDspUrl = "";

  $scope.loadUrl = function () {
    return oemResource.cnhDspSetup.get(
      {'siteLocation': location.origin,
        'brand': $stateParams.brand,
        'serialNumber': $stateParams.serialNumber,
        'userLocation': locationService.getDefaultLocation()
      },
      function () {},
      globalAlertService.handleErrorResponse)
      .$promise.then(function (value) {
        $scope.cnhDspUrl = $sce.trustAsResourceUrl(value.dspUrl);
        $scope.urlLoaded = true;
      });
  };
  $scope.loadUrl();

  window.addEventListener('message', addToCartProcurementSystem);
  function addToCartProcurementSystem(event) {
    var goToCheckout = false;
    if (event.data.type === "dsp-addToCart") {
      if(event.data.item) {
        event.data.item.forEach(function(part) {
          shoppingCartService.addItem(part.qty, part.itemNum, null, true);
          if(part.goToCheckout === "true"){
            goToCheckout = true;
          }
        });
      }
    }

    if(goToCheckout){
      $state.go('navigation.shopping.shoppingCart');
    }
  }

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toStateParams, fromState) {
    if(toState.name !== "navigation.parts.cnhDsp"){
      window.removeEventListener('message', addToCartProcurementSystem);
    }
  });


});
