'use strict';

intellidealerAppEquipmentModule.controller('MyServiceContractController', function ($scope, $state, $stateParams, $filter, serviceAgreements) {

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.my_service_contract.contract_number'), property:'contractNumber', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.my_service_contract.program'), property:'programDescription', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.my_service_contract.usage_per_week'), property:'usageWeek', direction: 'asc', align: 'right'},
    {label:$filter('language')('mydealer.ui.my_service_contract.expiry_date'), property:'expiryDate', direction: 'asc', align: 'right'},
    {}
  ];

  $scope.serviceAgreements = serviceAgreements;

  $scope.search = function(event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    var params = {};
    
    var sortParams = angular.fromJson($stateParams.myServiceContractPagination);
    if (property && direction) {
      sortParams.sort = property;
      sortParams.direction = direction;
    }
    sortParams.page = 0;
    params.myServiceContractPagination = angular.toJson(sortParams);
    
    $state.go('.', params);
  };

});
