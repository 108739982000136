'use strict';

intellidealerAppEquipmentModule.controller('OpenWorkOrdersController', function ($scope, $state, $stateParams, $filter, workOrders) {

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.open_work_orders.work_order_number'), property:'workOrderId.workOrderNumber', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.open_work_orders.estimated_date')},
    {label:$filter('language')('mydealer.ui.open_work_orders.description'), property:'descriptionOfWork', direction: 'asc'}
  ];

  $scope.workOrders = workOrders;
  
  $scope.search = function(event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    var params = {};
    
    var sortParams = angular.fromJson($stateParams.openWorkOrdersPagination);
    if (property && direction) {
      sortParams.sort = property;
      sortParams.direction = direction;
    }
    sortParams.page = 0;
    params.openWorkOrdersPagination = angular.toJson(sortParams);
    
    $state.go('.', params);
  };

});
