'use strict';

intellidealerAppCoreModule.service(
  'alertService', 
  function ($timeout, $filter) {

    /*
      'alert' is the alert being added to the 'alerts' collection in following the format, 
      or just a string representing the message if the defaults are fine for the other parameters:
      {
        message: string - he message to display
        type: string - one of: success, info, warning, danger.  defaults to success
        timed: boolean - whether the message times out or has a close button. defaults to false
        delay: number - if the message times out, how long in ms should it display for.  defaults to 5000
      }
    */
    this.addAlert = function(alerts, alert) {
      //If just a string, make the object out of it.      
      if (typeof alert === 'string') {
        alert = {
          message: alert
        };  
      }
      
      //Make sure the object has a message; the only parameter that doesn't have a default.
      if (!alert.message) {
        return;
      }   
      
      //Apply defaults if required
      alert.type = alert.type ? alert.type : 'danger';
      alert.timed = alert.timed ? alert.timed : false;
      alert.delay = alert.delay ? alert.delay : 5000;
      
      
      var content = {
        type: alert.type,
        msg: $filter('language')(alert.message),
        closeable: !alert.timed
      };
      alerts.push(content);     

      //Add timed function that removes alert if it's timed.
      if (alert.timed) {
        $timeout(
          function() {
            alerts.splice(alerts.indexOf(content),1);
          },
          alert.delay
        );
      }
    };

  });
