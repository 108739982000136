'use strict';

intellidealerAppCoreModule.service('breadcrumbsService', function () {
  this.getBreadcrumbNames = function recurse (currentState){
    var breadcrumbs = [];
    var uniqueBreadCrumbs = [];
    if (angular.isDefined(currentState.parent)){
      var returnedObject = recurse(currentState.parent);
      breadcrumbs = returnedObject.breadcrumbs;
      uniqueBreadCrumbs = returnedObject.uniqueBreadCrumbs;
    }
    if (currentState.locals && currentState.locals.globals && angular.isDefined(currentState.locals.globals.breadcrumb)) {
      if (uniqueBreadCrumbs.indexOf(currentState.locals.globals.breadcrumb) === -1) {
        uniqueBreadCrumbs.push(currentState.locals.globals.breadcrumb);
        breadcrumbs.push ({
          text:currentState.locals.globals.breadcrumb,
          state:currentState.name,
          abstract: currentState.abstract
        });
      }
    }
    return {breadcrumbs: breadcrumbs, uniqueBreadCrumbs: uniqueBreadCrumbs};
  };
});
