'use strict';

// eslint-disable-next-line no-undef
intellidealerAppCoreModule.config(function ($stateProvider) {
  $stateProvider.state(
    'public', {
      url: '/{library}/public/{company}/{division}',
      templateUrl: 'app/scripts/modules/core/views/public.html',
      abstract: true,
      controller: ['$scope', '$state', 'globalAlertService', 'shoppingCartService', 'dealershipConfiguration', 'multiMediaStreamingURLService', function ($scope, $state, globalAlertService, shoppingCartService, dealershipConfiguration, multiMediaStreamingURLService) {
        $scope.dealershipName = dealershipConfiguration.dealershipName;
        if (dealershipConfiguration.dealershipLogo) {
          $scope.dealershipLogo = multiMediaStreamingURLService.getMultiMediaStreamingPublicWebConfigURL(dealershipConfiguration.remoteServerPath + dealershipConfiguration.dealershipLogo, true);
        }
        $scope.alerts = globalAlertService.currentAlerts;
        if ($state.current.name.startsWith('public.parts') || $state.current.name.startsWith('public.shopping')) {
          if(!$state.params.cartId && !$state.params.catchACartId){
            //these two state params are set by the john deere parts catalog integration
            //if we run the loadShoppingCart command it causes a race condition on the back end with the
            //John Deere parts being loaded in and causes some data issues.  We therefore only want to do this initial
            //load if we're NOT entering these screens from parts catalog
            shoppingCartService.loadShoppingCart();
          }
        }
      }],
      resolve: {
        version: ['cdkAnalyticsService', function (cdkAnalyticsService) {
          return cdkAnalyticsService.setLibrary();
        }],
        userConfiguration: ['$stateParams', '$rootScope', 'loginResource', 'securityResource', '$state', '$q', 'publicModeService', 'locationService', 'cdkAnalyticsService', 'version',
          function ($stateParams, $rootScope, loginResource, securityResource, $state, $q, publicModeService, locationService, cdkAnalyticsService, version /*wait on this*/) {
            return loginResource.post({
              library: $stateParams.library.toUpperCase(),
              company: $stateParams.company,
              division: $stateParams.division,
              system: 'MD',
              publicMode: true,
              cartReferenceNumber: publicModeService.getCartReferenceNumber()
            }).$promise.then(function () {
              cdkAnalyticsService.setGlobalField('company', $stateParams.company);
              cdkAnalyticsService.setGlobalField('division', $stateParams.division);
              $rootScope.gadimension3 = $stateParams.company;
              $rootScope.gadimension4 = $stateParams.division;
              locationService.clearDefaultLocation();
              return securityResource.customerContactSecurity.get().$promise;
            }).catch(function (error) {
              if (error.data.message == 'Feature not licensed.') {
                $state.go('publicBadStateLicense');
              } else {
                $state.go('publicBadState');
              }
              return $q.reject();
            });
          }],
        dealershipConfiguration: ['dealershipConfigurationService', '$stateParams', 'themeApplicationService', 'userConfiguration', function (dealershipConfigurationService, $stateParams, themeApplicationService, userConfiguration /*wait on this*/) {
          return dealershipConfigurationService($stateParams.library.toUpperCase()).then(function (value) {
            themeApplicationService(value.baseColor.symbol);
            return value;
          });
        }],
        language: ['$stateParams', 'languageService', 'userConfiguration', function ($stateParams, languageService, userConfiguration /*wait on this*/) {
          return languageService.populatePublic($stateParams.library.toUpperCase(), userConfiguration.screenLanguage);
        }],
        customizedMessages: ['$stateParams', 'customizedMessagesService', function ($stateParams, customizedMessagesService) {
          return customizedMessagesService.populatePublicMessages($stateParams.library.toUpperCase(), $stateParams.company, $stateParams.division);
        }],
        locations: ['locationService', 'userConfiguration', function (locationService, userConfiguration /*wait on this*/) {
          return locationService.populateAssignedLocations(true, "");
        }],
        googleAnalytics: ['$rootScope','cdkAnalyticsService', 'locationService', 'locations', function ($rootScope, cdkAnalyticsService, locationService, locations /*wait on this*/) {
          return cdkAnalyticsService.loadTrackingIds().then(function (response) {
            cdkAnalyticsService.setGlobalField('branch', locationService.getBusinessLocation());
            $rootScope.gadimension5 = locationService.getBusinessLocation();
            return response;
          });
        }]
      }
    });
});
