'use strict';

intellidealerAppCoreModule.directive('breadcrumbs', function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
    },
    controller: function($scope, $state, breadcrumbsService) {

      $scope.breadcrumbs = [];

      $scope.$on('$stateChangeSuccess', function () {
        $scope.breadcrumbs = breadcrumbsService.getBreadcrumbNames($state.$current).breadcrumbs;
      });
    },
    template: 
    '<ol class="breadcrumb">' +
    '<li class="" data-ng-repeat="breadcrumb in breadcrumbs">' +
    '<a data-ui-sref="{{breadcrumb.state}}" data-ng-if="!breadcrumb.abstract && !$last">{{breadcrumb.text}}</a>' +
    '<span data-ng-if="breadcrumb.abstract || $last">{{breadcrumb.text}}</span>' +
    '</li>' +
    '</ol>'
  };
});
