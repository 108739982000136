'use strict';

intellidealerAppEquipmentModule.controller('MyEquipmentAddController', function ($scope, $location, $anchorScroll, globalAlertService, equipmentResource) {

  $scope.equipment = {};

  $scope.scrollHere = function() {
    $location.hash('add');
    $anchorScroll();
  };

  $scope.close = function() {
    $scope.$parent.toggleAdd(false); 
  };

  $scope.submit = function() {
    if ($scope.add.$valid) {
      equipmentResource.myDealerEquipmentAddUnitRequestEmail.save(
        {
          'equipmentMake': $scope.equipment.make,
          'equipmentModel': $scope.equipment.model,
          'equipmentSerialNumber': $scope.equipment.serialNumber,
          'equipmentDescription': $scope.equipment.description,
          'equipmentHours': $scope.equipment.hours
        },
        function(){
          globalAlertService.addSuccess('mydealer.ui.global.email_sent');
          $scope.close();
        },
        globalAlertService.handleErrorResponse
      );
    }
  };

});
