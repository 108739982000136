'use strict';

intellidealerAppCoreModule.controller('confirmationController',
  function ($sce, $scope, $uibModalInstance, $compile, properties) {
    $scope.labelText = properties.labelText;
    $scope.headerText = properties.headerText;
    if (properties.bodyHtml) {
      $scope.bodyHtml = properties.bodyHtml;
    }
    $scope.buttons = {
      yes: !properties.buttons ? 'mydealer.ui.global.yes' : properties.buttons.yes,
      no: !properties.buttons ? 'mydealer.ui.global.no' : properties.buttons.no,
    };
    $scope.alertClasses = function () {
      if (properties.alert) {
        return "alert alert-" + properties.alert;
      }
      return "";
    };
    $scope.dismiss = function (value) {
      $uibModalInstance.close(value);
    };
  }
);

