'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.myEquipment.myEquipmentDetails.serviceHistory', {
      url: '/service-history' +
      '?serviceHistoryPagination' +
      '&customerEquipmentHistoryPagination' +
      '&{serviceHistorySearch:any}',
      params: {
        serviceHistoryPagination: angular.toJson({
          sort: 'description',
          direction: 'asc'
        }),
        customerEquipmentHistoryPagination: angular.toJson({
          sort: 'equipmentCustomerHistoryId.description',
          direction: 'asc'
        })
      },
      templateUrl: 'app/scripts/modules/equipment/views/service-history.html',
      controller: 'serviceHistoryController',
      resolve: {

        serviceHistoryPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.serviceHistoryPagination);
        }],

        customerEquipmentHistoryPagination: ['$stateParams', function ($stateParams) {
          return angular.fromJson($stateParams.customerEquipmentHistoryPagination);
        }],

        customerEquipmentHistories : [
          'pageableService',
          'equipmentCustomerHistoryResource',
          'globalAlertService',
          '$stateParams',
          '$filter',
          'customerEquipmentHistoryPagination',
          function(pageableService, equipmentCustomerHistoryResource, globalAlertService, $stateParams, $filter, customerEquipmentHistoryPagination) {
            if (localStorage.getItem("serviceHistoryType") !== "Customer") {
              return;
            }
            var parameters = {
              stockNumber: $filter('pathParam')($stateParams.stockNumber),
              keywords: ($stateParams.serviceHistorySearch ? $stateParams.serviceHistorySearch : '')
            };
            pageableService.addPageableParameters(
              parameters,
              customerEquipmentHistoryPagination
            );
            return equipmentCustomerHistoryResource.equipmentCustomerHistory.get(
              parameters,
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ],

        invoices: [
          'pageableService',
          'equipmentHistoryResource',
          'globalAlertService',
          '$stateParams',
          '$filter',
          'serviceHistoryPagination',
          function(pageableService, equipmentHistoryResource, globalAlertService, $stateParams, $filter, serviceHistoryPagination) {
            if (localStorage.getItem("serviceHistoryType") !== "Dealer" && localStorage.getItem("serviceHistoryType") !== null) {
              return;
            }
            var parameters = {
              stockNumber: $filter('pathParam')($stateParams.stockNumber),
              keywords: ($stateParams.serviceHistorySearch ? $stateParams.serviceHistorySearch : '')
            };
            pageableService.addPageableParameters(
              parameters,
              serviceHistoryPagination
            );

            return equipmentHistoryResource.assignedEquipmentHistoryByStockNumber.get(
              parameters,
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ]
      }
    }
  );
}]);
