'use strict';

intellidealerAppQuotesModule.controller('QuotesController', function ($scope, $state, $stateParams, $filter, appConfigurationService, globalAlertService, quotes, quotesResource, search, myProfile) {

  // quote list values
  $scope.quotes = quotes;
  $scope.email = {
    address: myProfile.businessEmailAddress
  };
  $scope.acceptRequoteForm = {};
  $scope.emailForm = {};
  $scope.terms = {keywords: search};
  $scope.anySelected = false;
  $scope.anySelectedExpired = false;

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.quotes.reference_number'), property:'quoteCombinedId.quoteNumber', direction: 'desc', isHidden: false},
    {label:$filter('language')('mydealer.ui.quotes.description'),isHidden: false,property:'description', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.global.location'),isHidden: false},
    {label:$filter('language')('mydealer.ui.quotes.quoted_date'), property:'quotedDate', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.quotes.expiry_date'), property:'expiryDate', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.quotes.type'),isHidden: false,property:'quoteCombinedId.systemType', direction: 'asc'},
    {label:$filter('language')('mydealer.ui.quotes.status'),isHidden: false,property:'status', direction: 'asc'},
    {}
  ];

  $scope.columns = [];
  if(!sessionStorage.getItem("sortChoices")){
    sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
  }else {
    $scope.sortChoices = JSON.parse(sessionStorage.getItem("sortChoices"));
  }
  $scope.sortChoices.forEach(choice => {
    if(choice.label){
      $scope.columns.push({label:choice.label, isSelected:true});
    }
  });

  $scope.isSelectedCheckbox = function(event,quote){
    if(quote.selected){
      sessionStorage.removeItem(quote.quoteCombinedId.quoteNumber+'chk');
      sessionStorage.setItem(quote.quoteCombinedId.quoteNumber+'unchk','true');
    }else{
      sessionStorage.removeItem(quote.quoteCombinedId.quoteNumber+'unchk');
      sessionStorage.setItem(quote.quoteCombinedId.quoteNumber+'chk','true');
    }
  }

  if(!sessionStorage.getItem("columns")){
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
  }else {
    $scope.columns = JSON.parse(sessionStorage.getItem("columns"));
  }
  $scope.toggleColumns = function(col){
    let index = $scope.columns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.columns[index].isSelected = !$scope.columns[index].isSelected;
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
    let sortIndex = $scope.sortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if(sortIndex >=0){
      $scope.sortChoices[sortIndex].isHidden = !$scope.columns[index].isSelected;
      sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
    }

  }
  $scope.isColVisible = function(colName){
    let index = $scope.columns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.columns[index].isSelected;
  }

  $scope.quotes.content.forEach(quote => {
    if(sessionStorage.getItem("selectAllToggle") === "true" && !sessionStorage.getItem(quote.quoteCombinedId.quoteNumber + 'unchk'))
      quote.selected=true;
    else if(sessionStorage.getItem(quote.quoteCombinedId.quoteNumber+'chk'))
      quote.selected=true;
  });

  // determine if any quotes have been selected
  $scope.$watch(function() {
    var anySelected = false;
    $scope.quotes.content.forEach(function(current) {
      if (current.selected) {
        anySelected = true;
      }
    });
    Object.keys(sessionStorage).forEach(key => {
      if(key.includes("chk") && !key.includes("unchk"))
        anySelected = true;
    })
    return anySelected;
  }, function (newValue) {
    $scope.anySelected = newValue;
  });

  // determine if any expired quotes have been selected, by expired date or status
  $scope.$watch(function() {
    var anySelectedExpired = false;
    var expiry, today = new Date();
    today.setHours(0, 0, 0, 0);
    $scope.quotes.content.forEach(function(current) {
      expiry = new Date(current.expiryDate);
      expiry.setHours(0, 0, 0, 0);
      if (current.selected && (expiry.getTime() < today.getTime() || current.status.symbol === 'E')) {
        anySelectedExpired = true;
      }
    });
    return anySelectedExpired;
  }, function (newValue) {
    $scope.anySelectedExpired = newValue;
  });

  $scope.search = function(event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    var params = {};

    var sortParams = angular.fromJson($stateParams.quotesPagination);
    if (property && direction) {
      sortParams.sort = property;
      sortParams.direction = direction;
    }
    sortParams.page = 0;
    params.quotesPagination = angular.toJson(sortParams);
    params.forceRefresh = Math.random();
    //add keywords
    params.search = $scope.terms.keywords;
    $state.go('.', params);
  };

  $scope.getPDFUrl = function(quoteCombinedId) {
    return (appConfigurationService.basePath + '/rest/quotes/quoteAsPdf?' +
            'company=' + quoteCombinedId.company +
            '&division=' + quoteCombinedId.division +
            '&location=' + quoteCombinedId.location +
            '&quoteNumber=' + quoteCombinedId.quoteNumber +
            '&systemType=' + quoteCombinedId.systemType);
  };

  $scope.toggleAcceptRequoteForm = function(state) {
    $scope.acceptRequoteForm.toggle = (state ? state : !$scope.acceptRequoteForm.toggle);
    if ($scope.acceptRequoteForm.toggle) {
      $scope.emailForm.toggle = false;
    }
  };

  $scope.quoteIdMobileView = '';
  $scope.showEmailForm = function( quoteId ) {
    $scope.emailForm.toggle = true;
    $scope.acceptRequoteForm.toggle = false;
    $scope.quoteIdMobileView = quoteId != '' ? quoteId : '';

  };

  $scope.downloadSelected = function(quoteId) {

    quotesResource.allAssignedQuotes({}).get(
      null,
      function () {
      },
      globalAlertService.handleErrorResponse
    ).$promise.then(function (response) {
      let data = JSON.stringify(response);
      let allQuotes = JSON.parse(data);
      let processedQuotes = [];
      JSON.parse(allQuotes).forEach(quote => {
        if (sessionStorage.getItem("selectAllToggle") === "true" && !sessionStorage.getItem(quote.quoteCombinedId.quoteNumber + 'unchk')) {
          quote = angular.copy(quote);
          quote.selected = true;
        } else if (sessionStorage.getItem(quote.quoteCombinedId.quoteNumber + 'chk')) {
          quote = angular.copy(quote);
          quote.selected = true;
        } else if (quoteId === quote.quoteCombinedId.quoteNumber) {
          quote = angular.copy(quote);
          quote.selected = true;
        }
        processedQuotes.push(quote);
      });
      var selectedQuotes = [];
      processedQuotes.forEach(function(current) {
        if (current.selected) {
          selectedQuotes.push(current.quoteCombinedId);
        }
      });
      if (selectedQuotes.length > 0) {
        quotesResource.quoteListAsZip.get(
          {
            'quoteCombinedIdList': selectedQuotes
          },
          function(){
            sessionStorage.clear();
            $scope.search();
          },
          globalAlertService.handleErrorResponse
        );
      }

    });
  };

  $scope.emailSelected = function() {

    quotesResource.allAssignedQuotes({}).get(
      null,
      function () {
      },
      globalAlertService.handleErrorResponse
    ).$promise.then(function (response) {
      let data = JSON.stringify(response);
      let allQuotes = JSON.parse(data);
      let processedQuotes = [];
      JSON.parse(allQuotes).forEach(quote => {
        if(sessionStorage.getItem("selectAllToggle") === "true" && !sessionStorage.getItem(quote.quoteCombinedId.quoteNumber + 'unchk')) {
          quote = angular.copy(quote);
          quote.selected = true;
        }
        else if (sessionStorage.getItem(quote.quoteCombinedId.quoteNumber+ 'chk')) {
          quote = angular.copy(quote);
          quote.selected = true;
        } else if ($scope.quoteIdMobileView === quote.quoteCombinedId.quoteNumber){
          quote = angular.copy(quote);
          quote.selected = true;
          $scope.quoteIdMobileView = '';
        }
        processedQuotes.push(quote);
      });


      var selectedQuotes = [];
      processedQuotes.forEach(function(current) {
        if (current.selected) {
          selectedQuotes.push(current.quoteCombinedId);
        }
      });
      if (selectedQuotes.length > 0) {
        quotesResource.myDealerQuoteEmail.save(
          {
            'toEmailAddress': $scope.email.address,
            'quoteCombinedIdList': selectedQuotes
          },
          function() {
            sessionStorage.clear();
            $scope.emailForm.toggle = false;
            globalAlertService.addSuccess('mydealer.ui.global.email_sent');
            $scope.search();
          },
          globalAlertService.handleErrorResponse
        );
      }

    });


  };

  $scope.toggleCardMenu = function(event, quote) {
    event.stopPropagation();
    quote.isMenuOpen = !quote.isMenuOpen;
  };

  $scope.selectCard = function(quote) {
    $scope.emailForm.toggle = false;
    $scope.quotes.content.forEach(function(current) {
      current.selected = (current === quote ? true : false);
    });
  };

  $scope.getCsv = function () {
    quotesResource.csv({keywords: $stateParams.search}).get(
      null,
      function () {
      },
      function (response) {
        globalAlertService.addError('mydealer.ui.invoices.csv_notGenerated');
      }
    );
  };

});
