'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.myEquipment.myEquipmentDetails.recommendedService.parts', {
      url: '/parts/{make}/{model}/{jobCode}',
      views : {
        '@navigation.equipment.myEquipment.myEquipmentDetails' : {
          templateUrl: 'app/scripts/modules/equipment/views/recommended-service-parts.html',
          controller: 'RecommendedServicePartsController'
        }
      },
      resolve: {
        breadcrumb: ['$stateParams', '$filter', function($stateParams, $filter) {
          return $filter('pathParam')($stateParams.jobCode);
        }],

        jobCodePartList: [
          'equipmentJobCodesResource', 
          'globalAlertService', 
          '$stateParams', 
          '$filter', 
          function(equipmentJobCodesResource, globalAlertService, $stateParams, $filter) {
            var parameters = {
              make: $filter('pathParam')($stateParams.make),
              model: $filter('pathParam')($stateParams.model),
              jobCode: $filter('pathParam')($stateParams.jobCode)
            };

            return equipmentJobCodesResource.jobCodePartList.query(
              parameters, 
              function () {}, 
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ]
      }
    }
  );
}]);
