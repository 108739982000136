'use strict';

intellidealerAppPartsModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.parts.komatsu', {
      url: '/komatsu',
      templateUrl: 'app/scripts/modules/parts/views/komatsu-mydealer.html',
      controller: 'komatsuMyDealerController',
      resolve: {
        help: function () {
          return 'Parts-Komatsu_MyDealer.htm';
        },
        breadcrumb: function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.komatsu.breadcrumb'));
        },
        url: function(globalAlertService, oemResource) {
          return oemResource.komatsuMyDealerUrl.get({},
            function () {},
            globalAlertService.handleErrorResponse
          ).$promise;
        }
      }
    }
  );
}]);
