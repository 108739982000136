'use strict';

intellidealerAppCoreModule.factory('twocpResource', function ($resource, appConfigurationService) {

  return {
    authorizeACHWeb: function (param) {
      return $resource(appConfigurationService.basePath + '/rest/oem/twocp/authorizeACHWeb', param, {post: {method: 'POST'}});
    },

    readACHWeb: function (param) {
      return $resource(appConfigurationService.basePath + '/rest/oem/twocp/readACHWeb', param, {post: {method: 'POST'}});
    },

    authorizeCreditCardPayment: function (param) {
      return $resource(appConfigurationService.basePath + '/rest/oem/twocp/authorizeCreditCard', param, {post: {method: 'POST'}});
    },

    readCreditCardPayment: function (param) {
      return $resource(appConfigurationService.basePath + '/rest/oem/twocp/readCreditCard', param, {post: {method: 'POST'}});
    },
  };
});
