'use strict';

intellidealerAppCoreModule.factory('oemResource', function ($resource, appConfigurationService) {

  var resource = {};

  resource.deereUrl = $resource(appConfigurationService.basePath + '/rest/oem/deere/partsCatalogUrl');
  resource.komatsuMyDealerUrl = $resource(appConfigurationService.basePath + '/rest/oem/komatsu/parts/komatsuMyDealer');
  resource.deereEquipmentUrl = $resource(appConfigurationService.basePath + '/rest/oem/deere/partsCatalogSerialNumberUrl');
  resource.deereGuestModeUrl = $resource(appConfigurationService.basePath + '/rest/oem/deere/guestModePartsCatalogUrl');
  resource.cnhDspSetup = $resource(appConfigurationService.basePath + '/rest/oem/cnh/dspSetup');
  resource.cnhDspBrands = $resource(appConfigurationService.basePath + '/rest/oem/cnh/dspBrands');
  resource.ariPartstreamKey = $resource(appConfigurationService.basePath + '/rest/oem/ari/properties/getAriPartstreamKey');
  resource.ariPrefixList = $resource(appConfigurationService.basePath + '/rest/oem/ari/properties/getPrefixMappings', null, {get: {method: 'GET', isArray: true}})

  return resource;
});
