'use strict';

intellidealerAppCoreModule.factory('customerAccountsResource', function ($resource, appConfigurationService) {

  var resource = {};
  
  resource.assignedCustomerAccount =  $resource(
    appConfigurationService.basePath + '/rest/customeraccounts/assignedCustomerAccount/', 
    {
      accountNumber: '@accountNumber'
    }, {
      get: {
        method: 'GET',
        params: {
          accountNumber: '*ALL'
        }
      }
    }
  );

  return resource;
});
