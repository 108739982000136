'use strict';

intellidealerAppPartsModule.controller('OrderHistoryController', function ($scope, $state, $stateParams, $filter, orderHistory, PartsTopLevelTabsService, appConfigurationService, $http, globalAlertService, invoiceHistoryResource, partHistoryResource) {

  $scope.linkedStates = PartsTopLevelTabsService;

  $scope.orderHistory = orderHistory;

  $scope.sortChoices = [
    {
      label: $filter('language')('mydealer.ui.parts.part_number'),
      property: 'partHistoryId.partNumber',
      direction: 'asc',
      isHidden: false
    },
    {label: $filter('language')('mydealer.ui.parts.description'), property: 'part.description', direction: 'asc',isHidden: false},
    {label: $filter('language')('mydealer.ui.global.location'), property: 'partHistoryId.location', direction: 'asc',isHidden: false},
    {
      label: $filter('language')('mydealer.ui.my_parts_purchases.date_ordered'),
      property: 'receiptDate',
      direction: 'desc',
      isHidden: false
    },
    {
      label: $filter('language')('mydealer.ui.invoices.invoice_number'),
      property: 'vendorNumberInvoiceNumber',
      direction: 'asc',
      isHidden: false
    },
    {
      label: $filter('language')('mydealer.ui.parts.quantity'),
      property: 'qtyReceivedQtyShipped',
      direction: 'asc',
      align: 'right',
      isHidden: false
    },
    {}
  ];

  if(!sessionStorage.getItem("sortChoices")){
    sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
  }else {
    $scope.sortChoices = JSON.parse(sessionStorage.getItem("sortChoices"));
  }

  $scope.columns = [];

  $scope.sortChoices.forEach(choice => {
    if(Object.keys(choice).length > 0)
      $scope.columns.push({label:choice.label, isSelected:true});
  });
  if(!sessionStorage.getItem("columns")){
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
  }else {
    $scope.columns = JSON.parse(sessionStorage.getItem("columns"));
  }
  $scope.toggleColumns = function(col){
    let index = $scope.columns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.columns[index].isSelected = !$scope.columns[index].isSelected;
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
    let sortIndex = $scope.sortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if(sortIndex >=0){
      $scope.sortChoices[sortIndex].isHidden = !$scope.columns[index].isSelected;
      sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
    }

  }

  $scope.isColVisible = function(colName){
    let index = $scope.columns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.columns[index].isSelected;
  }

  $scope.terms = {
    keywords: $stateParams.orderHistorySearch
  };

  $scope.colIndex = function(index){
    return $scope.columns[index].label;
  }

  $scope.getPDFUrl = function (transactionIdString) {

    var parameters = {
      transactionId: transactionIdString
    };

    invoiceHistoryResource.pdf(parameters).get(
      null,
      function() {},
      function(response) {
        globalAlertService.addError('mydealer.ui.invoices.pdf_notGenerated');
      }
    );
  };

  $scope.search = function (event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    var params = {};

    var sortParams = angular.fromJson($stateParams.orderHistoryPagination);
    if (property && direction) {
      sortParams.sort = property;
      sortParams.direction = direction;
    }
    sortParams.page = 0;
    params.orderHistoryPagination = angular.toJson(sortParams);

    params.orderHistorySearch = $scope.terms.keywords;
    $state.go('.', params);
  };

  $scope.getCsv = function(){
    var parameters = {keywords: $stateParams.orderHistorySearch ? $stateParams.orderHistorySearch : ''};

    partHistoryResource.csv(parameters).get(
      null,
      function () {
      },
      function (response) {
        globalAlertService.addError('mydealer.ui.invoices.pdf_notGenerated');
      }
    );
  }
});
