'use strict';

intellidealerAppShoppingCartModule.config(['$stateProvider', function ($stateProvider) {
  var resolve = {
    help: function () {
      return 'Checkout.htm';
    },
    breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
      return ($filter('language')('mydealer.ui.checkout.breadcrumb'));
    }],
    businessProfile: ['customersResource', 'globalAlertService', function(customersResource, globalAlertService) {
      return customersResource.assignedCustomer.get(
        {},
        function () {},
        globalAlertService.handleErrorResponse
      ).$promise;
    }],
    myProfile: ['contactsResource', 'globalAlertService', function(contactsResource, globalAlertService) {
      return contactsResource.assignedContact.get(
        {},
        function () {},
        globalAlertService.handleErrorResponse
      ).$promise;
    }],
    shipVias: ['dealershipResource', 'globalAlertService', function(dealershipResource, globalAlertService) {
      return dealershipResource.dealershipShipViaList.query(
        {},
        function () {},
        globalAlertService.handleErrorResponse
      ).$promise;
    }],
    paymentAgencies: ['customerARAgenciesResource', 'globalAlertService', function(customerARAgenciesResource, globalAlertService) {
      return customerARAgenciesResource.assignedPaymentAgencies.get(
        {},
        function () {},
        globalAlertService.handleErrorResponse
      ).$promise;
    }],
    termsAndConditionsLinks: ['dealershipLinksResource', 'globalAlertService', function(dealershipLinksResource, globalAlertService){
      return dealershipLinksResource.myDealerTermsAndConditionsLinks.get(
        {},
        function () {},
        globalAlertService.handleErrorResponse
      ).$promise;
    }],
    shipToAddresses: ['shoppingCartResource', 'globalAlertService', 'userConfiguration', function(shoppingCartResource, globalAlertService, userConfiguration /*wait on this*/) {
      return shoppingCartResource.getCustomerShipToAddresses.getAddresses(
        {company:userConfiguration.contactId.company, division: userConfiguration.contactId.division, customerNumber: userConfiguration.contactId.customerNumber },
        function () {},
        globalAlertService.handleErrorResponse
      ).$promise;
    }],
  };

  var resolve_public = {
    businessProfile: ['customersResource', 'globalAlertService', 'userConfiguration', function(customersResource, globalAlertService, userConfiguration /*wait on this*/) {
      return customersResource.assignedCustomer.get(
        {},
        function () {},
        globalAlertService.handleErrorResponse
      ).$promise;
    }],
    myProfile: ['contactsResource', 'globalAlertService', 'userConfiguration', function(contactsResource, globalAlertService, userConfiguration /*wait on this*/) {
      return contactsResource.assignedContact.get(
        {},
        function () {},
        globalAlertService.handleErrorResponse
      ).$promise;
    }],
    shipVias: ['dealershipResource', 'globalAlertService', 'userConfiguration', function(dealershipResource, globalAlertService, userConfiguration /*wait on this*/) {
      return dealershipResource.dealershipShipViaList.query(
        {},
        function () {},
        globalAlertService.handleErrorResponse
      ).$promise;
    }],
    paymentAgencies: ['customerARAgenciesResource', 'globalAlertService', 'userConfiguration', function(customerARAgenciesResource, globalAlertService, userConfiguration /*wait on this*/) {
      return customerARAgenciesResource.assignedPaymentAgencies.get(
        {},
        function () {},
        globalAlertService.handleErrorResponse
      ).$promise;
    }],
      termsAndConditionsLinks: ['dealershipLinksResource', 'globalAlertService', function(dealershipLinksResource, globalAlertService){
        return dealershipLinksResource.myDealerTermsAndConditionsLinks.get(
          {},
          function () {},
          globalAlertService.handleErrorResponse
        ).$promise;
      }],
    shipToAddresses: ['shoppingCartResource', 'globalAlertService', 'userConfiguration', function(shoppingCartResource, globalAlertService, userConfiguration /*wait on this*/) {
      return shoppingCartResource.getCustomerShipToAddresses.getAddresses(
        {company:userConfiguration.contactId.company, division: userConfiguration.contactId.division, customerNumber: userConfiguration.contactId.customerNumber },
        function () {},
        globalAlertService.handleErrorResponse
      ).$promise;
    }],
  };

  $stateProvider.state(
    'navigation.shopping.shoppingCart.checkout', {
      url: '/checkout' +
      '?{SalesOrderNumber}' +
      '&{HostedPaymentStatus}' +
      '&{TransactionSetupID}' +
      '&{TransactionID}' +
      '&{ExpressResponseCode}' +
      '&{ExpressResponseMessage}' +
      '&{AVSResponseCode}' +
      '&{CVVResponseCode}' +
      '&{ApprovalNumber}' +
      '&{LastFour}' +
      '&{ValidationCode}' +
      '&{CardLogo}' +
      '&{ApprovedAmount}' +
      '&{ServicesID}' +
      '&{PaymentAccountID}' +
      '&{CommercialCardResponseCode}' +
      '&{TipAmount}'
      ,
      views : {
        '@navigation.shopping' : {
          templateUrl: 'app/scripts/modules/shopping-cart/views/shopping-cart-checkout.html',
          controller: 'shoppingCartCheckoutController'
        }
      },
      resolve: resolve
    });

  $stateProvider.state(
    'public.shopping.shoppingCart.checkout', {
      url: '/checkout' +
      '?{SalesOrderNumber}' +
      '&{HostedPaymentStatus}' +
      '&{TransactionSetupID}' +
      '&{TransactionID}' +
      '&{ExpressResponseCode}' +
      '&{ExpressResponseMessage}' +
      '&{AVSResponseCode}' +
      '&{CVVResponseCode}' +
      '&{ApprovalNumber}' +
      '&{LastFour}' +
      '&{ValidationCode}' +
      '&{CardLogo}' +
      '&{ApprovedAmount}' +
      '&{ServicesID}' +
      '&{PaymentAccountID}' +
      '&{CommercialCardResponseCode}' +
      '&{TipAmount}'
      ,
      views : {
        '@public.shopping' : {
          templateUrl: 'app/scripts/modules/shopping-cart/views/shopping-cart-checkout.html',
          controller: 'shoppingCartCheckoutController'
        }
      },
      resolve: resolve_public
    });

}]);
