'use strict';

intellidealerAppCoreModule.controller('NavigationController',
  function ($state, $scope, $rootScope, $window, $location, $stateParams, $filter, $timeout, locationService, globalAlertService, menuStructure,
            dealerContactInformation, shoppingCartService, logoutResource, dealershipConfiguration, myDealerDealershipLinks, language,
            uibPaginationConfig, dealershipContactTypes, userConfiguration, scrollbarSizeService, cdkAnalyticsService, sessionService) {

    $scope.$state = $state;
    $scope.alerts = globalAlertService.currentAlerts;
    $scope.navigationHeadings = menuStructure;
    $scope.dealerContactInformation = dealerContactInformation;
    $scope.shoppingCart = shoppingCartService.cart;
    $scope.dealershipName = dealershipConfiguration.dealershipName;
    $scope.dealershipMenuLinks = [];
    $scope.dealershipEquipmentSaleLink = null;
    $scope.dealershipContactTypes = dealershipContactTypes;
    $scope.userConfiguration = userConfiguration;

    // reload the shopping cart in case the data is stale
    shoppingCartService.loadShoppingCart();

    $scope.$watchCollection('shoppingCart.items', function (newValues) {
      $scope.cartBadgeNumber = 0;
      angular.forEach(newValues, function (value) {
        $scope.cartBadgeNumber += value.quantity;
      });
    });

    // load the dealership links by sub type
    angular.forEach(myDealerDealershipLinks, function (value) {
      if (value.subType === 'MENU_ITEM') {
        $scope.dealershipMenuLinks.push(value);
      } else if (value.subType === 'EQUIPMENT_FOR_SALE' && (!value.location || value.location === userConfiguration.businessLocation)) {
        $scope.dealershipEquipmentSaleLink = value;
      }
    });

    // load current language into the pagination buttons
    uibPaginationConfig.firstText = $filter('language')('mydealer.ui.global.first');
    uibPaginationConfig.lastText = $filter('language')('mydealer.ui.global.last');
    uibPaginationConfig.previousText = $filter('language')('mydealer.ui.global.previous');
    uibPaginationConfig.nextText = $filter('language')('mydealer.ui.global.next');

    $scope.$on('$stateChangeSuccess', function () {

      $scope.navigationExpanded = false;
      $scope.selectedSubHeadingTitle = '';
      $scope.navigationHeadings.forEach(function (heading) {
        if (angular.isDefined(heading.subMenu)) {
          heading.subMenu.forEach(function (subHeading) {
            if ($state.current.name.indexOf((subHeading.highlight ? subHeading.highlight : subHeading.state)) >= 0) {
              heading.open = true;
              $scope.selectedSubHeadingTitle = subHeading.title;
            }
            // open the parts menu when viewing cart or checkout
            if (heading.title === 'mydealer.ui.navigation.parts' && $state.current.name.indexOf('navigation.shopping') >= 0) {
              heading.open = true;
              $scope.selectedSubHeadingTitle = '';
            }
          });
        } else {
          if ($state.current.name.indexOf(heading.state) >= 0) {
            $scope.selectedSubHeadingTitle = heading.title;
          }
        }
      });

    });

    $scope.collapseAll = function () {
      $scope.navigationHeadings.forEach(function (element) {
        element.open = false;
      });
    };

    $scope.toggleNavigation = function () {
      $scope.navigationExpanded = !$scope.navigationExpanded;
    };

    $scope.profile = function () {
      $state.go('navigation.myAccount.profile');
    };

    $scope.logout = function () {
      logoutResource.get(
        function () {
          $rootScope.gadimension2 = '';
          cdkAnalyticsService.logLogout();
          //$window.location.href = $location.absUrl().split('#')[0] + '#/login/' + $stateParams.library;
          $location.url('/login/' + $stateParams.library.toUpperCase());
          locationService.clearDefaultLocation();
        },
        globalAlertService.handleErrorResponse
      );
    };

    $scope.hasTethrLink = function () {
      return sessionService.getFeatures()['PFW.6266'] && dealershipConfiguration.tethrItNowLink && userConfiguration.accessTethrItNow;
    };

    $scope.openTethrItLink = function () {
      window.open(dealershipConfiguration.tethrItNowLink , '_blank');
    };

    $scope.help = function () {
      return 'https://help.intellidealer.com/mydealer/Default.htm#' + $state.$current.locals.globals.help;
    };

    $scope.isGeneralInquiryOpen = false;
    $scope.toggleGeneralInquiry = function () {
      $scope.isGeneralInquiryOpen = !$scope.isGeneralInquiryOpen;
    };

    $scope.closeGeneralInquiryPopover = function (event) {
      if (event) {
        var target = event.target;
        if ($.isWindow(target)) {
          return;
        }
        while (target && !(target.tagName === 'BUTTON' && target.id === 'inquiryButton')) {
          if (angular.element(target).hasClass('popover')) {
            return;
          }
          target = target.parentElement;
        }
        if (target && target.tagName === 'BUTTON') {
          return;
        }
      }
      if ($scope.isGeneralInquiryOpen) {
        $scope.toggleGeneralInquiry();
      }
    };

    $scope.preventEnterKey = function (event) {
      if (event) {
        event.preventDefault(); // prevent default form submit (IE10)
      }
    };
    $scope.clearStorage = function(){
      sessionStorage.clear();
    }
    function setScrollbarOffset() {
      // get the scrollbar width and adjust the inner nav panel
      var navWidth = parseInt(angular.element('#navigationSidePanel').eq(0).css('width'), 10);
      navWidth += scrollbarSizeService.getScrollbarWidth();
      angular.element('#navigationScrollPanel').css({'width': navWidth + 'px', 'min-width': navWidth + 'px'});
    }

    setScrollbarOffset();
    angular.element($window).bind('resize', $scope.closeGeneralInquiryPopover);

  });
