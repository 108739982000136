'use strict';

intellidealerAppPartsModule.config(['$stateProvider', function ($stateProvider) {
  var resolve = {
    help: function () {
      return 'Part-Shop_For_Parts_Part_Detail.htm';
    },
    breadcrumb: ['$stateParams', '$filter',
      function ($stateParams, $filter) {
        return $filter('pathParam')($stateParams.partNumber);
      }],
    partMultimedia: [
      'multimediaResource',
      'globalAlertService',
      '$stateParams',
      '$filter',
      function (multimediaResource, globalAlertService, $stateParams, $filter) {
        return multimediaResource.pictureLocations.query(
          {
            location: '',
            keyToMultimedia: $filter('pathParam')($stateParams.partNumber),
            moduleDesignation: 'PARTS_PROFILE'
          },
          function () {
          },
          globalAlertService.handleErrorResponse
        ).$promise;
      }
    ]
  };

  var resolve_public = {
    partMultimedia: [
      'multimediaResource',
      'globalAlertService',
      '$stateParams',
      '$filter',
      'userConfiguration',
      function (multimediaResource, globalAlertService, $stateParams, $filter, userConfiguration /*wait on this*/) {
        return multimediaResource.pictureLocations.query(
          {
            location: '',
            keyToMultimedia: $filter('pathParam')($stateParams.partNumber),
            moduleDesignation: 'PARTS_PROFILE'
          },
          function () {
          },
          globalAlertService.handleErrorResponse
        ).$promise;
      }
    ]
  };

  $stateProvider.state('navigation.parts.shopForParts.partDetail', {
      url: '/part-detail/{partNumber}',
      views: {
        '@navigation.parts': {
          templateUrl: 'app/scripts/modules/parts/views/part-detail.html',
          controller: 'partDetailController'
        }
      },
      params: {
        partDetails: null
      },
      resolve: resolve
    }
  );

  $stateProvider.state('public.parts.shopForParts.partDetail', {
      url: '/part-detail/{partNumber}',
      views: {
        '@public.parts': {
          templateUrl: 'app/scripts/modules/parts/views/part-detail.html',
          controller: 'partDetailController'
        }
      },
      params: {
        partDetails: null
      },
      resolve: resolve_public
    }
  );

  $stateProvider.state('navigation.shopping.shoppingCart.partDetail', {
      url: '/part-detail/{partNumber}',
      views: {
        '@navigation.shopping': {
          templateUrl: 'app/scripts/modules/parts/views/part-detail.html',
          controller: 'partDetailController'
        }
      },
      params: {
        partDetails: null
      },
      resolve: resolve
    }
  );

  $stateProvider.state('public.shopping.shoppingCart.partDetail', {
      url: '/part-detail/{partNumber}',
      views: {
        '@public.shopping': {
          templateUrl: 'app/scripts/modules/parts/views/part-detail.html',
          controller: 'partDetailController'
        }
      },
      params: {
        partDetails: null
      },
      resolve: resolve_public
    }
  );
}]);
