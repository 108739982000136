'use strict';

intellidealerAppCoreModule.directive('dynamicAlert', function () {
  return {
    restrict: 'E',
    scope: {
      alerts: '='
    },
    template: '<div data-ng-repeat="alert in alerts" class="alert" ng-class="\'alert-\' + (alert.type || \'warning\')">' +
      '<uib-alert data-ng-if="alert.closeable"><span data-ng-bind-html="alert.msg"></span>' +
      '<button type="button" class="close" ng-click="close({$event: $event})">' +
      '<span aria-hidden="true">×</span>' +
      '</button>' +
      '</uib-alert>' +
      '<uib-alert data-ng-if="alert && !alert.closeable"><span data-ng-bind-html="alert.msg"></span></uib-alert>' +
      '</div>',
    link: function (scope, element, attrs) {//jshint ignore:line
      scope.close = function (alert) {
        scope.alerts.splice(scope.alerts.indexOf(alert), 1);
      };
    }
  };
});
