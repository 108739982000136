'use strict';

intellidealerAppCoreModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'login', {
      url: '/login/{library}?redirectTo',
      templateUrl: 'app/scripts/modules/core/views/login.html',
      controller: 'LoginController',
      resolve: {
        dealershipConfiguration: ['dealershipConfigurationService', '$stateParams', 'themeApplicationService', function (dealershipConfigurationService, $stateParams, themeApplicationService) {
          $stateParams.library = $stateParams.library.toUpperCase();
          return dealershipConfigurationService($stateParams.library.toUpperCase()).then(function (value) {
            themeApplicationService(value.baseColor.symbol);
            return value;
          });
        }],

        language: ['$stateParams', 'languageService', function ($stateParams, languageService) {
          return languageService.populatePublic($stateParams.library.toUpperCase());
        }],

        version: ['sessionService', function(sessionService) {
          return sessionService.getVersionPromise();
        }],

        customizedMessages: ['$stateParams', 'customizedMessagesService', function ($stateParams, customizedMessagesService) {
          return customizedMessagesService.populateLoginMessages($stateParams.library.toUpperCase());
        }],
        googleAnalytics: ['cdkAnalyticsService', function (cdkAnalyticsService) {
          return cdkAnalyticsService.loadTrackingIds();
        }]
      }
    });
}]);
