'use strict';

intellidealerAppPartsModule.controller('CnhDspLandingController', function ($scope, $sce, $state, $stateParams, dspBrands) {

  $scope.dspBrands = dspBrands;
  $scope.noBrandsConfigured = false;

  if(
    !$scope.dspBrands.showCaseIH
    && !$scope.dspBrands.showCaseCE
    && !$scope.dspBrands.showNewHollandAG
    && !$scope.dspBrands.showNewHollandCE
    && !$scope.dspBrands.showFlexicoil
    && !$scope.dspBrands.showMiller
  ) {
    $scope.noBrandsConfigured = true;
  }

  $scope.goToCNHDSP = function (brand) {
    $state.go('navigation.parts.cnhDsp', {
      brand: brand,
      serialNumber: $stateParams.serialNumber
    }, {reload: true});
  };

});
