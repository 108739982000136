'use strict';

intellidealerAppEquipmentModule.controller('MyEquipmentDetailsReadyToSellController', function ($scope, equipmentResource, globalAlertService) {

  $scope.formValues = {};

  $scope.submit = function() {
    if ($scope.readyToSellForm.$valid) {
      equipmentResource.myDealerEquipmentSellTradeRequestEmail.save(
        {
          equipmentDescription: $scope.model.description,
          equipmentMake: $scope.model.make,
          equipmentModel: $scope.model.model,
          equipmentSerialNumber: $scope.model.machineSerialNumber,
          inquiryRequest: $scope.formValues.additionalInformation,
          sellType: $scope.formValues.saleType
        },
        function() {
          globalAlertService.addSuccess('mydealer.ui.global.email_sent');
          $scope.configuration.selected = false;
        },
        globalAlertService.handleErrorResponse
      );
    }
  };
});
