'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  var resolve = {
    help: function () {
      return 'Equipment-_Equipment_for_Sale.htm';
    },
    breadcrumb: ['$filter', 'language', function ($filter, language) { // jshint ignore:line
      return ($filter('language')('mydealer.ui.equipment_for_sale.breadcrumb'));
    }],
    equipmentForSalePagination: ['$stateParams', function ($stateParams) {
      return angular.fromJson($stateParams.equipmentForSalePagination);
    }],

    equipmentForSaleFilters: ['$stateParams', function ($stateParams) {
      if ($stateParams.equipmentForSaleFilters) {
        return angular.fromJson($stateParams.equipmentForSaleFilters);
      }
      else {
        return null;
      }
    }],

    equipmentForSaleSearchValues: ['equipmentSalesResource', 'globalAlertService', '$stateParams',
      function (equipmentSalesResource, globalAlertService) {
        var params = {};
        return equipmentSalesResource.searchValues.get(
          params,
          function () {
          },
          globalAlertService.handleErrorResponse
        ).$promise;
      }],

    equipmentTypeSearchValues: ['equipmentResource', 'globalAlertService',
      function (equipmentResource, globalAlertService) {
        return equipmentResource.equipmentTypeSearchValues.get(
          {},
          function () {
          },
          globalAlertService.handleErrorResponse
        ).$promise;
      }],

    equipmentForSale: [
      'pageableService',
      'equipmentSalesResource',
      'globalAlertService',
      'equipmentForSalePagination',
      'equipmentForSaleFilters',
      '$stateParams',
      function (pageableService, equipmentSalesResource, globalAlertService, equipmentForSalePagination, equipmentForSaleFilters, $stateParams) {
        var parameters = {
          keywords: ($stateParams.search ? $stateParams.search : '')
        };
        if (equipmentForSaleFilters) {
          if (equipmentForSaleFilters.type) {
            parameters.type = equipmentForSaleFilters.type.symbol;
          }
          else {
            parameters.type = "A";
          }
          parameters.manufacturer = equipmentForSaleFilters.manufacturer;
          parameters.category = equipmentForSaleFilters.category;
          parameters.maxPrice = equipmentForSaleFilters.maxPrice;
          parameters.maxHours = equipmentForSaleFilters.maxHours;
          parameters.fromYear = equipmentForSaleFilters.minYear ? new Date(equipmentForSaleFilters.minYear).getFullYear() : null;
          parameters.toYear = equipmentForSaleFilters.maxYear ? new Date(equipmentForSaleFilters.maxYear).getFullYear() : null;
        }
        else{
          parameters.type = "A";
        }

        pageableService.addPageableParameters(
          parameters,
          equipmentForSalePagination
        );

        return equipmentSalesResource.dealershipEquipment.get(
          parameters,
          function () {
          },
          globalAlertService.handleErrorResponse
        ).$promise;
      }
    ]
  };

  var resolve_public = {
    equipmentForSalePagination: ['$stateParams', function ($stateParams) {
      return angular.fromJson($stateParams.equipmentForSalePagination);
    }],

    equipmentForSaleFilters: ['$stateParams', function ($stateParams) {
      if ($stateParams.equipmentForSaleFilters) {
        return angular.fromJson($stateParams.equipmentForSaleFilters);
      }
      else {
        return null;
      }
    }],

    equipmentForSaleSearchValues: ['equipmentSalesResource', 'globalAlertService', '$stateParams', 'userConfiguration',
      function (equipmentSalesResource, globalAlertService, userConfiguration /*wait on this*/) {
        var params = {};
        return equipmentSalesResource.searchValues.get(
          params,
          function () {
          },
          globalAlertService.handleErrorResponse
        ).$promise;
      }],

    equipmentTypeSearchValues: ['equipmentResource', 'globalAlertService', 'userConfiguration',
      function (equipmentResource, globalAlertService, userConfiguration /*wait on this*/) {
        return equipmentResource.equipmentTypeSearchValues.get(
          {},
          function () {
          },
          globalAlertService.handleErrorResponse
        ).$promise;
      }],

    equipmentForSale: [
      'pageableService',
      'equipmentSalesResource',
      'globalAlertService',
      'equipmentForSalePagination',
      'equipmentForSaleFilters',
      '$stateParams',
      'userConfiguration',
      function (pageableService, equipmentSalesResource, globalAlertService, equipmentForSalePagination, equipmentForSaleFilters, $stateParams, userConfiguration /*wait on this*/) {
        var parameters = {
          keywords: ($stateParams.search ? $stateParams.search : '')
        };
        if (equipmentForSaleFilters) {
          if (equipmentForSaleFilters.type) {
            parameters.type = equipmentForSaleFilters.type.symbol;
          }
          else {
            parameters.type = "A";
          }
          parameters.manufacturer = equipmentForSaleFilters.manufacturer;
          parameters.category = equipmentForSaleFilters.category;
          parameters.maxPrice = equipmentForSaleFilters.maxPrice;
          parameters.maxHours = equipmentForSaleFilters.maxHours;
          parameters.fromYear = equipmentForSaleFilters.minYear ? new Date(equipmentForSaleFilters.minYear).getFullYear() : null;
          parameters.toYear = equipmentForSaleFilters.maxYear ? new Date(equipmentForSaleFilters.maxYear).getFullYear() : null;
        }
        else{
          parameters.type = "A";
        }

        parameters.company = $stateParams.company;
        parameters.division = $stateParams.division;

        pageableService.addPageableParameters(
          parameters,
          equipmentForSalePagination
        );

        return equipmentSalesResource.dealershipEquipment.get(
          parameters,
          function () {
          },
          globalAlertService.handleErrorResponse
        ).$promise;
      }
    ]
  };

  var stateConfig = {
    url: '/equipment-for-sale' +
    '?equipmentForSalePagination' +
    '&equipmentForSaleFilters' +
    '&{search:any}',
    params: {
      equipmentForSalePagination: angular.toJson({
        sort: 'manufacturer',
        direction: 'asc',
        size: 12
      })
    },
    templateUrl: 'app/scripts/modules/equipment/views/equipment-for-sale.html',
    controller: 'EquipmentForSaleController'
  };

  stateConfig.resolve = resolve;
  var stateConfig_public = angular.copy(stateConfig);
  stateConfig_public.resolve = resolve_public;

  $stateProvider.state(
    'navigation.equipment.equipmentForSale', stateConfig
  );

  $stateProvider.state(
    'public.equipment.equipmentForSale', stateConfig_public
  );
}]);
