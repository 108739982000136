'use strict';

/*
  This decorator wraps the built-in required directive to append a '*' to the label that is for this element.
*/
intellidealerAppCoreModule.config(function($provide) {
  $provide.decorator('requiredDirective', ['$delegate', function($delegate) {
    var directive = $delegate[0];
    var link = directive.link;

    directive.compile = function() {
      return function(scope, element) {
        link.apply(this, arguments);

        function addAsteriskToSiblingLabel (baseElement) {
          if (baseElement.prev().is('label')) {
            baseElement.prev().append(' *');
            return true;
          }
          return false;
        }

        if (!(element.is('label'))) {
          if (!addAsteriskToSiblingLabel(element)) {
            addAsteriskToSiblingLabel(element.parent());
          }
        }

      };
    };
    return $delegate;
  }]);
});

/*
  This decorator is for ng-required which can dynamically change the required attribute.
*/
intellidealerAppCoreModule.config(function($provide) {
  $provide.decorator('ngRequiredDirective', ['$delegate', function($delegate) {
    var directive = $delegate[0];
    var link = directive.link;

    directive.compile = function() {
      return function(scope, element, attr) {
        link.apply(this, arguments);

        // adds or removes the asterisk based on the doAdd boolean value
        function addAsteriskToSiblingLabel (baseElement, doAdd) {
          if (baseElement.prev().is('label')) {
            var text = baseElement.prev().text();
            var hasA = (text.length > 2 && text.lastIndexOf(' *') === text.length - 2 ? true : false);
            if (doAdd && !hasA) {
              baseElement.prev().append(' *');
            } else if (!doAdd && hasA) {
              baseElement.prev().text(text.substr(0, text.length - 2));
            }
            return true;
          }
          return false;
        }

        // watch for changes to the required attribute
        attr.$observe('required', function(value) {
          if (!(element.is('label'))) {
            if (!addAsteriskToSiblingLabel(element, value)) {
              addAsteriskToSiblingLabel(element.parent(), value);
            }
          }
        });

      };
    };
    return $delegate;
  }]);
});
