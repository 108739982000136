'use strict';

// Important.  DO NOT ADD ES6 to app.js.  We need to be able to tell IE11 users they are not supported and adding any
// ES6 here will cause that to fail.

/* jshint ignore:start */
var intellidealerApp = angular.module(
  'intellidealerApp',
  [
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'ngTouch',
    'ngMessages',
    'ui.router',
    'ui.bootstrap',
    'angularCSS',
    'intellidealerApp.core',
    'intellidealerApp.equipment',
    'intellidealerApp.parts',
    'intellidealerApp.quotes',
    'intellidealerApp.myAccount',
    'intellidealerApp.shoppingCart',
    'intellidealerApp.alerts'
  ]
);
/* jshint ignore:end */

intellidealerApp.config(function($urlRouterProvider, uibPaginationConfig, $httpProvider){  //TODO: we should either redirect them to dashboard, and let the dashboard kick them to login if they aren't logged in. Or log them out before we kick them to the login page on a bad url.  If we just kick them to login, they might think they are logged out, and leave their session open.
  $urlRouterProvider.otherwise('/bad-state');

  //Pagination global settings.  Can be overridden on an individual scale
  uibPaginationConfig.boundaryLinks = true;
  uibPaginationConfig.directionLinks = true;
  uibPaginationConfig.firstText = 'First';
  uibPaginationConfig.lastText = 'Last';
  uibPaginationConfig.previousText = 'Previous';
  uibPaginationConfig.nextText = 'Next';
  uibPaginationConfig.rotate = true;
  uibPaginationConfig.maxSize = 5;

  //Adds header to requests - Needed to use the session cookie for ajax requests from a different origin.
  $httpProvider.defaults.withCredentials = true;
});

intellidealerApp.run(function ($rootScope, $anchorScroll, $window, $timeout, publicModeService, cdkAnalyticsService, $filter) {
  $rootScope.guestLoggedin = false;
  //Scroll to top after every state transition
  $rootScope.$on('$stateChangeSuccess', function(event, toState, toStateParams, fromState) {
    if (toState.name !== fromState.name) {
      // TODO: Find a better way to determine 'scroll to top'.
      //       Currently, anything 5 or more levels deep should not scroll
      if (fromState.name.split('.').length < 5 || toState.name.split('.').length < 5) {
        $anchorScroll();
      }
      $rootScope.isPublicState = toState.name.startsWith('public.');
      $rootScope.pageTitle = publicModeService.getPageTitle(toState.name);
    }
  });

  $rootScope.togglePopover = function (id, index) {
    $timeout(function () {
      angular.element('#' + id + (index ? index : '')).trigger('click');
    });
  };

  $rootScope.closePopovers = function (jquerySelector) {
    $timeout(function () {
      $(jquerySelector).each(function (index, element) {
          // Unfortunately there is not an easy way to close dynamic popovers in angular-ui
          // We developed our own popover in IntelliDealer, but for MyDealer this method will work until we refresh myDealer technology.
        var popoverElement = angular.element(element).next('.popover');
        if(popoverElement.length){
          var popoverScope = popoverElement.scope();
          do{
            if (popoverScope.hasOwnProperty('isOpen')){
              popoverScope.isOpen = false;
              break;
            }
            popoverScope = popoverScope.$parent;
          }while(popoverScope);
        }
      })
    });
  };

  $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
    console.log(event);
    console.log(error);
  });

  // force logout the user on master window close
  $window.addEventListener('beforeunload', function () {
    cdkAnalyticsService.logPageExit();
  });

});
