'use strict';

intellidealerAppCoreModule.factory('alertHistoryResource', function ($resource, appConfigurationService) {
  return {
    myDealerAlertHistorySinceLastLogin: $resource(appConfigurationService.basePath + '/rest/alerthistory/myDealerAlertHistorySinceLastLogin'),
    myDealerAlertHistoryByCustomer: $resource(appConfigurationService.basePath + '/rest/alerthistory/myDealerAlertHistoryByCustomer'),
    myDealerCustomerAlert: $resource(appConfigurationService.basePath + '/rest/customerAlertReceiver/myDealerCustomerAlert'),
    csv: function (parameters) {
      function getDateStamp(){
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();
        var monthStr = month +1 < 9 ? "0"+(month+1) : month+1;
        var dayStr = day < 9 ? "0"+(day) : day;
        var stamp = year + '_' + monthStr + '_' + dayStr;
        return stamp;

      }
      function convertBytes(byteArray) {
        const binaryString = window.atob(byteArray);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
      };
      return $resource(
        appConfigurationService.basePath + '/rest/alerthistory/exportAlertHistory',
        parameters,
        {
          get: {
            method: 'GET',
            url: appConfigurationService.basePath + '/rest/alerthistory/exportAlertHistory',
            interceptor: {
              response: function (response) {
                if (response.status === 200) {
                  var blob = new Blob([convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                  if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, 'alertHistory' + '.xls');
                  } else {
                    var fileUrl = URL.createObjectURL(blob);
                    var downloadLink = document.createElement('a');
                    downloadLink.href = fileUrl;
                    downloadLink.download = 'Alerts_' + getDateStamp() +'.xls';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                  }
                }
              }
            }
          }
        }
      );
    }
  }
});
