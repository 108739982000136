'use strict';

intellidealerAppCoreModule.factory('equipmentJobCodesResource', function ($resource, appConfigurationService) {
  
  var resource = {};
  resource.jobCodeDescriptions = $resource(appConfigurationService.basePath + '/rest/equipmentjobcodes/jobCodeDescriptions');
  resource.jobCodePartList = $resource(appConfigurationService.basePath + '/rest/equipmentjobcodes/jobCodePartList');
  
  return resource;
});
