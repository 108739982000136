'use strict';

intellidealerAppPartsModule.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/scripts/modules/parts/views/templates/shop-for-parts-popover-availability.html',
                     '<div data-ng-hide="!part.partAvailabilityList">' +
                     '<table class="table table-striped">' +
                     '<thead>' +
                     '<tr>' +
                     '<th>{{\'mydealer.ui.global.location\' | language}}</th>' +
                     '<th>{{\'mydealer.ui.parts.availability\' | language}}</th>' +
                     '</tr>' +
                     '</thead>' +
                     '<tbody>' +
                     '<tr data-ng-repeat="partAvailability in part.partAvailabilityList" data-ng-if="partAvailability.availability > 0">' +
                     '<td>{{partAvailability.locationName}}</td>' +
                     '<td class="table-align-right">' +
                     '{{partAvailability.availability > 0 ? partAvailability.availability : 0}}' +
                     '</td>' +
                     '</tr>' +
                     '<tr data-ng-if="!part.partAvailabilityList || part.partAvailabilityList.length == 0">' +
                     '<td colspan="2" style="text-align: center;">{{\'mydealer.ui.parts.no_availability_found\' | language}}</td>' +
                     '</tr>' +
                     '</tbody>' +
                     '</table>' +
                     '<div class="button-row-wrapper">' +
                     '<div></div>' +
                     '<div>' +
                     '<button class="btn btn-info" data-ng-click="$root.togglePopover(togglerId + $index);">{{\'mydealer.ui.global.cancel\' | language}}</button>' +
                     '</div>' +
                     '</div>' +
                     '</div>');
}]);
