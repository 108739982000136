'use strict';

intellidealerAppShoppingCartModule.controller('shoppingCartCheckoutController', function ($scope, $stateParams, $rootScope, $state, $filter, publicModeService, globalAlertService, shoppingCartService, userConfiguration, locationService, businessProfile,
                                                                                          myProfile, shipVias, paymentAgencies, termsAndConditionsLinks, onlinePartsOrdersResource, customizedMessagesService, $uibModal, cdkAnalyticsService, appConfigurationService, shipToAddresses, $window) {
  $scope.orderDetails = {};
  $scope.estimatedShipping = 0;
  $scope.authorizationAmount = 0;
  $scope.preGeneratedSalesOrderNumber = null;
  $scope.orderDetails.shipToVia = null;
  $scope.orderDetails.pickupLocation = {};
  $scope.shoppingCart = shoppingCartService.cart;
  $scope.businessProfile = businessProfile;
  $scope.shipVias = shipVias;
  $scope.paymentAgencies = paymentAgencies;
  $scope.assignedLocations = locationService.getAssignedLocations();
  $scope.customizedMessage = null;
  $scope.getUnitDescription = shoppingCartService.getUnitDescription;
  $scope.payByCreditCard = false;
  $scope.orderDetails.pickupLocation.location = locationService.getDefaultLocation();
  locationService.setDefaultLocation($scope.orderDetails.pickupLocation.location);
  $scope.showWorldPay = $scope.paymentAgencies.showWorldPay;
  $scope.termsAndConditionsLinks = termsAndConditionsLinks;
  $scope.shipToAddresses = shipToAddresses;
  $scope.shipToSelector = [];
  $scope.shipToAddresses.forEach(function(address, index) {
      $scope.shipToSelector.push({id: index, value: address})
  });
  $scope.selectedShipTo = {
    address:{}
  }

  $scope.selectedShipToDefault = $scope.shipToSelector[0].value.name + '-' +  $scope.shipToSelector[0].value.address1 + ' ' +  $scope.shipToSelector[0].value.address2
    + ' ' +  $scope.shipToSelector[0].value.address3 + ' ' + $scope.shipToSelector[0].value.zipCode;

  $scope.updateShipToFields = function() {
    $scope.orderDetails.shipToName = $scope.selectedShipTo.address.name;
    $scope.orderDetails.shipToAddress1 = $scope.selectedShipTo.address.address1;
    $scope.orderDetails.shipToAddress2 = $scope.selectedShipTo.address.address2;
    $scope.orderDetails.shipToAddress3 = $scope.selectedShipTo.address.address3;
    $scope.orderDetails.shipToPostalZipCode = $scope.selectedShipTo.address.zipCode;
  }

  $scope.updateShipToAddress = function() {
    if($scope.shipToSelector.length===1){
      $scope.selectedShipToDefault ='';
    }else{
      $scope.selectedShipTo = {
        address:{}
      }
    }

  }


  if ($rootScope.isPublicState && !$rootScope.guestLoggedin) {
    $scope.orderDetails.guest = {
      billToName: '',
      phoneNum: '',
      email: '',
      billToAddress1: '',
      billToAddress2: '',
      city: '',
      provinceState: '',
      billToPostalZipCode: '',
      country: ''
    };
  }

  angular.forEach($scope.termsAndConditionsLinks, function (value) {
    if(value.subType === "TERMS_AND_CONDITIONS" && (!value.location || value.location === userConfiguration.businessLocation)){
      $scope.termsAndConditionsLinks = value;
        }
  });

  //worldpay return handling
  if ($stateParams.HostedPaymentStatus) {
    if ($stateParams.HostedPaymentStatus === 'Complete') {
      var orderDetails = JSON.parse(localStorage.getItem('_worldpayOrderDetails'));
      localStorage.removeItem('_worldpayOrderDetails');
      var worldpayResults = {
        hostedPaymentStatus: $stateParams.HostedPaymentStatus,
        transactionSetupID: $stateParams.TransactionSetupID,
        transactionID: $stateParams.TransactionID,
        expressResponseCode: $stateParams.ExpressResponseCode,
        expressResponseMessage: $stateParams.ExpressResponseMessage,
        avsResponseCode: $stateParams.AVSResponseCode,
        cvvResponseCode: $stateParams.CVVResponseCode,
        approvalNumber: $stateParams.ApprovalNumber,
        lastFour: $stateParams.LastFour,
        validationCode: $stateParams.ValidationCode,
        cardLogo: $stateParams.CardLogo,
        approvedAmount: $stateParams.ApprovedAmount,
        servicesID: $stateParams.ServicesID,
        paymentAccountID: $stateParams.PaymentAccountID,
        commercialCardResponseCode: $stateParams.CommercialCardResponseCode,
        tipAmount: $stateParams.TipAmount,
      };

      var wrapper = {
        salesOrderNumber: $stateParams.SalesOrderNumber,
        worldpayResults: worldpayResults
      };
      showOrderSubmitDialog();
      onlinePartsOrdersResource.worldPayReadAuthorizationResult.post(wrapper).$promise.then(function (data) {
        if (data.myDealerResponse && data.myDealerResponse.expressResponseMessage === 'Approved') {
          globalAlertService.addSuccess('mydealer.ui.checkout.credit_card_auth_success');
          completeCheckout(orderDetails);
        } else {
          globalAlertService.addError('mydealer.ui.checkout.credit_card_auth_fail');
          hideOrderSubmitDialog();
        }

      }).catch(function (response) {
        globalAlertService.handleErrorResponse(response);
        hideOrderSubmitDialog();
      });

    }



  }

  if ($rootScope.isPublicState) {
    $scope.orderDetails.comment = getCommentsForDeereNotFoundParts();
  }

  function getCommentsForDeereNotFoundParts() {
    var comment = '';
    if (publicModeService.getDeereNotFoundParts()) {
      angular.forEach(publicModeService.getDeereNotFoundParts(), function (e) {
        comment = comment + ' ' + e.partNumber + ' ' + '(' + e.description + ') ' + e.quantity + ' |'
      });
      comment = $filter('language')('mydealer.ui.checkout.deere_not_found_parts') + comment.substring(0, comment.length - 2);
    }
    return comment;
  }

  $scope.myProfile = {
    fullName: (myProfile.firstName ? myProfile.firstName : '') +
    (myProfile.firstName && myProfile.lastName ? ' ' : '') +
    (myProfile.lastName ? myProfile.lastName : '')
  };

  $scope.showCartItems = false;
  $scope.showPrice = userConfiguration.displayPrices;
  $scope.purchaseOrderRequired = businessProfile.purchaseOrderRequired === 'Y';
  $scope.allowShipTo = userConfiguration.allowShipTo;
  $scope.allowPickup = userConfiguration.allowPickup;

  $scope.sortChoices = [
    {label: $filter('language')('mydealer.ui.parts.quantity')},
    {label: $filter('language')('mydealer.ui.shopping_cart.unit')},
    {label: $filter('language')('mydealer.ui.parts.part_number')},
    {label: $filter('language')('mydealer.ui.parts.description')}
  ];
  $scope.sortChoices.push({label: $filter('language')('mydealer.ui.shopping_cart.extended_weight'), align: 'right'});
  if ($scope.showPrice) {
    $scope.sortChoices.push({label: $filter('language')('mydealer.ui.parts.price'), align: 'right'});
    $scope.sortChoices.push({label: $filter('language')('mydealer.ui.parts.extended_price'), align: 'right'});
  }

  // Get the location name and address, separated by commas
  $scope.getLocationAddress = function (location) {
    return location.description +
      (location.branchAddressLine1 && location.branchAddressLine1 !== '' ? ', ' + location.branchAddressLine1 : '') +
      (location.branchAddressLine2 && location.branchAddressLine2 !== '' ? ', ' + location.branchAddressLine2 : '') +
      (location.branchAddressLine3 && location.branchAddressLine3 !== '' ? ', ' + location.branchAddressLine3 : '') +
      (location.branchAddressLine4 && location.branchAddressLine4 !== '' ? ', ' + location.branchAddressLine4 : '') +
      (location.postalZipCode && location.postalZipCode !== '' ? ', ' + location.postalZipCode : '');
  };

  $scope.previousScreen = function () {
    $state.go(publicModeService.getStateWithPrefix($rootScope.isPublicState, '.shopping.shoppingCart'));
  };

  function updateShipToAddress() {
    if (!$scope.orderDetails.shipToAddress2 && $scope.orderDetails.shipToAddress3) {
      $scope.orderDetails.shipToAddress2 = $scope.orderDetails.shipToAddress3;
      $scope.orderDetails.shipToAddress3 = '';
    }
  }

  function getOnlineOrderDetails() {
    var orderDetails = {
      referenceNumber: $scope.orderDetails.referenceNumber,
      description: $scope.orderDetails.description,
      purchaseOrderNumber: $scope.orderDetails.purchaseOrder,
      comment: $scope.orderDetails.comment,
      paymentMethod: $scope.orderDetails.cashCode
    };

    if ($rootScope.isPublicState && !$rootScope.guestLoggedin) {
      if ($scope.orderDetails.shipOrPickup === 'P') {
        orderDetails.onlineOrderDeliveryType = 'PICK_UP';
        if ($scope.orderDetails.pickupLocation) {
          orderDetails.company = $scope.orderDetails.pickupLocation.company;
          orderDetails.division = $scope.orderDetails.pickupLocation.division;
          orderDetails.location = $scope.orderDetails.pickupLocation.location;
        }
      } else {
        orderDetails.onlineOrderDeliveryType = 'SHIP';
        orderDetails.shipViaCode = ($scope.orderDetails.shipToVia ? $scope.orderDetails.shipToVia.shipViaCode : '');
      }
      orderDetails.name = $scope.orderDetails.guest.billToName;
      orderDetails.guestPhoneNum = $scope.orderDetails.guest.phoneNum;
      orderDetails.guestEmail = $scope.orderDetails.guest.email;
      orderDetails.address1 = $scope.orderDetails.guest.billToAddress1;
      orderDetails.address2 = $scope.orderDetails.guest.billToAddress2 ? $scope.orderDetails.guest.billToAddress2 : ($scope.orderDetails.guest.city + ', ' + $scope.orderDetails.guest.provinceState + ', ' + $scope.orderDetails.guest.country);
      orderDetails.address3 = $scope.orderDetails.guest.billToAddress2 ? ($scope.orderDetails.guest.city + ', ' + $scope.orderDetails.guest.provinceState + ', ' + $scope.orderDetails.guest.country) : '';
      orderDetails.postalCode = $scope.orderDetails.guest.billToPostalZipCode;
    } else {
      if ($scope.orderDetails.shipOrPickup === 'P') {
        orderDetails.onlineOrderDeliveryType = 'PICK_UP';
        if ($scope.orderDetails.pickupLocation) {
          orderDetails.company = $scope.orderDetails.pickupLocation.company;
          orderDetails.division = $scope.orderDetails.pickupLocation.division;
          orderDetails.location = $scope.orderDetails.pickupLocation.location;
        }
      } else {
        updateShipToAddress();
        orderDetails.onlineOrderDeliveryType = 'SHIP';
        orderDetails.shipViaCode = ($scope.orderDetails.shipToVia ? $scope.orderDetails.shipToVia.shipViaCode : '');
        orderDetails.name = $scope.orderDetails.shipToName;
        orderDetails.address1 = $scope.orderDetails.shipToAddress1;
        orderDetails.address2 = $scope.orderDetails.shipToAddress2;
        orderDetails.address3 = $scope.orderDetails.shipToAddress3;
        orderDetails.postalCode = $scope.orderDetails.shipToPostalZipCode;
      }

      if (shoppingCartService.cart) {
        angular.forEach(shoppingCartService.cart.cartUnits, function (unit) {
          if (unit.index === 1) {
            orderDetails.stockNumber1 = unit.stockNumber;
            orderDetails.description1 = unit.description;
            orderDetails.machineSerialNumber1 = unit.machineSerialNumber;
          }
          if (unit.index === 2) {
            orderDetails.stockNumber2 = unit.stockNumber;
            orderDetails.description2 = unit.description;
            orderDetails.machineSerialNumber2 = unit.machineSerialNumber;
          }
          if (unit.index === 3) {
            orderDetails.stockNumber3 = unit.stockNumber;
            orderDetails.description3 = unit.description;
            orderDetails.machineSerialNumber3 = unit.machineSerialNumber;
          }
          if (unit.index === 4) {
            orderDetails.stockNumber4 = unit.stockNumber;
            orderDetails.description4 = unit.description;
            orderDetails.machineSerialNumber4 = unit.machineSerialNumber;
          }
        });
      }
    }
    return orderDetails;
  }

  function completeCheckout(orderDetails) {
    showOrderSubmitDialog();
    onlinePartsOrdersResource.checkOutOnlineOrder.save(orderDetails, function (data) {
        hideOrderSubmitDialog();
        shoppingCartService.loadShoppingCart();
        publicModeService.clearDeereNotFoundParts();
        cdkAnalyticsService.logSubmitOrder($scope.shoppingCart, data.referenceNumber, $rootScope.isPublicState && !$rootScope.guestLoggedin);
        $state.go(publicModeService.getStateWithPrefix($rootScope.isPublicState, '.shopping.shoppingCart.checkoutComplete'), {
          company: data.company,
          division: data.division,
          location: data.location,
          referenceNumber: data.referenceNumber,
          salesOrderNumber: data.salesOrderNumber,
          customerNumber: businessProfile.customerNumber
        });
        globalAlertService.addSuccess('mydealer.ui.global.email_sent');
      }, function (response) {
        globalAlertService.handleErrorResponse(response);
        hideOrderSubmitDialog();
        shoppingCartService.loadShoppingCart();
      }
    );
  }

  $scope.submitOrder = function () {
    if ($scope.checkoutForm.$valid) {
      var orderDetails = getOnlineOrderDetails();
      onlinePartsOrdersResource.validateAvalaraAddress.save(orderDetails, function (data) {
        if (data.avalaraEEEID) { // Found suggested address
          $uibModal.open({
            templateUrl: "app/scripts/modules/shopping-cart/views/templates/address-popup.html",
            controller: "addressPopupCtrl",
            size: 'lg',
            keyboard: false,
            backdrop: 'static',
            resolve: {
              properties: function () {
                return {
                  avalaraSuggestedAddress: data
                };
              }
            }
          }).result.then(function (value) {
            if (value === 'accept') {
              useAddressSuggestion(data);
              var orderDetailsWithSuggestedAddress = getOnlineOrderDetails();
              processOrder(orderDetailsWithSuggestedAddress);
            } else { // If user clicks "Cancel" button, it will NOT apply the suggested address.
              processOrder(orderDetails);
            }
          });
        } else {
          processOrder(orderDetails);
        }
      }, function (response) {
        globalAlertService.handleErrorResponse(response);
      });
    }
  };

  $scope.setPayByCreditCard = function (value) {
    $scope.payByCreditCard = value;
  };

  $scope.setPayByWorldPay = function () {
    $scope.payByWorldPay = true;
  };

  $scope.shipClicked = function () {
    if ($scope.orderDetails.cashCode === '2') {
      $scope.orderDetails.cashCode = '';
    }
  };

  function processOrder(orderDetails) {
    if ($scope.orderDetails.shipOrPickup === 'S' && !($rootScope.isPublicState && !$rootScope.guestLoggedin)) {
      locationService.setDefaultLocation(userConfiguration.businessLocation);
    }
    if ($scope.payByCreditCard) {
      shoppingCartService.loadShoppingCart().then((response) => {
        if (response.total !== 0) {
          if ($scope.orderDetails.shipOrPickup === 'P') {
            $scope.authorizationAmount = response.total;
          } else if ($scope.orderDetails.shipOrPickup === 'S' && $rootScope.isPublicState && !$rootScope.guestLoggedin) {
            $scope.authorizationAmount = response.estimatedShipping + response.subtotal;
          }
          $scope.blockedPopupDetails = null;
          $uibModal.open({
            templateUrl: 'app/scripts/modules/shopping-cart/views/credit-card-processing-prompt.html',
            scope: $scope,
            controller: function ($uibModalInstance) {
              onlinePartsOrdersResource.authorizeCreditCard.get({
                authorizationAmount: $scope.authorizationAmount,
                preGeneratedSalesOrderNumber: $scope.preGeneratedSalesOrderNumber
              }).$promise.then(function (data) {
                if (!data.twoCPPortalAvailable) {
                  $uibModalInstance.dismiss();
                  globalAlertService.addError('mydealer.ui.checkout.credit_card_portal_fail');
                } else {
                  angular.element($window).on('beforeunload', confirmLeavePaymentPortal);
                  $scope.preGeneratedSalesOrderNumber = data.preGeneratedSalesOrderNumber;
                  /*
                    Pass the pre-generated sales order number if it is a "ShipTo" order and user pays by credit card, so
                    that when processing the online order it will re-use this sales order number, instead of creating a new one.
                  */
                  orderDetails.preGeneratedSalesOrderNumber = data.preGeneratedSalesOrderNumber;
                  var twoCPWindow = openTwoCPPortal($scope.paymentAgencies.clientId, data.portalId);
                  if (!twoCPWindow || twoCPWindow.closed || typeof twoCPWindow.closed === 'undefined') {
                    $scope.blockedPopupDetails = {modalInstance: $uibModalInstance, orderDetails: orderDetails};
                  } else {
                    var timer = setInterval(function () {
                      if (twoCPWindow.closed) {
                        clearInterval(timer);
                        readAuthorizationResult($uibModalInstance, orderDetails);
                      }
                    }, 1000);
                  }
                }
              }).catch(function () {
                $uibModalInstance.dismiss();
                globalAlertService.addError('mydealer.ui.checkout.credit_card_auth_fail');
              });
            },
            size: 'md',
            animation: true,
            windowClass: 'center-modal',
            keyboard: false,
            backdrop: 'static'
          });
        } else {
          globalAlertService.addError('mydealer.ui.checkout.credit_card_total_error');
        }
      })
    } else if ($scope.payByWorldPay) { //worldpay functionality
      shoppingCartService.loadShoppingCart().then((response) => {
        if (response.total !== 0) {
          var shipToName, shipToAddress1, shipToAddress2, shipToAddress3, shipToPostalZipCode;
          if ($scope.orderDetails.shipOrPickup === 'P') {
            $scope.authorizationAmount = response.total;
          } else if ($scope.orderDetails.shipOrPickup === 'S' && $rootScope.isPublicState && !$rootScope.guestLoggedin) {
            $scope.authorizationAmount = response.estimatedShipping + response.subtotal;
          }
          if ($scope.orderDetails.shipOrPickup === 'S'){
            shipToName = $scope.orderDetails.shipToName;
            shipToAddress1 = $scope.orderDetails.shipToAddress1;
            shipToAddress2 = $scope.orderDetails.shipToAddress2;
            shipToAddress3 =  $scope.orderDetails.shipToAddress3
            shipToPostalZipCode = $scope.orderDetails.shipToPostalZipCode
          }
          onlinePartsOrdersResource.worldPayAuthorizeCreditCard.get({
            authorizationAmount: $scope.authorizationAmount,
            preGeneratedSalesOrderNumber: $scope.preGeneratedSalesOrderNumber,
            siteLocation: window.location.origin,
            shipToName: shipToName,
            shipToAddress1: shipToAddress1,
            shipToAddress2: shipToAddress2,
            shipToAddress3: shipToAddress3,
            shipToPostalCode: shipToPostalZipCode,
            isPublic: $rootScope.isPublicState
          }).$promise.then(function (results) {
            //load the worldpay window
            orderDetails.preGeneratedSalesOrderNumber = results.preGeneratedSalesOrderNumber;
            $scope.preGeneratedSalesOrderNumber = results.preGeneratedSalesOrderNumber;
            localStorage.setItem('_worldpayOrderDetails', JSON.stringify(orderDetails));
            var worldPayUrl = results.worldPayUrl;
            if(results.worldpayPortalAvailable) {
              window.open(worldPayUrl, "_self");
            } else {
              localStorage.removeItem('_worldpayOrderDetails');
              globalAlertService.addError('mydealer.ui.checkout.worldpay_error');
            }
          }).catch(function () {
            localStorage.removeItem('_worldpayOrderDetails');
            globalAlertService.addError('mydealer.ui.checkout.credit_card_auth_fail');
          });
        } else {
          localStorage.removeItem('_worldpayOrderDetails');
          globalAlertService.addError('mydealer.ui.checkout.credit_card_total_error');
        }
      });
    } else {
      completeCheckout(orderDetails);
    }
  }

  function openTwoCPPortal(clientId, portalId) {
    var w = 820;
    var h = 500;
    var y = $window.top.outerHeight / 2 + $window.top.screenY - (h / 2);
    var x = $window.top.outerWidth / 2 + $window.top.screenX - (w / 2);
    return $window.open(appConfigurationService.getTwoCPPaymentPath(clientId, portalId), '_blank',
      'resizeable=true, scrollbars=yes, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
  }

  function readAuthorizationResult(modalInstance, orderDetails) {
    angular.element($window).off('beforeunload', confirmLeavePaymentPortal);
    modalInstance.dismiss();

    showOrderSubmitDialog();
    onlinePartsOrdersResource.readAuthorizationResult.get({
      salesOrderNumber: $scope.preGeneratedSalesOrderNumber,
      authorizationAmount: $scope.authorizationAmount
    }).$promise.then(function (data) {
      if (data.transaction && data.successful) {
        globalAlertService.addSuccess('mydealer.ui.checkout.credit_card_auth_success');
        completeCheckout(orderDetails);
      } else {
        globalAlertService.addError('mydealer.ui.checkout.credit_card_auth_fail');
        hideOrderSubmitDialog();
      }
    }).catch(function (response) {
      globalAlertService.handleErrorResponse(response);
      hideOrderSubmitDialog();
    });
  }

  $scope.submitBlockedPopupOrder = function () {
    if ($scope.blockedPopupDetails) {
      readAuthorizationResult($scope.blockedPopupDetails.modalInstance, $scope.blockedPopupDetails.orderDetails);
    }
  };

  function confirmLeavePaymentPortal(eventObject) {
    var message = $filter('language')('mydealer.ui.checkout.credit_card_prompt_body_leaving');
    eventObject.returnValue = message;
    return message;
  }

  function confirmLeaveOrderSubmit(eventObject) {
    var message = $filter('language')('mydealer.ui.checkout.order_submitting_prompt_body');
    eventObject.returnValue = message;
    return message;
  }

  var orderSubmitDialogInstance = null;

  function showOrderSubmitDialog() {
    if (!orderSubmitDialogInstance) {
      angular.element($window).on('beforeunload', confirmLeaveOrderSubmit);
      $uibModal.open({
        templateUrl: 'app/scripts/modules/shopping-cart/views/order-submitting-prompt.html',
        scope: $scope,
        controller: function ($uibModalInstance) {
          orderSubmitDialogInstance = $uibModalInstance;
        },
        size: 'md',
        animation: true,
        windowClass: 'center-modal',
        keyboard: false,
        backdrop: 'static'
      });
    }
  }

  function hideOrderSubmitDialog() {
    if (orderSubmitDialogInstance) {
      orderSubmitDialogInstance.dismiss();
      orderSubmitDialogInstance = null;
      angular.element($window).off('beforeunload', confirmLeaveOrderSubmit);
    }
  }

  // Use the suggested address
  function useAddressSuggestion(avalaraSuggestedAddress) {
    if ($rootScope.isPublicState && !$rootScope.guestLoggedin) {
      $scope.orderDetails.guest.billToAddress1 = avalaraSuggestedAddress.line1;
      $scope.orderDetails.guest.billToAddress2 = avalaraSuggestedAddress.line2;
      $scope.orderDetails.guest.city = avalaraSuggestedAddress.city;
      $scope.orderDetails.guest.provinceState = avalaraSuggestedAddress.state;
      $scope.orderDetails.guest.country = avalaraSuggestedAddress.country;
      $scope.orderDetails.guest.billToPostalZipCode = avalaraSuggestedAddress.postalCode.replace('-', '');
    } else {
      $scope.orderDetails.shipToAddress1 = avalaraSuggestedAddress.line1;
      $scope.orderDetails.shipToAddress2 = avalaraSuggestedAddress.line2;
      $scope.orderDetails.shipToAddress3 = avalaraSuggestedAddress.line3;
      $scope.orderDetails.shipToPostalZipCode = avalaraSuggestedAddress.postalCode.replace('-', '');
      if (!$scope.orderDetails.shipToAddress2) {
        $scope.orderDetails.shipToAddress2 = avalaraSuggestedAddress.city + ', ' + avalaraSuggestedAddress.state;
      } else {
        $scope.orderDetails.shipToAddress3 = avalaraSuggestedAddress.city + ', ' + avalaraSuggestedAddress.state;
      }
    }
  }

  // Load the initial order defaults for the checkout form
  function loadOrderDefaults() {
    if (myProfile.purchaseOrderNumberRequired == 'Y') {
      $scope.purchaseOrderRequired = myProfile.purchaseOrderNumberRequired;
    } else {
      $scope.orderDetails.purchaseOrder = myProfile.purchaseOrderNumberRequired;
    }

    $scope.orderDetails.shipOrPickup = (myProfile.defaultPickupOrShipTo ? (myProfile.defaultPickupOrShipTo === 'PICKUP' ? 'P' : 'S') : '');
    if (!$scope.orderDetails.shipOrPickup && $scope.allowShipTo) {
      $scope.orderDetails.shipOrPickup = 'S';
    }
    if (!$scope.orderDetails.shipOrPickup && $scope.allowPickup) {
      $scope.orderDetails.shipOrPickup = 'P';
    }
    if ($scope.orderDetails.shipOrPickup === 'S' && !$scope.allowShipTo) {
      $scope.orderDetails.shipOrPickup = 'P';
    }
    if ($scope.orderDetails.shipOrPickup === 'P' && !$scope.allowPickup) {
      $scope.orderDetails.shipOrPickup = 'S';
    }

    angular.forEach(angular.copy($scope.assignedLocations), function (value) {
      if (value.location === locationService.getDefaultLocation()) {
        $scope.orderDetails.pickupLocation = value;
      }
    });

    // if there is no shipping name and address, use billing
    if ((myProfile.businessAddress1 && myProfile.businessAddress1 !== '') ||
      (myProfile.businessAddress2 && myProfile.businessAddress2 !== '') ||
      (myProfile.businessAddress3 && myProfile.businessAddress3 !== '')) {
      $scope.orderDetails.shipToName = myProfile.firstName + ' ' + myProfile.lastName;
      $scope.orderDetails.shipToAddress1 = (myProfile.businessAddress1 ? myProfile.businessAddress1 : '');
      $scope.orderDetails.shipToAddress2 = (myProfile.businessAddress2 ? myProfile.businessAddress2 : '');
      $scope.orderDetails.shipToAddress3 = (myProfile.businessAddress3 ? myProfile.businessAddress3 : '');
      $scope.orderDetails.shipToPostalZipCode = (myProfile.businessPostalCode ? myProfile.businessPostalCode : '');

    } else if ((myProfile.shipToName && myProfile.shipToName !== '') ||
      (myProfile.shipToAddress1 && myProfile.shipToAddress1 !== '') ||
      (myProfile.shipToAddress2 && myProfile.shipToAddress2 !== '') ||
      (myProfile.shipToAddress3 && myProfile.shipToAddress3 !== '') ||
      (myProfile.shipToPostalZipCode && myProfile.shipToPostalZipCode !== '')) {
      $scope.orderDetails.shipToName = (myProfile.shipToName ? myProfile.shipToName : '');
      $scope.orderDetails.shipToAddress1 = (myProfile.shipToAddress1 ? myProfile.shipToAddress1 : '');
      $scope.orderDetails.shipToAddress2 = (myProfile.shipToAddress2 ? myProfile.shipToAddress2 : '');
      $scope.orderDetails.shipToAddress3 = (myProfile.shipToAddress3 ? myProfile.shipToAddress3 : '');
      $scope.orderDetails.shipToPostalZipCode = (myProfile.shipToPostalZipCode ? myProfile.shipToPostalZipCode : '');
    } else {
      $scope.orderDetails.shipToName = (businessProfile.soldToCustomerName ? businessProfile.soldToCustomerName : '');
      $scope.orderDetails.shipToAddress1 = (businessProfile.soldToAddress1 ? businessProfile.soldToAddress1 : '');
      $scope.orderDetails.shipToAddress2 = (businessProfile.soldToAddress2 ? businessProfile.soldToAddress2 : '');
      $scope.orderDetails.shipToAddress3 = (businessProfile.customerCity ? businessProfile.customerCity : '') +
        (businessProfile.customerCity && businessProfile.provinceState ? ', ' : '') +
        (businessProfile.provinceState ? businessProfile.provinceState : '');
      $scope.orderDetails.shipToPostalZipCode = (businessProfile.soldToPostalZipCode ? businessProfile.soldToPostalZipCode : '');
    }
    $scope.shipToAddresses.forEach(function(address, index) {
      if(address.name === $scope.orderDetails.shipToName){
        $scope.selectedShipTo = {
          address:$scope.shipToSelector[index].value
        }
      }
    });



    updateShipToAddress();
  }

  $scope.setDefaultLocation = function () {
    publicModeService.clearDeereDealerNumber();
    locationService.setDefaultLocation($scope.orderDetails.pickupLocation.location);
    shoppingCartService.loadShoppingCart();
  };

  $scope.invalidLocation = function () {
       return $scope.orderDetails.pickupLocation.location === '';
  };

  // set the customized message if one is configured
  function loadCustomizedMessage() {
    $scope.customizedMessage = null;
    angular.forEach(customizedMessagesService.getMessages(), function (value) {
      if (value.referenceNumber === 'CHECKOUT' &&
        (($scope.orderDetails.shipOrPickup === 'S' && (!value.location || value.location === userConfiguration.businessLocation)) ||
          ($scope.orderDetails.shipOrPickup === 'P' && (!value.location || value.location === $scope.orderDetails.pickupLocation.location)))) {
        $scope.customizedMessage = value.description;
      }
    });
  }

  $scope.$watch('orderDetails.shipOrPickup', function (newVal, oldVal) {
    if (newVal !== oldVal) {
      loadCustomizedMessage();
    }
    if (newVal === 'P') {
      $scope.orderDetails.shipToVia = null;
    } else if (newVal === 'S' && _.isEmpty($scope.orderDetails.shipToVia)) {
      angular.forEach($scope.shipVias, function (value) {
        if (value.shipViaCode === myProfile.defaultShipVia) {
          $scope.orderDetails.shipToVia = value;
        }
      });
    }
  });

  $scope.$watch('orderDetails.shipToVia', function (newVal) {
    if ($scope.orderDetails.shipOrPickup === 'S' && $scope.showPrice && !($rootScope.isPublicState && $rootScope.guestLoggedin)) {
      if (newVal) {
        shoppingCartService.handleShippingAmount(newVal.shipViaCode).then(function (data) {
          $scope.estimatedShipping = data.estimatedShipping;
          /*
            Authorization amount is driven by contact's business location for a 'shipTo' order, and it could be different from the total
            populated on MyDealer checkout page, which is driven by the location user selects from the drop-down.
           */
          $scope.authorizationAmount = data.total;
        });
      }
    }
  });

  $scope.$watch('orderDetails.pickupLocation', function (newVal, oldVal) {
    if (newVal !== oldVal) {
      loadCustomizedMessage();
    }
  });

  loadOrderDefaults();
  loadCustomizedMessage();
});
