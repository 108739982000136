'use strict';

intellidealerAppCoreModule.factory('onlinePartsOrdersResource', function ($resource, $http, appConfigurationService) {
  var resource = {};
  function convertBytes(byteArray) {
    const binaryString = window.atob(byteArray);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }
  function getDateStamp(){
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var monthStr = month +1 < 9 ? "0"+(month+1) : month+1;
    var dayStr = day < 9 ? "0"+(day) : day;
    var stamp = year + '_' + monthStr + '_' + dayStr;
    return stamp;

  }
  return {
    assignedOrders: $resource(appConfigurationService.basePath + '/rest/parts/assignedOrdersByPage/'),
    assignedOnlineOrders: $resource(appConfigurationService.basePath + '/rest/onlinepartsorders/assignedOnlineOrders/'),
    assignedOnlineOrderDetails: $resource(appConfigurationService.basePath + '/rest/onlinepartsorders/assignedOnlineOrderDetailsByPage/'),
    assignedPartInvoiceDetails: $resource(appConfigurationService.basePath + '/rest/parts/assignedPartsInvoiceDetailsByPage/'),
    allAssignedOnlineOrderDetailsByPage: $resource(appConfigurationService.basePath + '/rest/onlinepartsorders/allAssignedOnlineOrderDetailsByPage/'),
    addPartsFromOnlineOrders: $resource(appConfigurationService.basePath + '/rest/onlinepartsorders/addPartsFromOnlineOrders/'),
    deleteOnlineOrders: {
      remove: function(data, success, failure) {
        $http({
          method: 'DELETE',
          url: appConfigurationService.basePath + '/rest/onlinepartsorders/deleteOnlineOrders/',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
          data: data
        }).then(success, failure);
      }
    },
    checkOutOnlineOrder: $resource(appConfigurationService.basePath + '/rest/onlinepartsorders/checkOutOnlineOrder/'),
    validateAvalaraAddress: $resource(appConfigurationService.basePath + '/rest/onlinepartsorders/avalaraAddressValidation/'),
    authorizeCreditCard: $resource(appConfigurationService.basePath + '/rest/onlinepartsorders/authorizeCreditCard/'),
    readAuthorizationResult: $resource(appConfigurationService.basePath + '/rest/onlinepartsorders/readAuthorizationResult/'),
    worldPayAuthorizeCreditCard: $resource(appConfigurationService.basePath + '/rest/onlinepartsorders/worldpay/authorizeCreditCard/'),
    worldPayReadAuthorizationResult: $resource(appConfigurationService.basePath
      + '/rest/onlinepartsorders/worldpay/readAuthorizationResult/', {}, {post: {method: 'POST'}}),
   exportCsvData: {
      csv: function (parameters) {
        return $resource(
          appConfigurationService.basePath + '/rest/parts/getAssignedOrdersCsv',
          parameters,
          {
            get: {
              method: 'GET',
              url: appConfigurationService.basePath + '/rest/parts/getAssignedOrdersCsv',
              interceptor: {
                response: function (response) {
                  if (response.status === 200) {
                    var blob = new Blob([convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                    if (window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(blob, 'previousOrders' + '.xls');
                    } else {
                      var fileUrl = URL.createObjectURL(blob);
                      var downloadLink = document.createElement('a');
                      downloadLink.href = fileUrl;
                      downloadLink.download = 'MyOrders_' + getDateStamp() +'.xls';
                      document.body.appendChild(downloadLink);
                      downloadLink.click();
                      document.body.removeChild(downloadLink);
                    }
                  }
                }
              }
            }
          }
        );
      }
    },
    assignedOnlineOrderDetailsByList:{
      getOnlineOrdersDetailsList: function (parameters){

        return $resource(
          appConfigurationService.basePath + '/rest/onlinepartsorders/assignedOnlineOrderDetailsByList',
          parameters,
          {
            get: {
              method: 'GET',
              url: appConfigurationService.basePath + '/rest/onlinepartsorders/assignedOnlineOrderDetailsByList',
              responseType: 'arraybuffer',

              interceptor: {
                response: function (response) {
                  let decoder = new TextDecoder("utf-8");
                  return decoder.decode(response.data);
                }
              }
            }
          }
        );
      }
    },
    assignedPartsInvoiceDetailsByList:
      {
        getPartsInvoiceDetailsList: function (parameters){

          return $resource(
            appConfigurationService.basePath + '/rest/parts/assignedPartsInvoiceDetailsByList',
            parameters,
            {
              get: {
                method: 'GET',
                url: appConfigurationService.basePath + '/rest/parts/assignedPartsInvoiceDetailsByList',
                responseType: 'arraybuffer',

                interceptor: {
                  response: function (response) {
                    let decoder = new TextDecoder("utf-8");
                    return decoder.decode(response.data);
                  }
                }
              }
            }
          );
        }
      },
    assignedOnlineOrdersList:
      {
        getAllList: function (parameters){

          return $resource(
            appConfigurationService.basePath + '/rest/onlinepartsorders/assignedOnlineOrdersList',
            parameters,
            {
              get: {
                method: 'GET',
                url: appConfigurationService.basePath + '/rest/onlinepartsorders/assignedOnlineOrdersList',
                responseType: 'arraybuffer',

                interceptor: {
                  response: function (response) {
                    let decoder = new TextDecoder("utf-8");
                    return decoder.decode(response.data);
                  }
                }
              }
            }
          );
        }
      },
    exportShoppingListCsvData: {

      csv: function (parameters) {

        return $resource(
          appConfigurationService.basePath + '/rest/onlinepartsorders/exportAssignedOnlineOrders',
          parameters,
          {
            get: {
              method: 'GET',
              url: appConfigurationService.basePath + '/rest/onlinepartsorders/exportAssignedOnlineOrders',
              interceptor: {
                response: function (response) {
                  if (response.status === 200) {
                    var blob = new Blob([convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                    if (window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(blob, '' + '.xls');
                    } else {
                      var fileUrl = URL.createObjectURL(blob);
                      var downloadLink = document.createElement('a');
                      downloadLink.href = fileUrl;
                      downloadLink.download = 'MyShoppingList_' + getDateStamp() +'.xls';
                      document.body.appendChild(downloadLink);
                      downloadLink.click();
                      document.body.removeChild(downloadLink);
                    }
                  }
                }
              }
            }
          }
        );
      }
    },
    exportCsvDetailsData: {
      csv: function (parameters) {
        return $resource(
          appConfigurationService.basePath + '/rest/onlinepartsorders/exportAssignedOnlineOrdersDetails',
          parameters,
          {
            get: {
              method: 'GET',
              url: appConfigurationService.basePath + '/rest/onlinepartsorders/exportAssignedOnlineOrdersDetails',
              interceptor: {
                response: function (response) {
                  if (response.status === 200) {
                    var blob = new Blob([convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                    if (window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(blob, '' + '.xls');
                    } else {
                      var fileUrl = URL.createObjectURL(blob);
                      var downloadLink = document.createElement('a');
                      downloadLink.href = fileUrl;
                      downloadLink.download = 'MyShoppingListDetails_' + getDateStamp() +'.xls';
                      document.body.appendChild(downloadLink);
                      downloadLink.click();
                      document.body.removeChild(downloadLink);
                    }
                  }
                }
              }
            }
          }
        );
      }
    },
    exportCsvOrderData: {
      csv: function (parameters) {
        return $resource(
          appConfigurationService.basePath + '/rest/onlinepartsorders/exportAssignedOnlineOrdersDetails',
          parameters,
          {
            get: {
              method: 'GET',
              url: appConfigurationService.basePath + '/rest/onlinepartsorders/exportAssignedOnlineOrdersDetails',
              interceptor: {
                response: function (response) {
                  if (response.status === 200) {
                    var blob = new Blob([convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                    if (window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(blob, '' + '.xls');
                    } else {
                      var fileUrl = URL.createObjectURL(blob);
                      var downloadLink = document.createElement('a');
                      downloadLink.href = fileUrl;
                      downloadLink.download = 'MyOrdersDetails_' + getDateStamp() +'.xls';
                      document.body.appendChild(downloadLink);
                      downloadLink.click();
                      document.body.removeChild(downloadLink);
                    }
                  }
                }
              }
            }
          }
        );
      }
    },
    exportPartInvoiceDetailsData: {
      xls: function (parameters) {
        return $resource(
          appConfigurationService.basePath + '/rest/parts/exportAssignedPartsInvoiceDetails',
          parameters,
          {
            get: {
              method: 'GET',
              url: appConfigurationService.basePath + '/rest/parts/exportAssignedPartsInvoiceDetails',
              interceptor: {
                response: function (response) {
                  if (response.status === 200) {
                    var blob = new Blob([convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                    if (window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(blob, '' + '.xls');
                    } else {
                      var fileUrl = URL.createObjectURL(blob);
                      var downloadLink = document.createElement('a');
                      downloadLink.href = fileUrl;
                      downloadLink.download = 'MyOrdersDetails_' + getDateStamp() +'.xls';
                      document.body.appendChild(downloadLink);
                      downloadLink.click();
                      document.body.removeChild(downloadLink);
                    }
                  }
                }
              }
            }
          }
        );
      }
    },
  }
});

