'use strict';

intellidealerAppCoreModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'forgotPassword', {
      url: '/forgot-password?library&encryptedKey1&encryptedKey2',
      templateUrl: 'app/scripts/modules/core/views/forgot-password.html',
      controller: 'ForgotPasswordController',
      resolve: {
        dealershipConfiguration: ['dealershipConfigurationService', '$stateParams', 'themeApplicationService', function (dealershipConfigurationService, $stateParams, themeApplicationService) {
          var test = dealershipConfigurationService($stateParams.library.toUpperCase()).then(function (value) {
            themeApplicationService(value.baseColor.symbol);
            return value;
          });
          return test;
        }],
        language: ['$stateParams', 'languageService', function ($stateParams, languageService) {
          return languageService.populatePublic($stateParams.library.toUpperCase());
        }],
        myDealerPassword: ['$stateParams', 'forgotPasswordService', function($stateParams, forgotPasswordService) {
          return forgotPasswordService.populatePassword($stateParams.library.toUpperCase(), $stateParams.encryptedKey1, $stateParams.encryptedKey2);
        }]
      }
    });
}]);
