'use strict';

intellidealerAppEquipmentModule.controller('MyEquipmentDetailsController', function ($scope, $state, userConfiguration, sessionService, myEquipment, equipmentNoLongerOwnedStatusSearchValues, equipmentReadyToSellStatusSearchValues, $filter, deereUrl, multiMediaStreamingURLService) {

  $scope.myEquipment = myEquipment;
  $scope.myEquipment.requestServiceStockNumber = $scope.myEquipment.equipmentId.stockNumber;
  $scope.myEquipment.equipmentNoLongerOwnedStatusSearchValues = equipmentNoLongerOwnedStatusSearchValues;
  $scope.myEquipment.equipmentReadyToSellStatusSearchValues = equipmentReadyToSellStatusSearchValues;
  $scope.myEquipment.displayPartsCatalog = userConfiguration.displayPartsCatalogLink;
  $scope.myEquipment.deereUrl = deereUrl.url;
  $scope.myEquipment.displayCnhDsp = sessionService.getFeatures()['PFW.6414'];

  $scope.myEquipment.pictureLocations = myEquipment.pictureLocations.map(pictureLocation => {
    const httpPattern = /http(s)?:\/\/\S+/;
    if (!httpPattern.test(pictureLocation)) {
      let params = multiMediaStreamingURLService.getEquipmentMultimediaParams(myEquipment, pictureLocation);
      return multiMediaStreamingURLService.getMultiMediaStreamingURL(pictureLocation, params);
    }
    else {
      return pictureLocation;
    }
  });

  $scope.goBack = function () {
    $state.go('navigation.equipment.myEquipment');
  };

  $scope.showcase = {
    defaultTemplate: 'app/scripts/modules/equipment/views/templates/my-equipment-details-default.html',
    narrow: myEquipment.pictureLocations.length < 1,
    selected: '',
    subViews: [
      {'label': $filter('language')('mydealer.ui.equipment.actions.request_service'),
       'glyphicon':'glyphicon-wrench',
       'template': 'app/scripts/modules/equipment/views/templates/equipment-schedule-service.html'},
      {'label': $filter('language')('mydealer.ui.equipment.actions.update_hours'),
       'glyphicon':'glyphicon-time',
       'template': 'app/scripts/modules/equipment/views/templates/my-equipment-details-update-hours.html'},
      {'label': $filter('language')('mydealer.ui.equipment.actions.add_service_history'),
       'glyphicon':'glyphicon-file',
       'template': 'app/scripts/modules/equipment/views/templates/my-equipment-details-add-service-history.html'},
      {'label': $filter('language')('mydealer.ui.equipment.actions.ready_to_sell'),
       'glyphicon':'glyphicon-tags',
       'template': 'app/scripts/modules/equipment/views/templates/my-equipment-details-ready-to-sell.html'},
      {'label': $filter('language')('mydealer.ui.equipment.actions.no_longer_own'),
       'glyphicon':'glyphicon-remove',
       'template': 'app/scripts/modules/equipment/views/templates/my-equipment-details-no-longer-own.html'}
    ],
    goBack: $scope.goBack
  };

  $scope.linkedStates = [
    {title:$filter('language')('mydealer.ui.open_work_orders.breadcrumb'),
     state:'navigation.equipment.myEquipment.myEquipmentDetails.openWorkOrders'},
    {title:$filter('language')('mydealer.ui.my_service_contract.breadcrumb'),
     state:'navigation.equipment.myEquipment.myEquipmentDetails.myServiceContract'},
    {title:$filter('language')('mydealer.ui.service_history.breadcrumb'),
     state:'navigation.equipment.myEquipment.myEquipmentDetails.serviceHistory'},
    {title:$filter('language')('mydealer.ui.recommended_service.breadcrumb'),
     state:'navigation.equipment.myEquipment.myEquipmentDetails.recommendedService'}
  ];

  $scope.myEquipment.goToCNHDSP = function () {
    $state.go('navigation.parts.cnhDspLanding', {
      serialNumber: $scope.myEquipment.machineSerialNumber
    });
  };
});
