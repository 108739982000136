'use strict';

intellidealerAppEquipmentModule.controller('EquipmentForSaleController', function ($scope, $rootScope, $filter, publicModeService, equipmentForSale, $state, $stateParams, equipmentForSaleSearchValues, equipmentForSaleFilters, equipmentTypeSearchValues, sessionService, equipmentSalesResource, multiMediaStreamingURLService) {

  var dealershipEquipmentSaleLink = null;
  $scope.linkedStates = [];

  if (!$rootScope.isPublicState) {
    var userConfiguration = $state.$current.locals.globals.userConfiguration || {};
    var myDealerDealershipLinks = $state.$current.locals.globals.myDealerDealershipLinks || [];

    angular.forEach(myDealerDealershipLinks, function (value) {
      if (value.subType === 'EQUIPMENT_FOR_SALE' && (!value.location || value.location === userConfiguration.businessLocation)) {
        dealershipEquipmentSaleLink = value;
      }
    });

    if (userConfiguration.myDealerEquipmentSalesAccess && !dealershipEquipmentSaleLink) {
      $scope.linkedStates.push({
        title: $filter('language')('mydealer.ui.equipment_for_sale.breadcrumb'),
        state: 'navigation.equipment.equipmentForSale'
      });
    }
    if (sessionService.getFeatures()['PFW.6512'] && userConfiguration.accessRentalOptions) {
      $scope.linkedStates.push({
        title: $filter('language')('mydealer.ui.equipment_for_rent.breadcrumb'),
        state: 'navigation.equipment.equipmentForRent'
      });
    }
  }
  $scope.clearStorage = function () {
    sessionStorage.clear()
  }

  $scope.getCsv = function () {
    let parameters = {
      keywords: ($stateParams.search ? $stateParams.search : ''),
    };
    if (equipmentForSaleFilters) {
      if (equipmentForSaleFilters.type) {
        parameters.type = equipmentForSaleFilters.type.symbol;
      } else {
        parameters.type = "A";
      }
      parameters.manufacturer = equipmentForSaleFilters.manufacturer;
      parameters.category = equipmentForSaleFilters.category;
      parameters.maxPrice = equipmentForSaleFilters.maxPrice;
      parameters.maxHours = equipmentForSaleFilters.maxHours;
      parameters.fromYear = equipmentForSaleFilters.minYear ? new Date(equipmentForSaleFilters.minYear).getFullYear() : null;
      parameters.toYear = equipmentForSaleFilters.maxYear ? new Date(equipmentForSaleFilters.maxYear).getFullYear() : null;
    } else {
      parameters.type = "A";
    }
    equipmentSalesResource.csv(parameters).get(
      null,
      function () {
      },
      function (response) {
        globalAlertService.addError('mydealer.ui.equipment.csv_notGenerated');
      }
    );
  };

  $scope.filters = equipmentForSaleFilters || {};

  $scope.categories = equipmentForSaleSearchValues.searchDistinctCategories;
  $scope.manufacturers = equipmentForSaleSearchValues.searchDistinctManufacturers;
  $scope.types = equipmentTypeSearchValues.searchEquipmentTypes;

  //remove types 'None' and 'Traded' as they are not relevant
  $scope.types.splice(2);

  $scope.equipmentForSale = equipmentForSale;

  $scope.equipmentForSale.content.map((equipment) => {
    if (equipment.pictureLocations && equipment.pictureLocations.length > 0) {
      {
      let params = multiMediaStreamingURLService.getEquipmentSalesMultimediaParams(equipment, equipment.pictureLocations[0]);
      // if images are accessed in public mode stream images without login
      equipment.pictureStream = $rootScope.isPublicState ? multiMediaStreamingURLService.getMultiMediaStreamingPublicURL(equipment.pictureLocations[0], params) :
        multiMediaStreamingURLService.getMultiMediaStreamingURL(equipment.pictureLocations[0], params);
    }
    }
  });

  $scope.terms = {
    keywords: $stateParams.search
  };

  $scope.sortChoices = [
    {label: $filter('language')('mydealer.ui.equipment.manufacturer'), property: 'manufacturer', direction: 'asc'},
    {label: $filter('language')('mydealer.ui.equipment.model'), property: 'model', direction: 'asc'},
    {label: $filter('language')('mydealer.ui.equipment.price'), property: 'price', direction: 'asc'},
    {label: $filter('language')('mydealer.ui.equipment.year'), property: 'modelYear', direction: 'asc'},
    {label: $filter('language')('mydealer.ui.equipment.hours'), property: 'unitHours', direction: 'asc'}
  ];

  $scope.search = function (event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    if ($scope.filtersform.$valid) {
      var params = {};

      var sortParams = angular.fromJson($stateParams.equipmentForSalePagination);
      //re-sort if sorting properties passed in
      if (property && direction) {
        sortParams.sort = property;
        sortParams.direction = direction;
      }
      sortParams.page = 0;
      params.equipmentForSalePagination = angular.toJson(sortParams);

      //add filters
      params.equipmentForSaleFilters = angular.toJson($scope.filters);

      //add keywords
      params.search = $scope.terms.keywords;

      $state.go('.', params);
    }
  };

  $scope.showFilters = !!(
    $scope.filters.type ||
    $scope.filters.maxPrice ||
    $scope.filters.maxHours ||
    $scope.filters.manufacturer ||
    $scope.filters.category ||
    $scope.filters.minYear ||
    $scope.filters.maxYear
  );
  $scope.toggleFilters = function () {
    $scope.showFilters = !$scope.showFilters;
  };

  function isPopulated(object) {
    var populated = false;
    angular.forEach(object, function (value) {
      if (value) {
        populated = true;
      }
    });
    return populated;
  }

  $scope.getDetailsLink = function (equipment) {
    var params = {
      company: equipment.equipmentSaleId.company,
      stockNumber: equipment.equipmentSaleId.stockNumber,
      division: equipment.equipmentSaleId.division
    };

    var baseState = publicModeService.getStateWithPrefix($rootScope.isPublicState, '.equipment.equipmentForSale.equipmentForSaleDetails');

    if (isPopulated(equipment.engine)) {
      return baseState + '.engine(' + angular.toJson(params) + ')';
    } else if (isPopulated(equipment.transmission)) {
      return baseState + '.transmission(' + angular.toJson(params) + ')';
    } else if (isPopulated(equipment.differential)) {
      return baseState + '.differential(' + angular.toJson(params) + ')';
    } else if (isPopulated(equipment.tire)) {
      return baseState + '.tires(' + angular.toJson(params) + ')';
    } else if (isPopulated(equipment.groundEngaging)) {
      return baseState + '.groundEngaging(' + angular.toJson(params) + ')';
    } else if (isPopulated(equipment.hydraulic)) {
      return baseState + '.hydraulics(' + angular.toJson(params) + ')';
    } else if (isPopulated(equipment.cab)) {
      return baseState + '.cab(' + angular.toJson(params) + ')';
    }
    return baseState + '(' + angular.toJson(params) + ')';
  };

});
