'use strict';

/*
  This directive is added from validation-popover.  It creates a child scope to populate the error popover
*/
intellidealerAppCoreModule.directive('validationPopoverInternal', ['$parse', function($parse) {
  return {
    restrict: 'A',
    require: ['^form', 'ngModel'],
    scope: true,
    link: function (scope, element, attrs, required) {
      scope.errorObject = required[0][attrs.name].$error;
      scope.backendErrorFlag = false;
      if (attrs.validationPopoverInternal) {
        required[1].$validators.backend = function(modelValue, viewValue) {
          scope.backendError = $parse('$parent.' + attrs.validationPopoverInternal)(scope);
          if (scope.backendError) {
            if (scope.backendErrorFlag) {
              $parse('$parent.' + attrs.validationPopoverInternal + ' = \'\'')(scope);
              scope.backendErrorFlag = false;
              return true;
            }
            scope.backendErrorFlag = true;
            return false;
          }
          return true;    
        };
      }
    }
  };
}]);