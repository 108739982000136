'use strict';

intellidealerAppPartsModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.parts.orderHistory', {
      url: '/order-history' +
      '?orderHistoryPagination' + 
      '&{orderHistorySearch:any}',
      params: {
        orderHistoryPagination: angular.toJson({
          sort: 'receiptDate',
          direction: 'desc'
        })
      },
      templateUrl: 'app/scripts/modules/parts/views/order-history.html',
      controller: 'OrderHistoryController',
      resolve: {
        help: function () {
          return 'Parts-_My_Parts_Purchases.htm';
        },
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.my_parts_purchases.breadcrumb'));
        }],
        orderHistoryPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.orderHistoryPagination);
        }],

        orderHistory: [
          'pageableService', 
          'partHistoryResource', 
          'globalAlertService', 
          '$stateParams', 
          'orderHistoryPagination',
          function(pageableService, partHistoryResource, globalAlertService, $stateParams, orderHistoryPagination) {

            var parameters = {
              keywords: $stateParams.orderHistorySearch ? $stateParams.orderHistorySearch : ''
            };
            pageableService.addPageableParameters(
              parameters,
              orderHistoryPagination
            );

            return partHistoryResource.assignedPartHistory.get(
              parameters, 
              function () {}, 
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ]
      }
    });
}]);
