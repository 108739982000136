'use strict';

intellidealerAppQuotesModule.controller('QuotesAcceptOrRequoteController', function ($scope, $location, $anchorScroll, globalAlertService, quotesResource) {

  $scope.formValues = {};

  $scope.scrollHere = function() {
    $location.hash('acceptOrRequote');
    $anchorScroll();
  };

  $scope.cancel = function() {
    $scope.toggleAcceptRequoteForm(false);
  };

  $scope.send = function() {
    if ($scope.acceptOrRequoteForm.$valid) {

      quotesResource.allAssignedQuotes({}).get(
        null,
        function () {
        },
        globalAlertService.handleErrorResponse
      ).$promise.then(function (response) {
        let data = JSON.stringify(response);
        let allQuotes = JSON.parse(data);
        let processedQuotes = [];
        JSON.parse(allQuotes).forEach(quote => {
          if (sessionStorage.getItem("selectAllToggle") === "true" && !sessionStorage.getItem(quote.quoteCombinedId.quoteNumber + 'unchk')) {
            quote = angular.copy(quote);
            quote.selected = true;
          } else if (sessionStorage.getItem(quote.quoteCombinedId.quoteNumber + 'chk')) {
            quote = angular.copy(quote);
            quote.selected = true;
          }
          processedQuotes.push(quote);
        });

        var selectedQuotes = [];
        processedQuotes.forEach(function(current) {
          if (current.selected) {
            selectedQuotes.push(current.quoteCombinedId);
          }
        });
        if (selectedQuotes.length > 0) {
          quotesResource.myDealerAcceptOrRequoteRequestEmail.save(
            {
              'quoteAcceptRequote': $scope.formValues.acceptOrRequote,
              'quoteComment': $scope.formValues.comment,
              'quoteCombinedIdList': selectedQuotes
            },
            function() {
              globalAlertService.addSuccess('mydealer.ui.quotes.submitted');
              $scope.toggleAcceptRequoteForm(false);
              sessionStorage.clear();
              $scope.search();
            },
            globalAlertService.handleErrorResponse
          );
        }
      });
    }
  };

});
