'use strict';

intellidealerAppMyAccountModule.filter('invoiceReferenceNumber', function () {
    return function (rowEntity) {

        var contractNumber = rowEntity.contractNumber;

        if (rowEntity.invoiceType.symbol === 'G') {
            return contractNumber.substring(4, 9) + contractNumber.substring(5);
        } else {
            return contractNumber;
        }
    };
});
