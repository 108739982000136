'use strict';

intellidealerAppPartsModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.parts.myShoppingLists.myShoppingListsDetails', {
      url: '/my-shopping-lists-details/{company}/{division}/{location}/{referenceNumber}' +
      '?myShoppingListsDetailsPagination',
      params: {
        myShoppingListsDetailsPagination: angular.toJson({
          sort: 'partNumber',
          direction: 'asc'
        })
      },
      views : {
        '@navigation.parts' : {
          templateUrl: 'app/scripts/modules/parts/views/my-shopping-lists-details.html',
          controller: 'MyShoppingListsDetailsController'
        }
      },
      resolve: {
        breadcrumb: ['$stateParams', '$filter', 'language', function($stateParams, $filter, language) { // jshint ignore:line
          return ($stateParams.referenceNumber ? $filter('pathParam')($stateParams.referenceNumber) : ($filter('language')('mydealer.ui.my_shopping_lists.breadcrumb.all_lists')));
        }],

        myShoppingListsDetailsPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.myShoppingListsDetailsPagination);
        }],

        myShoppingListsDetails: [
          'pageableService', 
          'onlinePartsOrdersResource', 
          'globalAlertService', 
          '$stateParams', 
          '$filter', 
          'myShoppingListsDetailsPagination',
          function(pageableService, onlinePartsOrdersResource, globalAlertService, $stateParams, $filter, myShoppingListsDetailsPagination) {
            var parameters = {
              company: $stateParams.company,
              division: $stateParams.division,
              location: $stateParams.location,
              referenceNumber: $filter('pathParam')($stateParams.referenceNumber)
            };

            pageableService.addPageableParameters(
              parameters,
              myShoppingListsDetailsPagination
            );

            if ($stateParams.referenceNumber && $stateParams.referenceNumber !== '') {
              return onlinePartsOrdersResource.assignedOnlineOrderDetails.get(
                parameters, 
                function () {}, 
                globalAlertService.handleErrorResponse
              ).$promise;
            } else {
              return onlinePartsOrdersResource.allAssignedOnlineOrderDetailsByPage.get(
                parameters, 
                function () {}, 
                globalAlertService.handleErrorResponse
              ).$promise;
            }
          }
        ]
      }
    }
  );
}]);
