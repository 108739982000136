'use strict';

intellidealerAppPartsModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.parts.cnhDspLanding', {
      url: '/cnh-dsp-landing?serialNumber',
      templateUrl: 'app/scripts/modules/parts/views/cnh-dsp-landing.html',
      controller: 'CnhDspLandingController',
      resolve: {
        help: function () {
          return 'Parts-_CNH_DSP_Landing.htm';
        },
        breadcrumb: function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.cnhDsp.breadcrumb'));
        },
        dspBrands: function(globalAlertService, oemResource, locationService) {
          return oemResource.cnhDspBrands.get({
              'userLocation': locationService.getDefaultLocation()
            },
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }
      }
    }
  );
}]);
