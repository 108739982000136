'use strict';

intellidealerAppShoppingCartModule.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/scripts/modules/shopping-cart/views/templates/shopping-cart-popover-update-item.html',
                     '<div class="shop-for-parts-add" data-ng-form="updatePartForm">' +
                     '  <div class="form-group" data-ng-class="{\'has-error\': updatePartForm[\'updateQuantity\'].$invalid}">' +
                     '    <div class="input-group">' +
                     '      <input data-ng-model="cartItem.updateQuantity"' +
                     '             name="updateQuantity"' +
                     '             type="text"' +
                     '             class="form-control shop-for-parts-add-quantity"' +
                     '             placeholder="{{cartItem.quantity}}"'+
                     '             data-ui-keypress="{\'enter\': \'updateItem(cartItem.partNumber, cartItem.updateQuantity, cartItem, shoppingCartForm.items, togglerId, $index, $event)\'}"' +
                     '             data-validation-popover' +
                     '             data-valid-number>' +
                     '      <span class="input-group-btn">' +
                     '        <button class="btn btn-primary" ' +
                     '                data-ng-click="updateItem(cartItem.partNumber, cartItem.updateQuantity, cartItem, shoppingCartForm.items, togglerId, $index)"' +
                     '                data-ng-disabled="updatePartForm.$invalid">' +
                     '          {{\'mydealer.ui.global.update\' | language}}' +
                     '        </button>' +
                     '      </span>' +
                     '    </div>' +
                     '  </div>' +
                     '  <div class="shop-for-parts-add-buttons">' +
                     '    <button class="btn btn-primary" data-ng-if="cartItem.quantity > 0 && !cartItem.isSubPart" data-ng-click="removeOneItem(cartItem.partNumber, cartItem, shoppingCartForm.items, togglerId, $index);">{{\'mydealer.ui.global.remove\' | language}}</button>' +
                     '    <button class="btn btn-info" data-ng-click="$root.togglePopover(togglerId + $index);">{{\'mydealer.ui.global.cancel\' | language}}</button>' +
                     '  </div>' +
                     '</div>');
}]);
