'use strict';

intellidealerAppMyAccountModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.myAccount', {
      abstract: true,
      template: '<ui-view class="navigation-content"></ui-view>',
      resolve: {
        breadcrumb: ['$filter', 'language', function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.navigation.my_account'));
        }],
        //we inject userConfiguration here from the navigation parent to ensure that our security model is resolved before we load this state
        authorization: ['userConfiguration', function(userConfiguration) {
          return userConfiguration.isAuthorized(['myDealerAccountAccess']);
        }]
      }
    }
  );
}]);