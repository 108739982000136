'use strict';

intellidealerAppCoreModule.service('languageService', ['$rootScope', '$q', 'globalAlertService', 'localeResource', function ($rootScope, $q, globalAlertService, localeResource) {
  var results = {}
    , service = {}
    , isPublic
    , transitionDeferred;

  // Listen on transition when starting create a new promise
  // These transition hooks are required to give us the ability to
  // keep the current language properties before loading new ones
  // until the state is successfully loaded.
  $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {
    transitionDeferred = $q.defer();
  });

  // when transition is successful, resolve the deferred
  // promise from when the transition had started
  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams, options) {
    transitionDeferred && transitionDeferred.resolve();
  });

  service.populate = function () {
    if (_.isEmpty(results) || isPublic) {
      return localeResource.localeData.get({group: "mydealer.ui,enum,exception.usage"}).$promise
        .then(function (response) {
          isPublic = false;
          results = response.languageProperties;
          $rootScope.isLanguageLoaded = true;
        }).catch(globalAlertService);
    } else {
      return $q.resolve();
    }
  };

  service.populatePublic = function (library, language) {
    return localeResource.publicLocaleData.get({
      library: library,
      languageOverride: language,
      group: "mydealer.ui,enum,exception.usage"
    }).$promise
      .then(function (response) {
        isPublic = true;
        if (_.isEmpty(results)) {
          results = response.languageProperties;
          $rootScope.isLanguageLoaded = true;
        } else {
          var transitioningResults = response.languageProperties;
          transitionDeferred.promise.then(function () {
            if (transitioningResults) {
              results = transitioningResults;
              transitioningResults = undefined;
            }
            $rootScope.isLanguageLoaded = true;
          });
        }
      }).catch(globalAlertService);
  };

  /**
   * gets the list of available language properties
   */
  service.getLanguage = function () {
    return results;
  };

  return service;
}]);
