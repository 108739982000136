'use strict';

intellidealerAppCoreModule.controller('ForgotPasswordController', function ($scope, $state, $stateParams, globalAlertService, dealershipConfiguration, forgotPasswordService, multiMediaStreamingURLService) {
  $scope.alerts = globalAlertService.currentAlerts;
  $scope.logo = multiMediaStreamingURLService.getMultiMediaStreamingPublicWebConfigURL(dealershipConfiguration.remoteServerPath + dealershipConfiguration.dealershipLogo, true);
  $scope.password = forgotPasswordService.getPassword();

  $scope.login = function() {
    $state.go('login', {library: $stateParams.library.toUpperCase()});
  };
  //reset password
  $scope.user = {newPassword: '', verifyPassword: ''};
  $state.newPassword='newPassword';
  $scope.resetPassword = function(){
    forgotPasswordService.resetPassword($stateParams.library.toUpperCase(),  $stateParams.encryptedKey1, $scope.user.newPassword, $scope.user.verifyPassword);
  };
});
