'use strict';

// eslint-disable-next-line no-undef
intellidealerAppCoreModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'badState', {
      url: '/bad-state',
      templateUrl: 'app/scripts/modules/core/views/bad-state.html',
      controller: 'BadStateController',
      resolve: {
        library: ['dealershipConfigurationService', '$q', function (dealershipConfigurationService, $q) {
          var library = $q.defer();
          dealershipConfigurationService().then(function (value) {
            library.resolve(value.filesLibrary);
          }, function () {
            library.resolve(false);
          });
          return library.promise;
        }]
      }
    });

  $stateProvider.state(
    'publicBadState', {
      url: '/public-bad-state',
      templateUrl: 'app/scripts/modules/core/views/public-bad-state.html'
    });
  $stateProvider.state(
    'publicBadStateLicense', {
      url: '/public-bad-state-license',
      templateUrl: 'app/scripts/modules/core/views/public-bad-state-license.html'
    });
}]);
