'use strict';

intellidealerAppPartsModule.controller('MacDonController', function ($scope, $rootScope, $sce,
                                                                     $state, $stateParams,
                                                                     shoppingCartService
) {


  window.addEventListener('message', addMacDonPartsToCart);
  function addMacDonPartsToCart(event) {
    var goToCheckout = false;
    if (event.data && event.data.productNumber) {
          shoppingCartService.addItem(1, event.data.productNumber, null, true);
    }
  }

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toStateParams, fromState) {
    if(toState.name !== "navigation.parts.macdon"){
      window.removeEventListener('message', addMacDonPartsToCart);
    }
  });

});
