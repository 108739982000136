'use strict';

intellidealerAppMyAccountModule.controller('ProfileController', ['$state', '$scope', 'globalAlertService', 'myProfile', 'businessProfile', 'contactsResource', 'userConfigurationService', 'cellCarriers', function ($state, $scope, globalAlertService, myProfile, businessProfile, contactsResource, userConfigurationService, cellCarriers) {

  $scope.businessAreaCodeAndPhoneNumber = '' + (businessProfile.areaCode ? businessProfile.areaCode : '') + businessProfile.phoneNumber;
  $scope.modifyProfile = userConfigurationService.modifyProfile;
  $scope.modifyBusinessEmailAddress = userConfigurationService.modifyBusinessEmailAddress;
  $scope.myProfile = myProfile;
  $scope.businessProfile = businessProfile;
  $scope.cellCarriers = cellCarriers;
  $scope.validationErrors = {};
  $scope.changePasswordForm = {
    toggle: false
  };

  if ($scope.myProfile.carrierCode) {
    $scope.myProfile.carrierCode = {
      cellCarrierCode: $scope.myProfile.carrierCode
    };
  }

  $scope.saveChanges = function () {
    $scope.validationErrors = {};
    globalAlertService.clearErrors();

    var profileCopy = angular.copy($scope.myProfile);
    if (profileCopy.carrierCode) {
      profileCopy.carrierCode = profileCopy.carrierCode.cellCarrierCode;
    }

    contactsResource.assignedContact.update(
      profileCopy,
      function () {
        globalAlertService.addSuccess('mydealer.ui.profile.saved');
      },
      function (response) {
        globalAlertService.handleErrorResponse(response, $scope.validationErrors, $scope.contact);
      }
    );
  };

  $scope.toggleChangePasswordForm = function (state) {
    $scope.changePasswordForm.toggle = (state ? state : !$scope.changePasswordForm.toggle);
  };

}]);
