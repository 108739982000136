'use strict';

intellidealerAppPartsModule.controller('komatsuMyDealerController', function ($scope, $rootScope, $sce, $state, $stateParams, url, shoppingCartService) {

  window.open(url.url, '_blank');

  window.addEventListener('message', goToCartFromKomatsu);
  function goToCartFromKomatsu(event){
    var data = JSON.parse(event.data);
    if(data.cartId){
      shoppingCartService.catchACartUpdateGeneric(data.cartId).then(function () {
          $state.go('navigation.shopping.shoppingCart');
      });
    }
  }
  $rootScope.$on('$stateChangeSuccess', function (event, toState, toStateParams, fromState) {
    if(toState.name !== "navigation.parts.komatsu"){
      window.removeEventListener('message', goToCartFromKomatsu);
    }
  });

});
