'use strict';

intellidealerAppCoreModule.factory('quotesResource', function ($resource, appConfigurationService) {

  var resource = {};
  resource.assignedQuotes = $resource(appConfigurationService.basePath + '/rest/quotes/assignedQuotes');

  resource.allAssignedQuotes = function (params) {

    return $resource(
      appConfigurationService.basePath + '/rest/quotes/allAssignedQuotes',
      params,
      {
        get: {
          method: 'GET',
          url: appConfigurationService.basePath + '/rest/quotes/allAssignedQuotes',
          responseType: 'arraybuffer',
          interceptor: {
            response: function (response) {
              let decoder = new TextDecoder("utf-8");
              return decoder.decode(response.data);
            }
          }
        }
      }
    );
  };

  resource.quoteListAsZip = $resource(
    appConfigurationService.basePath + '/rest/quotes/quoteListAsZip',
    {},
    {
      get: {
        method: 'POST', //even though the intention was to GET, the complex transaction requires a POST
        url: appConfigurationService.basePath + '/rest/quotes/quoteListAsZip',
        responseType: 'arraybuffer',
        interceptor: {
          response: function (response) {
            if (response.status === 200) {
              var blob = new Blob([response.data], {type: 'application/zip;'});

              saveAs(blob, 'quotes.zip');
              //TODO: this is an alternative approach that might work better with Safari on IOS.  Test which we should use.
              //var URL = window.URL || window.webkitURL;
              //var downloadUrl = URL.createObjectURL(blob);
              //window.location = downloadUrl;
            }
          }
        }
      }
    }
  );
  resource.myDealerQuoteEmail = $resource(appConfigurationService.basePath + '/rest/quotes/myDealerQuoteEmail');
  resource.myDealerAcceptOrRequoteRequestEmail = $resource(appConfigurationService.basePath + '/rest/quotes/myDealerAcceptOrRequoteRequestEmail');

  resource.convertBytes = function (byteArray) {
    const binaryString = window.atob(byteArray);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  function getDateStamp() {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var monthStr = month + 1 < 9 ? "0" + (month + 1) : month + 1;
    var dayStr = day < 9 ? "0" + (day) : day;
    var stamp = year + '_' + monthStr + '_' + dayStr;
    return stamp;
  }

  resource.csv = function (parameters) {
    return $resource(
      appConfigurationService.basePath + '/rest/quotes/assignedQuotesXls',
      parameters,
      {
        get: {
          method: 'GET',
          url: appConfigurationService.basePath + '/rest/quotes/assignedQuotesXls',
          interceptor: {
            response: function (response) {
              if (response.status === 200) {
                var blob = new Blob([resource.convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob, 'quotes' + '.xls');
                } else {
                  var fileUrl = URL.createObjectURL(blob);
                  var downloadLink = document.createElement('a');
                  downloadLink.href = fileUrl;
                  downloadLink.download = 'MyQuotes_' + getDateStamp() + '.xls';
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                }
              }
            }
          }
        }
      }
    );
  };
  return resource;
});

