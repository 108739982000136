'use strict';

// Executes an angular expression when the input is blurred out of after changing (JQuery's .change)
// Once we move to angular 1.3, this can go away by setting the model to only update on blur with ng-model-options.
//TODO: We're now on angular 1.3, so this can change.

intellidealerAppCoreModule.directive('changedBlur', function () {
    return {
        restrict: 'A',
        link: function link(scope, element, attrs) {
            $(element).change(function(){
                scope.$eval(attrs.changedBlur);
            });
        }
    };
});
