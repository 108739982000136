'use strict';

intellidealerAppCoreModule.service('recommendedServiceService', ['$q', 'serviceAgreementsResource', function($q, serviceAgreementsResource) {
  var programs = [];
  var schedule = [];
  
  this.populatePrograms = function(make, model) {
    var deferred = $q.defer();
    serviceAgreementsResource.serviceAgreementProgramsByMakeModel.query(
      {
        make: make,
        model: model
      },
      function (value) {
        if (value) {
          programs = value;
        }
        deferred.resolve();
      },
      function() {
        programs = [];
        deferred.resolve();
      }
    );
    return deferred.promise;
  };
  
  this.populateSchedule = function(make, model, program, usageHours, startHours, startDate) {
    var deferred = $q.defer();
    serviceAgreementsResource.serviceAgreementScheduleByMakeModelProgram.query(
      {
        make: make,
        model: model,
        program: program,
        estimate: (usageHours ? usageHours : 7),
        startingHours: (startHours ? startHours : 0),
        hourReadingDate: (startDate ? startDate : Date.now())
      },
      function (value) {
        if (value) {
          schedule = value;
        }
        deferred.resolve();
      },
      function() {
        schedule = [];
        deferred.resolve();
      }
    );
    return deferred.promise;
  };
  
  this.getPrograms = function() {
    return programs;
  };
  
  this.getSchedule = function() {
    return schedule;
  };
  
}]);
