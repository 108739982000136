'use strict';

intellidealerAppPartsModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.parts.ariPartstream', {
      url: '/ari-partstream',
      templateUrl: 'app/scripts/modules/parts/views/ari-partstream.html',
      controller: 'AriPartstreamController',
      resolve: {
        help: function () {
          return 'Parts-_AriPartstream.htm';
        },
        breadcrumb: function($filter, language) { // jshint ignore:line
          return ($filter('language')('mydealer.ui.ariPartstream.breadcrumb'));
        },
        prefixList: function(oemResource){
          return oemResource.ariPrefixList.get();
        },
        loadKey: function(sessionService, oemResource, locationService, globalAlertService) {
          let key = localStorage.getItem("_ariPartstreamKey");
          if(!key && sessionService.getFeatures()['PFW.6421']){
            oemResource.ariPartstreamKey.get({'userLocation': locationService.getDefaultLocation()},
              function () {}, globalAlertService.handleErrorResponse).$promise.then(function (value) {
              if(value && value.string) {
                localStorage.setItem('_ariPartstreamKey', value.string);
              }
            });
          }
          return null;
        }
      }
    }
  );
}]);
