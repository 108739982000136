'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.myEquipment.myEquipmentDetails.openWorkOrders', {
      url: '/open-work-orders' +
      '?openWorkOrdersPagination',
      params: {
        openWorkOrdersPagination: angular.toJson({
          sort: 'workOrderId.workOrderNumber',
          direction: 'asc'
        })
      },
      templateUrl: 'app/scripts/modules/equipment/views/open-work-orders.html',
      controller: 'OpenWorkOrdersController',
      resolve: {
        
        openWorkOrdersPagination: ['$stateParams', function($stateParams) {
          return angular.fromJson($stateParams.openWorkOrdersPagination);
        }],
        
        workOrders: [
          'pageableService', 
          'workOrdersResource', 
          'globalAlertService', 
          '$stateParams', 
          '$filter', 
          'openWorkOrdersPagination',
          function(pageableService, workOrdersResource, globalAlertService, $stateParams, $filter, openWorkOrdersPagination) {
            var parameters = {
              stockNumber: $filter('pathParam')($stateParams.stockNumber)
            };
            pageableService.addPageableParameters(
              parameters,
              openWorkOrdersPagination
            );

            return workOrdersResource.assignedWorkOrdersByStockNumber.get(
              parameters, 
              function () {}, 
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ]
      }
    }
  );
}]);
