'use strict';

intellidealerAppShoppingCartModule.controller('ShoppingCartCheckoutCompleteController', function ($scope, $rootScope, $stateParams, $state) {

  $scope.orderSummary = {
    company: $stateParams.company,
    division: $stateParams.division,
    location: $stateParams.location,
    referenceNumber: $stateParams.referenceNumber,
    salesOrderNumber: $stateParams.salesOrderNumber,
    customerNumber: $stateParams.customerNumber
  };

  $scope.viewOrderDetails = function() {
    var parameters = {
      company: $scope.orderSummary.company,
      division: $scope.orderSummary.division,
      location: $scope.orderSummary.location,
      referenceNumber: $scope.orderSummary.referenceNumber,
      salesOrderNumber: $stateParams.salesOrderNumber
    };
    $state.go('navigation.parts.previousOrders.previousOrdersDetails', parameters);
  };

  $scope.done = function () {
    if (!$rootScope.isPublicState) {
      $state.go('navigation.dashboard');
    } else {
      $state.go('public.parts.shopForParts');
    }
  };

});
