'use strict';

intellidealerAppCoreModule.directive('validEmail', [function() {
return {
  restrict: 'A',
  require: 'ngModel',
  link: function (scope, element, attrs, required) {
    required.$validators.email = function(modelValue, viewValue) {
      if(!modelValue) {
        return true;
      }
      var emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$/;
      if(emailRegex.test(viewValue)) {
        return true;
      }
      return false;
    }
  }
}
}]);
