'use strict';

intellidealerAppCoreModule.directive('dropdownTabs', ['$state', '$rootScope', function($state, $rootScope) {
  return {
    restrict: 'E',
    scope: {
      linkedStates: '='
    },
    transclude: true,
    template: '<div class="dropdown-tabs-wrapper" data-ng-show="linkedStates.length > 1"><label data-ng-transclude></label> <select class="form-control sorting-dropdown-select" data-ng-model="currentState" data-ng-options="(linkedState.title | language) for linkedState in linkedStates" data-ng-change="viewchange(currentState);"></select></div>',
    link: function (scope) {

      function matchCurrentState () {
        scope.linkedStates.forEach(function(current) {
          if ($state.current.name.indexOf(current.state) === 0) {
            scope.currentState = current;
          }
        }); 
      }
      matchCurrentState();

      $rootScope.$on(
        '$stateChangeSuccess', 
        function(){
          matchCurrentState();
        }
      );

      scope.viewchange = function (changedState) {
        $state.go(changedState.state);
      };
    }
  };
}]);

