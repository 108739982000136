'use strict';

intellidealerAppCoreModule.factory('partPricesResource', function ($resource, appConfigurationService) {
  var resource = {};
  resource.myDealerPartPriceCombinedList= $resource(appConfigurationService.basePath + '/rest/partprices/myDealerPartPriceCombinedList/')

  resource.convertBytes = function (byteArray) {
    const binaryString = window.atob(byteArray);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };
  function getDateStamp(){
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    var monthStr = month +1 < 9 ? "0"+(month+1) : month+1;
    var dayStr = day < 9 ? "0"+(day) : day;
    var stamp = year + '_' + monthStr + '_' + dayStr;
    return stamp;
}
  resource.csv = function (parameters) {
    return $resource(
      appConfigurationService.basePath + '/rest/partprices/exportAssignedShopForPartCsv/',
      parameters,
      {
        get: {
          method: 'GET',
          url: appConfigurationService.basePath + '/rest/partprices/exportAssignedShopForPartCsv/',
          interceptor: {
            response: function (response) {
              if (response.status === 200) {
                var blob = new Blob([resource.convertBytes(response.data.data)], {type: 'application/vnd.ms-excel'});
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob, 'partprices' + '.xls');
                } else {
                  var fileUrl = URL.createObjectURL(blob);
                  var downloadLink = document.createElement('a');
                  downloadLink.href = fileUrl;
                  downloadLink.download = 'ShopForParts' +'_'+getDateStamp()+ '.xls';
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                }
              }
            }
          }
        }
      }
    );
  };






  return resource
});
