'use strict';

intellidealerAppPartsModule.controller('AriPartstreamController', function ($scope, $rootScope, $sce, $state, $stateParams, oemResource,
                                                                            shoppingCartService, prefixList
) {


  $scope.prefixList = prefixList;

  window.addEventListener('message', addARIPartsToCart);
  function addARIPartsToCart(event) {
    if (event.data && event.data) {
      var ariData = {};
      event.data.split('&').forEach(function(i) {
        var token = i.split('=');
        ariData[token[0]] = decodeURIComponent(token[1]);
      });
      if(ariData.arisku){

        //this log statement is deliberately left in the code as ARI has a habit of randomly
        //changing the parameters they send us and it will be impossible to debug what is going
        //wrong for a dealer without this
        console.log(ariData);

        //description logic
        var description = '';
        if(ariData.aridescription){
          description = ariData.aridescription.replace(new RegExp("\\+","g"),' ');
        }
        //manufacturer logic
        var manufacturer = '';
        if(ariData.aribrandname){
          manufacturer = ariData.aribrandname.replace(new RegExp("\\+","g"),' ');
        }

        //brand prefix logic
        var partNumber = ariData.arisku;
        var ariBrandCode = ariData.aribrand ? ariData.aribrand : ariData.aribrandcode;
        if(ariBrandCode && $scope.prefixList){
          $scope.prefixList.forEach(function(prefixMapping) {
            if (prefixMapping.ariBrand === ariBrandCode) {
              partNumber = prefixMapping.prefix + partNumber;
            }
          });
        }

        shoppingCartService.addItem(ariData.ariqty, partNumber, null, true, manufacturer, description);
      }
    }
  }

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toStateParams, fromState) {
    if(toState.name !== "navigation.parts.ariPartstream"){
      window.removeEventListener('message', addARIPartsToCart);
    }
  });

  $scope.apiKey = localStorage.getItem("_ariPartstreamKey");
  $scope.keyConfiguredForLocation = $scope.apiKey;

});
