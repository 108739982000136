'use strict';

intellidealerAppCoreModule.directive('fullCellHref', [function() {
  return {
    restrict: 'A',
    transclude: true,
    scope: {
      fullCellHref: '@fullCellHref',
    },
    template: '<a class="table-full-cell-href" data-ng-href="{{fullCellHref}}" target="_blank" data-ng-transclude></a>',

    link: function (scope, element) {
      element.addClass('table-full-cell-href');
    }
  };
}]);