'use strict';

intellidealerAppPartsModule.controller('PreviousOrdersController', function ($scope, $state, $stateParams, $filter, previousOrders, PartsTopLevelTabsService,onlinePartsOrdersResource) {

  $scope.linkedStates = PartsTopLevelTabsService;
  $scope.previousOrders = previousOrders;

  $scope.sortChoices = [
    {label:$filter('language')('mydealer.ui.my_orders.date_ordered'), property:'dateOpened', direction: 'desc',isHidden: false},
    {label:$filter('language')('mydealer.ui.my_orders.sales_order_number'), property:'partInvoiceOnlineOrderCombinedId.salesOrderNumber', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.my_orders.reference_number'), property:'referenceNumber', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.my_orders.description'), property:'description', direction: 'asc',isHidden: false},
    {label:$filter('language')('mydealer.ui.my_orders.status'),isHidden: false},
    {label:$filter('language')('mydealer.ui.my_orders.line_items'), align: 'right',isHidden: false},
    {}
  ];

  if(!sessionStorage.getItem("sortChoices")){
    sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
  }else {
    $scope.sortChoices = JSON.parse(sessionStorage.getItem("sortChoices"));
  }


  $scope.columns = [];

  $scope.sortChoices.forEach(choice => {
    if(choice.label){
      $scope.columns.push({label:choice.label, isSelected:true});
    }
  });
  if(!sessionStorage.getItem("columns")){
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
  }else {
    $scope.columns = JSON.parse(sessionStorage.getItem("columns"));
  }
  $scope.toggleColumns = function(col){
    let index = $scope.columns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.columns[index].isSelected = !$scope.columns[index].isSelected;
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
    let sortIndex = $scope.sortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if(sortIndex >=0){
      $scope.sortChoices[sortIndex].isHidden = !$scope.columns[index].isSelected;
      sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
    }

  }

  $scope.isColVisible = function(colName){
    let index = $scope.columns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.columns[index].isSelected;
  }

  $scope.colIndex = function(index){
    return $scope.columns[index].label;
  }

  $scope.terms = {
    keywords:  $stateParams.previousOrdersSearch
  };

  $scope.search = function(event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    var params = {};
    var sortParams = {};
    sortParams = angular.fromJson($stateParams.previousOrdersPagination);
    if (property && direction) {
      sortParams.sort = property;
      sortParams.direction = direction;
    }
    sortParams.page = 0;
    params.previousOrdersPagination = angular.toJson(sortParams);

    params.previousOrdersSearch = $scope.terms.keywords;
    $state.go('.', params);
  };

  $scope.getDetails = function(selectedOrder) {
    if(selectedOrder.type === "AssignedPartInvoiceDTO") {
      $state.go('navigation.parts.previousOrders.previousOrdersDetails',
        {
          company: selectedOrder.partInvoiceId.company,
          division: selectedOrder.partInvoiceId.division,
          location: selectedOrder.partInvoiceId.location,
          salesOrderNumber: selectedOrder.partInvoiceId.salesOrderNumber
        });
    }
    else {
      $state.go('navigation.parts.previousOrders.previousOnlineOrdersDetails',
        {
          company: selectedOrder.onlineOrderId.company,
          division: selectedOrder.onlineOrderId.division,
          location: selectedOrder.onlineOrderId.location,
          referenceNumber: selectedOrder.referenceNumber
        });
    }
  };

  $scope.getCsv = function () {
    var parameters = {
      historicalOrders: true,
      keywords: $stateParams.previousOrdersSearch ? $stateParams.previousOrdersSearch : ''
    };
    onlinePartsOrdersResource.exportCsvData.csv(parameters).get(
       null,
       function () {
       },
       function (response) {
         globalAlertService.addError('mydealer.ui.invoices.pdf_notGenerated');
       }
     );
  };

});
