'use strict';

intellidealerAppCoreModule.service('scrollbarSizeService', function() {
  
  var width = 0;
  var height = 0;
  
  this.getScrollbarWidth = function() {
    return width;
  };
  
  this.getScrollbarHeight = function() {
    return height;
  };
  
  function calculateSizes() {
    // create a div with scrollbars, outside of viewing area
    var div = angular.element('<div></div>');
    div.css({
      'width': '100px',
      'height': '100px',
      'overflow': 'scroll',
      'position': 'absolute',
      'top': '-9999px'
    });
    
    // add div to the dom so it can be measured
    angular.element(document.body).append(div);
    
    // get the scrollbar width and height
    if (div[0]) {
      width = div[0].offsetWidth - div[0].clientWidth;
      height = div[0].offsetHeight - div[0].clientHeight;
    }
    
    // remove the div
    div.remove();
  }
  
  calculateSizes();
  
});
