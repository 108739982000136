'use strict';

intellidealerAppCoreModule.directive('emailForm', function($anchorScroll, $location) {
  return {
    restrict: 'E',
    templateUrl: 'app/scripts/modules/core/views/templates/email-form.html',
    scope: {
      sendFunction: '&',
      ngIf: '=',
      emailAddress: '=',
      emailBody: '=',
      sendDisableToggle: '='
    },
    link: function (scope, element, attrs) {
      scope.isBody = !!attrs.emailBody;

      scope.cancel = function() {
        $location.hash('');
        $anchorScroll();
        scope.ngIf = false; 
      };

      scope.preventEnterKey = function(event) {
        if (event) {
          event.preventDefault(); // prevent default form submit (IE10)
        }
      };

      $location.hash('emailForm');
      $anchorScroll();
    }
  };
});
