'use strict';

/*
  Validation of currency numbers
*/
intellidealerAppCoreModule.directive('validCurrency', [function() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, required) {
      required.$validators.currency = function(modelValue, viewValue) {
        if (!modelValue) {
          return true;
        }

        var currencyRegex = /^-?\d+(?:\.\d{0,2})?$/;

        if(element.attr("decimalplaces")) {
          var stringRegex = "^-?\\d+(?:\\.\\d";
          stringRegex += "{0," + element.attr("decimalplaces") + "})?$";
          currencyRegex = new RegExp(stringRegex);
        }
        else {
          currencyRegex = /^-?\d+(?:\.\d{0,2})?$/;
        }


        if (currencyRegex.test(viewValue)) {
          return true;
        }
        return false;
      };

      required.$validators.currencyRange = function(modelValue, viewValue) {
        if(!modelValue) {
          return true;
        }
        var maxvalue, minvalue;
        if( element.attr("maxvalue") ) {
          maxvalue = parseFloat( element.attr("maxvalue") );
          if ( parseFloat(viewValue) > maxvalue)  {
            return false;
          }
        }
        if(element.attr("minvalue")) {
          minvalue = parseFloat( element.attr("minvalue") );
          if(parseFloat(viewValue) < minvalue) {
            return false;
          }
        }
        return true;
      };
    }
  };
}]);

