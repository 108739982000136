'use strict';

intellidealerAppPartsModule.controller('shopForPartsController', function ($scope, $uibModal, $rootScope, $state, $stateParams, $filter,
                                                                           $window, shopForParts, locationService, publicModeService,
                                                                           PartsTopLevelTabsService, partCrossReferencesResource,
                                                                           partSubsResource, customerPartCrossReferencesResource,
                                                                           shoppingCartService, shopForPartsFilters,
                                                                           globalAlertService, $timeout, $location, $anchorScroll,
                                                                           partsResource, userConfiguration,
                                                                           customizedMessagesService, deereUrl, sessionService,
                                                                           partRemanufacturedResource, partRelatedResource, partPricesResource, pageableService, shopForPartsPagination, oemResource) {
  $scope.showAvailability = userConfiguration.displayAvailability !== 'NONE';
  $scope.showPrice = userConfiguration.displayPrices;
  $scope.showVendor = !userConfiguration.suppressVendorNumber;
  $scope.displayMyKomatsu = userConfiguration.displayMyKomatsuLink && !$rootScope.isPublicState;
  $scope.displayPartsCatalog = userConfiguration.displayPartsCatalogLink;
  $scope.displayCnhDsp = sessionService.getFeatures()['PFW.6414'];
  $scope.displayMacDon = sessionService.getFeatures()['PFW.6282'];
  $scope.displayAriPartstream = false;
  $scope.shoppingCart = shoppingCartService.cart;
  $scope.linkedStates = PartsTopLevelTabsService;
  $scope.shopForParts = shopForParts;
  $scope.userLocations = {};
  $scope.cityOrPostalCode = '';
  $scope.assignedLocations = locationService.getAssignedLocations();
  $scope.modalInstance = {};
  $scope.publicMode = ($rootScope.isPublicState && !$rootScope.guestLoggedin);
  $scope.partRelatedResource = partRelatedResource.myDealerCustomerPartRelatedDetailsByPartAndLocation;
  $scope.updateWarning = false;

  var showWarning;
  var userLocation = '';


  if ($rootScope.isPublicState) {
    $scope.linkedStates = $scope.linkedStates.slice(0, 1);
  }

  $scope.$watchCollection('shoppingCart.items', function (newValues) {
    $scope.related($scope.shoppingCart.items);
    $scope.cartBadgeNumber = 0;
    angular.forEach(newValues, function (value) {
      $scope.cartBadgeNumber += value.quantity;
    });
  });

  $scope.userLocations.location = locationService.getDefaultLocation();
  locationService.setDefaultLocation($scope.userLocations.location);

  $scope.email = {};
  $scope.customizedMessage = null;
  $scope.deereUrl = deereUrl.url;

  $scope.sortChoices = [
    {}, //multimedia
    {label: $filter('language')('mydealer.ui.parts.part_number'), property: 'partNumber', direction: 'asc'},
    {label: $filter('language')('mydealer.ui.parts.description'), property: 'description', direction: 'asc'}
  ];
  if ($scope.showVendor) {
    $scope.sortChoices.push({
      label: $filter('language')('mydealer.ui.parts.vendor'),
      property: 'vendorName',
      direction: 'asc'
    });
  }
  if ($scope.showAvailability) {
    $scope.sortChoices.push({label: $filter('language')('mydealer.ui.parts.locationAvailability'), align: 'right'});
    $scope.sortChoices.push({label: $filter('language')('mydealer.ui.parts.totalAvailability'), align: 'right'});
  }
  if ($scope.showPrice) {
    $scope.sortChoices.push({label: $filter('language')('mydealer.ui.parts.price'), align: 'right'});
  }
  $scope.sortChoices.push({});  //add to cart

  $scope.terms = {
    keywords: $stateParams.shopForPartsSearch
  };

  $scope.filters = shopForPartsFilters || {};
  $scope.showFilters = !!($scope.filters.description);

  $scope.toggleFilters = function () {
    $scope.showFilters = !$scope.showFilters;
  };

  // Don't enable the shopping cart button if no location is selected
  // Would only be an issue in public mode
  $scope.locationSelected = null;
  if ($scope.userLocations.location) {
    $scope.locationSelected = 'Y';
  }

  $scope.colIndex = function(index) {
    return $scope.columns[index].label;
  }

  $scope.getColIndex = function(name){
    if(name=='vendor'){
      return 2;
    }

    if(name=='location'){
      if($scope.showVendor){
        return 3;
      }else{
        return 2;
      }
    }

    if(name=='total'){
      if($scope.showVendor){
        return 4;
      }else{
        return 3;
      }
    }

    if(name=='price'){
      if($scope.showVendor){
        if($scope.showAvailability){
          return 5;
        }
        return 3;
      }else{
        if($scope.showAvailability){
          return 4;
        }
        return 2;
      }
    }
  }


  $scope.setDefaultLocation = function () {
    publicModeService.clearDeereDealerNumber();
    locationService.setDefaultLocation($scope.userLocations.location);
    $state.reload($state.current);
  };

  $scope.showSortedLocations = function () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        userLocation = `${position.coords.latitude},${position.coords.longitude}`;
        locationService.populateAssignedLocations($scope.publicMode, userLocation).then(function () {
          $scope.assignedLocations = locationService.getAssignedLocations();
          localStorage.setItem('latLongValue', userLocation);
          $scope.cityOrPostalCode = '';
          locationService.clearCityPostalCode();
          globalAlertService.addSuccess('mydealer.ui.parts.userLocation.success');
        });
      }, function (error) {
        locationService.showError(error)
      });
    } else {
      globalAlertService.addError('mydealer.ui.parts.userLocation.permission_denied_error');
    };
  }

  $scope.showLocationsOnMap = function () {
    $scope.modalInstance = $uibModal.open({
      templateUrl: 'app/scripts/modules/parts/geolocation/home/geolocation.html',
      controller: 'geolocationController',
      scope: $scope,
      size: 'lg',
      animation: true,
      windowClass: 'geoLocation-modal',
      keyboard: false,
      backdrop: true,
    });
  }

  //To capture map marker click
  $scope.$on('$setMarkerClickDefaultLocation', function (event, data) {
      $scope.userLocations.location = data && data.location;
      $scope.locationSelected = 'Y';
      locationService.setDefaultLocation($scope.userLocations.location);
      globalAlertService.addSuccess('mydealer.ui.parts.map.location');
    }
  );

  $scope.getLatLongByAddress = function () {
    locationService.getGeoLocationForAddress($scope.publicMode, $scope.cityOrPostalCode).then(function (response) {
      $scope.assignedLocations = locationService.getAssignedLocations();
      $scope.cityOrPostalCode = locationService.getCityOrPostalCode();
    });
  };

  //To retain Zip/postal code value
  $scope.$watch('cityOrPostalCode', function (newVal,oldValue) {
    if (newVal === oldValue && locationService.getCityOrPostalCode()) {
      $scope.cityOrPostalCode = locationService.getCityOrPostalCode();
    }
  });
  if(!sessionStorage.getItem("sortChoices")){
    sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
  }else {
    $scope.sortChoices = JSON.parse(sessionStorage.getItem("sortChoices"));
  }

  $scope.columns = [];

  $scope.sortChoices.forEach(choice => {
    if(choice.label && choice.label != $filter('language')('mydealer.ui.parts.priceIncludesCore')){
      $scope.columns.push({label:choice.label, isSelected:true});
    }
  });

  if(!sessionStorage.getItem("columns")){
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
  }else {
    $scope.columns = JSON.parse(sessionStorage.getItem("columns"));
  }
  $scope.toggleColumns = function(col){
    let index = $scope.columns.findIndex(column => {
      return column.label === col.label;
    });
    $scope.columns[index].isSelected = !$scope.columns[index].isSelected;
    sessionStorage.setItem("columns",JSON.stringify($scope.columns))
    let sortIndex = $scope.sortChoices.findIndex(sortChoice => {
      return sortChoice.label === col.label;
    });
    if(sortIndex >=0){
      $scope.sortChoices[sortIndex].isHidden = !$scope.columns[index].isSelected;
      sessionStorage.setItem("sortChoices",JSON.stringify($scope.sortChoices))
    }

  }
  $scope.isColVisible = function(colName){
    let index = $scope.columns.findIndex(column => {
      return column.label === colName;
    });
    return $scope.columns[index].isSelected;
  }
  $scope.search = function (event, property, direction) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    if ($scope.filtersform.$valid) {
      var params = {};

      var sortParams = angular.fromJson($stateParams.shopForPartsPagination);
      if (property && direction) {
        sortParams.sort = property;
        sortParams.direction = direction;
      }
      sortParams.page = 0;
      params.shopForPartsPagination = angular.toJson(sortParams);
      params.shopForPartsFilters = angular.toJson($scope.filters);
      params.shopForPartsSearch = $scope.terms.keywords;
      $state.go('.', params);
    }
  };

  function fetchFromResource(part, resource, defaultLocation) {
    var param = {
      partNumber: part.partNumber,
      defaultLocation: defaultLocation
    };
    resource.query(param,
      function (crossReferencedParts) {
        crossReferencedParts.forEach(function (value) {
          if(resource === $scope.partRelatedResource){
            value.isRelatedPart = true;
            value.mandatory = !value.prompt;
            if(part.cartItem) {
              value.relatedQuantity = value.relatedPartQuantity * part.cartItem.quantity;
            }
          }else{
            value.marked = true;
            value.mandatory = false;
            if(part.relatedQuantity){
              value.relatedQuantity = part.relatedQuantity;
            }
          }
        });
        if($scope.fetchedRelated && $scope.fetchedRelated.length >0)
        {
          var relatedIndex  = $scope.fetchedRelated.reduce(function (value, currentObj, index) {
            if (currentObj === part) {
              currentObj.expandedsub = true;
              return index
            }
            return value;
          }, 0);
          Array.prototype.splice.apply($scope.fetchedRelated, [relatedIndex + 1, 0].concat(crossReferencedParts));
        } else {
          $scope.fetchedRelated = crossReferencedParts;
          $scope.fetchedRelatedIndex = $scope.shopForParts.content.reduce(function (value, currentObj, index) {
            currentObj.expanded = false;
            if (currentObj === part) {
              currentObj.expanded = true;
              return index;
            }
            else{
              currentObj.expanded = false;
              currentObj.expandedsub = false;
              currentObj.expandedrelated = false;
            }
            return value;
          }, 0);
        }
      },
      globalAlertService.handleErrorResponse
    );
  }
  $scope.toggleSubs = function (part) {
    part.expandedsub = false;
    $scope.resetRows(part);
  };
  $scope.resetRows = function(part)
  {

    if(part.isRelatedPart)
    {

      if($scope.inRelated) {
        var inSub = false;
        $scope.fetchedRelated = $scope.fetchedRelated.filter(function (value) {
          if(value === part)
          {
            inSub =true;
          }
          else if(inSub && !value.isRelatedPart)
          {
            return false;
          }
          else{
            inSub =false;
          }
          return true;

        });
      }
    }

    else{
      $scope.fetchedRelated = [];
    }
  };

  $scope.resetQuantity = function (parts) {
    parts.forEach(function (value) {
      value.expandedsub = false;
      value.expandedrelated = false;
      $scope.fetchedRelated = [];
    });
  };

  $scope.relatedList = function () {
    $scope.relatedPartList =[];
    if($scope.shopForParts && $scope.shopForParts.content){
      $scope.shopForParts.content.forEach(function (value) {
        if(value.expandedrelated == true)
        {
          $scope.relatedPartList.push(value.partNumber);
        }
      });
    }

    return $scope.relatedPartList;
  };

  $scope.crossList = function () {
    $scope.crossPartList =[];
    if($scope.shopForParts && $scope.shopForParts.content){
      $scope.shopForParts.content.forEach(function (value) {
        if(value.expandedsub == true)
        {
          $scope.crossPartList.push(value.partNumber);
        }
      });
    }

    return $scope.crossPartList;
  };

  $scope.subList = function () {
    $scope.subPartList = [];
    if($scope.fetchedRelated){
      $scope.fetchedRelated.forEach(function (value) {
        if (value.expandedsub == true) {
          $scope.subPartList.push(value.partNumber);
        }
      });
    }

    return $scope.subPartList;
  };

  $scope.fetchRelated = function (part, collapse) {
    if (collapse) {
      part.expandedsub = false;
      $scope.toggleSubs(part);
      return;
    } $scope.resetRows(part);
    if (part.customerPartCrossReferenceAvailable) {
      fetchFromResource(part, customerPartCrossReferencesResource.myDealerCustomerPartCrossReferencesByPart);
    } else if (part.crossReferenceAvailable) {
      part.expandedsub = true;
      part.expandedrelated = false;
      fetchFromResource(part, partCrossReferencesResource.myDealerPartCrossReferenceDetailsByPartAndLocation, $scope.userLocations.location);
    } else if (part.subsAvailable) {
      part.expandedsub = true;
      part.expandedrelated = false;
      fetchFromResource(part, partSubsResource.myDealerCustomerPartSubDetailsByPartAndLocation, $scope.userLocations.location);
    } else if (part.remanufacturedPartNumber) {
      part.expandedsub = true;
      part.expandedrelated = false;
      fetchFromResource(part, partRemanufacturedResource.myDealerCustomerPartRemanufacturedByPartAndLocation, $scope.userLocations.location);
    }
  };

  $scope.fetchRelatedPart = function (part, collapse) {
    if (collapse) {
      part.expandedrelated = false;
      $scope.toggleSubs(part);
      return;
    }
    $scope.fetchedRelated = [];
    $scope.inRelated=true;
    fetchFromResource(part, $scope.partRelatedResource, $scope.userLocations.location);

    $scope.fetchedRelatedIndex = $scope.shopForParts.content.reduce(function (value, currentObj, index) {
      if (currentObj === part) {
        currentObj.expandedrelated = true;
        currentObj.expandedsub = false;
        return index;
      }
      return value;
    }, 0);
  };


  $scope.showWarning = function (partNumber, partObject) {
    $uibModal.open({
      templateUrl: 'app/scripts/modules/my-account/views/templates/confirmation-view.html',
      controller: 'confirmationController',
      size: "sm",
      animation: false,
      windowClass: 'center-modal',
      keyboard: false,
      backdrop: 'static',
      resolve: {
        properties: function () {
          return {
            labelText: `${'mydealer.ui.global.remove_message'}`,
            buttons: {
              yes: `${'mydealer.ui.global.cancel'}`,
            }
          };
        }
      }
    }).result.then(function(value) {
      if(value === "yes") {
        partObject.quantity = partObject.prevQuantity;
        partObject.updateQuantity = '';
      }
      showWarning = false;
    });
  };

  $scope.related = function(items){
    var relatedNumbers = []
    var mandatoryRelatedNumbers=[];
    items.forEach(item => {
      if (item.relatedPartList && item.relatedPartList.length > 0){
        item.relatedPartList.forEach(relatedPart =>{
          relatedNumbers.push(relatedPart.partNumber);
          if(relatedPart.prompt==false){
            mandatoryRelatedNumbers.push(relatedPart.partNumber)
          }
        })
      }
    })
    $scope.relatedPartNumbers = relatedNumbers;
    $scope.relatedMandatoryPartNumbers = mandatoryRelatedNumbers;
  };
  $scope.addItem = function (quantity, partNumber, event, partObject, index, searchResult, togglerId) {
    $rootScope.togglePopover(togglerId + index);

    if(partObject.relatedAvailable){
      $scope.resetQuantity(searchResult);
    }

    shoppingCartService.addItem(quantity, partNumber, partObject).then(() => $scope.closePopover(togglerId));
    partObject.addedQty = '';
  };

  $scope.updateItem = function (partNumber, quantity, event, partObject, index, searchResult, togglerId) {
    $rootScope.togglePopover(togglerId + index);

    if(partObject.relatedAvailable){
      $scope.resetQuantity(searchResult);
    }

    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }

    quantity = Number(quantity);
    if (quantity === 0) {
      $scope.removeItem(partNumber, partObject, index, searchResult, togglerId);
    }

    if (!isNaN(quantity) && quantity > 0) {
      for (const partList of searchResult) {
        if (partList.relatedAvailable) {
          for (const part of partList.relatedPartList) {
            if (part.partNumber === partNumber && quantity < partList.cartItem.quantity * part.quantity && !part.prompt) {
              showWarning = true;
              break;
            }
          }
        }
      }

      if (showWarning) {
        $scope.updateWarning = true;
        $scope.showWarning(partNumber, partObject);
      } else {
        shoppingCartService.updateItem(partNumber, quantity, partObject).then(() => $scope.closePopover(togglerId));
      }
    }
    partObject.addedQty = '';
  };

  $scope.removeItem = function (partNumber, partObject, index, searchResult, togglerId) {
    $rootScope.togglePopover(togglerId + index);

    if(partObject.relatedAvailable){
      $scope.resetQuantity(searchResult);
    }

    for(const partList of searchResult) {
      if (partList.relatedAvailable) {
        for (const part of partList.relatedPartList) {
          if (part.partNumber === partNumber && !part.prompt){
            showWarning = true;
            break;
          }
        }
      }
    }

    if(showWarning) {
      $scope.updateWarning = false;
      $scope.showWarning(partNumber, partObject);
    }else{
      shoppingCartService.removeOneItem(partNumber, partObject).then(() => $scope.closePopover(togglerId));
    }
    partObject.addedQty = '';
  };

  $scope.closePopover = function (id, index) {
    $timeout(() => angular.element('#' + id + (index ? index : '')).trigger('click'));
  };

  $scope.showEmailForm = function () {
    $scope.email.partNumber = $scope.terms.keywords ? $scope.terms.keywords : '';
    $scope.emailForm = true;
    $location.hash('email-form');
    $anchorScroll();
  };

  $scope.goToCNHDSP = function () {
    $state.go('navigation.parts.cnhDspLanding');
  };
  $scope.goToMacDon = function () {
    $state.go('navigation.parts.macdon');
  };
  $scope.goToKomatsu = function () {
    $state.go('navigation.parts.komatsu');
  };
  $scope.goToAriPartstream = function () {
    $state.go('navigation.parts.ariPartstream');
  };

  $scope.hideEmailForm = function () {
    $scope.emailForm = false;
    $location.hash('');
    $anchorScroll();
  };

  $scope.sendEmail = function () {
    var parts = [];
    parts.push(
      {
        description: $scope.email.description,
        manufacturer: $scope.email.manufacturer,
        partNumber: $scope.email.partNumber,
        quantity: $scope.email.quantity
      });

    partsResource.requestPartList.save({
      myDealerPartRequestDTOList: parts
    }, function () {
      $scope.emailForm = false;
      $scope.email = {
        partNumber: '',
        description: '',
        manufacturer: ''
      };
      globalAlertService.addSuccess('mydealer.ui.global.email_sent');
    }, globalAlertService.handleErrorResponse);
  };

  // get the availability list for the popover contents
  $scope.loadPartAvailability = function (part) {
    if (part && (!part.availability || part.availability <= 0)) {
      return;
    }
    // if already have list, do nothing
    if (part && !part.partAvailabilityList) {
      partsResource.partAvailabilityByPart.query(
        {partNumber: part.partNumber},
        function (value) {
          // if successful, add the list to the part
          part.partAvailabilityList = value;
        },
        function () {
          // if failed, add an empty list to the part
          part.partAvailabilityList = [];
        }
      );
    }
  };

  $scope.toggleCardMenu = function (event, part) {
    if (!part.description) {
      return;
    }
    event.stopPropagation();
    part.isMenuOpen = !part.isMenuOpen;
  };

  $scope.openPartProfile = function (part) {
    if (part.hasMultimedia) {
      var toState = publicModeService.getStateWithPrefix($rootScope.isPublicState, '.parts.shopForParts.partDetail');
      $state.go(toState, {partNumber: part.partNumber, partDetails: part});
    }
  };

  // set the customized message if one is configured
  function loadCustomizedMessage() {
    $scope.customizedMessage = null;
    angular.forEach(customizedMessagesService.getMessages(), function (value) {
      if (value.referenceNumber === 'SHOPFORPARTS' && (!value.location || value.location === userConfiguration.businessLocation)) {
        $scope.customizedMessage = value.description;
      }
    });
  }

  $scope.getShowAriPartstream = function (){
    localStorage.removeItem('_ariPartstreamKey');
    if(sessionService.getFeatures()['PFW.6421']){
      $scope.displayAriPartstream = true;
      oemResource.ariPartstreamKey.get({'userLocation': locationService.getDefaultLocation()},
        function () {}, globalAlertService.handleErrorResponse).$promise.then(function (value) {
        if(value && value.string) {
          localStorage.setItem('_ariPartstreamKey', value.string);
        }
      });
    }
  };
  $scope.getShowAriPartstream();

  loadCustomizedMessage();

  $scope.getCsv = function () {
    let parameters = {
      keywords: $stateParams.shopForPartsSearch,
      partNumber: $stateParams.shopForPartsSearch,
      description: shopForPartsFilters.description,
      defaultLocation: locationService.getDefaultLocation(),
      relatedExportList: $scope.relatedList(),
      subExportList: $scope.subList(),
      crossExportList: $scope.crossList(),
    };
    pageableService.addPageableParameters(
      parameters,
      shopForPartsPagination
    );
    partPricesResource.csv(parameters).get(
      null,
      function () {
      },
      function (response) {
        globalAlertService.addError('mydealer.ui.equipment.csv_notGenerated');
      }
    );
  };
});

