'use strict';

intellidealerAppCoreModule.service('forgotPasswordService', ['$q', 'securityResource', 'globalAlertService', function($q, securityResource, globalAlertService) {
  var password = '';

  this.populatePassword = function(library, key1, key2) {
    var deferred = $q.defer();
    securityResource.retrieveMyDealerPassword.get(
      {
        library: library,
        encryptedKey1: key1,
        encryptedKey2: key2
      },
      function (value) {
        if (value.password) {
          password = value.password;
        }
        deferred.resolve();
      },
      function() {
        password = '';
        deferred.resolve();
      }
    );
    return deferred.promise;
  };

  this.getPassword = function() {
    return password;
  };

  this.resetPassword = function(library, key1, newPassword, verifyPassword) {
    var deferred = $q.defer();
    securityResource.resetMyDealerPassword.get(
      {
        library: library,
        encryptedKey1: key1,
        newPassword: newPassword,
        verifyPassword: verifyPassword
      },
      function () {
        globalAlertService.addSuccess('mydealer.ui.login.reset_password_success');
      },
      globalAlertService.handleErrorResponse
    );
    return deferred.promise;
  };
}]);
