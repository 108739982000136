'use strict';

intellidealerAppMyAccountModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.myAccount.invoices', {
      url: '/invoices?' +
        '&invoicesPagination' +
        '&invoicesFilters' +
        '&forceRefresh' +
        '&{search:any}',
      params: {
        invoicesPagination: angular.toJson({
          sort: 'billingDate',
          direction: 'desc'
        }),
        invoicesFilters: angular.toJson({
          status: 'O'
        })
      },
      templateUrl: 'app/scripts/modules/my-account/views/invoices.html',
      controller: 'InvoicesController',
      resolve: {
        help: function () {
          return 'My_Account-_Invoices.htm';
        },
        breadcrumb: ['$filter', 'language', function ($filter, language) {
          return ($filter('language')('mydealer.ui.invoices.breadcrumb'));
        }],

        invoicesPagination: ['$stateParams', function ($stateParams) {
          return angular.fromJson($stateParams.invoicesPagination);
        }],

        invoicesFilters: ['$stateParams', function ($stateParams) {
          if ($stateParams.invoicesFilters) {
            return angular.fromJson($stateParams.invoicesFilters);
          } else {
            return null;
          }
        }],

        search: ['$stateParams', function ($stateParams) {
          return $stateParams.search;
        }],

        accountSummary: ['customerAccountsResource', 'globalAlertService', function (customerAccountsResource, globalAlertService) {
          return customerAccountsResource.assignedCustomerAccount.get(
            {},
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }],

        searchValues: [
          'invoiceHistoryResource',
          'globalAlertService',
          function (invoiceHistoryResource, globalAlertService) {
            return invoiceHistoryResource.myDealerInvoiceListingSearchValues.get(
              {},
              function () {
              },
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ],


        division1: ['invoiceHistoryResource', 'globalAlertService', "userConfiguration", function (invoiceHistoryResource, globalAlertService, userConfiguration) {
          var parameters = {
            company: userConfiguration.contactId.company,
            division: userConfiguration.contactId.division,
            location: userConfiguration.businessLocation
          };
          return invoiceHistoryResource.getDiivison1(parameters).get(
            {},
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }],

        dealerDivisionSettings: ['invoiceHistoryResource', 'globalAlertService', "userConfiguration", function (invoiceHistoryResource, globalAlertService, userConfiguration) {
          var parameters = {
            company: userConfiguration.contactId.company,
            division: userConfiguration.contactId.division
          };
          return invoiceHistoryResource.dealerDivisionSettings(parameters).get(
            {},
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }],

        paymentAgencies: ['customerARAgenciesResource', 'globalAlertService', function (customerARAgenciesResource, globalAlertService) {
          return customerARAgenciesResource.assignedPaymentAgencies.get(
            {},
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }],

        achPaymentAgencies: ['achPaymentAgencies', 'globalAlertService', function (achPaymentAgencies, globalAlertService) {
          return achPaymentAgencies.achPaymentAgencies.get(
            {},
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }],

        authDotNetProperties: ['jsonPropertiesResource', 'globalAlertService', function (jsonPropertiesResource, globalAlertService) {
          return jsonPropertiesResource.authDotNetProperties.get(
            {
              jsonValueKey: 'AUTHORIZE_DOT_NET_PROPERTIES',
              company: null,
              division: null,
              location: null
            },
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise.then(function (response) {
            return response.jsonData;
          });
        }],

        invoices: [
          'invoiceHistoryResource',
          'invoicesPagination',
          'invoicesFilters',
          'search',
          'userConfiguration',
          function (invoiceHistoryResource, invoicesPagination, invoicesFilters, search, userConfiguration) {
            return invoiceHistoryResource.assignedInvoices(invoicesPagination, invoicesFilters, search, userConfiguration);
          }
        ],

        myProfile: ['contactsResource', 'globalAlertService', function (contactsResource, globalAlertService) {
          return contactsResource.assignedContact.get(
            {},
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }]
      }
    });
}]);
