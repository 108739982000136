'use strict';

intellidealerAppCoreModule.directive('sortable', ['$state', '$stateParams', function($state, $stateParams) {
  return {
    restrict: 'A',
    transclude: true,
    scope: {
      sortable: '@sortable',
      queryParams: '@' //if not specified, the directive will assume [stateName]Pagination as the query parameter to use
    },
    template: '<span data-ng-transclude></span><span class="fa" aria-hidden="true" ' +
    'data-ng-class="{\'fa-sort\': queryParameter.sort !== sortable && sortable, ' +
    '\'fa-sort-asc\': ((queryParameter.sort === sortable) && (queryParameter.direction === \'asc\')), ' +
    '\'fa-sort-desc\': ((queryParameter.sort === sortable) && (queryParameter.direction === \'desc\'))}"></span>',
    controller: ['$scope', '$element', function($scope, $element) {

      $scope.queryParameterName = {};
      if (!$scope.queryParams) {
        $scope.queryParameterName = $element.inheritedData('$uiView').state.self.name.split('.');
        $scope.queryParameterName = ($scope.queryParameterName[$scope.queryParameterName.length - 1] + 'Pagination');
      }
      else {
        $scope.queryParameterName = $scope.queryParams;
      }


      $scope.queryParameter = $stateParams[$scope.queryParameterName] ? angular.fromJson($stateParams[$scope.queryParameterName]) : {};
      $scope.sortOn = function() {
        var queryObject = {};
        if ($scope.queryParameter.sort === $scope.sortable && $scope.queryParameter.direction === 'asc'){
          $scope.queryParameter.sort = $scope.sortable;
          $scope.queryParameter.direction = 'desc';
          $scope.queryParameter.page = 0;
          queryObject[$scope.queryParameterName] = angular.toJson($scope.queryParameter);
          return queryObject;
        }
        $scope.queryParameter.sort = $scope.sortable;
        $scope.queryParameter.direction = 'asc';
        $scope.queryParameter.page = 0;
        queryObject[$scope.queryParameterName] = angular.toJson($scope.queryParameter);
        return queryObject;
      };

    }],
    link: function (scope, element) {

      if (scope.sortable) {
        element.bind('click', function() {
          $state.go('.', scope.sortOn());
        });
      }

    }
  };
}]);
