'use strict';

intellidealerAppPartsModule.filter('linkCart', function (shoppingCartService) {
  return function(parts) {

    if (!parts) {
      return parts;
    }

    var modifiedParts = parts.map(function(part){
      delete part.cartItem;
      shoppingCartService.cart.items.forEach(function(cartItem){
        if (angular.equals(cartItem.partNumber, part.partNumber)){
          part.cartItem = cartItem;
        }
      });
      return part;
    });
    return modifiedParts;
  };
});