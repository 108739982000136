'use strict';

intellidealerApp.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/scripts/modules/equipment/views/templates/recommended-service-popover-request-service.html',
'<div class="recommended-service-request" data-ng-form="requestServiceForm">' +
'  <div class="form-group" data-ng-class="{\'has-error\': requestServiceForm[\'requested-date\'].$invalid}">' +
'    <label for="requested-date" class="control-label">{{\'mydealer.ui.recommended_service.requested_date\' | language }}</label>' +
'    <cdk-datepicker name="requested-date" value-model="schedule.requestedDate"></cdk-datepicker>' +
'  </div>' +
'  <div class="form-group" data-ng-class="{\'has-error\': requestServiceForm[\'comment\'].$invalid}">' +
'    <label for="comment" class="control-label">{{\'mydealer.ui.recommended_service.note\' | language }}</label>' +
'     <textarea rows="4" cols="25" data-ng-model="schedule.comment" '+
'               style="resize: none;" ' +
'               name="comment" type="text" class="form-control recommended-service-reuqest-comment" ' +
'               data-ui-keypress="{\'enter\' : \'preventEnterKey($event)\'}">' +
'     </textarea>' +
'  </div>' +
'  <div class="recommended-service-request-buttons">' +
'    <button class="btn btn-primary"' +
'            type="button"' +
'            data-ng-disabled="requestServiceForm.$invalid"' +
'            data-ng-click="scheduleService(schedule)">{{\'mydealer.ui.global.submit\' | language}}</button>' +
'    <button class="btn btn-info" data-ng-click="$root.togglePopover(togglerId);">{{\'mydealer.ui.global.cancel\' | language}}</button>' +
'  </div>' +
'</div>'
);
}]);
