'use strict';

intellidealerAppCoreModule.directive('cdkCarousel', ['$window', '$timeout', function($window, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      slides: '=',
      control: '=',  // adds methods: prev, next, start, stop
      interval: '@'
    },
    link: function(scope) {
      var resolutionToViewableWidgets = {480:1, 768:1, 992:2, 1365:3, 99999: 3};
      var intervalValue = 0;
      var intervalTimer = null;

      scope.carouselState = {
        slidesShown: 0,
        slideIndex: 0
      };

      scope.prevSlide = function() {
        scope.carouselState.slideIndex = scope.carouselState.slideIndex - 1;
        intervalReset();
      };
      scope.nextSlide = function() {
        scope.carouselState.slideIndex = scope.carouselState.slideIndex + 1;
        intervalReset();
      };

      scope.$on('$destroy', function() {
        intervalStop();
      });

      function resize() {
        for (var size in resolutionToViewableWidgets) {
          if($window.innerWidth < size) {
            if (resolutionToViewableWidgets[size] === scope.carouselState.slidesShown) {
              break; 
            }
            scope.carouselState.slidesShown = resolutionToViewableWidgets[size];
            scope.carouselState.slideIndex = (scope.carouselState.slidesShown > (scope.slides.length - scope.carouselState.slideIndex)) ? (scope.slides.length - scope.carouselState.slidesShown) : scope.carouselState.slideIndex;
            if (scope.carouselState.slideIndex < 0) {
              scope.carouselState.slideIndex = 0;
            }
            $timeout(function() {
              scope.$digest();
            }); // jshint ignore:line

            // reset the timer since displayed slides changed
            if (intervalTimer) {
              intervalReset();
            }
            break;
          }
        }
      }

      // functions to handle automatic scrolling, based on the interval
      function intervalStart() {
        if (intervalValue === 0 || isNaN(intervalValue)) {
          return;
        }
        intervalTimer = $timeout(intervalTick, intervalValue);
      }
      function intervalTick() {
        scope.carouselState.slideIndex++;
        if (scope.carouselState.slideIndex > scope.slides.length - scope.carouselState.slidesShown) {
          scope.carouselState.slideIndex = 0;
        }
        intervalTimer = $timeout(intervalTick, intervalValue);
      }
      function intervalStop() {
        if (intervalTimer) {
          $timeout.cancel(intervalTimer);
          intervalTimer = null;
        }
      }
      function intervalReset() {
        intervalStop();
        intervalStart();
      }

      // if a control object is passed in, add methods to it
      if (scope.control) {
        scope.control = {
          prev: scope.prevSlide,
          next: scope.nextSlide,
          start: intervalStart,
          stop: intervalStop
        };
      }
      
      // track the window size changing to adjust index and slidesShown
      angular.element($window).bind('resize', resize);

      // size the carousel once on load
      resize();

      // start the interval timer, if applicable
      intervalValue = (scope.interval ? Number(scope.interval) : 0);
      intervalStart();
    },
    templateUrl: 'app/scripts/modules/core/views/templates/cdk-carousel.html'
  };
}]);
