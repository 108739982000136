'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.myEquipment.myEquipmentDetails.recommendedService', {
      url: '/recommended-service' +
        '?selectedProgram&selectedUsage',
      templateUrl: 'app/scripts/modules/equipment/views/recommended-service.html',
      controller: 'RecommendedServiceController',
      resolve: {
      }
    }
  );
}]);
