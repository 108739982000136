'use strict';

intellidealerAppCoreModule
  .filter('language', function (languageService) {
    function replaceVariables(text, args) {
      var result = text;
      if (args) {
        var j = 0;
        for (var i = 1; i < args.length; i++) {
          if (Array.isArray(args[i])) {
            args[i].forEach(function (arg) {
              result = result.replace(new RegExp('\\{' + (i + j - 1) + '\\}', "g"), arg);
              j++;
            })
          } else {
            result = result.replace(new RegExp('\\{' + (i + j - 1) + '\\}', "g"), args[i]);
          }
        }
      }
      return result;
    }

    /**
     * gets the value for the language variable, by replacing any trailing arguments with '{i}'
     * <pre>
     *     e.g.
     *     for language property of:
     *          test.property=This is a test property. {0} {1} {0}
     *
     *     calling:
     *          language('test.property', 'use wisely.', 'be mindful of the variables;')
     *
     *     will result in:
     *          This is a test property. use wisely. be mindful of the variables; use wisely.
     *
     * </pre>
     */
    function language(id) {
      var text = languageService.getLanguage()[id];
      if (text) {
        return replaceVariables(text, arguments);
      }
      return id;
    }

    language.$stateful = true;

    return language;
  })
  .filter('languageEnum', function ($filter) {
    return function (enumValue, enumName) {
      if (enumValue) {
        var args = Array.from(arguments);
        args.shift();
        args[0] = 'enum.' + enumName + '.' + enumValue;

        return $filter('language').apply(null, args);
      } else {
        return "";
      }
    };
  })
  .filter('languageHyperlink', function ($filter) {

    /**
     * gets the value for the language variable, by replacing any arguments with '{i}' hyperlinks if provided on the same index
     * <pre>
     *     e.g.
     *     for language property of:
     *          test.property=This is a test property. {0} {1} {0}
     *
     *     calling:
     *          language('test.property', ['use wisely.', 'be mindful of the variables;'], ['mailto:wisely@email.com'])
     *
     *     will result in:
     *          This is a test property. &lt;a target="_blank" href="mailto:wisely@email.com">use wisely.&lt;/a> be mindful of the variables; use wisely.
     *
     * </pre>
     */
    function languageHyperlink(id, args, hyperlinks) {
      var messageArgs = [];
      if (args) {
        for (var i = 0; i < args.length; i++) {
          var arg = args[i];
          var hyperlink = hyperlinks && hyperlinks.length > i && hyperlinks[i];


          if (hyperlink) {
            messageArgs.push('<a ' +
              /* Using indexOf here instead of startsWith to support IE (required for login page)*/
              (hyperlink.indexOf('mailto:') === 0  ? '' : 'target="_blank" ') +
              'href="' + hyperlink + '">'
              + $filter('language')(arg)
              + '</a>');
          } else {
            messageArgs.push($filter('language')(arg));
          }
        }
      }
      return $filter('language')(id, messageArgs);
    }

    languageHyperlink.$stateful = true;

    return languageHyperlink;
  });
