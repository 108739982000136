'use strict';

intellidealerAppEquipmentModule.controller('telematicsController',
  function ($scope, $filter, $compile, $state, $stateParams, sessionService, globalAlertService, equipmentResource) {

    $scope.fleet = {};

    //Init
    $scope.initMap = function() {
      equipmentResource.getFleet().get({},
        function(response) {
          $scope.fleet = response;
          $scope.updateMap();
        },
        globalAlertService.handleErrorResponse
      );
    };

    //Update
    $scope.updateMap = function () {
      $scope.map = new google.maps.Map(document.getElementById('telematicsMap'), {});
      $scope.map.setOptions({streetViewControl: false});

      var center = {
        lat: Number($scope.fleet[0].latitude),
        lng: Number($scope.fleet[0].longitude)
      };
      $scope.map.setCenter(center);
      $scope.map.setZoom(7);

      $scope.fleet.forEach(function(entity) {
        //set markers
        var point = {
          lat: Number(entity.latitude),
          lng: Number(entity.longitude)
        };
        var image = $scope.getMarkerPath(entity.gpsCompany);

        var marker = new google.maps.Marker({
          position: point,
          map: $scope.map,
          icon: image
        });

        //popup handling
        var equipmentDetailsPopup = $scope.getPopupDetails(entity);
        var compiled = $compile(equipmentDetailsPopup)($scope);

        var infowindow = new google.maps.InfoWindow({
          content: compiled[0]
        });
        marker.addListener("click", function () {
          infowindow.open($scope.map, marker);
        });
      });
    };

    //Popup Styling
    $scope.getPopupDetails = function (entity) {

      var runningString = '';
      if(entity.engineRunning){
        runningString = '<b class="running">Running</b>';
      } else {
        runningString = 'Not Running';
      }


      return '<div id="content">' +
        '<h2 id="firstHeading" class="details-popup-header">' + entity.stockNumber + '</h2>' +
        '<h3>' + entity.aempIdentifier +'</h3>' +

        '<div class="telematicsPopupBodyContent">' +
        '<div>Model: <b>' + entity.make + ' ' + entity.model + '</b></div>' +
        '<div>S/N: <b>' + entity.machineSerialNumber + '</b></div>' +
        '<div>Data From: <b>' + entity.gpsCompanyName + '</b></div>' +
        '<div>Last Updated: <b>' + $filter('cdkDate')(entity.timestamp) + ' ' + $filter('cdkTime')(entity.timestamp) + '</b></div>' +
        '<div></br></div>' +
        '</div>' +

        '<div class="telematicsBigBoxContent">' +
        '<table>' +
        '<tr>' +
        '<td>' +
        '<b>' + entity.cumulativeOperatingHours + '</b></br>Engine Hours' +
        '</td>' +

        '<td>' +
        '<b>' + entity.odometer + ' ' + entity.odometerUnits + '</b></br>Odometer' +
        '</td>' +
        '</tr>' +
        '</table>' +
        '</div>' +
        '<div></br></div>' +

        '<div class="telematicsTableContent">' +
        '<table>' +
        '<tr>' +

        '<td>' +
        'Fuel Used: <b>' + entity.fuelUsed + ' ' + entity.fuelUsedUnits + '</b>' +
        '</td>' +

        '<td>' +
        'Last 24 Hrs: <b>' + entity.fuelUsed24Hours + ' ' + entity.fuelUsed24HoursUnits + '</b>' +
        '</td>' +

        '</tr>' +
        '<tr>' +

        '<td>' +
        'Load Factor: <b>' + entity.loadFactor + '</b>' +
        '</td>' +

        '<td>' +
        'Load Count: <b>' + entity.loadCount + '</b>' +
        '</td>' +

        '</tr>' +
        '<tr>' +

        '<td>' +
        'Payload Totals: <b>' + entity.payload + ' ' + entity.payloadUnits + '</b>' +
        '</td>' +

        '<td>' +
        runningString +
        '</td>' +

        '</tr>' +
        '</table>' +
        '</div>' +

        "</div>";
    };

    $scope.getMarkerPath = function (gpsCompany) {
      var path = "";
      switch(gpsCompany){
        case 'JDLINK_AEMP_2':
          path = 'images/deere-marker.png';
          break;
        case 'PRECISE':
          path = 'images/precise-marker.png';
          break;
        case 'TITAN':
          path = 'images/titan-marker.png';
          break;
        case 'LIEBHERR':
          path = 'images/liebherr-marker.png';
          break;
        case 'KUBOTA_NOW':
          path = 'images/kubota-marker.png';
          break;
        case 'CNH_SITEWATCH':
          path = 'images/cnh-marker.png';
          break;
        default:
          path = 'images/generic-marker.png';
          break;
      }
      return path;
    }


  });
