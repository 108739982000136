'use strict';

intellidealerAppShoppingCartModule.controller('guestCheckoutPromptController', function ($scope, $rootScope, $uibModalInstance, $state, $stateParams, publicModeService,
                                                                                         loginResource, globalAlertService, securityResource, shoppingCartService,
                                                                                         properties, $timeout, $location, loginService) {
  $scope.forgotForm = {};

  var guestLoginCallback = function () {
    securityResource.customerContactSecurity.get().$promise.then(function (data) {
      $uibModalInstance.close('guestLogin');
      _.merge(properties.userConfiguration, data);
      $state.reload($state.current).then(function () {
        $rootScope.guestLoggedin = true;
        shoppingCartService.mergeGuestCartItems(properties.cartItems, properties.guestOnlineOrderInfo).then(function () {
          publicModeService.clearCartReferenceNumber();
        });
      });
    });
  };

  $scope.dismiss = function (value) {
    globalAlertService.clearErrors();
    if (value === 'guestLogin') {
      if (!$scope.username || !$scope.password) {
        globalAlertService.addError('mydealer.ui.login.username_and_password_invalid');
      } else {
        loginResource.post({
          company: $stateParams.company,
          division: $stateParams.division,    //company and division are passed to verify if user logs in with a different company/division than the public user's company/division
          username: $scope.username,
          password: $scope.password,
          library: $stateParams.library.toUpperCase(),
          system: 'MD',
          guestLogin: true
        }).$promise.then(function () {
          loginService.populateAssignedContacts(guestLoginCallback, $stateParams.company, $stateParams.division);
        }).catch(function (response) {
          globalAlertService.handleErrorResponse(response);
        });
      }
    } else {
      if (value === 'guestCheckout') {
        $state.go('public.shopping.shoppingCart.checkout');
      }
      $uibModalInstance.close(value);
    }
  };

  $scope.togglePopover = function(id) {
    if (!$scope.forgotForm.username) {
      $scope.forgotForm.username = $scope.username;
    }
    var element = angular.element('#' + id);
    $timeout(function() {
      element.trigger('togglePopover');
    });
  };

  $scope.forgotPassword = function() {
    $scope.togglePopover('forgotPasswordLink');

    securityResource.generateMyDealerForgotPasswordEmail.save(
      {
        hostUrl: $location.absUrl().split('#')[0] + '#/forgot-password?',
        library: $stateParams.library.toUpperCase(),
        contactUser: $scope.forgotForm.username,
        contactEmail: $scope.forgotForm.emailAddress
      },
      function() {
        globalAlertService.addSuccess('mydealer.ui.login.password_emailed');
      },
      globalAlertService.handleErrorResponse
    );
  };

});
