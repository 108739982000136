'use strict';

intellidealerAppCoreModule.factory('contactsResource', function ($resource, appConfigurationService) {
  return {
    assignedContact: $resource(appConfigurationService.basePath + '/rest/security/assignedMyDealerContact/', null, {update: {method: 'PUT'}}),
    assignedLocations: $resource(appConfigurationService.basePath + '/rest/security/assignedMyDealerLocations/'),
    alternateContacts: $resource(appConfigurationService.basePath + '/rest/contacts/assignedContacts'),
    updateSessionCustomer: $resource(appConfigurationService.basePath + '/rest/contacts/updateSessionCustomer', null, {update: {method: 'PUT'}})
  }
});
