'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.myEquipment.myEquipmentDetails', {
      url: '/my-equipment-details/{company}/{stockNumber}/{division}',
      views : {
        '@navigation.equipment' : {
          templateUrl: 'app/scripts/modules/equipment/views/my-equipment-details.html',
          controller: 'MyEquipmentDetailsController'
        }
      },
      resolve: {
        help: function () {
          return 'Equipment-_Equipment_Details.htm';
        },
        breadcrumb: ['$stateParams', '$filter',
                     function ($stateParams, $filter) {
                       return $filter('pathParam')($stateParams.stockNumber);
                     }],
        myEquipment: [
          'equipmentResource',
          'globalAlertService',
          '$stateParams',
          '$filter',
          function(equipmentResource, globalAlertService, $stateParams, $filter) {
            return equipmentResource.equipmentDetails.get(
              {
                company: $stateParams.company,
                stockNumber: $filter('pathParam')($stateParams.stockNumber),
                division: $stateParams.division,
              },
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ],
        equipmentNoLongerOwnedStatusSearchValues: [
          'equipmentResource',
          'globalAlertService',
          function(equipmentResource, globalAlertService) {
            return equipmentResource.equipmentNoLongerOwnedStatusSearchValues.get(
              {},
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ],
        equipmentReadyToSellStatusSearchValues: [
          'equipmentResource',
          'globalAlertService',
          function(equipmentResource, globalAlertService) {
            return equipmentResource.equipmentReadyToSellStatusSearchValues.get(
              {},
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ],
        deereUrl: [
          'globalAlertService',
          'oemResource',
          'locationService',
          function(globalAlertService, oemResource, locationService) {
            return oemResource.deereEquipmentUrl.get({
                'siteLocation': location.origin,
                'location': locationService.getDefaultLocation()
              },
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ],
      }
    }
  );
}]);
