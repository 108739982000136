'use strict';

intellidealerAppEquipmentModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.equipment.myRentals.myRentalsDetails', {
      url: '/my-rentals-details/{company}/{division}/{location}/{contractNumber}/{lineNumber}/{dateOut}/{timeOut}/{rentalDuration}',
      views : {
        '@navigation.equipment' : {
          templateUrl: 'app/scripts/modules/equipment/views/my-rentals-details.html',
          controller: 'MyRentalsDetailsController'
        }
      },
      resolve: {
        help: function () {
          return 'Equipment-_Equipment_Details.htm';
        },
        breadcrumb: [
          '$state',
          'myRentalsDetailsWithMultimedia',
          function ($state, myRentalsDetailsWithMultimedia) {
            return myRentalsDetailsWithMultimedia.id.contractNumber;
          }],
        myRentalsDetailsWithMultimedia: [
          'myRentalsDetails',
          'equipmentDetails',
          function (myRentalsDetails, equipmentDetails) {
            if (equipmentDetails) {
              myRentalsDetails.machineSerialNumber = equipmentDetails.machineSerialNumber;
              myRentalsDetails.pictureLocations = equipmentDetails.pictureLocations;
            }
            else {
              myRentalsDetails.pictureLocations = [];
            }
            return myRentalsDetails;
          }
        ],
        equipmentDetails: [
          'myRentalsDetails',
          'equipmentResource',
          'globalAlertService',
          function (myRentalsDetails, equipmentResource, globalAlertService) {
            if (!myRentalsDetails.serialized) {
              return null;
            } else {
              return equipmentResource.equipmentDetails.get(
                {
                  company: myRentalsDetails.id.company,
                  division: myRentalsDetails.id.division,
                  stockNumber: myRentalsDetails.combinedStockPartNumber
                },
                function () {},
                globalAlertService.handleErrorResponse
              ).$promise;
            }
          }
        ],
        myRentalsDetails: [
          'rentalContractsResource',
          'globalAlertService',
          '$stateParams',
          '$filter',
          function(rentalContractsResource, globalAlertService, $stateParams, $filter) {
            return rentalContractsResource.assignedRentalContractDetails.get(
              {
                company: $stateParams.company,
                division: $stateParams.division,
                location: $stateParams.location,
                contractNumber: $filter('pathParam')($stateParams.contractNumber),
                lineNumber: $stateParams.lineNumber,
                dateOut: $stateParams.dateOut,
                timeOut: $stateParams.timeOut,
                rentalDuration: $stateParams.rentalDuration
              },
              function () {},
              globalAlertService.handleErrorResponse
            ).$promise;
          }
        ]
      }
    }
  );
}]);
