'use strict';

intellidealerAppMyAccountModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation.shopping', {
      abstract: true,
      template: '<ui-view class="navigation-content"></ui-view>'
    });

  $stateProvider.state(
    'public.shopping', {
      template: '<ui-view></ui-view>',
      abstract: true
    });
}]);
