'use strict';

intellidealerAppEquipmentModule.controller('MyRentalsDetailsTakeOffRentController', function ($scope, $filter, rentalContractsResource, globalAlertService) {
  $scope.submitModel = {};

  $scope.submit = function() {
    rentalContractsResource.rentalUnitOffRent.save(
      {
        contractLocation: $scope.model.id.location,
        contractNumber: $scope.model.id.contractNumber,
        lineNumber: $scope.model.id.lineNumber
      },
      function(value){
        globalAlertService.addSuccess($filter('language')('mydealer.ui.rental.confirmation_number') + ' : ' + value.returnNumber);
        $scope.configuration.subViews = $filter('filter')($scope.configuration.subViews, {label: '!' + $scope.configuration.selected});
        $scope.configuration.selected = false;
      },
      globalAlertService.handleErrorResponse
    );
  };
});
