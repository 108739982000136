intellidealerAppPartsModule.controller('geolocationController',
  function ($scope, $window, $rootScope, globalAlertService, locationService) {
    $scope.latitude = '';
    $scope.longitude = '';
    $scope.latLongValue = locationService.getGeoLocation() ? locationService.getGeoLocation() : localStorage.getItem('latLongValue');
    $scope.mapModalClose = 'false';
    if ($scope.latLongValue) {
      $scope.latLongValue = $scope.latLongValue && $scope.latLongValue.split(',');
    } else {
      globalAlertService.addError('mydealer.ui.parts.userLocation.zero_result_error');
    }

    $scope.close = function () {
      $window.close();
    };

    $scope.closeButtonLabel = 'Intellidealer.ui.button.close';

    $scope.initMap = function () {
      var lat = '';
      var lng = '';
      var markers = [];
      var imagePath = "images";
      // default lat and long to Chicago to center on north america
      var defaultToChicago = {lat: 41.850033, lng: -87.6500523};

      if ($scope.latLongValue) {
        lat = $scope.latLongValue[0];
      }
      if ($scope.latLongValue) {
        lng = $scope.latLongValue[1];
      }
      $scope.map = new google.maps.Map(document.getElementById('geoLocationMap'), {});
      $scope.map.setOptions({streetViewControl: false});
      // Define address to center map to
      if ((lat != '' && lng != '') && (lat != 0 && lng != 0) && (isFinite(lat) && Math.abs(lat) <= 90) && (isFinite(lng) && Math.abs(lng) <= 180)) {
        try {
          var infowindow = new google.maps.InfoWindow();
          var icon = {
            url: (imagePath + '/maps-person-icon.png'), // url
            scaledSize: new google.maps.Size(50, 50), // scaled size
          };
          var latLng = {lat: Number(lat), lng: Number(lng)};
          var marker, i, j;
          var locations = locationService.getAssignedLocations();
          $scope.map.setZoom(11);
          $scope.map.setCenter(latLng);
          marker = new google.maps.Marker({
            position: new google.maps.LatLng(latLng),
            map: $scope.map,
            icon: icon
          });
          for (i = 0; i < locations.length; i++) {
            if (locations[i].latitude && locations[i].longitude) {
              var storeMarker = new google.maps.Marker({
                position: new google.maps.LatLng(locations[i].latitude, locations[i].longitude),
                label: (locations[i].index).toString(),
                map: $scope.map
              });
              $scope.latitude = locations[i].latitude;
              $scope.longitude = locations[i].longitude;

              google.maps.event.addListener(storeMarker, 'mouseover', (function (storeMarker, i) {
                return function () {
                  var distanceUnit = locations[i].isMiles ? ' miles' : ' kms';
                  infowindow.setContent(`${locations[i].description},${locations[i].postalZipCode},${locations[i].distance + distanceUnit}`);
                  infowindow.open($scope.map, storeMarker);
                }
              })(storeMarker, i));

              google.maps.event.addListener(storeMarker, 'click', (function (storeMarker, i) {
                return function () {
                  locations[i].location && $rootScope.$broadcast('$setMarkerClickDefaultLocation', {"location": locations[i].location});
                  $scope.modalInstance.close();
                }
              })(storeMarker, i));
              //Add marker to the array.
              markers.push(storeMarker);
              $scope.map.setZoom(8);
            }
          }
        } catch (e) {
          $scope.map.setZoom(4);
          $scope.map.setCenter(defaultToChicago);
        }
      }
    };
  }
);


