'use strict';

intellidealerAppEquipmentModule.controller('EquipmentForSaleDetailsInquireController', function ($scope, $rootScope, globalAlertService, equipmentSalesResource) {

  $scope.formValues = {};

  $scope.inquire = function() {
    if ($scope.inquireNowForm.$valid) {
      var params = {
        equipmentStockNumber: $scope.model.equipmentSaleId.stockNumber,
        emailBody: $scope.formValues.description
      };
      if ($rootScope.isPublicState) {
        params.customerName = $scope.formValues.customerName;
        params.customerEmail = $scope.formValues.customerEmail;
        params.customerPhone = $scope.formValues.customerPhone;
      }

      equipmentSalesResource.myDealerEquipmentInquiryEmail.save(params,
        function() {
          globalAlertService.addSuccess('mydealer.ui.global.email_sent');
          $scope.configuration.selected = false;
        },
        globalAlertService.handleErrorResponse
      );
    }
  };
});
